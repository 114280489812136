import React, { Suspense, useEffect, useLayoutEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import moment from 'moment/moment';
import 'moment/locale/ru';
import VKAuth from './auth/VKAuth';
// import 'moment/locale/en';
import { showModal, showNotification } from '../actions/app';
import { unauthorized } from '../actions/auth';
import { requestEnd, requestStart } from '../actions/requests';
import i18n from '../i18n';
import store from '../store';
import { ApiClient } from '../utils/api-client';
import '../utils/element-closest.polyfill';
import { getReferralByUrl } from '../utils/getReferral';
import history from '../utils/history';
import isMobileTablet from '../utils/isMobileTablet';
import { getLimitsErrorsConfig } from '../utils/modal-utils';
import throttle from '../utils/throttle';
import Auth, { authPaths } from './auth/Auth';
import Logout from './auth/Logout';
import TrustedAuth from './auth/TrustedAuth';
import YoomoneyAuth from './auth/YoomoneyAuth';
import YandexAuth from './auth/YandexAuth';
import AppleAuthProvider from './common/utils/AppleAuthProvider';
import ErrorBoundary from './common/utils/ErrorBoundary';
import ModalContainer from './common/utils/ModalContainer';
import NotificationContainer from './common/utils/NotificationContainer';
// import FacebookProvider from './common/utils/FacebookProvider';
import ProgressBarContainer from './common/utils/ProgressBarContainer';
import ThunderBotProvider from './common/utils/ThunderBotProvider';
import WebFontLoader from './common/utils/WebFontLoader';
import LK from './lk/LK';

const fontConfig = {
  classes: false,
  google: {
    families: [
      'Rubik:400,500,700:latin,cyrillic',
      'PT Mono:400:latin,cyrillic',
    ],
  },
};

const brand = getReferralByUrl() || 'teko';

const primaryColorByBrand = {
  atol: '#ED1B2F',
  teko: '#774EEC',
  talktoBusiness: '#774EEC',
};

const theme = {
  primary: primaryColorByBrand[brand] ?? '#774EEC',
  black: '#333',
  error: '#F93A77',
  success: '#06cf7f',
  info: '#774EEC',
  warning: '#ffc900',
};

ApiClient.onRequest = () => {
  store.dispatch(requestStart());
};

ApiClient.onResponse = () => {
  setTimeout(() => store.dispatch(requestEnd()), 50);
};

ApiClient.onUnauthorized = () => {
  store.dispatch(unauthorized());
};

ApiClient.onError = (error) => {
  // i18n.t(`errors.${error.code}2`, { defaultValue: null }) // defaultValue nice by Georgii the Boss
  // ?? error.description ?? error.message ?? JSON.stringify(error)

  if ((error.code >= 1418 && error.code <= 1420) || error.code === 1424) {
    store.dispatch(showModal(getLimitsErrorsConfig(error)));
    return;
  }

  const message = i18n.t([`errors.${error.code}`, 'errors.default']);
  const notification = {
    type: 'error',
    message,
    timeout: 5000,
  };

  store.dispatch(showNotification(notification));
};

// function getUserConfirmation(message, callback) {
//   // TODO: user confirmation
//   console.log(message);
//   callback(false);
// }

function setTrueVhCssVariable() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.document.documentElement.clientHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const throttledSetTrueVhCssVariable = throttle(setTrueVhCssVariable, 50);

// TODO: Suspense on every page?
// TODO: 404

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
  moment.locale(lng);
});

function App() {
  useLayoutEffect(() => {
    setTrueVhCssVariable();
  }, []);

  useEffect(() => {
    const onResize = () => {
      throttledSetTrueVhCssVariable();
    };

    // disable lags onScroll when mobile/tablet browsers trigger resize event because of nav appearing/disappearing
    if (!isMobileTablet()) {
      window.addEventListener('resize', onResize);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundary className="adaptive">
        {/*<FacebookProvider>*/}
        <AppleAuthProvider>
          <Router history={history}>
            {/*<I18nextProvider i18n={i18n}>*/}
            <ThunderBotProvider>
              <ThemeProvider theme={theme}>
                <WebFontLoader config={fontConfig}>

                  <ProgressBarContainer />

                  <NotificationContainer />

                  <ModalContainer />

                  <ErrorBoundary className="adaptive">
                    <Suspense fallback={<div />}>
                      <Switch>
                        <Redirect from="/" to="/lk" exact />
                        <Route path="/logout" component={Logout} />
                        <Route path={authPaths} component={Auth} />
                        <Route path="/trustedauth" component={TrustedAuth} />
                        <Route path="/yoomoneyauth" component={YoomoneyAuth} />
                        <Route path="/yandexauth" component={YandexAuth} />
                        <Route path="/auth/vkLogin" component={VKAuth} />
                        <Route path="/lk" component={LK} />
                        <Redirect to="/lk" />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>

                </WebFontLoader>
              </ThemeProvider>
            </ThunderBotProvider>
            {/*</I18nextProvider>*/}
          </Router>
        </AppleAuthProvider>
        {/*</FacebookProvider>*/}
      </ErrorBoundary>
    </Provider>
  );
}

export default hot(App);
