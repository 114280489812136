import { CodeVerificationInput, Hint } from '@teko/ui-kit';
import classnames from 'classnames';
import { Field, Form, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { registerCodeAsync, registerEmailAsync, yandexLoginAsync, vkLoginAsync } from '../../actions/auth';
import { getAuthRequestExtraData } from '../../utils';
import compose from '../../utils/compose';
import withQueryParams from '../common/hoc/withQueryParams';
import CollapsibleElement from '../common/ui/CollapsibleElement';
import { triggerAllMetricsEvents } from '../common/utils/metrics';

const validationSchema = yup.object().shape({
  code: yup.string().min(6).required('Required'),
});

class RegistrationFormStepTwo extends PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    otpId: PropTypes.string.isRequired,
    regCodeStatus: PropTypes.object.isRequired,
    resendCodeStatus: PropTypes.object.isRequired,
    yandexLoginStatus: PropTypes.object.isRequired,
    vkLoginStatus: PropTypes.object.isRequired,
    registerCode: PropTypes.func.isRequired,
    yandexLogin: PropTypes.func.isRequired,
    vkLogin: PropTypes.func.isRequired,
    resendCode: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    secondsLeft: 30,
    initialError: this.props.regCodeStatus.error,
    errorHintVisible: false,
    disabled: false,
  };

  timerId = 0;
  codeFieldWrapRef = React.createRef();

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate(prevProps) {
    const { regCodeStatus, setSubmitting, resetForm, yandexLoginStatus, vkLoginStatus, params, isNewVKUser } = this.props;
    if (prevProps.regCodeStatus.success !== regCodeStatus.success && regCodeStatus.success) {
      setTimeout(() => {
        this.setState({ disabled: true });
        setSubmitting(false);
        this.props.onStepChange((step) => step + 1);
      }, 1200);
    }
    if ((prevProps.regCodeStatus.error !== regCodeStatus.error && regCodeStatus.error) ||
      (prevProps.yandexLoginStatus.error !== yandexLoginStatus.error && yandexLoginStatus.error) ||
      (prevProps.vkLoginStatus.error !== vkLoginStatus.error && vkLoginStatus.error)) {
      this.setState({ errorHintVisible: true });
      setTimeout(() => {
        resetForm();
        setSubmitting(false);
        this.codeFieldWrapRef.current.querySelector('input').focus();

        setTimeout(() => {
          this.setState({ errorHintVisible: false });
        }, 1000);
      }, 800);
    }
    if (yandexLoginStatus.success) {
      setTimeout(() => {
        this.setState({ disabled: true });
        setSubmitting(false);
        sessionStorage.removeItem('authQueryParams');
        this.props.history.push(params.origin ?? '/lk');
      }, 1200);
    }

    if (vkLoginStatus.success) {
      setTimeout(() => {
        this.setState({ disabled: true });
        setSubmitting(false);
        sessionStorage.removeItem('authQueryParams');
        this.props.history.push(params.origin ?? '/lk');
        if (isNewVKUser) {
          triggerAllMetricsEvents('regvksuccess');
        }
      }, 1200);
    }

    // if (prevProps.resendCodeStatus.success !== resendCodeStatus.success && resendCodeStatus.success) {
    //   this.startTimer();
    // }
  }

  startTimer = () => {
    if (this.state.secondsLeft !== 30) {
      this.setState({ secondsLeft: 30 });
    }

    this.timerId = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.secondsLeft === 1) {
          clearInterval(this.timerId);
        }
        return { secondsLeft: prevState.secondsLeft - 1 };
      });
    }, 1000);
  };

  handleSubmit = (values, formikBag) => {
    if (formikBag.isSubmitting) return;

    this.props.registerCode(values.code);
  };

  handleBackClick = () => {
    this.props.onStepChange((step) => step - 1);
  };

  handleResendClick = () => {
    const { email, resendCodeStatus } = this.props;
    if (resendCodeStatus.loading) return;
    this.startTimer();
    this.props.resendCode({ email, ...getAuthRequestExtraData(false) });
  };

  render() {
    const {
      isSubmitting, submitForm, t, email, regCodeStatus: { loading, error }, yandexLoginStatus, vkLoginStatus, params,
    } = this.props;
    const { secondsLeft, initialError, errorHintVisible, disabled } = this.state;
    const hasError = (error && error !== initialError) || (yandexLoginStatus.error && yandexLoginStatus.error !== initialError) || (vkLoginStatus.error && vkLoginStatus.error !== initialError);
    const inputStateVisible = !loading && !yandexLoginStatus.loading && !vkLoginStatus.loading && isSubmitting;
    const inputState = hasError ? 'invalid' : 'valid';
    return (
      <Form className={classnames('registration-form-step', '_step-two', { 'has-error': hasError })}>
        {params.haveAccount && (
          <div className="registration-hint">
            <Hint type="primary" withIcon={false} title={t('already-exists')}>
              {t('link-accounts')}
            </Hint>
          </div>
        )}
        <div className="registration-form-step-hint">
          <Trans i18nKey="registration-step-two-hint" ns="auth" values={{ email }} />
        </div>

        <div ref={this.codeFieldWrapRef}>
          <Field name="code">
            {({ field }) => (
              <CodeVerificationInput
                {...field}
                disabled={isSubmitting || disabled}
                state={inputStateVisible ? inputState : undefined}
                submitForm={submitForm}
              />
            )}
          </Field>
        </div>

        <div className="check-spam-hint">
          {secondsLeft > 0 && (
            <Trans i18nKey="registration-code-resend-after-seconds" ns="auth" count={secondsLeft}>
              Повторно запросить отправку письма <br />можно через <strong>{{ secondsLeft }} секунд</strong>
            </Trans>
          )}
          {secondsLeft < 1 && (
            <Trans i18nKey="registration-code-resend-request" ns="auth">
              Если письмо не пришло, проверьте папку спам, либо <button type="button" className="resend-link" onClick={this.handleResendClick}>запросите повторную отправку</button> письма
            </Trans>
          )}
        </div>

        <CollapsibleElement active={errorHintVisible}>
          <div className="api-error-wrap">
            <Hint type="error">
              {t('code-is-not-valid')}
            </Hint>
          </div>
        </CollapsibleElement>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  regCodeStatus: state.auth.regCodeStatus,
  resendCodeStatus: state.auth.regEmailStatus,
  yandexLoginStatus: state.auth.yandexLoginStatus,
  otpId: state.auth.registration.id,
  isNewYandexUser: state.auth.isNewYandexUser,
  vkLoginStatus: state.auth.vkLoginStatus,
  isNewVKUser: state.auth.isNewVKUser,
});

const mapDispatchToProps = {
  registerCode: registerCodeAsync,
  resendCode: registerEmailAsync,
  yandexLogin: yandexLoginAsync,
  vkLogin: vkLoginAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withQueryParams,
  withFormik({
    mapPropsToValues: () => ({
      code: '',
    }),
    handleSubmit: (values, formikBag) => {
      if (formikBag.isSubmitting) return;
      const { uuid, otpId, ...authQueryParams } = JSON.parse(sessionStorage.getItem('authQueryParams')) ?? {};
      const socialAuthParams = JSON.parse(sessionStorage.getItem('socialAuthParams')) ?? {};
      const accessToken = authQueryParams?.accessToken || socialAuthParams?.accessToken;

      if (formikBag.props.params.nonEmail) {
        const data = { otpCode: values.code, otpId: formikBag.props.otpId, accessToken, ...getAuthRequestExtraData() };

        if (formikBag.props.params.authType === 'yandex') {
          formikBag.props.yandexLogin(data);
        }
        if (formikBag.props.vkLoginStatus.authType === 'vk') {
          formikBag.props.vkLogin({ ...data, uuid });
        }
      } else if (formikBag.props.params.haveAccount) {
        const data = { otpCode: values.code, otpId, accessToken, ...getAuthRequestExtraData() };
        formikBag.props.vkLogin({ ...data, uuid });
      } else {
        formikBag.props.registerCode(values.code);
        triggerAllMetricsEvents('regstep3');
      }
    },
    validationSchema,
  }),
  withTranslation('auth'),
)(RegistrationFormStepTwo);
