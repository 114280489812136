import { useRef, useState } from 'react';
import useStorableState from './useStorableState';

function usePageReset(search, tableParams, storableKey = '', storage = localStorage) {
  // TODO: refactor
  // pagination bug workaround
  // reset page on search change
  const ref = useRef(search);
  const [page, setPage] = useState(0);
  const [storablePage, setStorablePage] = useStorableState(storableKey, 0, storage);

  if (ref.current !== search) {
    if (storableKey) {
      setStorablePage(0);
    } else {
      setPage(0);
    }
    tableParams.offset = 0;
  }

  ref.current = search;

  return storableKey ? [storablePage, setStorablePage] : [page, setPage];
}

export default usePageReset;
