import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { getUserAsync } from '../../actions/users';
import LKSrc1x from '../../assets/img/auth/platform-select-lk.jpg';
import LKSrc2x from '../../assets/img/auth/platform-select-lk@2x.jpg';
import LKSrcWebp1x from '../../assets/img/auth/platform-select-lk.webp';
import LKSrcWebp2x from '../../assets/img/auth/platform-select-lk@2x.webp';
import TalktoSrc1x from '../../assets/img/auth/platform-select-talkto.png';
import TalktoSrc2x from '../../assets/img/auth/platform-select-talkto@2x.png';
import TalktoSrcWebp1x from '../../assets/img/auth/platform-select-talkto.webp';
import TalktoSrcWebp2x from '../../assets/img/auth/platform-select-talkto@2x.webp';
import redirectToTalkto from '../../utils/redirectToTalkto';
import useReferrerUrls from '../common/hooks/useReferrerUrls';
import { triggerAllMetricsEvents } from '../common/utils/metrics';

function RegistrationFinalWithPlatformSelect() {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const referrerUrls = useReferrerUrls();

  const handleGoToTalktoClick = useCallback(() => {
    setSelectedPlatform('talkto');
    triggerAllMetricsEvents('regstep5talkto');
  }, []);

  const handleGoToLKClick = useCallback(() => {
    setSelectedPlatform('teko');
    triggerAllMetricsEvents('regstep5teko');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (selectedPlatform === 'teko') {
        dispatch(getUserAsync());
      }

      if (selectedPlatform === 'talkto') {
        redirectToTalkto({ redirectUrl: `${referrerUrls.talkto}/lk` });
      }
    }, 1500);
  }, [selectedPlatform]);

  return (
    <div className={classnames('page-register-final', { [`_selected-${selectedPlatform}`]: !!selectedPlatform })}>
      <button
        type="button"
        className={classnames('platform-button', '_talkto', { _active: selectedPlatform === 'talkto' })}
        onClick={handleGoToTalktoClick}
      >
        <span className="centered-block">
          <span className="platform-title" dangerouslySetInnerHTML={{ __html: t('platform-select.talkto') }} />
          <span className="call-to-action-or-state">{t(selectedPlatform === 'talkto' ? 'logging-in' : 'log-in')}</span>
        </span>
        <span className="platform-illustration">
          <picture>
            <source srcSet={`${TalktoSrcWebp1x} 1x, ${TalktoSrcWebp2x} 2x`} type="image/webp" />
            <img className="auth-illustration" srcSet={`${TalktoSrc1x} 1x, ${TalktoSrc2x} 2x`} src={TalktoSrc1x} alt="Talkto" />
          </picture>
        </span>
      </button>
      <button
        type="button"
        className={classnames('platform-button', '_teko', { _active: selectedPlatform === 'teko' })}
        onClick={handleGoToLKClick}
      >
        <span className="centered-block">
          <span className="platform-title" dangerouslySetInnerHTML={{ __html: t('platform-select.teko') }} />
          <span className="call-to-action-or-state">{t(selectedPlatform === 'teko' ? 'logging-in' : 'log-in')}</span>
        </span>
        <span className="platform-illustration">
          <picture>
            <source srcSet={`${LKSrcWebp1x} 1x, ${LKSrcWebp2x} 2x`} type="image/webp" />
            <img className="auth-illustration" srcSet={`${LKSrc1x} 1x, ${LKSrc2x} 2x`} src={LKSrc1x} alt="Teko" />
          </picture>
        </span>
      </button>
    </div>
  );
}

export default memo(RegistrationFinalWithPlatformSelect);
