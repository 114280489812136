import { Button, FormFooter } from '@teko/ui-kit';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { hideModal } from '../actions/app';

function LimitsErrorsModalFooter() {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = useCallback(() => {
    dispatch(hideModal());
  }, []);

  const handleLinkClick = useCallback(() => {
    handleClose();
    history.push('/lk/payment');
  }, []);

  return (
    <FormFooter align="right">
      <Button design="neutral" onClick={handleClose}>
        Отменить
      </Button>

      <Button design="primary" onClick={handleLinkClick}>
        Перейти
      </Button>
    </FormFooter>
  );
}

function LimitsErrorsModalDescription({ error }) {
  const { t } = useTranslation();
  return (
    <p>
      { t(`errors.${error.code}`, { currentCount: error.info?.currentCount, subscriptionLimit: error.info?.subscriptionLimit }) }
    </p>
  );
}

export function getLimitsErrorsConfig(error) {
  return {
    id: 1418,
    header: 'Превышен лимит',
    description: <LimitsErrorsModalDescription error={error} />,
    footer: <LimitsErrorsModalFooter />,
  };
}
