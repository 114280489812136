import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/myclients';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const servicesByIdReducer = createReducer({})
  .handleAction(actions.getMyclientsServices.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.services),
  }));

const servicesTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getMyclientsServices.success, (state, { payload }) => ({
    ids: payload.services.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const locationsByIdReducer = createReducer({})
  .handleAction(actions.getMyclientsLocations.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.locations),
  }))
  .handleAction(actions.updateMyclientsLocation.success, (state, { payload }) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      ...payload,
    },
  }));

const locationsTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getMyclientsLocations.success, (state, { payload }) => ({
    ids: payload.locations.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const employeesByIdReducer = createReducer({})
  .handleAction(actions.getMyclientsEmployees.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.employees),
  }))
  .handleAction(actions.updateMyclientsEmployee.success, (state, { payload }) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      ...payload,
    },
  }));

const employeesTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getMyclientsEmployees.success, (state, { payload }) => ({
    ids: payload.employees.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const serviceCategoriesByProjectIdReducer = createReducer({})
  .handleAction(actions.getMyclientsServiceCategories.success, (state, { payload, meta }) => ({
    ...state,
    [meta.projectId]: normalize(payload),
  }))
  .handleAction(actions.createMyclientsServiceCategory.success, (state, { payload, meta: { projectId, ...meta } }) => ({
    ...state,
    [projectId]: {
      ...state[projectId],
      [payload.id]: { id: payload.id, ...meta },
    },
  }))
  .handleAction(actions.updateMyclientsServiceCategory.success, (state, { payload, meta: { projectId, ...meta } }) => ({
    ...state,
    [projectId]: {
      ...state[projectId],
      [payload.id]: { id: payload.id, ...meta },
    },
  }));

const allShortServicesByProjectIdReducer = createReducer({})
  .handleAction(actions.getMyclientsShortServices.success, (state, { payload, meta }) => ({
    ...state,
    [meta.projectId]: normalize(payload),
  }));

const allShortLocationsByProjectIdReducer = createReducer({})
  .handleAction(actions.getMyclientsShortLocations.success, (state, { payload, meta }) => ({
    ...state,
    [meta.projectId]: normalize(payload),
  }));

const allShortEmployeesByProjectIdReducer = createReducer({})
  .handleAction(actions.getMyclientsShortEmployees.success, (state, { payload, meta }) => ({
    ...state,
    [meta.projectId]: normalize(payload),
  }));

const schedulesByIdReducer = createReducer({})
  .handleAction(actions.getMyclientsSchedules.success, (state, { payload }) => normalize(payload));

const widgetsByIdReducer = createReducer({})
  .handleAction(actions.getMyclientsWidgets.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.widgets),
  }))
  .handleAction([
    actions.createMyclientsWidget.success,
    actions.updateMyclientsWidget.success,
  ], (state, { payload, meta: { projectId, ...meta } }) => ({
    ...state,
    [projectId]: {
      ...state[projectId],
      [payload.id ?? meta.id]: meta,
    },
  }));

const widgetsTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getMyclientsWidgets.success, (state, { payload }) => ({
    ids: payload.widgets.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const mountedWidgetStagesReducer = createReducer({ employee: true, locations: true })
  .handleAction(actions.setMountedWidgetStages, (state, { payload }) => ({
    ...state,
    [payload]: false,
  }));

const myclientsUrl = process.env.MYCLIENTS_URL;
const tmpUrlReducer = createReducer(null)
  .handleAction(actions.getMyclientsTmpUrl.success, (state, { payload }) => `${myclientsUrl}/${payload.id}`);

export default combineReducers({
  services: combineReducers({
    byId: servicesByIdReducer,
    table: servicesTableReducer,
    allShortByProjectId: allShortServicesByProjectIdReducer,
    categoriesByProjectId: serviceCategoriesByProjectIdReducer,
    getFetchStatus: asyncReducerFactory(actions.getMyclientsServices),
    getAllShortFetchStatus: asyncReducerFactory(actions.getMyclientsShortServices),
    getCategoriesFetchStatus: asyncReducerFactory(actions.getMyclientsServiceCategories),
    createFetchStatus: asyncReducerFactory(actions.createMyclientsService),
    updateFetchStatus: asyncReducerFactory(actions.updateMyclientsService),
    deleteFetchStatus: asyncReducerFactory(actions.deleteMyclientsService),
    createCategoryFetchStatus: asyncReducerFactory(actions.createMyclientsServiceCategory),
    updateCategoryFetchStatus: asyncReducerFactory(actions.updateMyclientsServiceCategory),
    deleteCategoryFetchStatus: asyncReducerFactory(actions.deleteMyclientsServiceCategory),
  }),
  locations: combineReducers({
    byId: locationsByIdReducer,
    table: locationsTableReducer,
    allShortByProjectId: allShortLocationsByProjectIdReducer,
    getFetchStatus: asyncReducerFactory(actions.getMyclientsLocations),
    getAllShortFetchStatus: asyncReducerFactory(actions.getMyclientsShortLocations),
    createFetchStatus: asyncReducerFactory(actions.createMyclientsLocation),
    updateFetchStatus: asyncReducerFactory(actions.updateMyclientsLocation),
    archiveFetchStatus: asyncReducerFactory(actions.archiveMyclientsLocation),
  }),
  employees: combineReducers({
    byId: employeesByIdReducer,
    table: employeesTableReducer,
    allShortByProjectId: allShortEmployeesByProjectIdReducer,
    getFetchStatus: asyncReducerFactory(actions.getMyclientsEmployees),
    getAllShortFetchStatus: asyncReducerFactory(actions.getMyclientsShortEmployees),
    createFetchStatus: asyncReducerFactory(actions.createMyclientsEmployee),
    updateFetchStatus: asyncReducerFactory(actions.updateMyclientsEmployee),
    archiveFetchStatus: asyncReducerFactory(actions.archiveMyclientsEmployee),
  }),
  schedules: combineReducers({
    byId: schedulesByIdReducer,
    getFetchStatus: asyncReducerFactory(actions.getMyclientsSchedules),
    createFetchStatus: asyncReducerFactory(actions.createMyclientsSchedule),
    updateFetchStatus: asyncReducerFactory(actions.updateMyclientsSchedule),
    archiveByEntityStatus: asyncReducerFactory(actions.archiveMyclientsScheduleByEntity),
  }),
  widgets: combineReducers({
    byId: widgetsByIdReducer,
    table: widgetsTableReducer,
    mountedStages: mountedWidgetStagesReducer,
    tmpUrl: tmpUrlReducer,
    getFetchStatus: asyncReducerFactory(actions.getMyclientsWidgets),
    createFetchStatus: asyncReducerFactory(actions.createMyclientsWidget),
    updateFetchStatus: asyncReducerFactory(actions.updateMyclientsWidget),
    deleteFetchStatus: asyncReducerFactory(actions.deleteMyclientsWidget),
    checkAliasFetchStatus: asyncReducerFactory(actions.checkMyclientsWidgetAlias),
    getTmpUrlFetchStatus: asyncReducerFactory(actions.getMyclientsTmpUrl),
  }),
  connectFetchStatus: asyncReducerFactory(actions.connectMyclients),
});
