import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletsAsync } from '../../../actions/companies';
import { getEmployeesAsync } from '../../../actions/employees';
import { currentEmployeesSelector } from '../../../selectors/employees';
import useGlobalMemo from './useGlobalMemo';

function useEmployeesFilter() {
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.app.currentCompanyId);
  const outlets = useSelector((state) => state.companies.outletsByCompany);
  const employees = useSelector(currentEmployeesSelector);
  const loading = useSelector((state) => state.employees.fetchStatus.loading || state.companies.outletsFetchStatus.loading);

  useEffect(() => {
    let params;
    if (companyId && companyId !== 'all') {
      params = { companyId };
    }
    dispatch(getEmployeesAsync(params));
  }, [companyId]);
  useEffect(() => dispatch(getOutletsAsync()), []);

  return useGlobalMemo(
    'useEmployeesFilter',
    () => ({
      id: 'cashiers',
      label: 'Кассиры',

      items: employees.map((employee) => {
        const { id, login, firstName, lastName, middleName, companyId, outletId } = employee;
        const fio = `${lastName} ${firstName} ${middleName}`;
        const outlet = outlets[companyId]?.[outletId]?.fullName ?? '';

        return {
          id: `${id}`,
          label: (
            <>
              <span className="filter-option-main">{fio}</span>
              <span className="filter-option-secondary">{outlet}</span>
            </>
          ),
          search: [firstName, middleName, lastName, id, outlet, login],
          badge: fio,
          height: 58,
        };
      }),
      loading,
    }),
    [employees, outlets, loading],
  );
}

export default useEmployeesFilter;
