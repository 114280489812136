import React from 'react';
import classnames from 'classnames';
import CollapsibleElement from './CollapsibleElement';

function FieldError({ shown, message, children }) {
  return (
    <CollapsibleElement className="collapsible-field-error" active={shown}>
      {shown && (
        <p className={classnames('field-hint _red', { _visible: shown })}>
          {children || message}
        </p>
      )}
    </CollapsibleElement>
  );
}

export default FieldError;
