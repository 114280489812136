import { useEffect, useMemo, useState } from 'react';

const empty = [];

function useDateMemo(create, inputs = empty) {
  const [date, setDate] = useState(new Date().getDate());

  useEffect(() => {
    const intervalID = setInterval(() => {
      const dt = new Date().getDate();

      if (dt !== date) {
        if (process.env.NODE_ENV === 'development') console.info(`[useDateMemo] Date changed ${date} => ${dt}`);
        setDate(dt);
      }
    }, 60 * 1000);

    return () => clearInterval(intervalID);
  }, [date]);

  return useMemo(create, [date, ...inputs]);
}

export default useDateMemo;
