import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

const noop = () => {};

export const PageContext = React.createContext({});

/**
 * @param {string} [className]
 * @param {string} [title]
 * @param {string} [titleTKey]
 */
function usePage({ className, title, titleTKey: _titleTKey = '' }) {
  const { setTitle } = useContext(PageContext);

  const titleTNs = _titleTKey.split(':')[0];
  const titleTKey = _titleTKey.split(':')[titleTNs ? 1 : 0];
  const { t } = useTranslation(titleTNs || 'common');

  useLayoutEffect(
    () => {
      if (!className) return noop;

      document.body.classList.add(...className.split(' '));

      return () => document.body.classList.remove(...className.split(' '));
    },
    [className, title],
  );

  useEffect(() => {
    if (!title && !titleTKey) return noop;

    setTitle(titleTKey ? t(`${titleTKey}`) : title);

    return () => setTitle('');
  }, [title]);
}

export default usePage;
