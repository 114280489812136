import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaycafeCategoriesAsync } from '../../../actions/dictionaries';

const selector = (state) => state.dictionaries.paycafeCategories;

/**
 * @returns {PaycafeCategoryModel[]}
 */
function usePaycafeCategories() {
  const dispatch = useDispatch();
  const products = useSelector(selector);

  useEffect(() => dispatch(getPaycafeCategoriesAsync()), []);

  return products;
}

export default usePaycafeCategories;
