import { useEffect } from 'react';

export default function usePassQueryToSessionStorage(params) {
  useEffect(() => {
    const sortedParams = Object.entries(params).reduce((acc, [key, value]) => {
      const formattedValue = Array.isArray(value) ? value.join(',') : value;

      if (key === 'service' || key.startsWith('utm_')) {
        if (!acc.utmInfo) acc.utmInfo = {};
        acc.utmInfo[key.replace('utm_', '')] = formattedValue;
      } else {
        acc[key] = formattedValue;
      }
      return acc;
    }, {});

    const oldParams = JSON.parse(sessionStorage.getItem('authQueryParams'));
    sessionStorage.setItem('authQueryParams', JSON.stringify({
      ...oldParams,
      ...sortedParams,
      utmInfo: { ...oldParams?.utmInfo, ...sortedParams.utmInfo },
    }));
  }, []);
}
