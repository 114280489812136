import { triggerYMetrics } from '@common/utils/metrics';
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
// import MockAdapter from 'axios-mock-adapter';
import { stringify } from 'qs';
import { getOrigin } from './index';
import { isAuthVersion } from './isAuthVersion';

export const defaultErrorDesc = 'Ошибка при выполнении запроса. Попробуйте повторить запрос позднее или обратитесь к менеджеру.';

axios.defaults.baseURL = process.env.BACKEND_URL;
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = (params) => stringify(params, { arrayFormat: 'comma' });

axios.interceptors.request.use((config) => {
  ApiClient.onRequest();
  return config;
});

axios.interceptors.response.use(
  (response) => {
    ApiClient.onResponse();

    if (response.data instanceof Blob) return response;

    if (typeof response.data.success === 'undefined') {
      return response.data;
    }

    if (!response.data.success) {
      const code = response.data.error?.code;
      const info = response.data.error?.info;

      if ((code === 401 || code === 1010) && !response.config.noUnauthorize) {
        ApiClient.onUnauthorized();
        // } else if (Math.trunc((code ?? 0) / 100) === 5 || code === -1) {
        if (!isAuthVersion() && info?.auth && process.env.NODE_ENV !== 'development') {
          const origin = getOrigin();
          const qsParams = { redirectUrl: window.location.href };
          if (!origin.includes('business') && !origin.includes('dashboard') && !origin.includes('loki')) {
            qsParams.service = 'talkto';
          }
          // TODO: договориться с бэком чтобы в info.auth был урл уже с гет параметром service?
          window.location.href = `${info.auth}/login?${stringify(qsParams)}`;
        }
      } else if (!response.config.noNotification && (code >= 1000 || code === -1 || Math.trunc((code ?? 0) / 100) === 5)) {
        ApiClient.onError({ code, description: defaultErrorDesc, info });
        // ApiClient.onError(code >= 1000 ? response.data.error : { code: -1, description: defaultErrorDesc });
      }

      const { config } = response;
      const errorData = {
        endPoint: `${config.method.toUpperCase()} ${config.baseURL + config.url} ${response.status}`,
        response: JSON.stringify(response.data?.error),
      };

      if (code !== 401) triggerYMetrics('requestError', { error: errorData });

      return Promise.reject(response.data.error);
    }

    return response.data.result;
  },
  (error) => {
    ApiClient.onResponse();
    const { config, response } = error;
    const errorData = {
      endPoint: `${config.method.toUpperCase()} ${config.baseURL + config.url} ${response.status}`,
      response: JSON.stringify(response.data?.error),
    };

    // eslint-disable-next-line no-console
    console.log(error);

    if (typeof error.code !== 'undefined') {
      // api error
    } else {
      // http error
      // eslint-disable-next-line no-lonely-if
      if (error.response?.status === 401 && !error.response?.config?.noUnauthorize) {
        ApiClient.onUnauthorized();
      } else if (!error.response?.config?.noNotification) {
        ApiClient.onError(error);
      }
    }

    if (response.status !== 401) triggerYMetrics('requestError', { error: errorData });

    return Promise.reject(error);
  },
);

// if (process.env.NODE_ENV === 'development') {
//   const mock = new MockAdapter(axios);
//   const txsChildrenUrl = new RegExp('/monitoring/transactions/.*/children');
//
//   mock
//     .onGet(txsChildrenUrl)
//     .replyOnce(500)
//     .onGet(txsChildrenUrl)
//     .replyOnce(200, { success: true, result: { txHeaders: [], t: Date.now() } })
//     .onGet(txsChildrenUrl)
//     .reply(200, {
//       success: true,
//       result: {
//         txHeaders: [
//           {
//             dealer: 'temik',
//             id: 'xxx111xxx222xxx333',
//             inPO: { amount: 1000000, currency: 643, exp: 2 },
//             outPO: { amount: 1000000, currency: 643, exp: 2 },
//             outlet: 'artemii_wallet',
//             product: 'babki',
//             source: 'artemii_wallet',
//             startT: 1616155597082,
//             status: 'success',
//           },
//           {
//             dealer: 'temik',
//             id: 'xxx333xxx222xxx111',
//             inPO: { amount: 1000000, currency: 643, exp: 2 },
//             outPO: { amount: 1000000, currency: 643, exp: 2 },
//             outlet: 'artemii_wallet',
//             product: 'babki',
//             source: 'artemii_wallet',
//             startT: 1616155597082,
//             status: 'success',
//           },
//         ],
//         t: Date.now(),
//       },
//     })
//     .onAny().passThrough();
// }

/**
 * @typedef {Object} ApiError
 */
/**
 * @typedef {Object} PaginationParam
 */
/**
 * @typedef {Object} SortParam
 * @property {string} sort
 * @property {string} order
 */
/**
 * @typedef {Object} MoneyAmount
 * @property {number} amount
 * @property {number} currency
 * @property {number} exp
 */
/**
 * @typedef {'info'|'contract'|'kyc'|'infoInProgress'|'kycInProgress'|'contractInProgress'|
 * 'pending'|'accepted'|'blocked'|'declined'} CompanyStatus
 */

export const ApiClient = {
  onUnauthorized: () => {},
  onError: () => {},
  onRequest: () => {},
  onResponse: () => {},

  get baseURL() {
    return axios.defaults.baseURL;
  },

  auth: {
    /**
     * @param {Object} data
     * @param {string} [data.username]
     * @param {string} [data.password]
     * @param {string} [data.token]
     * @param {string} [data.signature]
     * @param {'password'|'trustedAuth'} data.type
     * @returns {Promise<any>}
     */
    login(data) {
      // return HttpClient.post('/auth/login', data);
      return axios.post('/auth/login', data, {
        noNotification: true,
        noUnauthorize: true,
      });
    },

    /**
     * @returns {Promise<any>}
     */
    logout() {
      return axios.post('/auth/logout');
    },

    /**
     * @param {{ email: string; [service]: string; [redirectUrl]: string }} data
     * @returns {Promise<any>}
     */
    recoverPass(data) {
      return axios.post('/registration/recoverPassword', data, {
        noNotification: true,
      });
    },

    /**
     * @param {string} token
     * @returns {Promise<any>}
     */
    checkResetPasswordToken(token) {
      return axios.post('/registration/checkToken', { token }, {
        noNotification: true,
      });
    },

    /**
     * @param {string} token
     * @param {string} password
     * @returns {Promise<any>}
     */
    resetAndChangePassword({ token, password }) {
      return axios.post('/registration/changePassword', { token, password }, {
        noNotification: true,
      });
    },

    /**
     * TODO: confirm
     * @param {any} data
     * @returns {Promise<any>}
     */
    confirm(data) {
      return axios.post('/auth/confirm', data);
    },

    /**
     * @param {Object} data
     * @param {string} data.email
     * @returns {Promise<any>}
     */
    registrationEmail(data) {
      return axios.post('/registration/sendOTP', data, { noNotification: true });
    },

    /**
     * @param {Object} data
     * @param {string} data.code
     * @param {string} data.id
     * @returns {Promise<any>}
     */
    registrationCode(data) {
      return axios.post('/registration/confirmOTP', data, { noNotification: true });
    },

    /**
     * @param {Object} data
     * @param {string} data.password
     * @param {string} data.id
     * @returns {Promise<any>}
     */
    registrationPass(data) {
      return axios.post('/registration/password', data);
    },

    googleLogin(data) {
      return axios.post('/auth/googleLogin', data, { noNotification: true });
    },

    facebookLogin(data) {
      return axios.post('/auth/facebookLogin', data, { noNotification: true });
    },

    appleLogin(data) {
      return axios.post('/auth/appleLogin', data, { noNotification: true });
    },

    yandexLogin(data) {
      return axios.post('/auth/yandexLogin', data, { noNotification: true });
    },

    vkLogin(data) {
      return axios.post('/auth/vkLogin', data, { noNotification: true });
    },

    /**
     * Подсказки логина по ФИО
     * @param {{ firstName: string, lastName: string, patronymic: string }} fullNameData
     * @returns {Promise<{ suggestions: PartySuggestionModel[] }>}
     */
    loginSuggestsByFullName(fullNameData) {
      return axios.post('/registration/loginHints', fullNameData);
    },
  },

  billing: {
    getSubscriptions() {
      return axios.get('/billing/subscriptions');
    },

    getPlansDictionary() {
      return axios.get('/billing/tariffs');
    },

    getPayments(params) {
      return axios.get('/billing/payments', { params });
    },

    getLastPayment(companyId, linkId) {
      return axios.get(`/billing/${companyId}/payment/${linkId}`);
    },

    changeTariff(companyId, data) {
      return axios.post(`/billing/${companyId}/subscription`, data);
    },

    cancelTariff(companyId) {
      return axios.delete(`/billing/${companyId}/subscription`);
    },

    resumeTariff(companyId) {
      return axios.get(`/billing/${companyId}/subscription/resume`);
    },

    cancelChangeTariff(companyId) {
      return axios.delete(`/billing/${companyId}/subscription/tariff/change`);
    },

    getBillingMethods() {
      return axios.get('/billing/billingMethods');
    },

    deleteBillingMethod(id) {
      return axios.delete(`/billing/billingMethods/${id}`);
    },

    bindCard(companyId) {
      return axios.post('/billing/createBinding', { companyId });
    },

    setMainCard({ id, companyId }) {
      return axios.post('/billing/switchBillingMethod', { id, companyId, status: 'primary' });
    },
    getPaymentReceipt({ companyId, linkId }) {
      return axios.get(`/billing/${companyId}/payment/${linkId}/receipt`);
    },
  },

  users: {
    /**
     * @typedef {Object} CurrentUserModel
     * @property {string} id
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} middleName
     * @property {string} email
     * @property {string} phoneNumber
     * @property {string[]} companies
     * @property {string[]} roles
     * @property {boolean} isCrmAdmin
     * @property {boolean} isPrimaryOwner
     * @property {boolean} isTrustedApi
     * @property {boolean} [mfaEnabled]
     * @property {number} createdAt
     * @property {{ id: string, name: string }} [avatarFile]
     * @property {number} [guideFinishedAt]
     * @property {number} [onboardingFinishedAt]
     */
    /**
     * @typedef {Object} CreateUserModel
     * @property {string} id
     * @property {string} email
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} middleName
     * @property {string} phoneNumber
     * @property {string} password
     * @property {string[]} companies
     * @property {string[]} roles
     */
    /**
     * @typedef {Object} UserModel
     * @property {string} id
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} middleName
     * @property {string} email
     * @property {string} phoneNumber
     * @property {string} outletName
     * @property {string} companyName
     * @property {string[]} roles
     * @property {Object} extra
     */
    /**
     * @returns {Promise<UserModel>}
     */
    me() {
      // return HttpClient.get('/users/me');
      return axios.get('/users/me');
    },

    /**
     * @typedef {Object} UsersGetParams
     * @property {SortParam} sort
     * @property {string} [search]
     * @property {number} [offset=0]
     * @property {number} [count=15]
     */
    /**
     * @param {UsersGetParams} params
     * @returns {Promise<{users: UserModel[], totalCount: number}>}
     */
    get({ id, sort = {}, startT: startTime, finishT: endTime, offset = 0, count = 15, ...rest }) {
      let params;

      if (id) {
        params = { id };
      } else {
        params = { ...sort, startTime, endTime, offset, count };
        ['search', 'status'].filter((key) => rest[key]).forEach((key) => { params[key] = rest[key]; });
      }

      return axios.get('/users', { params });
    },

    /**
     * @param {string} id
     * @returns {Promise<UserModel>}
     */
    getById(id) {
      return axios.get(`/users/${id}`);
    },

    /**
     * @param {CreateUserModel} user
     * @returns {Promise<UserModel>}
     */
    create(user) {
      return axios.put('/users', user);
    },

    /**
     * @param {CreateUserModel} user
     * @returns {Promise<UserModel>}
     */
    update(user) {
      return axios.post(`/users/${user.id}`, user);
    },

    /**
     * @param {string} token
     * @returns {Promise<any>}
     */
    checkInviteToken(token) {
      return axios.post('/users/checkInvite', { token }, {
        noNotification: true,
      });
    },
    /**
     * @param {string} token
     * @param {string} password
     * @returns {Promise<any>}
     */
    setPassword({ token, password }) {
      return axios.post('/users/setPassword', { token, password });
    },
    /**
     * @param {string} oldPassword
     * @param {string} newPassword
     * @returns {Promise<any>}
     */
    changePassword({ oldPassword, newPassword }) {
      return axios.post('/users/password', { oldPassword, newPassword });
    },
    invite(user) {
      return axios.put('/users', user);
    },
    inviteAgain(userEmail) {
      return axios.post(`/users/invite/${userEmail}`);
    },
    deleteInvite(userEmail) {
      return axios.delete(`/users/invite/${userEmail}`);
    },
    getInvites(params) {
      return axios.get('/users/invites', { params });
    },
    getAvailableUserRoles() {
      return axios.get('/users/roles');
    },
    validateMFA(data) {
      return axios.post('/users/validateMFA', data, {
        noNotification: true,
      });
    },
    disableMFA(data) {
      return axios.post('/users/disableMFA', data, {
        noNotification: true,
      });
    },
    updateCurrent(data) {
      return axios.post('/users/info', data);
    },
    getLoginHistory(params) {
      return axios.get('/users/loginHistory', { params });
    },
    skipQuickStart() {
      return axios.post('/users/guide/skip');
    },
    finishOnboarding() {
      return axios.post('/users/onboarding/finish');
    },
    getReferrerUrls(data) {
      return axios.post('/referral', data);
    },
  },

  notifications: {
    getUserNotifications(params) {
      return axios.get('/notifications', { params, noNotification: true });
    },

    readUserNotification(id) {
      return axios.post(`/notifications/${id}/read`);
    },

    readAllUserNotifications(companyId, startT, finishT) {
      return axios.post('/notifications/read', null, {
        params: { companyId, startT, finishT },
      });
    },
  },

  cashiers: {
    /**
     * @typedef {Object} EmployeeModel
     * @property {string} id
     * @property {string} fullName
     * @property {string} lastName
     * @property {string} middleName
     * @property {string} username
     * @property {string} phoneNumber
     * @property {string[]} roles
     * @property {string} companyName
     * @property {string} outletName
     */
    /**
     * @param {{ search: string, companyId: string }} params
     * @returns {Promise<{employees: EmployeeModel[]}>}
     */
    get(params) {
      return axios.get('/boxretail/cashiers', { params });
    },

    /**
     * @param {EmployeeModel[]} cashiers
     * @returns {Promise<EmployeeModel[]>}
     */
    create(cashiers) {
      return axios.post('/boxretail/cashiers', cashiers);
    },

    /**
     * @param {EmployeeModel} cashier
     * @returns {Promise<EmployeeModel>}
     */
    update(cashier) {
      return axios.put('/boxretail/cashiers', cashier);
    },

    /**
     * @typedef {Object} ChangeEmployeePasswordModel
     * @property {string} id
     * @property {string} password
     * @property {string} companyId
     * @property {string} outletId
     */
    /**
     * @param {ChangeEmployeePasswordModel} changePasswordModel
     * @returns {Promise<EmployeeModel>}
     */
    changePassword(changePasswordModel) {
      return axios.put('/boxretail/cashiers/password', changePasswordModel, { noNotification: true });
    },

    accept(user) {
      return axios.post('/boxretail/cashiers/accept', user);
    },

    getHistory(id) {
      return axios.get('/boxretail/cashiers/history', {
        params: { id },
      });
    },

    checkLogin(login, companyId) {
      return axios.get(`/boxretail/cashiers/${login}/exists/${companyId}`);
    },

    /**
     * Подсказки логина по ФИО
     * @param {{ firstName: string, lastName: string, patronymic: string }} fullNameData
     * @returns {Promise<{ suggestions: PartySuggestionModel[] }>}
     */
    loginSuggestsByFullName(fullNameData) {
      return axios.post('/boxretail/cashiers/loginHints', fullNameData);
    },

    setMigration(migrationData) {
      return axios.post('/boxretail/cashiers/migration', migrationData);
    },
  },

  companies: {

    /**
     * @typedef {Object} ServicesRequestState
     * @property {'accepted' | 'inProgress' | 'pending'} status
     * @property {number} updatedAt
     */
    /**
     * @typedef {Object} CompanyModel
     * @property {string} id
     * @property {string} inn
     * @property {string} name
     * @property {string} address
     * @property {Array<'paycafe_app'|'boxretail_app'|'awiza_app'>} availableApps
     * @property {number} [awizaInstalledAt]
     * @property {number} [myclientsInstalledAt]
     * @property {number} createdAt
     * @property {'inProgress'|'accepted'|'accept'|'blocked'} companyStatus
     * @property {'accepted'|'blocked'|'expired'} status
     * @property {ServicesRequestState} [servicesRequestState]
     * @property {number} subjectsCount
     * @property {'ok'|'expired'|'semiExpired'} subscriptionStatus
     * @property {number} [subscriptionEndTime]
     * @property {number} updatedAt
     * @property {number} usersCount
     */
    /**
     * @returns {Promise<{companies: CompanyModel[]}>}
     */
    get() {
      return axios.get('/projects/list');
    },

    updateContactSettings(id, data) {
      return axios.put(`/projects/${id}/merchant/contact`, data);
    },

    /**
     * @typedef {Object} CompanyBalanceModel
     * @property {number} balance
     * @property {number} limit
     * @property {number} toTransfer
     */
    /**
     * @param {number} id
     * @returns {Promise<CompanyBalanceModel>}
     */
    balance(id) {
      return axios.get(`/companies/${id}/balance`, { noNotification: true });
    },

    /**
     * @typedef {Object} OutletModel
     * @property {string} id
     * @property {string} globalId
     * @property {string} address
     * @property {string} fullName
     * @property {string} phone
     */
    /**
     * @param {{ search: string }} [params]
     * @returns {Promise<{outlets: OutletModel[]}>}
     */
    outlets(params) {
      return axios.get('/boxretail/paymentPoints', { params });
    },

    /**
     * @typedef {Object} CreateOutletModel
     * @property {string} id
     * @property {string} globalId
     * @property {string} address
     * @property {string} fullName
     * @property {string} phone
     * @property {Object<string, string>} extra
     */
    /**
     * @param {CreateOutletModel[]} model
     * @returns {Promise<{}>}
     */
    createOutlets(model) {
      return axios.post('/boxretail/paymentPoints', model);
    },

    /**
     * @typedef {Object} UpdateOutletModel
     * @property {string} id
     * @property {string} globalId
     * @property {string} address
     * @property {string} fullName
     * @property {string} phone
     * @property {Object} extra
     * @property {MerchantCredentialsModel[]} merchants
     */
    /**
     * @param {UpdateOutletModel} model
     * @returns {Promise<{}>}
     */
    updateOutlet(model) {
      return axios.put('/boxretail/paymentPoints', model, { noNotification: true });
    },

    updateOutletPaymentMethods(data) {
      return axios.post('/boxretail/paymentPoints/sources', data, { noNotification: true });
    },

    updateOrderAutoReleaseStatus(data) {
      return axios.post(`/boxretail/paymentPoints/completeOnPaid?id=${data.id}&set=${data.completeOnPaid}`, data);
    },

    // better /companies/outlets/${id}/stock, but this is what we've got now
    /**
     * @typedef {Object} OutletStockItemModel
     * @property {string} barcode
     * @property {number} availableQuantity
     * @property {number} availableCommQuantity
     * @property {number} price
     * @property {number} timestamp
     */
    /**
     * @typedef {Object} OutletStockInfoModel
     * @property {string} posId
     * @property {OutletStockItemModel[]} stock
     */
    /**
     * @typedef {Object} StockRequestParams
     * @property {string|string[]} [posId]
     * @property {string|string[]} [barcode]
     */
    /**
     * @param {StockRequestParams} params
     * @returns {Promise<{stockInfo: OutletStockInfoModel[]}>}
     */
    outletStock(params) {
      return axios.get('/retail/stock', { params });
    },

    /**
     * @typedef {Object} BarcodeItemModel
     * @property {string} id
     * @property {string} name
     * @property {string} category
     */
    /**
     * @param {object} params
     * @returns {Promise<{barcodes: BarcodeItemModel[]}>}
     */
    getRetailProducts(params) {
      return axios.get('/retail/barcodes', { params });
    },

    /**
     * @typedef {Object} BankModel
     * @property {string} id
     * @property {string} fullName
     */
    /**
     * @returns {Promise<{banks: BankModel[]}>}
     */
    banks() {
      return axios.get('/retail/banks');
    },

    /**
     * @typedef {Object} BpaBankModel
     * @property {string} id
     * @property {string} fullName
     */
    /**
     * @returns {Promise<{banks: BpaBankModel[]}>}
     */
    bpaBanks() {
      return axios.get('/monitoring/banks');
    },

    /**
     * @typedef {Object} MerchantModel
     * @property {string} id
     * @property {string} fullName
     * @property {boolean} isBpa
     * @property {Array} requiredParams
     */
    /**
     * @returns {Promise<{merchants: MerchantModel[]}>}
     */
    merchants() {
      return axios.get('/monitoring/merchants');
    },

    /**
     * @typedef {Object} MerchantCredentialsModel
     * @property {string} merchantId
     * @property {string[]} targets
     * @property {boolean} isAccepted
     * @property {Object} requiredParams
     */
    /**
     * @param {string} globalId - company id
     * @returns {Promise<{merchantCredentials: MerchantCredentialsModel[]}>}
     */
    subjectMerchantCredentials(globalId) {
      const params = { globalId };
      return axios.get('/boxretail/subjects/merchantCredentials', { params });
    },

    /**
     * @param {string} globalId - company id
     * @param {string} id - outlet id
     * @returns {Promise<{merchantCredentials: MerchantCredentialsModel[]}>}
     */
    outletMerchantCredentials(globalId, id) {
      const params = { globalId, id };
      return axios.get('/boxretail/paymentPoints/merchantCredentials', { params });
    },

    /**
     * @typedef {Object} AcceptRequestModel
     * @property {string} id - full outlet id (globalId|id)
     * @property {string[]} merchants
     */
    /**
     * @param {AcceptRequestModel} data
     * @returns {Promise<OutletModel>}
     */
    acceptOutlet(data) {
      return axios.post('/boxretail/paymentPoints/accept', data);
    },

    /**
     * @typedef {Object} SchemePaymentModel
     * @property {string} [conc_m]
     * @property {string} [conc_m_fix]
     * @property {string} [conc_m_min]
     * @property {string} [conc_m_max]
     */
    /**
     * @typedef {Object} SchemeFieldModel
     * @property {string} _id
     * @property {string} name
     * @property {Object[]} conditions
     * @property {'string'|'int'|'payment'} tpe
     * @property {{ payment: SchemePaymentModel }|{ common: string }} value
     */
    /**
     * @typedef {Object} SchemeModel
     * @property {string} name
     * @property {SchemeFieldModel[]} fin
     * @property {SchemeFieldModel[]} tech
     */
    /**
     * @typedef {Object} SchemeRequestParams
     * @property {string} target
     * @property {string} merchant
     * @property {string} [initiator] - dealer
     * @property {string} [provider] - outlet
     * @property {string} source
     */
    /**
     * @param {SchemeRequestParams} params
     * @returns {Promise<{schemes: SchemeModel[]}>}
     */
    getSchemes(params) {
      return axios.get('/boxretail/schemes', { params });
    },

    /**
     * @typedef {Object} SaveSchemeModel
     * @property {SchemeModel} scheme
     * @property {string} schemeId
     * @property {string} circleIdWithScheme
     * @property {boolean} goUp
     */
    /**
     * @param {SaveSchemeModel} data
     * @returns {Promise<*>}
     */
    saveScheme(data) {
      return axios.post('/companies/scheme', data);
    },

    /**
     * @typedef {Object} ProductModel
     * @property {string} id
     * @property {string} name
     * @property {string} cat
     * @property {string[]} merchant
     */
    /**
     * @param {string} merchant
     * @returns {Promise<{ products: ProductModel[] }>}
     */
    getProductsByMerchant(merchant) {
      const params = { merchant };
      return axios.get('/monitoring/productsByMerchant', { params });
    },

    /**
     * @typedef {Object} SaveRatesRequestModel
     * @property {string} target
     * @property {string} merchant
     * @property {string} initiator
     * @property {string} source
     * @property {Object} ratesData
     */
    /**
     * @param {SaveRatesRequestModel} data
     * @returns {Promise<*>}
     */
    saveRates(data) {
      return axios.post('/companies/rates', data);
    },

    /**
     * @typedef {Object} CalculateRequestParams
     * @property {string} target
     * @property {string} initiator
     * @property {string} scheme
     * @property {string} merchant
     * @property {number} amount
     * @property {string} [account]
     */
    /**
     * @param {CalculateRequestParams} params
     * @returns {Promise<*>}
     */
    calculate(params) {
      return axios.get('/boxretail/calculate', { params });
    },

    getProviders(source) {
      return axios.get('/monitoring/providersBySource', {
        params: { source },
      });
    },

    createProject(model, FINGERPRINT) {
      return axios.post('/projects', model, { noNotification: true, headers: { FINGERPRINT } });
    },

    updateProject(model) {
      return axios.put('/projects', model);
    },

    updateCompany(model) {
      return axios.put('/companies', model);
    },

    /**
     * @param {string} id
     * @param {string} password
     * @param {string} FINGERPRINT
     */
    deleteProject(id, password, FINGERPRINT) {
      return axios.delete('/projects', {
        data: { id, password },
        headers: { FINGERPRINT },
      });
    },

    /**
     * @typedef {Object} AcceptModel
     * @property {string} id - companyId
     * @property {CompanyStatus} status
     * @property {Object} notes
     */
    /**
     * @param {AcceptModel} model
     * @returns {Promise<*>}
     */
    acceptCompany(model) {
      return axios.post('/companies/accept', model);
    },

    /**
     * @typedef {Object} AddressModel
     * @property {string} address
     * @property {string} fiasId
     * @property {string} [country]
     * @property {string} [city]
     * @property {string} [street]
     * @property {string} [house]
     * @property {string} [block]
     * @property {string} [flat]
     */
    /**
     * @typedef {Object} BoxretailServiceMerchantStateModel
     * @property {string} id
     * @property {string} status
     */
    /**
     * @typedef {Object} BoxretailServiceModel
     * @property {string} id
     * @property {number} updatedAt
     * @property {Array<BoxretailServiceMerchantStateModel>} [merchants]
     */
    /**
     * @typedef {Object} FilesOfContractModel
     * @property {string} id
     * @property {string} name
     * @property {string} title
     */
    /**
     * @typedef {Object} ContractInfoModel
     * @property {Array<FilesOfContractModel>} filesOfContracts
     * @property {Object<string, { id: string, name: string }>} filesOfContractPhotocopies
     */
    /**
     * @typedef {Object} BoxretailServiceMerchantModel
     * @property {string} id
     * @property {string} status
     * @property {ContractInfoModel} contractInfo
     */
    /**
     * @typedef {Object} ProjectModel
     * @property {string} id
     * @property {string} name
     * @property {string} country
     * @property {string} contactPersonName
     * @property {string} contactPersonPhone
     */
    /**
     * @typedef {Object} CompanyInfoModel
     * @property {string} id
     * @property {string} accountNumber
     * @property {string} bankAccountNumber
     * @property {string} bankBic
     * @property {string} bankName
     * @property {string} contactPersonName
     * @property {string} contactPersonPhone
     * @property {string} country
     * @property {string} inn
     * @property {string} name
     * @property {Object} notes
     * @property {string} registrationType
     * @property {string} [registrationOrg]
     * @property {string} [registrationDate]
     * @property {string} [registrationId]
     * @property {string[]} [mainActivities]
     * @property {AddressModel} legalAddress
     * @property {AddressModel} actualAddress
     * @property {AddressModel} correspondentAddress
     * @property {ContractInfoModel} contractInfo
     * @property {CompanyStatus} status
     * @property {Array<BoxretailServiceModel>} [services]
     * @property {Object<string, BoxretailServiceMerchantModel>} [serviceMerchants]
     * @property {Object<string, string>} notes
     * @property {KYCModel} [kycInfo]
     */
    getCompanyInfo(id) {
      // TODO: после удаления "/subjects" этот endpoint перестал работать, вопросы к Артему
      return axios.get('/projects', {
        params: { id },
      });
    },

    /**
     * @typedef {Object} CompanyHistoryModel
     */
    getProjectHistory(id) {
      return axios.get('/projects/history', {
        params: { id },
      });
    },

    /**
     * @typedef {Object} OutletHistoryModel
     */
    getOutletHistory(id) {
      return axios.get('/boxretail/paymentPoints/history', {
        params: { id },
      });
    },

    acceptKYC(model) {
      return axios.post('/companies/kyc/accept', model);
    },

    /**
     * @typedef {Object} LicenseModel
     * @property {string} issueBy
     * @property {string} issueDate
     * @property {string} number
     * @property {string} period
     * @property {string} type
     * @property {string[]} typesOfLicensedActivities
     */
    /**
     * @typedef {Object} FIOModel
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} middleName
     */
    /**
     * @typedef {Object} PersonModel
     * @property {FIOModel} fio
     * @property {string} citizenship
     * @property {string} birthDate
     * @property {string} birthPlace
     * @property {string} passportIssuedBy
     * @property {string} passportSerial
     * @property {string} passportNumber
     * @property {string} issueDate
     * @property {string} passportIssuedId
     */
    /**
     * @typedef {Object} KYCModel
     * @property {string} companyId
     * @property {PersonModel[]} beneficiaries
     * @property {PersonModel[]} beneficiariesOwners
     * @property {string} collegialExecutive
     * @property {FIOModel[]} collegialExecutiveMembers
     * @property {boolean} contactPersonAtLegalAddress
     * @property {string} fundsOrigin
     * @property {string} generalMeeting
     * @property {FIOModel[]} generalMeetingMembers
     * @property {boolean} isRegisteredAfterFirstJuly2002
     * @property {LicenseModel[]} licenseInfo
     * @property {FIOModel} soleExecutiveBodyFio
     * @property {string} soleExecutiveBodyPosition
     * @property {string} sourceOfFunds
     * @property {FIOModel[]} supervisoryBoardMembers
     * @property {string} supervisoryBoard
     */
    /**
     * @param {KYCModel} model
     * @returns {Promise<*>}
     */
    sendKYC(model) {
      return axios.post('/companies/kyc', model);
    },

    /**
     * @param {string} companyId
     * @param {FormData} model
     * @returns {Promise<*>}
     */
    sendFiles(companyId, model) {
      return axios.post('/companies/files', model, {
        params: { companyId },
      });
    },

    /**
     * @param {object} model
     * @returns {Promise<*>}
     */
    sendContract(model) {
      return axios.post('/companies/contract', model);
    },

    /**
     * @param {FormData} model
     * @param {string} companyId
     * @param {string} type
     * @returns {Promise<*>}
     */
    saveMultipleOutlets(model, companyId, type = 'boxretail') {
      return axios.post(`/${type}/paymentPoints/multi`, model, {
        params: { companyId },
      });
    },

    /**
     * @param {FormData} model
     * @param {string} companyId
     * @param {string} type
     * @returns {Promise<*>}
     */
    saveMultipleEmployees(model, companyId, type = 'boxretail') {
      return axios.post(`/${type}/employees/multi`, model, {
        params: { companyId },
      });
    },

    /**
     * @typedef {Object} SecretKeys
     * @property {string} full
     * @property {string} masked
     */
    /**
     * @property {string} id - companyId
     * @returns {Promise<SecretKeys>}
     */
    getSecretKey(id) {
      return axios.get('/companies/secret', { params: { id } });
    },

    /**
     * @property {string} companyId
     * @property {string} password
     * @returns {Promise<SecretKeys>}
     */
    refreshSecretKey(companyId, password) {
      return axios.post('/companies/secret', { companyId, password });
    },

    /**
     * @property {string} companyId
     * @property {string} password
     * @returns {Promise<*>}
     */
    deleteSecretKey(companyId, password) {
      return axios.delete('/companies/secret', {
        data: { companyId, password },
      });
    },

    downloadSecretKeyFile(model) {
      return axios.post('/companies/download_secret', model);
    },

    /**
     * @typedef {Object} FileOfContract
     * @property {string} id
     * @property {string} name
     * @property {string} title
     */
    /**
     * @returns {Promise<FileOfContract[]>}
     */
    getContracts() {
      return axios.get('/companies/contract/list');
    },

    editContract(data) {
      return axios.post('/companies/contract/edit', data);
    },

    getPayments() {
      return axios.get('/billing/payments');
    },

    getLastPayment(companyId) {
      return axios.get(`/billing/lastPayment/${companyId}`);
    },

    getInvoiceUrl(companyId) {
      return axios.get(`/billing/invoice/${companyId}`);
    },

    getAppsConfig() {
      return axios.get('/billing/tariff/etalon');
    },

    getTariffConfigs(companyId) {
      return axios.get(`/billing/${companyId}/tariffs`);
    },

    tariffAdditionalPrice(companyId, data) {
      /**
       * @typedef {object[]} additionalPrice
      /**
       * @returns {Promise<additionalPrice>}
       */
      return axios.post(`/billing/${companyId}/payment/additional`, data);
    },

    changeSubscriptionPaymentMethod(data) {
      return axios.post('/billing/payments/method', data);
    },

    createInvoice(data) {
      return axios.put('/billing/invoice/create', data);
    },
  },

  analytics: {
    /**
     * @typedef {Object} TxHeaderModel
     * @property {string} id
     * @property {string} status
     * @property {string} outlet
     * @property {string} product
     * @property {MoneyAmount} inPO
     * @property {MoneyAmount} outPO
     * @property {number} startT
     */
    /**
     *
     * @returns {Promise<{txHeaders: TxHeaderModel[]}>}
     */
    transactions(params) {
      return axios.get('/monitoring/transactions', {
        params,
      });
    },

    /**
     * @param {string} id
     * @returns {Promise<TxHeaderModel>}
     */
    transaction(id) {
      return axios.get(`/monitoring/transactions/${id}`);
    },

    /**
     * @param {string} id
     * @returns {Promise<TxHeaderModel>}
     */
    transactionChildren(id) {
      return axios.get(`/monitoring/transactions/${id}/children`, {
        noNotification: true,
      });
    },

    /**
     * @param {{ id: string, password: string }} data
     * @returns {Promise<*>}
     */
    rollbackTransaction({ id, password }) {
      return axios.post(`/monitoring/transactions/${id}/rollback`, { password }, {
        noNotification: true,
      });
    },

    /**
     * @typedef {Object} BreakdownRequest
     * @property {number} startT
     * @property {number} finishT
     * @property {string[]} subjects
     * @property {'source'|'destination'|'initiator'} mode
     * @property {'amount'|'count'} orderBy
     */
    /**
     * @typedef {Object} BreakdownModel
     * @property {string} id
     * @property {string} name
     * @property {number} count
     * @property {number} amount
     */
    /**
     * @param {BreakdownRequest} params
     * @param {'realtime'|'historical'} type
     * @returns {Promise<{breakdown: BreakdownModel}>}
     */
    breakdown(params, type) {
      return axios.get(`/monitoring/${type}/breakdown`, { params, noNotification: process.env.APP_ENV === 'development' });
    },

    /**
     * @typedef {Object} ChartRequest
     * @property {number} startT
     * @property {number} finishT
     * @property {string[]} subjects
     */
    /**
     * @param {ChartRequest} params
     * @param {'realtime'|'historical'} type
     * @returns {Promise<*>}
     */
    chart(params, type) {
      return axios.get(`/monitoring/${type}/chart`, { params, noNotification: process.env.APP_ENV === 'development' });
    },

    supportChart(params, type) {
      return axios.get(`/monitoring/${type}/support/chart`, { params, noNotification: process.env.APP_ENV === 'development' });
    },

    updateChart(settings) {
      return axios.post('/monitoring/realtime/support/chart', settings);
    },

    deleteChart(params, type) {
      return axios.delete(`/monitoring/${type}/support/chart`, { params, noNotification: process.env.APP_ENV === 'development' });
    },

    /**
     * @returns {Promise<ProductModel[]>}
     */
    getProducts() {
      return axios.get('/monitoring/products');
    },

    /**
     * @typedef {Object} SourceModel
     * @property {number} id
     * @property {number} fullName
     */
    /**
     * @returns {Promise<{ sources: SourceModel[] }>}
     */
    getSources() {
      return axios.get('/monitoring/sources');
    },

    getKeyIndicatorsSummary(params) {
      return axios.get('/monitoring/summary/chart', { params });
      // return new Promise((res) => {
      //   setTimeout(() => {
      //     res([{
      //       currency: 643,
      //       exp: 2,
      //       data: {
      //         avgBill: [
      //           { atom: { amount: 400000 }, timestamp: Date.now() - 1000 * 60 * 60 * 4 },
      //           { atom: { amount: 902500 }, timestamp: Date.now() - 1000 * 60 * 60 * 3 },
      //           { atom: { amount: 575000 }, timestamp: Date.now() - 1000 * 60 * 60 * 2 },
      //           { atom: { amount: 675000 }, timestamp: Date.now() - 1000 * 60 * 60 },
      //         ],
      //         income: [
      //           { atom: { amount: 900000 }, timestamp: Date.now() - 1000 * 60 * 60 * 4 },
      //           { atom: { amount: 400000 }, timestamp: Date.now() - 1000 * 60 * 60 * 3 },
      //           { atom: { amount: 1250000 }, timestamp: Date.now() - 1000 * 60 * 60 * 2 },
      //           { atom: { amount: 850000 }, timestamp: Date.now() - 1000 * 60 * 60 },
      //         ],
      //         paid: [
      //           { atom: { amount: 1500000 }, timestamp: Date.now() - 1000 * 60 * 60 * 4 },
      //           { atom: { amount: 200000 }, timestamp: Date.now() - 1000 * 60 * 60 * 3 },
      //           { atom: { amount: 100000 }, timestamp: Date.now() - 1000 * 60 * 60 * 2 },
      //           { atom: { amount: 450000 }, timestamp: Date.now() - 1000 * 60 * 60 },
      //         ],
      //       },
      //       totalStatistics: { avgBill: 90000, income: 1180000, paid: 1850000 },
      //       totalDynamic: { avgBill: -62.96, income: 25, paid: 33 },
      //     }]);
      //   }, 1000);
      // });
    },

    getKeyIndicatorsStatuses(params) {
      return axios.get('/monitoring/statuses/chart', { params });
      // return new Promise((res) => {
      //   setTimeout(() => {
      //     res([{
      //       currency: 643,
      //       exp: 2,
      //       data: {
      //         new: [
      //           {
      //             atom: {
      //               count: 260,
      //             },
      //             timestamp: 1677110400000,
      //           },
      //           {
      //             atom: {
      //               count: 279,
      //             },
      //             timestamp: 1676505600000,
      //           },
      //         ],
      //         confirmed: [
      //           {
      //             atom: {
      //               count: 28,
      //             },
      //             timestamp: 1677110400000,
      //           },
      //           {
      //             atom: {
      //               count: 10,
      //             },
      //             timestamp: 1676505600000,
      //           },
      //         ],
      //         delivery: [
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1677110400000,
      //           },
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1676505600000,
      //           },
      //         ],
      //         sold: [
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1677110400000,
      //           },
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1676505600000,
      //           },
      //         ],
      //         refused: [],
      //         cancelled: [
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1677110400000,
      //           },
      //           {
      //             atom: {
      //               count: 0,
      //             },
      //             timestamp: 1676505600000,
      //           },
      //         ],
      //       },
      //       totalStatistics: {
      //         new: 539,
      //         confirmed: 38,
      //         delivery: 0,
      //         sold: 41,
      //         refused: 5,
      //         cancelled: 44,
      //       },
      //       totalDynamic: {
      //         new: -24.860855102539062,
      //         confirmed: 76.31578063964844,
      //         delivery: -100,
      //         sold: 0,
      //         refused: 25,
      //         cancelled: -90.90908813476562,
      //       },
      //     }]);
      //   }, 1000);
      // });
    },
    getProductAnalyticsChart(params, segment) {
      return axios.get(`/monitoring/${segment}/pieChart`, { params });
    }
  },

  reports: {
    /**
     * @typedef {Object} ReportModel
     * @property {string} id
     * @property {number} endTime
     * @property {number} startTime
     * @property {string[]} logs
     * @property {Object} options
     * @property {string} partnerId
     * @property {string} partnerName
     * @property {string} status
     * @property {string} statusDescription
     * @property {number} statusTime
     * @property {string} taskId
     * @property {string} taskName
     * @property {string} taskType
     * @property {number} _t
     */
    /**
     * @typedef {Object} ReportListRequest
     * @property {number} startT
     * @property {number} finishT
     * @property {string[]} subjects
     */
    /**
     * @param {ReportListRequest} params
     * @returns {Promise<{reports: ReportModel[]}>}
     */
    get(params) {
      return axios.get('/reports/list', { params });
    },

    /**
     * @typedef {Object} ReportCreateRequest
     * @property {string} partnerId
     * @property {string} taksId
     * @property {number} startT
     * @property {number} endT
     */
    /**
     * @param {ReportCreateRequest} report
     * @returns {Promise<{}>}
     */
    create(report) {
      return axios.put('/reports', report);
    },

    /**
     * @param {string} id
     * @returns {Promise<{}>}
     */
    restart(id) {
      return axios.post(`/reports/${id}/refresh`);
    },

    /**
     * @returns {Promise<{}>}
     */
    getReportTypes() {
      return axios.get('/reports/types');
    },

    /**
     * @typedef {Object} ReportTaskModel
     * @property {string} id
     * @property {string} name
     * @property {string} formatName
     * @property {Object[]} cron
     * @property {Object[]} sendByEmail
     * @property {Object[]} sendByFtp
     */
    /**
     * @param {ReportTaskModel} data
     * @returns {Promise<{}>}
     */
    saveReportTask(data) {
      return axios.put('/recon', data);
    },

    deleteReportTask(taskId) {
      return axios.delete('/recon', {
        params: { taskId },
      });
    },

    /**
     * @returns {Promise<{ tasks: ReportTaskModel[] }>}
     */
    getReportTasks() {
      return axios.get('/recon');
    },
  },

  blacklist: {
    /**
     * @typedef {Object} RuleModel
     * @property {string} id
     * @property {string} number
     * @property {string} product
     * @property {boolean} isActive
     * @property {number} creationTime
     */
    /**
     * @typedef {Object} GetRulesRequestParams
     * @property {string} [search]
     * @property {number} offset
     * @property {number} count
     */
    /**
     * @param {GetRulesRequestParams} params
     * @returns {Promise<{rules: RuleModel[]}>}
     */
    getRules(params) {
      return axios.get('/blacklist/rules', { params });
    },

    /**
     * @typedef {Object} RuleInfoModel
     * @property {string} id
     * @property {string} number
     * @property {string} product
     * @property {boolean} isActive
     * @property {number} creationTime
     * @property {string} author
     * @property {string} direction
     * @property {string} reason
     */
    /**
     * @param {string} id
     * @returns {Promise<RuleInfoModel>}
     */
    getRuleById(id) {
      return axios.get(`/blacklist/rules/${id}`);
    },

    /**
     * @typedef {Object} CreateRuleRequestModel
     * @property {string} number
     * @property {string} product
     * @property {string} direction
     * @property {string} reason
     * @property {boolean} isActive
     */
    /**
     * @param {CreateRuleRequestModel} model
     * @returns {Promise<RuleInfoModel>}
     */
    createRule(model) {
      return axios.put('/blacklist/rules', model);
    },

    /**
     * @typedef {CreateRuleRequestModel} EditRuleRequestModel
     */
    /**
     * @param {string} id
     * @param {EditRuleRequestModel} model
     * @returns {Promise<RuleInfoModel>}
     */
    updateRule(id, model) {
      return axios.post(`/blacklist/rules/${id}`, model);
    },

    /**
     * @returns {Promise<{}>}
     */
    export() {
      return axios.get('/blacklist/rules/export');
    },
  },

  subscriptions: {
    /**
     * @typedef {Object} MessageModel
     * @property {string} text
     * @property {number} time
     * @property {string} src
     * @property {string} log
     */
    /**
     * @typedef {Object} SubscriptionModel
     * @property {string} id
     * @property {string} number
     * @property {string} policy
     * @property {'active'|'unsubscribed'} status
     * @property {string} acceptCode
     * @property {number} date
     * @property {number} [subscriptionTime]
     * @property {number} [unsubscriptionTime]
     * @property {Object[]} [messages]
     */
    /**
     * @typedef {Object} SubscriptionsSearchResultModel
     * @property {SubscriptionModel[]} clients
     * @property {MessageModel[]} messages
     */
    /**
     * @param {string} number
     * @returns {Promise<SubscriptionsSearchResultModel>}
     */
    get(number) {
      return axios.get('/subscriptions/search', { params: { number } });
    },

    start(model) {
      return axios.post('/subscriptions/start', model);
    },

    stop(model) {
      return axios.post('/subscriptions/stop', model);
    },
  },

  paycafe: {
    /**
     * @param {PaycafeFormModel} data
     */
    savePaycafeForm(data) {
      return axios.put('/paycafe', data);
    },

    /**
     * @param {string} initiatorId
     * @param {string} showcaseId
     */
    deletePaycafeForm({ initiatorId, showcaseId }) {
      return axios.delete('/paycafe', {
        params: { initiatorId, showcaseId },
      });
    },

    /**
     * @typedef {Object} PaycafeFormModel
     * @property {number} amount
     * @property {number} defaultAmountOption
     * @property {string} dstId
     * @property {Object} extra
     * @property {string} initiatorId
     * @property {string} invoiceMessage
     * @property {number} liveTimeInSec
     * @property {string} locale
     * @property {boolean} mutableAmount
     * @property {string[]} paymentMethods
     * @property {string} productId
     * @property {string} productName
     * @property {string} productUrl
     * @property {string} showcaseId
     * @property {string} showcaseName
     * @property {number[]} singleAmountOptions
     * @property {string} themeBg
     * @property {string} themeText
     * @property {boolean} withYourAmountInput
     */
    /**
     * @param {string} initiatorId
     * @returns {Promise<{ warehouses: PaycafeFormModel[] }>}
     */
    getPaycafeForms(initiatorId) {
      return axios.get('/paycafe', {
        params: { initiatorId },
      });
    },

    /**
     * @typedef {Object} PaycafeUrlsModel
     * @property {string} url
     * @property {string} unlimitedSessionUrl
     * @property {string} sessionUrl
     */
    /**
     * @param {string} initiatorId
     * @param {string} showcaseId
     * @return {Promise<PaycafeUrlsModel>}
     */
    getPaycafeUrls({ initiatorId, showcaseId }) {
      return axios.get('/paycafe/urls', {
        params: { initiatorId, showcaseId },
      });
    },

    getPaycafeTmpUrl(data) {
      return axios.post('/paycafe/tmp-url', data);
    },

    /**
     * @typedef {Object} PaycafeOutletCreateModel
     * @property {string} id
     * @property {string} globalId
     * @property {string} fullName
     * @property {Object<string, string>} extra
     */
    /**
     * Создание "точек продаж" в PayCafe
     * @param {PaycafeOutletCreateModel[]} model
     */
    createOutlets(model) {
      return axios.post('/paycafe/paymentPoints', model);
    },

    /**
     * @typedef {Object} PaycafeOutletCreateModel
     * @property {string} id
     * @property {string} globalId
     * @property {string} fullName
     * @property {Object<string, string>} extra
     */
    /**
     * Изменение "точки продаж" в PayCafe
     * @param {PaycafeOutletCreateModel} model
     */
    updateOutlet(model) {
      return axios.put('/paycafe/paymentPoints', model);
    },

    changeDefaultOutlet(data) {
      return axios.put('/paycafe/paymentPoints/default', data);
    },
    registerMerchantInSBP(model) {
      return axios.post('/tochka/sbp/beginEnrollment', model);
    },

    registerMerchantInAcquiring(model) {
      return axios.post('/tochka/acquiring/beginEnrollment', model);
    },

    enrollmentSbpStatusInTochka(model) {
      return axios.post(`/tochka/sbp/enrollmentStatus?companyId=${model.companyId}&paymentPointId=${model.paymentPointId}&bik=${model.bik}&account=${model.account}`);
    },

    enrollmentAcqStatusInTochka(model) {
      return axios.post(`/tochka/acquiring/enrollmentStatus?companyId=${model.companyId}&paymentPointId=${model.paymentPointId}`);
    },

    /**
     * @typedef {Object} PaycafeOutletSourcesUpdateModel
     * @property {string} id
     * @property {string} globalId
     * @property {Object.<string, Object>} sources
     * @property {Array.<string>} paymentMethods
     */
    /**
     * Изменение "точки продаж" в PayCafe
     * @param {PaycafeOutletSourcesUpdateModel} model
     */
    updateOutletSources(model) {
      return axios.put('/paycafe/paymentPoints/sources', model);
    },

    /**
     * @typedef {Object} PaycafeOutletModel
     * @property {string} id
     * @property {string} name
     * @property {string[]} merchants
     * @property {Object<string, *>} extras
     */
    /**
     * @return {Promise<PaycafeOutletModel[]>}
     */
    getOutlets() {
      return axios.get('/paycafe/paymentPoints');
    },

    /**
     * @typedef {Object} AcceptRequestModel
     * @property {string} id - full outlet id (globalId|id)
     * @property {string[]} merchants
     */
    /**
     * @param {AcceptRequestModel} data
     * @param {object} FINGERPRINT
     * @returns {Promise<OutletModel>}
     */
    acceptOutlet(data, FINGERPRINT) {
      return axios.post('/paycafe/paymentPoints/accept', data, {
        headers: { FINGERPRINT },
      });
    },

    /**
     * @typedef {Object} OutletHistoryModel
     */
    getOutletHistory(id) {
      return axios.get('/paycafe/paymentPoints/history', {
        params: { id },
      });
    },

    /**
     * @typedef {Object} PaycafeCategoryModel
     * @property {string} id
     * @property {string} name
     * @property {string} cat
     */
    getCategories() {
      return axios.get('/paycafe/categories');
    },

    /**
     * @typedef {Object} PaycafeBillModel
     * @property {string} paymentPointId
     * @property {string[]} paymentMethods
     * @property {string} email
     * @property {string} phoneNumber
     * @property {string} buyerName
     * @property {number} expiryDate
     * @property {string} orderId
     * @property {string} comment
     * @property {string[]} products
     */
    /**
     * Выставление счета в PayCafe
     * @param {PaycafeBillModel} model
     */
    createInvoice(model) {
      return axios.put('/paycafe/invoices', model);
    },

    getInvoices(params) {
      return axios.get('/paycafe/invoices', { params });
    },

    disableInvoice(id) {
      return axios.post(`/paycafe/invoices/${id}/disable`);
    },

    getStaticQrCodes(companyId) {
      return axios.get('/paycafe/staticQrCodes', { params: { companyId } });
    },

    createStaticQrCode(model) {
      return axios.put('/paycafe/staticQrCodes', model);
    },

    updateStaticQrCode(model) {
      return axios.post('/paycafe/staticQrCodes', model);
    },

    deleteStaticQrCode(data) {
      return axios.delete('/paycafe/staticQrCodes', { data });
    },

    uploadOgImage(data) {
      return axios.post('/paycafe/uploadFormImage', data);
    },

    atolAuth(data) {
      return axios.post('/paycafe/atol/auth', data, {
        noNotification: true,
      });
    },

    addOnlineCashbox(data) {
      return axios.post('/paycafe/atol/cashRegisters', data);
    },

    editOnlineCashbox(data) {
      return axios.put('paycafe/atol/cashRegisters', data);
    },

    toggleStatusOnlineCashbox(data) {
      return axios.put('paycafe/atol/cashRegisters/status', data);
    },

    getOnlineCashboxes(params) {
      return axios.get('/paycafe/atol/cashRegisters', { params });
    },

    connectOnlineCashbox(data) {
      return axios.put('/paycafe/paymentPoints/atolCashRegister', data);
    },

    disconnectOnlineCashbox(data) {
      return axios.delete('/paycafe/paymentPoints/atolCashRegister', { data });
    },
    //
    // getSdekCities(params) {
    //   return axios({
    //     method: 'get',
    //     baseURL: 'https://api.cdek.ru/city/getListByTerm/json.php',
    //     params,
    //   });
    // },

    yoomoney(params) {
      return axios.post('/yoomoney/oauth', null, { params });
    },
  },

  awiza: {
    getMarketplaces(params) {
      return axios.get('/awiza/marketplaces', { params });
    },
    getCategories(params) {
      return axios.get('/awiza/local/categories', { params });
    },
    getCategoryFields(category) {
      return axios.get(`/awiza/local/categories/${category}/fields`);
    },
    addCategory(data) {
      return axios.put('/awiza/local/categories', data);
    },
    deleteCategory(data) {
      return axios.delete('/awiza/local/categories', { data });
    },
    editCategory(category) {
      return axios.post('/awiza/local/categories', category);
    },
    editCategoryPositions(data, currentCompanyId) {
      return axios.post(`/awiza/local/categories/positions?companyId=${currentCompanyId}`, data);
    },
    uploadImages(images) {
      return axios.post('/awiza/uploadImages', images);
    },
    uploadDigitalProducts(data) {
      return axios.post('/awiza/uploadDigitalProducts', data);
    },
    uploadTemplate(file) {
      return axios.post('/awiza/local/templates/upload', file);
    },
    products(params) {
      return axios.get('/awiza/local/products', { params });
    },
    productsToPublish(params) {
      return axios.get('/awiza/local/productsToPublish', { params });
    },
    publishedProducts(params) {
      return axios.get('/awiza/local/publishedProducts', { params });
    },
    publishedWarehouses(params) {
      return axios.get('/awiza/publishedWarehouses', { params });
    },
    productDetails(id, params) {
      return axios.get(`/awiza/local/products/${id}`, { params });
    },
    productStock(id, params) {
      return axios.get(`/awiza/local/products/${id}/stock`, { params });
    },
    updateStockInfo(data, id) {
      return axios.put(`/awiza/local/products/${id}/stock`, data);
    },
    getProductsCount(params) {
      return axios.get('/awiza/local/products/count', { params });
    },
    checkVendorId(vendorId, params) {
      return axios.get(`/awiza/isVidUnique/${vendorId}`, { params });
    },
    addProduct(product) {
      return axios.post('/awiza/local/products', product);
    },
    deleteProducts(data) {
      return axios.delete('/awiza/local/products', { data });
    },
    getProductsExtra() {
      return axios.get('/awiza/local/products/measure');
    },
    editProduct(product) {
      return axios.put(`/awiza/local/products/${product.id}`, product);
    },
    updateProductProperties(data) {
      return axios.put('/awiza/local/products/properties', data);
    },
    updateProductsStocks(data) {
      return axios.put('/awiza/local/products/edit/stocks', data);
    },
    getProductsStocks(data) {
      return axios.post('/awiza/local/products/stock/list', data);
    },
    duplicateAwizaProduct(product, withImages) {
      return axios.post(`/awiza/local/products/duplicate/${product.id}?withImages=${withImages}`, product);
    },
    getWarehouses(params) {
      return axios.get('/awiza/warehouses', { params });
    },
    editWarehouse(data) {
      return axios.put('/awiza/warehouses', data);
    },
    newBoxretailWarehouse(id) {
      return axios.post('/awiza/warehouses/boxretail', id);
    },
    newWarehouse(id) {
      return axios.post('/awiza/warehouses/usual', id, { noNotification: true });
    },
    deleteWarehouse(data) {
      return axios.post('/awiza/warehouses/archive', data);
    },
    connect(data) {
      return axios.post('/awiza/connect', data);
    },
    publishProducts(products) {
      return axios.post('/awiza/publish', products);
    },
    unpublishProducts(products) {
      return axios.post('/awiza/unpublish', products);
    },
    getVkGroups(data) {
      return axios.post('/awiza/vk/settings/auth', data);
    },
    getVkPublishedProducts({ companyId }) {
      return axios.get(`/awiza/local/published/products/vk/${companyId}`);
    },
    getVkCategories() {
      return axios.get('/awiza/vk/categories');
    },
    saveVKSettings(data) {
      return axios.post('/awiza/vk/settings', data);
    },
    changeVKSettingStatus(data) {
      return axios.put('/awiza/vk/settings', data);
    },
    getVkCategoriesMatch(params) {
      return axios.get('/awiza/vk/categories/match', { params });
    },
    saveVkCategoriesMatch(data) {
      return axios.post('/awiza/vk/categories/match', data);
    },
    saveGoodsSettings(data) {
      return axios.post('/awiza/settings/goods', data);
    },
    getSbermegamarketSettings(params) {
      return axios.get('/awiza/settings/goods', { params });
    },
    getAvailableWarehouses(params) {
      return axios.get('/awiza/warehouses/available', { params });
    },
    checkOzonToken(params) {
      return axios.get('/ozon/check/token', { params });
    },
    saveOzonSettings(data) {
      return axios.post('/ozon/save/settings', data);
    },
    saveOzonWarehousesMatch(data) {
      return axios.put('/awiza/warehouses/list', data);
    },

    /**
     * @typedef {Object} WarehouseModel
     * @property {number} warehouse_id
     * @property {string} name
     * @property {boolean} is rfbs
     */
    /**
     * @param params
     * @return {Promise<WarehouseModel[]>}
     */
    getOzonWarehouses(params) {
      return axios.get('/ozon/warehouses', { params });
    },

    /**
     * @typedef {Object} DiscountOption
     * @property {number} [percent]
     * @property {MoneyAmount} [amount]
     * @property {MoneyAmount} [orderPriceFrom]
     * @property {MoneyAmount} [orderPriceTo]
     * @property {number} [totalItemsFrom]
     */
    /** @typedef {Object} ExplicitSource
      * @property {'explicit'} type
      * @property {string[]} codes
      */
    /** @typedef {Object} GenerateSource
      * @property {'generated'} type
      * @property {string} pattern
      * @property {number} quantity
      * @property {boolean} useDigits
      * @property {boolean} useLowerCaseAlpha
      * @property {boolean} useUpperCaseAlpha
      * @property {boolean} excludeSimilarLookingChars
      */
    /**
     * @typedef {Object} Promocode
     * @property {string} [id] - omitted when creating
     * @property {string} projectId
     * @property {boolean} isActive
     * @property {'active' | 'paused' | 'ended' | 'planned' | 'redeemed'} [status] - omitted when creating
     * @property {string} [name]
     * @property {string} [description]
     * @property {ExplicitSource | GenerateSource} voucherSource
     * @property {number} [activeFrom]
     * @property {number} [activeUntil]
     * @property {'never' | 'onCreation' | 'onPayment'} expirationRule
     * @property {'itemsOnly' | 'deliveryOnly' | 'itemsAndDelivery'} applicationRule
     * @property {boolean} applyToDiscountedItems
     * @property {boolean} applyToBundles
     * @property {string[]} [applyToProducts]
     * @property {string[]} [excludeProducts]
     * @property {string[]} [applyToCategories]
     * @property {string[]} [excludeCategories]
     * @property {string[]} [applyToOutlets]
     * @property {string[]} [applyToPlatforms]
     * @property {DiscountOption[]} discountLevels
     */
    /**
     * @typedef {Object} Discount
     * @property {string} [id] - omitted when creating
     * @property {string} projectId
     * @property {boolean} isActive
     * @property {string} [description]
     * @property {number} [activeFrom]
     * @property {number} [activeUntil]
     * @property {boolean} applyToBundles
     * @property {string[]} [applyToProducts]
     * @property {string[]} [excludeProducts]
     * @property {string[]} [applyToCategories]
     * @property {string[]} [excludeCategories]
     * @property {string[]} [applyToOutlets]
     * @property {string[]} [applyToPlatforms]
     * @property {DiscountOption[]} discountLevels
     */
    promotions: {
      /**
       * @param {string} id
       * @param {{ projectId: string }} params
       * @returns {Promise<AxiosResponse<Promocode>>}
       */
      getPromocodeById({ id, ...params }) {
        return axios.get(`/awiza/local/promocode/${id}`, { params });
      },
      getPromocodes(params) {
        return axios.get('/awiza/local/promocode/list', { params });
      },
      /**
       * @param {Promocode} data
       */
      createPromocode(data) {
        return axios.post('/awiza/local/promocode', data);
      },
      updatePromocode(data) {
        return axios.put('/awiza/local/promocode', data);
      },
      updatePartPromocode(data) {
        return axios.patch('/awiza/local/promocode', data);
      },
      deletePromocode(data) {
        return axios.post('/awiza/local/promocode/list/delete', data);
      },
      checkPromocode(params) {
        return axios.get('/awiza/local/promocode/isFree', { params});
      },
      getDiscounts(params) {
        return axios.get('/awiza/local/discount/list', { params });
      },
      /**
       * @param {string} id
       * @param {{ projectId: string }} params
       * @returns {Promise<AxiosResponse<Discount>>}
       */
      getDiscountById({ id, ...params }) {
        return axios.get(`/awiza/local/discount/${id}`, { params });
      },
      /**
       * @param {Discount} data
       */
      createDiscount(data) {
        return axios.post('/awiza/local/discount', data);
      },
      updateDiscount(data) {
        return axios.put('/awiza/local/discount', data);
      },
    },
  },

  poster: {
    getProducts({ companyId }) {
      return axios.get(`/poster/importData/${companyId}`);
    },
    savePosterCompanyName(params) {
      return axios.post('/poster/company', params);
    },
    savePosterToken(model) {
      return axios.post('/poster/token', model);
    },
    getPosterInfo({ companyId }) {
      return axios.get(`/poster/info/${companyId}`);
    },
    getPosterFullInfo(downloadId) {
      return axios.get(`/poster/fullInfo/${downloadId}`);
    },
    savePosterSettings(params) {
      return axios.post('/poster', params, { noNotification: true });
    },
    connect(companyId) {
      return axios.post('/poster', companyId);
    },
  },

  boxretail: {
    notifications: {
      /**
       * @typedef {Object} NotificationsRequestModel
       * @property {number} limit
       * @property {number} lastT
      /**

       * @param {NotificationsRequestModel} params
       * @returns {Promise<any>}
       */
      get(params) {
        return axios.get('/boxretail/notifications', {
          params,
        });
      },

      /**
       * @typedef {Object} NotificationModel
       * @property {string[]} recipients
       * @property {string} level
       * @property {string} message
      /**
       * @param {NotificationModel} notification
       * @returns {Promise<NotificationModel>}
       */
      create(notification) {
        return axios.put('/boxretail/notifications', notification);
      },

      /**
       * @param {string} id
       * @returns {Promise<*>}
       */
      remove(id) {
        return axios.delete(`/boxretail/notifications/${id}`);
      },
    },
    services: {
      /**
       * @typedef {Object} BoxretailServicesModel
       */
      save(model) {
        return axios.post('/boxretail/services', model);
      },

      sendContractsRequest(model) {
        return axios.post('/boxretail/services/requests', model);
      },

      getContractsRequests(params) {
        return axios.get('/boxretail/services/requests', { params });
      },

      /**
       * @param {Array<{ requestId: string, photocopies: Object<string, { id: string, name: string }> }>} model
       * @returns {Promise<*>}
       */
      sendContractFiles(model) {
        return axios.post('/boxretail/services/requests/photocopies', model);
      },

      /**
       * @param {{ id: string, status: 'pending'|'inProgress'|'accepted' }} model
       * @returns {Promise<*>}
       */
      acceptContractsRequest(model) {
        return axios.post('/boxretail/services/requests/accept', model);
      },
    },

    updateShortProjectId(data) {
      return axios.post('boxretail/boxRetailCustomProjectId', data, { noNotification: true });
    },

    setConnectionType(data) {
      return axios.post('boxretail/paymentPoints/kkt', data);
    },

    updateTaxInfo(data) {
      return axios.post('boxretail/paymentPoints/taxInfo', data);
    },

    /**
     * @typedef {Object} AcceptRequestModel
     * @property {string} id - full outlet id (globalId|id)
     * @property {string[]} merchants
     */
    /**
     * @param {AcceptRequestModel} data
     * @param {object} FINGERPRINT
     * @returns {Promise<OutletModel>}
     */
    acceptOutlet(data, FINGERPRINT) {
      return axios.post('/boxretail/paymentPoints/accept', data, {
        headers: { FINGERPRINT },
      });
    },

    setOrderNotifications(data) {
      return axios.post('/boxretail/paymentPoints/orderNotifications', data);
    },

    // getLicenseInfo(outletId) {
    //   return axios.get(`boxretail/license/${outletId}`);
    // },

    reissueLicenseKey(key) {
      return axios.post(`license/reissue/${key}`);
    },

    getRutokenCertificates(params) {
      return axios.get('/certificates', { params });
    },

    createRutokenCertificate(data) {
      return axios.put('/certificates', data);
    },

    updateRutokenCertificate(data) {
      return axios.post('/certificates', data);
    },

    deleteCertificate(params) {
      return axios.delete('/certificates', { params });
    },
  },

  services: {
    getTree(params) {
      return axios.get('/awiza/foreign/categoriesTree', { params });
    },
    getCategories() {
      return axios.get('/awiza/foreign/categories');
    },
    getConnected(params) {
      return axios.get('/awiza/foreign/products', { params });
    },
    getPublished(params) {
      return axios.get('/awiza/foreign/publishedProducts', { params });
    },
    getToPublish(params) {
      return axios.get('/awiza/foreign/productsToPublish', { params });
    },
    addServices(data) {
      return axios.put('/awiza/foreign/products', data);
    },
    deleteServices(data) {
      return axios.delete('/awiza/foreign/services', { data });
    },
    getServicesByCategory(categoryId) {
      return axios.get(`/awiza/foreign/categories/${categoryId}`);
    },
  },

  utils: {
    /**
     * @param {FormData} model
     * @returns {Promise<*>}
     */
    sendFiles(model) {
      return axios.post('/utils/files', model);
    },

    qrcodeUrl: (text) => `${axios.defaults.baseURL}/utils/qrcode?text=${encodeURIComponent(text)}`,

    suggest: {
      /**
       * @typedef {Object} AddressSuggestionModel
       * @property {string} address
       * @property {string} fias_id
       */
      /**
       * Подсказки для ввода адреса
       * @param {string} q - поисковой запрос
       * @param {string[]} extraFields - доп поля которые будут возвращены
       * @returns {Promise<{ suggestions: AddressSuggestionModel[] }>}
       */
      address(q, extraFields) {
        return axios.get('/utils/suggest/address', {
          params: { q, extraFields },
        });
      },

      /**
       * Подсказки для ввода региона или города
       * @param {string} q - поисковой запрос
       * @returns {Promise<{ suggestions: string[] }>}
       */
      area(q) {
        return axios.get('/utils/suggest/area', {
          params: { q },
        })
      },

      /**
       * @typedef {Object} PartySuggestionModel
       * @property {string} inn
       * @property {string} name
       */
      /**
       * Подсказки для ввода компании по названию или ИНН
       * @param {string} q - поисковой запрос
       * @param {'LEGAL'|'INDIVIDUAL'} type - тип регистрации (компания/ип)
       * @returns {Promise<{ suggestions: PartySuggestionModel[] }>}
       */
      party(q, type) {
        const params = { q };
        if (type) {
          params.type = type;
        }
        return axios.get('/utils/suggest/party', { params });
      },

      /**
       * @typedef {Object} BankSuggestionModel
       * @property {string} bic
       * @property {string} correspondentAccount
       * @property {string} inn
       * @property {string} kpp
       * @property {string} name
       * @property {string} paymentCity
       */
      /**
       * Подсказки для ввода Банка по названию, БИК-у или ИНН
       * @param {string} q - поисковой запрос
       * @returns {Promise<{ suggestions: BankSuggestionModel[] }>}
       */
      bank(q) {
        return axios.get('/utils/suggest/bank', {
          params: { q },
        });
      },
    },

    /**
     * @param {string} id
     * @param {string} name
     * @return {Promise<*>}
     */
    getFile({ id, name }) {
      return axios.get('/utils/file', {
        params: { id, name },
      });
    },
  },

  crm: {
    sendSms(data) {
      return axios.post('/sms', data, {
        noNotification: true,
      });
    },
    getSmsProjects() {
      return axios.get('/sms/projects');
    },
    updateSmsProject(data) {
      return axios.post('/sms/upload/config', data);
    },
    updateSmsProjectStatus(data) {
      return axios.post('/sms/update/status', data);
    },
    createSmsProject(data) {
      return axios.post('/sms/create/project', data, {
        noNotification: true,
      });
    },
    getSmppConfig() {
      return axios.get('/sms/smpp');
    },
    getSmsProjectInfo(params) {
      return axios.get('/sms/projects/info', { params });
    },
    updateSubscription() {
      return new Promise((resolve) => {
        setTimeout(() => resolve({ success: true, t: 1652860573467 }), 1000);
      }); //TODO mock
      // return axios.post('/subscription/update', data); //TODO change endpoint url
    },
    changeTariffCrm(data, FINGERPRINT) {
      return axios.post('/billing/subscription/changes', data, { noNotification: true, headers: { FINGERPRINT } });
    },
    updateTrialSubscription(data) {
      return axios.post('/billing/subscription/trial', data);
    },
    deleteTrialSubscription(data) {
      return axios.delete('/billing/subscription/trial', { data });
    },
    getSubscriptionsHistory(id) {
      return axios.get('/billing/subscription/history', { params: { id } });
    },
  },

  clients: {
    getClients(params) {
      return axios.get('/crm/clients', { params });
    },

    updateClient(data) {
      return axios.post('/crm/clients', data);
    },

    getClientDetailsById(id) {
      return axios.get(`/crm/clients/${id}`);
    },

    getClientPayments(params) {
      return axios.get('/crm/payments', { params });
    },
  },
  orders: {
    getOrders(params) {
      return axios.get('/orders', { params });
    },

    getOrderDetailsById({ id }) {
      return axios.get(`/orders/${id}`);
    },

    getOrdersByClientId(params) {
      return axios.get('/orders/clients', { params });
    },
  },

  cdek: {
    saveSettings(data) {
      return axios.post('/cdek/info', data, {
        noNotification: true,
      });
    },

    getCdekCities(params) {
      return axios.get('/cdek/cities', { params });
    },

    getCdekShipmentPoints(params) {
      return axios.get('/cdek/pvz', { params });
    },
  },

  yabusiness: {
    connect(data) {
      return axios.post('/projects/ya-business/connect', data);
    },
  },

  talkto: {
    createSite(data) {
      return axios.post('/talkto/create/site', data, {
        noNotification: true,
      });
    },
  },

  myclients: {
    connect(data) {
      return axios.post('/myclients/connect', data);
    },
    getServices(params) {
      return axios.get('/myclients/services', { params });
    },
    getShortServices(params) {
      return axios.get('/myclients/services/shortened', { params });
    },
    createService(data) {
      return axios.post('/myclients/services', data);
    },
    updateService(data) {
      return axios.put('/myclients/services', data);
    },
    deleteService(serviceId, params) {
      return axios.delete(`/myclients/services/${serviceId}`, { params });
    },
    getServiceCategories(params) {
      return axios.get('/myclients/services/categories', { params });
    },
    createServiceCategory(data) {
      return axios.post('/myclients/services/categories', data);
    },
    updateServiceCategory(data) {
      return axios.put('/myclients/services/categories', data);
    },
    deleteServiceCategory(categoryId, projectId) {
      return axios.delete(`/myclients/services/categories/${categoryId}`, { params: { projectId } });
    },
    getLocations(params) {
      return axios.get('/myclients/locations', { params });
    },
    getShortLocations(params) {
      return axios.get('/myclients/locations/shortened', { params });
    },
    createLocation(data) {
      return axios.post('/myclients/locations', data);
    },
    updateLocation(data) {
      return axios.put('/myclients/locations', data);
    },
    archiveLocation(locationId, params) {
      return axios.delete(`/myclients/locations/${locationId}`, { params });
    },
    getEmployees(params) {
      return axios.get('/myclients/employees', { params });
    },
    getShortEmployees(params) {
      return axios.get('/myclients/employees/shortened', { params });
    },
    createEmployee(data) {
      return axios.post('/myclients/employees', data);
    },
    updateEmployee(data) {
      return axios.put('/myclients/employees', data);
    },
    archiveEmployee(employeeId, params) {
      return axios.delete(`/myclients/employees/${employeeId}`, { params });
    },
    getSchedules(params) {
      return axios.get('/myclients/schedules', { params });
    },
    createSchedule(data) {
      return axios.post('/myclients/schedules', data);
    },
    updateSchedule(data) {
      return axios.post('/myclients/schedules', data);
    },
    archiveScheduleByEntity(params) {
      return axios.delete('/myclients/schedules/archive', { params });
    },
    uploadImages(images) {
      return axios.post('/myclients/uploadImages', images);
    },
    getWidgets(params) {
      return axios.get('/myclients/widgets', { params });
    },
    createWidget(data) {
      return axios.post('/myclients/widgets', data);
    },
    updateWidget(data) {
      return axios.put('/myclients/widgets', data);
    },
    deleteWidget(widgetId, params) {
      return axios.delete(`/myclients/widgets/${widgetId}`, { params });
    },
    checkWidgetAlias(alias) {
      return axios.get(`/myclients/widgets/${alias}/alias/available`, { noNotification: true });
    },
  },
  emails: {
    createEmail(data) {
      return axios.put('/clientNotifications/settings', data);
    },
    getEmailSettings(context, projectId) {
      return axios.get(`/clientNotifications/settings?context=${context}&projectId=${projectId}`);
    },
    sendEmailPreview({ builder, projectId, emails, method }) {
      return axios.post(`/clientNotifications/mock/${builder}?projectId=${projectId}&emails=${emails}&method=${method}`);
    },
    uploadImages(images) {
      return axios.post('/projects/uploadCustomLogo', images);
    },
  },
};
