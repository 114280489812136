import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaycafeOutletsAsync } from '../../../actions/paycafe';
import { currentPaycafeOutletsSelector } from '../../../selectors/paycafe';

/**
 * @returns {PaycafeOutletModel[]}
 */
function usePaycafeOutlets(forceFetch = false) {
  const dispatch = useDispatch();
  const outlets = useSelector(currentPaycafeOutletsSelector);

  useEffect(() => dispatch(getPaycafeOutletsAsync(forceFetch)), [forceFetch]);

  return outlets;
}

export default usePaycafeOutlets;
