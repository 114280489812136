import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { Button, FormControl, Hint } from '@teko/ui-kit';
import { registerPasswordAsync } from '../../actions/auth';
import { getAuthRequestExtraData } from '../../utils';
import { defaultErrorDesc } from '../../utils/api-client';
import { getReferralByUrl } from '../../utils/getReferral';
import { useAsyncStatus } from '../common/hooks';
import { triggerAllMetricsEvents } from '../common/utils/metrics';
import StrictPasswordInputField from './StrictPasswordInputField';

const schema = yup.object().shape({
  password: yup.string().min(8).matches(/[A-Z]/g).matches(/[a-z]/g)
    .matches(/\d/g)
    .required(),
});

const renderSubmitBtn = (loading, disabled, t) => (
  <Button
    className="registration-form-step-btn"
    design="primary"
    size="l"
    loading={loading}
    type="submit"
    disabled={disabled}
  >
    {t('next')}
  </Button>
);

function RegistrationFormStepThree({ onStepChange }) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation('auth');
  const { loading, error, success } = useAsyncStatus((state) => state.auth.regPassStatus);
  const dispatch = useDispatch();
  const hasError = !!error;
  const brand = getReferralByUrl() || 'teko';

  const handleSubmit = useCallback(({ password }) => {
    dispatch(registerPasswordAsync({ password, ...getAuthRequestExtraData() }));
    triggerAllMetricsEvents('regstep4');
  }, []);
  const handleShowPassClick = useCallback(() => {
    setPasswordVisible((visible) => !visible);
  }, []);

  useEffect(() => {
    if (success) {
      sessionStorage.removeItem('authQueryParams');
      onStepChange((step) => step + 1);
    }
  }, [success]);

  return (
    <Formik initialValues={{ password: '' }} validationSchema={schema} onSubmit={handleSubmit}>
      {({ errors }) => (
        <Form className={classnames('registration-form-step', '_step-three', { 'has-error': hasError })}>
          {/*<div className="registration-form-step-hint">{t('registration-step-three-hint')}</div>*/}

          <FormControl>
            <StrictPasswordInputField
              name="password"
              autoFocus
              placeholder={t('enter-password')}
            />
          </FormControl>

          <p className="password-info">
            {t('password-info')}
          </p>

          {error && (
            <FormControl compact>
              <Hint type="error" className="error-auth-hint">
                {error.description ?? defaultErrorDesc}
              </Hint>
            </FormControl>
          )}

          <div className="registration-form-step-footer">
            {brand === 'talkto' && (
              <ThemeProvider theme={{ primary: '#452994' }}>
                {renderSubmitBtn(loading, !!errors.password, t)}
              </ThemeProvider>
            )}
            {brand !== 'talkto' && renderSubmitBtn(loading, !!errors.password, t)}
          </div>
        </Form>
      )}
    </Formik>
  );
}

RegistrationFormStepThree.propTypes = {
  onStepChange: PropTypes.func.isRequired,
};

export default memo(RegistrationFormStepThree);
