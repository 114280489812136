import React, { memo, useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAsyncStatus } from '../common/hooks';
import { AppleAuthContext } from '../common/utils/AppleAuthProvider';
import AppleIcon from '../../assets/svg/networks/apple.svg';
import { triggerAllMetricsEvents } from '../common/utils/metrics';

function AppleLoginButton() {
  const AppleID = useContext(AppleAuthContext);
  const { success: appleLoginSuccess } = useAsyncStatus((state) => state.auth.appleLoginStatus);
  const isNewAppleUser = useSelector((state) => state.auth.isNewAppleUser);

  const handleAppleLoginClick = useCallback(() => {
    triggerAllMetricsEvents('regappleclick');
    if (AppleID) {
      AppleID.auth.signIn();
    }
  }, [AppleID]);

  useEffect(() => {
    if (appleLoginSuccess) {
      sessionStorage.removeItem('authQueryParams');
    }
  }, [appleLoginSuccess]);

  return (
    <button type="button" className="social-auth-button" onClick={handleAppleLoginClick}>
      <AppleIcon className="icon" />
    </button>
  );
}

export default memo(AppleLoginButton);
