import React, { memo, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getOrigin } from '../../utils';
import { loadScript } from '../../utils/loadScript';
import { useQueryParams } from '../common/hooks';
import usePassQueryToSessionStorage from '../common/hooks/usePassQueryToSessionStorage';
import { triggerAllMetricsEvents } from '../common/utils/metrics';
import VKIcon from '../../assets/svg/networks/vk.svg';

const VK_ID = process.env.VK_ID || 51877262;

function VKLoginButton() {
  const vkAuthData = useSelector((state) => state.auth.vkAuth);
  const vkBtnRef = useRef();
  const isMountedRef = useRef(false);
  const [params] = useQueryParams();
  const initialParamsRef = useRef(params);

  const startApp = useCallback(() => {
    if (!isMountedRef.current) return;

    const origin = getOrigin();
    const VKID = window.VKIDSDK;

    const handleClick = (e) => {
      e.preventDefault(); //не отправлять форму
      triggerAllMetricsEvents('regvkclick');
      window.localStorage.setItem('vkAuthInitialParams', JSON.stringify(initialParamsRef.current));
      VKID.Auth.login();
    };

    const oneTap = new VKID.OneTap();
    const container = document.getElementById('VkIdSdkOneTap');
    if (container) {
      oneTap.render({
        container,
        showAlternativeLogin: false,
        styles: {
          width: 36,
          height: 36,
          borderRadius: 50,
        },
        skin: 'secondary',
      });
      container.onclick = handleClick;
    }

    VKID.Config.set({
      app: VK_ID, // Идентификатор приложения.
      redirectUrl: `${process.env.NODE_ENV === 'development' ? 'https://auth-dev.talkto.io' : origin}/auth/vkLogin`, // Адрес для перехода после авторизации.
      state: '',
    });
  }, []);

  usePassQueryToSessionStorage(vkAuthData);

  useEffect(() => {
    isMountedRef.current = true;
    loadScript('https://unpkg.com/@vkid/sdk@<2.0.0/dist-sdk/umd/index.js', 'vk-suggest')
      .then(startApp);

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <div className="social-auth-button vk">
      <VKIcon className="icon vk-icon" />
      <div id="VkIdSdkOneTap" className="vk-button" ref={vkBtnRef} />
    </div>
  );
}

export default memo(VKLoginButton);
