import { H1 } from '@teko/ui-kit';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import useHeadTitle from '../common/hooks/useHeadTitle';

function Processing() {
  const { t } = useTranslation('auth');
  useHeadTitle(t('registration'));

  return (
    <div className="registration-form">
      <div className="registration-title-wrap">
        <H1 className="registration-title" bold dangerouslySetInnerHTML={{ __html: t('request-processing') }} />
      </div>
    </div>
  );
}

export default memo(Processing);
