export function triggerVKMetrics(goal) {
  if (!('_tmr' in window)) return;
  const { _tmr } = window;
  _tmr.push({ id: process.env.VK_PIXEL_ID, type: 'reachGoal', goal });
}

export function triggerYMetrics(goal, params) {
  if (!('ym' in window)) return;
  window.ym(process.env.YANDEX_METRIKA_ID, 'reachGoal', goal, params);
}

export function triggerAllMetricsEvents(goal) {
  if (goal) {
    if (process.env.APP_ENV === 'development') console.log('Metrics goal:', goal);
    triggerVKMetrics(goal);
    triggerYMetrics(goal);
  }
}
