import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getCompanyHistoryAsync, getOutletHistoryAsync } from '../../../actions/companies';
import { getEmployeeHistoryAsync } from '../../../actions/employees';
import { getPaycafeOutletHistoryAsync } from '../../../actions/paycafe';

/**
 * @param {'boxretail'|'paycafe'} appId
 * @param {'outlets'|'employees'|'kyc'} entityType
 * @param {string} id - entityId
 * @returns {CompanyHistoryModel}
 */
export default function useEntityHistory(appId, entityType = 'outlets', id) {
  const dispatch = useDispatch();

  const kycHistorySelector = useMemo(() => createSelector(
    (state) => state.companies.companyHistory,
    (companyHistoryById) => companyHistoryById[id]
      ?.filter((historyItem) => historyItem.change.newValue?.kycInfo || historyItem.change.newValue?.kycInfoStatus)
      ?.map(({ change, ...historyItem }) => {
        const { kycInfo: oldKycInfo, ...oldValues } = change.oldValue;
        const { kycInfo: newKycInfo, ...newValues } = change.newValue;

        return {
          ...historyItem,
          change: {
            oldValue: { ...oldValues, ...oldKycInfo },
            newValue: { ...newValues, ...newKycInfo },
          },
        };
      }),
  ), [id]);

  const entityHistory = useSelector((() => {
    if (entityType === 'kyc') return kycHistorySelector;
    if (appId === 'paycafe') return (state) => state.paycafe.outletHistory[id];
    if (entityType === 'outlets') return (state) => state.companies.outletHistory[id];
    if (entityType === 'employees') return (state) => state.employees.history[id];
    // TODO: requests history
    return () => null;
  })());

  useEffect(() => {
    if (id) {
      if (entityType === 'kyc') {
        dispatch(getCompanyHistoryAsync(id));
      } else if (appId === 'paycafe') {
        dispatch(getPaycafeOutletHistoryAsync(id));
      } else if (entityType === 'outlets') {
        dispatch(getOutletHistoryAsync(id));
      } else if (entityType === 'employees') {
        dispatch(getEmployeeHistoryAsync(id));
      }
      // TODO: requests history
    }
  }, [appId, entityType, id]);

  return entityHistory;
}
