import { useField } from 'formik';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@teko/ui-kit';

function SwitchField({ title, desc, ...props }) {
  const [, meta, helpers] = useField(props.name);

  const switchNode = (
    <Switch
      {...props}
      checked={meta.value}
      onChange={helpers.setValue}
    />
  );

  if (!title) return switchNode;

  return (
    <div className="switch-container">
      {switchNode}
      <div className="switch-text">
        <p className="switch-title">{title}</p>
        {desc && <span className="switch-desc">{desc}</span>}
      </div>
    </div>
  );
}

SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(SwitchField);
