import { useLayoutEffect } from 'react';

function useMetaViewport(content = '') {
  useLayoutEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    viewport.setAttribute('content', content);
  }, [content]);
}

export default useMetaViewport;
