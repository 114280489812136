import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { createTableAction } from '../utils/redux-utils';

export const getBreakdown = createAsyncAction(
  'ANALYTICS_GET_BREAKDOWN_REQUEST',
  'ANALYTICS_GET_BREAKDOWN_SUCCESS',
  'ANALYTICS_GET_BREAKDOWN_FAIL',
)();

export function getBreakdownAsync(params, { id, type }) {
  return (dispatch, getState) => {
    if (getState().analytics.charts.status[id]?.loading) {
      return;
    }

    dispatch(getBreakdown.request(null, { id }));

    ApiClient.analytics.breakdown(params, type)
      .then((data) => dispatch(getBreakdown.success(data.breakdown, { id })))
      .catch((error) => dispatch(getBreakdown.failure(error, { id })));
  };
}

export const getChart = createAsyncAction(
  'ANALYTICS_GET_CHART_REQUEST',
  'ANALYTICS_GET_CHART_SUCCESS',
  'ANALYTICS_GET_CHART_FAIL',
)();

export function getChartAsync(params, { id, type }) {
  return (dispatch, getState) => {
    if (getState().analytics.charts.status[id]?.loading) {
      return;
    }

    dispatch(getChart.request(null, { id }));

    ApiClient.analytics.chart(params, type)
      .then((data) => dispatch(getChart.success(data, { id, params, type })))
      .catch((error) => dispatch(getChart.failure(error, { id })));
  };
}

export const getSupportChart = createAsyncAction(
  'ANALYTICS_GET_SUPPORT_CHART_REQUEST',
  'ANALYTICS_GET_SUPPORT_CHART_SUCCESS',
  'ANALYTICS_GET_SUPPORT_CHART_FAIL',
)();

export function getSupportChartAsync(params, { id, type }) {
  return (dispatch, getState) => {
    if (getState().analytics.charts.status[id]?.loading) {
      return;
    }

    dispatch(getSupportChart.request(null, { id }));

    ApiClient.analytics.supportChart(params, type)
      .then((data) => dispatch(getSupportChart.success(data, { id, params, type })))
      .catch((error) => dispatch(getSupportChart.failure(error, { id })));
  };
}

export const updateChart = createAsyncAction(
  'ANALYTICS_UPDATE_CHART_REQUEST',
  'ANALYTICS_UPDATE_CHART_SUCCESS',
  'ANALYTICS_UPDATE_CHART_FAIL',
)();

export function updateChartAsync(data) {
  return (dispatch) => {
    dispatch(updateChart.request());

    ApiClient.analytics.updateChart(data)
      .then((response) => dispatch(updateChart.success(response)))
      .catch((error) => dispatch(updateChart.failure(error)));
  };
}

export const deleteChart = createAsyncAction(
  'ANALYTICS_DELETE_CHART_REQUEST',
  'ANALYTICS_DELETE_CHART_SUCCESS',
  'ANALYTICS_DELETE_CHART_FAIL',
)();

export function deleteChartAsync(params, type) {
  return (dispatch) => {
    dispatch(deleteChart.request());

    ApiClient.analytics.deleteChart(params, type)
      .then((data) => dispatch(deleteChart.success(data, params)))
      .catch((error) => dispatch(deleteChart.failure(error)));
  };
}

export const getTransactions = createAsyncAction(
  'ANALYTICS_GET_TRANSACTIONS_REQUEST',
  'ANALYTICS_GET_TRANSACTIONS_SUCCESS',
  'ANALYTICS_GET_TRANSACTIONS_FAIL',
)();

export const transactionsTable = createTableAction(
  'ANALYTICS_TRANSACTIONS_TABLE_ADD',
  'ANALYTICS_TRANSACTIONS_TABLE_PREV',
  'ANALYTICS_TRANSACTIONS_TABLE_NEXT',
  'ANALYTICS_TRANSACTIONS_TABLE_FIRST',
);

export function getTransactionsAsync(params, append = false) {
  return (dispatch, getState) => {
    if (getState().analytics.transactions.status.loading) {
      return;
    }

    dispatch(getTransactions.request());

    ApiClient.analytics.transactions(params)
      .then((data) => {
        const { txHeaders, hasMore } = data;
        const meta = { append };
        const ids = txHeaders.map((item) => item.id);

        dispatch(getTransactions.success(txHeaders, meta));
        dispatch(transactionsTable.add({ ids, hasMore }, meta));
      })
      .catch((error) => dispatch(getTransactions.failure(error)));
  };
}

export const getTransactionInfo = createAsyncAction(
  'ANALYTICS_GET_TRANSACTION_INFO_REQUEST',
  'ANALYTICS_GET_TRANSACTION_INFO_SUCCESS',
  'ANALYTICS_GET_TRANSACTION_INFO_FAIL',
)();

export function getTransactionInfoAsync(id) {
  return (dispatch) => {
    dispatch(getTransactionInfo.request());

    ApiClient.analytics.transaction(id)
      .then((data) => dispatch(getTransactionInfo.success(data)))
      .catch((error) => dispatch(getTransactionInfo.failure(error)));
  };
}

export const getTransactionChildren = createAsyncAction(
  'ANALYTICS_GET_TRANSACTION_CHILDREN_REQUEST',
  'ANALYTICS_GET_TRANSACTION_CHILDREN_SUCCESS',
  'ANALYTICS_GET_TRANSACTION_CHILDREN_FAIL',
)();

export function getTransactionChildrenAsync(id, rowIndex) {
  return (dispatch, getState) => {
    if (getState().analytics.transactions.childrenById[id]?.status === 'loading') return;

    dispatch(getTransactionChildren.request(null, { id, rowIndex }));

    ApiClient.analytics.transactionChildren(id)
      .then((data) => dispatch(getTransactionChildren.success(data.txHeaders, { id, rowIndex })))
      .catch((error) => dispatch(getTransactionChildren.failure(error, { id, rowIndex })));
  };
}

export const rollbackTransaction = createAsyncAction(
  'ANALYTICS_ROLLBACK_TRANSACTION_REQUEST',
  'ANALYTICS_ROLLBACK_TRANSACTION_SUCCESS',
  'ANALYTICS_ROLLBACK_TRANSACTION_FAIL',
)();

export function rollbackTransactionAsync(id, password) {
  return (dispatch, getState) => {
    const txById = getState().analytics.transactions.byId;

    if (txById[id]?.status === 'loading') return;

    const initialStatus = txById[id]?.status;

    dispatch(rollbackTransaction.request(null, { id, status: 'loading' }));

    ApiClient.analytics.rollbackTransaction({ id, password })
      .then((data) => dispatch(rollbackTransaction.success(data, { id, status: 'rollback' })))
      .catch((error) => dispatch(rollbackTransaction.failure(error, { id, status: initialStatus })));
  };
}

export const getKeyIndicatorsSummaryCharts = createAsyncAction(
  'ANALYTICS_GET_KEY_INDICATORS_SUMMARY_CHART_REQUEST',
  'ANALYTICS_GET_KEY_INDICATORS_SUMMARY_CHART_SUCCESS',
  'ANALYTICS_GET_KEY_INDICATORS_SUMMARY_CHART_FAIL',
)();

export function getKeyIndicatorsSummaryChartsAsync(params) {
  return (dispatch, getState) => {
    if (getState().analytics.stats.keyIndicators.summaryChartFetchStatus.loading) {
      return;
    }

    dispatch(getKeyIndicatorsSummaryCharts.request());

    ApiClient.analytics.getKeyIndicatorsSummary(params)
      .then((data) => dispatch(getKeyIndicatorsSummaryCharts.success(data, params)))
      .catch((error) => dispatch(getKeyIndicatorsSummaryCharts.failure(error)));
  };
}

export const getKeyIndicatorsStatusesCharts = createAsyncAction(
  'ANALYTICS_GET_KEY_INDICATORS_STATUSES_CHART_REQUEST',
  'ANALYTICS_GET_KEY_INDICATORS_STATUSES_CHART_SUCCESS',
  'ANALYTICS_GET_KEY_INDICATORS_STATUSES_CHART_FAIL',
)();

export function getKeyIndicatorsStatusesChartsAsync(params) {
  return (dispatch, getState) => {
    if (getState().analytics.stats.keyIndicators.statusesChartFetchStatus.loading) {
      return;
    }

    dispatch(getKeyIndicatorsStatusesCharts.request());

    ApiClient.analytics.getKeyIndicatorsStatuses(params)
      .then((data) => dispatch(getKeyIndicatorsStatusesCharts.success(data, params)))
      .catch((error) => dispatch(getKeyIndicatorsStatusesCharts.failure(error)));
  };
}

export const getProductAnalyticsChart = createAsyncAction(
  'ANALYTICS_GET_PRODUCT_ANALYTICS_CHART_REQUEST',
  'ANALYTICS_GET_PRODUCT_ANALYTICS_CHART_SUCCESS',
  'ANALYTICS_GET_PRODUCT_ANALYTICS_CHART_FAIL',
)();

export function getProductAnalyticsChartAsync(params, { id, segment }) {
  return (dispatch, getState) => {
    if (getState().analytics.charts.status[id]?.loading) {
      return;
    }

    dispatch(getProductAnalyticsChart.request(null, { id }));

    ApiClient.analytics.getProductAnalyticsChart(params, segment)
      .then((response) => dispatch(getProductAnalyticsChart.success(response, { id, params, segment })))
      .catch((error) => dispatch(getProductAnalyticsChart.failure(error, { id })));
  };
}
