import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsAsync } from '../../../actions/dictionaries';

const selector = (state) => state.dictionaries.products;

/**
 * @returns {ProductModel[]}
 */
function useProducts() {
  const dispatch = useDispatch();
  const products = useSelector(selector);

  useEffect(() => dispatch(getProductsAsync()), []);

  return products;
}

export default useProducts;
