import { useLayoutEffect, useState } from 'react';

const usePrefixDimensions = (inputRef, prefix) => {
  const [style, setStyle] = useState(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      setStyle({
        paddingLeft: `${inputRef.current.previousElementSibling.clientWidth + 13}px`,
      });
    }
  }, [prefix]);

  return style;
};

export default usePrefixDimensions;
