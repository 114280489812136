import moment from 'moment';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getProductAnalyticsChart } from '../actions/analytics';
import * as actions from '../actions/analytics';
import normalize from '../utils/normalize';
import { asyncReducerFactory, tableReducerFactory } from '../utils/redux-utils';

const transactionsDataReducer = createReducer({})
  .handleAction(actions.getTransactions.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload),
  }))
  .handleAction(actions.getTransactionInfo.success, (state, { payload }) => ({
    ...state,
    [payload.id]: {
      ...state[payload.id],
      status: payload.status?.status,
    },
  }))
  .handleAction([
    actions.rollbackTransaction.request,
    actions.rollbackTransaction.success,
    actions.rollbackTransaction.failure,
  ], (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      status: meta.status ?? state[meta.id].status,
    },
  }));

const transactionsInfoDataReducer = createReducer({})
  .handleAction(actions.getTransactionInfo.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }))
  .handleAction([
    actions.rollbackTransaction.request,
    actions.rollbackTransaction.success,
    actions.rollbackTransaction.failure,
  ], (state, { meta }) => ({
    ...state,
    ...(state[meta.id] ? {
      [meta.id]: {
        ...state[meta.id],
        status: meta.status ? { status: meta.status } : state[meta.id],
      },
    } : null),
  }));

const transactionsChildrenReducer = createReducer({})
  .handleAction(actions.getTransactionChildren.request, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      status: 'loading',
    },
  }))
  .handleAction(actions.getTransactionChildren.failure, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      error: payload,
      status: 'error',
    },
  }))
  .handleAction(actions.getTransactionChildren.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      data: payload,
      status: 'loaded',
    },
  }));

const transactionsChildrenStatusReducer = createReducer({ loading: false, error: null, state: 'initial', success: false, id: null })
  .handleAction(actions.getTransactionChildren.request, (state, { meta }) => ({
    loading: true,
    success: false,
    error: null,
    state: 'loading',
    id: meta.id,
    rowIndex: meta.rowIndex,
  }))
  .handleAction(actions.getTransactionChildren.failure, (state, { payload, meta }) => ({
    loading: false,
    success: false,
    error: payload,
    state: 'error',
    id: meta.id,
    rowIndex: meta.rowIndex,
  }))
  .handleAction(actions.getTransactionChildren.success, (state, { meta }) => ({
    loading: false,
    success: true,
    error: null,
    state: 'loaded',
    id: meta.id,
    rowIndex: meta.rowIndex,
  }));

const chartsReducer = createReducer({ data: {}, status: {} })
  .handleAction(
    [actions.getChart.request, actions.getBreakdown.request, actions.getSupportChart.request, actions.getProductAnalyticsChart.request],
    (state, { meta }) => ({
      ...state,
      status: {
        ...state.status,
        [meta.id]: {
          loading: true,
          error: null,
        },
      },
    }),
  )
  .handleAction(
    [actions.getChart.failure, actions.getSupportChart.failure],
    (state, { payload, meta }) => ({
      data: {
        ...state.data,
        [meta.id]: { ok: [], pending: [], failed: [] },
      },
      status: {
        ...state.status,
        [meta.id]: {
          loading: false,
          error: payload,
        },
      },
    }),
  )
  .handleAction(
    [actions.getBreakdown.failure, actions.getProductAnalyticsChart.failure],
    (state, { payload, meta }) => ({
      data: {
        ...state.data,
        [meta.id]: [],
      },
      status: {
        ...state.status,
        [meta.id]: {
          loading: false,
          error: payload,
        },
      },
    }),
  )
  .handleAction(
    [actions.getChart.success, actions.getBreakdown.success, actions.getSupportChart.success, actions.getProductAnalyticsChart.success],
    (state, { payload, meta }) => ({
      data: {
        ...state.data,
        [meta.id]: payload,
      },
      status: {
        ...state.status,
        [meta.id]: {
          loading: false,
          error: null,
        },
      },
    }),
  );

const keyIndicatorsSummaryChartsReducer = createReducer([])
  .handleAction(actions.getKeyIndicatorsSummaryCharts.success, (state, { payload, meta }) => payload.map((currencyChart) => ({
    sampling: meta.sampling,
    ...currencyChart,
    data: Object.fromEntries(Object.keys(currencyChart.data).map((key) => (
      [key, currencyChart.data[key].length === 0 ? currencyChart.data[key] : getNormalizedChartData(currencyChart.data[key], meta)]
    ))),
  })));

const keyIndicatorsStatusesChartsReducer = createReducer([])
  .handleAction(actions.getKeyIndicatorsStatusesCharts.success, (state, { payload, meta }) => payload.map((currencyChart) => ({
    sampling: meta.sampling,
    ...currencyChart,
    data: Object.fromEntries(Object.keys(currencyChart.data).map((key) => (
      [key, currencyChart.data[key].length === 0 ? currencyChart.data[key] : getNormalizedChartData(currencyChart.data[key], meta)]
    ))),
  })));

function getNormalizedChartData(_data, { sampling, startT, finishT }) {
  const thisDataAtomKeys = Object.keys(_data[0].atom);
  // eslint-disable-next-line no-underscore-dangle
  const _normalizedData = normalize(_data, 'timestamp');
  const diff = finishT - startT;
  let stepUnit = sampling.toLowerCase();
  let stepUnitsAmount = 1;
  if (sampling === 'TEN_MINUTES') {
    stepUnit = 'minute';
    stepUnitsAmount = 10;
  }
  if (sampling === 'MONTH' || sampling === 'YEAR') {
    stepUnit = 'day';
    stepUnitsAmount = sampling === 'MONTH' ? 30 : 365;
  }
  const step = moment.duration(stepUnitsAmount, stepUnit).asMilliseconds();
  const dataItemsCount = Math.floor(diff / step);
  const data = [];

  // TODO: make mutable moment and keep adding (sync with backend)

  while (data.length <= dataItemsCount) {
    const timestamp = startT + data.length * step;
    data.push(_normalizedData[timestamp] ?? {
      atom: thisDataAtomKeys.reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {}),
      timestamp,
    });
  }

  return data;
}

export default combineReducers({
  transactions: combineReducers({
    byId: transactionsDataReducer,
    info: transactionsInfoDataReducer,
    childrenById: transactionsChildrenReducer,
    table: tableReducerFactory(actions.transactionsTable),
    status: asyncReducerFactory(actions.getTransactions),
    infoStatus: asyncReducerFactory(actions.getTransactionInfo),
    childrenByIdStatus: transactionsChildrenStatusReducer,
    rollbackStatus: asyncReducerFactory(actions.rollbackTransaction),
  }),
  charts: chartsReducer,
  stats: combineReducers({
    keyIndicators: combineReducers({
      summaryCharts: keyIndicatorsSummaryChartsReducer,
      statusesCharts: keyIndicatorsStatusesChartsReducer,
      summaryChartFetchStatus: asyncReducerFactory(actions.getKeyIndicatorsSummaryCharts),
      statusesChartFetchStatus: asyncReducerFactory(actions.getKeyIndicatorsStatusesCharts),
    }),
  }),
  getChartStatus: asyncReducerFactory(actions.getChart),
});
