import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/cdek';
import { asyncReducerFactory } from '../utils/redux-utils';

const shipmentPointsByCityIdReducer = createReducer({})
  .handleAction(actions.getCdekShipmentPoints.success, (state, { payload, meta }) => ({
    ...state,
    [meta.cityId]: payload.pvz,
  }));

export default combineReducers({
  shipmentPointsByCityId: shipmentPointsByCityIdReducer,
  getCdekShipmentPointsStatus: asyncReducerFactory(actions.getCdekShipmentPoints),
});
