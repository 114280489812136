import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { Button, FormControl } from '@teko/ui-kit';
import { checkResetPasswordTokenAsync, resetAndChangePasswordAsync } from '../../actions/auth';
import compose from '../../utils/compose';
import { getPageTitleSuffixByReferral } from '../../utils/getReferral';
import { InputField } from '../common/forms/fields';
import withQueryParams from '../common/hoc/withQueryParams';
import EyeOnIcon from '../../assets/svg/eye.svg';
import EyeOffIcon from '../../assets/svg/eye-off.svg';
import StrictPasswordInputField from './StrictPasswordInputField';
import FieldError from '../common/ui/FieldError';

const initialValues = {
  password: '',
  password2: '',
};
const initialErrors = {
  password: 'required',
};
const validationSchema = yup.object().shape({
  password: yup.string().min(8).matches(/[A-Z]/g).matches(/[a-z]/g)
    .matches(/\d/g)
    .required(),
  password2: yup.string().when('password', {
    is: (val) => val?.length > 0,
    then: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required(),
  }),
});

class ResetForm extends PureComponent {
  static propTypes = {
    params: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkResetPasswordTokenStatus: PropTypes.object.isRequired,
    checkResetPasswordToken: PropTypes.func.isRequired,
    resetAndChangePassword: PropTypes.func.isRequired,
    resetAndChangePasswordStatus: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    checkTokenPassed: false,
    newPasswordSent: false,
    // password1Visible: false,
    password2Visible: false,
    initialCheckError: this.props.checkResetPasswordTokenStatus.error,
    initialResetError: this.props.resetAndChangePasswordStatus.error,
  };

  componentDidMount() {
    const { t } = this.props;
    window.document.getElementsByTagName('title')[0].innerText = `${t('reset-form-title')} | ${getPageTitleSuffixByReferral()}`;

    const { params, checkResetPasswordToken } = this.props;

    // if (params.token) {
    checkResetPasswordToken(params.token);
    // }
  }

  componentDidUpdate(prevProps) {
    const { checkResetPasswordTokenStatus, resetAndChangePasswordStatus } = this.props;
    if (prevProps.checkResetPasswordTokenStatus.success !==
      checkResetPasswordTokenStatus.success && checkResetPasswordTokenStatus.success) {
      this.handleCheckSuccess();
    }
    if (prevProps.resetAndChangePasswordStatus.success !==
      resetAndChangePasswordStatus.success && resetAndChangePasswordStatus.success) {
      this.handleSubmitSuccess();
    }
  }

  handleResetAgainClick = () => {
    this.props.history.push('/auth/restore');
  };

  handleGoToLKClick = () => {
    this.props.history.push(`/login${this.props.location.search}`);
  };

  handleSubmit = ({ password }) => {
    const { params: { token } } = this.props;
    this.props.resetAndChangePassword({ token, password });
  };

  handleCheckSuccess = () => this.setState({ checkTokenPassed: true });

  handleSubmitSuccess = () => this.setState({ newPasswordSent: true });

  // handlePassword1VisibilityToggle = () => this.setState((prevState) => ({ password1Visible: !prevState.password1Visible }));
  handlePassword2VisibilityToggle = () => this.setState((prevState) => ({ password2Visible: !prevState.password2Visible }));

  renderForm() {
    const { resetAndChangePasswordStatus, t } = this.props;
    const { checkTokenPassed, password2Visible, initialResetError } = this.state;
    const hasError = resetAndChangePasswordStatus.error && resetAndChangePasswordStatus.error !== initialResetError;

    if (!checkTokenPassed) {
      return (
        <p className="reset-password-text">
          <Trans i18nKey="check-reset-token-loading" ns="auth" />
        </p>
      );
    }

    return (
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ isValid }) => (
          <Form className={classnames('reset-password-form', { 'has-error': hasError })}>
            <FormControl label={t('password')}>
              <StrictPasswordInputField
                name="password"
              />
              <FieldError
                shown={!!resetAndChangePasswordStatus.error}
                message={t([`common:errors.${resetAndChangePasswordStatus.error?.code}`, 'common:errors.default'])}
              />
            </FormControl>

            <FormControl label={t('password-repeat')}>
              <InputField
                name="password2"
                type={password2Visible ? 'text' : 'password'}
                suffix={password2Visible ? (
                  <EyeOffIcon
                    className={classnames('password-unhide-icon')}
                    onClick={this.handlePassword2VisibilityToggle}
                  />
                ) : (
                  <EyeOnIcon
                    className={classnames('password-unhide-icon')}
                    onClick={this.handlePassword2VisibilityToggle}
                  />
                )}
              />
            </FormControl>

            <Button className="reset-password-btn" design="primary" size="l" loading={resetAndChangePasswordStatus.loading} disabled={!isValid}>
              {t('reset-submit')}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  renderResult() {
    const { t } = this.props;

    return (
      <>
        <p className="remember-form-text">
          <Trans i18nKey="reset-form-result" ns="auth" />
        </p>

        <Button className="reset-password-btn" design="primary" size="l" onClick={this.handleGoToLKClick}>
          {t('reset-form-result-btn')}
        </Button>
      </>
    );
  }

  render() {
    const { t, checkResetPasswordTokenStatus } = this.props;
    const { initialCheckError, newPasswordSent } = this.state;
    const hasCheckError = checkResetPasswordTokenStatus.error && checkResetPasswordTokenStatus.error !== initialCheckError;

    if (hasCheckError) {
      return (
        <div className="reset-password-form-wrap">
          <h1 className="reset-password-form-title" dangerouslySetInnerHTML={{ __html: t('check-reset-token-failure') }} />
          <p className="reset-password-text">
            <Trans i18nKey="reset-again-hint" ns="auth" />
          </p>
          <Button className="reset-password-btn" design="primary" size="l" onClick={this.handleResetAgainClick}>
            {t('reset-again-btn')}
          </Button>
        </div>
      );
    }

    return (
      <div className="reset-password-form-wrap">
        <h1 className="reset-password-form-title">{t('reset-form-title')}</h1>
        {newPasswordSent ? this.renderResult() : this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkResetPasswordTokenStatus: state.auth.checkResetPasswordTokenStatus,
  resetAndChangePasswordStatus: state.auth.resetAndChangePasswordStatus,
});

const mapDispatchToProps = {
  checkResetPasswordToken: checkResetPasswordTokenAsync,
  resetAndChangePassword: resetAndChangePasswordAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withQueryParams,
  withTranslation('auth'),
)(ResetForm);
