import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/blacklist';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getRules.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.rules),
  }))
  .handleAction(
    [actions.getRuleById.success, actions.createRule.success, actions.updateRule.success],
    (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  );

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getRules.success, (state, { payload }) => ({
    ids: payload.rules.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

export default combineReducers({
  byId: byIdReducer,
  table: tableReducer,
  fetchStatus: asyncReducerFactory(actions.getRules),
  fetchByIdStatus: asyncReducerFactory(actions.getRuleById),
  createStatus: asyncReducerFactory(actions.createRule),
  updateStatus: asyncReducerFactory(actions.updateRule),
});
