import { useSelector } from 'react-redux';
import useCompanyInfo from './useCompanyInfo';

const selector = (state) => state.app.currentCompanyId;

/**
 * @returns {CompanyInfoModel}
 */
export default function useCurrentCompanyInfo() {
  const currentCompanyId = useSelector(selector);
  return useCompanyInfo(currentCompanyId);
}
