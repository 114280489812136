import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';

export const getServicesTree = createAsyncAction(
  'GET_SERVICES_TREE_REQUEST',
  'GET_SERVICES_TREE_SUCCESS',
  'GET_SERVICES_TREE_FAIL',
)();

export function getServicesTreeAsync({ companyId }) {
  return (dispatch, getState) => {
    const { services: { getServicesTreeFetchStatus } } = getState();
    if (getServicesTreeFetchStatus.loading || getServicesTreeFetchStatus.success) {
      return;
    }
    dispatch(getServicesTree.request());
    ApiClient.services.getTree({ companyId })
      .then((data) => dispatch(getServicesTree.success(data)))
      .catch((error) => dispatch(getServicesTree.failure(error)));
  };
}

//категории услуг
export const getServiceCategories = createAsyncAction(
  'GET_SERVICES_CATEGORIES_REQUEST',
  'GET_SERVICES_CATEGORIES_SUCCESS',
  'GET_SERVICES_CATEGORIES_FAIL',
)();

export function getServiceCategoriesAsync() {
  return (dispatch, getState) => {
    const { services: { getServiceCategoriesFetchStatus } } = getState();
    if (getServiceCategoriesFetchStatus.loading || getServiceCategoriesFetchStatus.success) {
      return;
    }
    dispatch(getServiceCategories.request());
    ApiClient.services.getCategories()
      .then((data) => {
        dispatch(getServiceCategories.success(data));
      })
      .catch((error) => dispatch(getServiceCategories.failure(error)));
  };
}

export const addServices = createAsyncAction(
  'ADD_SERVICES_REQUEST',
  'ADD_SERVICES_SUCCESS',
  'ADD_SERVICES_FAIL',
)();

export function addServicesAsync(ids) {
  return (dispatch, getState) => {
    const { currentCompanyId } = getState().app;
    dispatch(addServices.request());

    ApiClient.services.addServices({ companyId: currentCompanyId, ids })
      .then((data) => {
        const notification = {
          type: 'success',
          message: `Товар${ids.length > 1 ? 'ы' : ''} успешно добавлен`,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(addServices.success(data));
      })
      .catch((error) => dispatch(addServices.failure(error)));
  };
}

export const deleteServices = createAsyncAction(
  'DELETE_SERVICES_REQUEST',
  'DELETE_SERVICES_SUCCESS',
  'DELETE_SERVICES_FAIL',
)();

export function deleteServicesAsync(data) {
  return (dispatch) => {
    dispatch(deleteServices.request());

    ApiClient.services.deleteServices(data)
      .then((data) => {
        dispatch(deleteServices.success(data));
      })
      .catch((error) => dispatch(deleteServices.failure(error)));
  };
}

//get all services in category
export const getServicesByCategory = createAsyncAction(
  'GET_SERVICES_BY_CATEGORIES_REQUEST',
  'GET_SERVICES_BY_CATEGORIES_SUCCESS',
  'GET_SERVICES_BY_CATEGORIES_FAIL',
)();

export function getServicesByCategoryAsync(categoryId) {
  return (dispatch) => {
    dispatch(getServicesByCategory.request());
    ApiClient.services.getServicesByCategory(categoryId)
      .then((data) => {
        dispatch(getServicesByCategory.success(data?.products, { categoryId }));
      })
      .catch((error) => dispatch(getServicesByCategory.failure(error)));
  };
}

//connected services
export const getConnectedServices = createAsyncAction(
  'GET_CONNECTED_SERVICES_REQUEST',
  'GET_CONNECTED_SERVICES_SUCCESS',
  'GET_CONNECTED_SERVICES_FAIL',
)();

export function getConnectedServicesAsync(params) {
  return (dispatch, getState) => {
    dispatch(getConnectedServices.request());
    const { currentCompanyId } = getState().app;

    ApiClient.services.getConnected({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getConnectedServices.success(data?.products));
      })
      .catch((error) => dispatch(getConnectedServices.failure(error)));
  };
}

//connected services ready to be published on Boxretail
export const getServicesToPublish = createAsyncAction(
  'GET_SERVICES_TO_PUBLISH_REQUEST',
  'GET_SERVICES_TO_PUBLISH_SUCCESS',
  'GET_SERVICES_TO_PUBLISH_FAIL',
)();

export function getServicesToPublishAsync(params) {
  return (dispatch, getState) => {
    dispatch(getServicesToPublish.request());
    const { currentCompanyId } = getState().app;
    ApiClient.services.getToPublish({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getServicesToPublish.success(data));
      })
      .catch((error) => dispatch(getServicesToPublish.failure(error)));
  };
}

//services published on platform
export const getPublishedServices = createAsyncAction(
  'GET_PUBLISHED_SERVICES_REQUEST',
  'GET_PUBLISHED_SERVICES_SUCCESS',
  'GET_PUBLISHED_SERVICES_FAIL',
)();

export function getPublishedServicesAsync(params) {
  return (dispatch, getState) => {
    dispatch(getPublishedServices.request());
    const { currentCompanyId } = getState().app;
    const meta = { platform: params.platform, warehouse: params.warehouse };
    ApiClient.services.getPublished({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getPublishedServices.success(data, meta));
      })
      .catch((error) => dispatch(getPublishedServices.failure(error, meta)));
  };
}
