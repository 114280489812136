import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { companiesSelector } from '../../../selectors/companies';

function useDealerOptions() {
  const companies = useSelector(companiesSelector);

  return useMemo(
    () => companies.map((company) => ({
      value: company.id,
      label: company.name,
      search: `${company.name} ${company.id}`,
    })),
    [companies],
  );
}

export default useDealerOptions;
