import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyHistoryAsync } from '../../../actions/companies';

/**
 * @param {string} id - companyId
 * @returns {CompanyHistoryModel}
 */
export default function useCompanyHistory(id) {
  const dispatch = useDispatch();
  const companyHistory = useSelector((state) => state.companies.companyHistory[id]);

  useEffect(() => {
    dispatch(getCompanyHistoryAsync(id));
  }, [id]);

  return companyHistory;
}
