import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { companiesByIdSelector } from '../../../selectors/companies';
import useBoxretailOutlets from './useBoxretailOutlets';

function useBoxretailOutletsOptions({ withGlobalIds = true, companyId, complexLabel } = {}) {
  const { activeBoxretailOutlets: outlets } = useBoxretailOutlets(companyId);
  const companies = useSelector(companiesByIdSelector);

  return useMemo(
    () => (outlets ?? []).map((outlet) => ({
      value: withGlobalIds ? `${outlet.globalId}|${outlet.id}` : outlet.id,
      name: outlet.fullName,
      label: complexLabel ? <><p className="complex-label-main">{outlet.fullName}</p><p className="complex-label-additional">{outlet.id}</p></> : (outlet.fullName || outlet.id),
      search: `${outlet.fullName} ${outlet.id}`,
      group: companies[outlet.globalId]?.name ?? outlet.globalId,
    })),
    [outlets, companies, withGlobalIds],
  );
}

export default useBoxretailOutletsOptions;
