import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/reports';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getReports.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.reports),
  }))
  .handleAction(actions.restartReport.success, (state, { payload: id }) => {
    if (!state[id]) return state;

    return {
      ...state,
      [id]: {
        ...state[id],
        status: 'PENDING',
      },
    };
  });

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getReports.success, (state, { payload }) => ({
    ids: payload.reports.map((report) => report.id),
    totalCount: payload.totalCount,
  }));

const typeByPartnerIdReducer = createReducer([])
  .handleAction(actions.getReportTypes.success, (state, { payload }) => payload);

const tasksReducer = createReducer([])
  .handleAction(actions.getReportTasks.success, (state, { payload }) => payload.tasks)
  .handleAction(actions.deleteReportTask.success, (state, { payload: id }) => state.filter((item) => item.id !== id));

export default combineReducers({
  byId: byIdReducer,
  table: tableReducer,
  types: typeByPartnerIdReducer,
  tasks: tasksReducer,
  fetchStatus: asyncReducerFactory(actions.getReports),
  getTypesStatus: asyncReducerFactory(actions.getReportTypes),
  createStatus: asyncReducerFactory(actions.createReport),
  saveReportTaskStatus: asyncReducerFactory(actions.saveReportTask),
  fetchReportTasksStatus: asyncReducerFactory(actions.getReportTasks),
});
