import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { asyncReducerFactory } from '../utils/redux-utils';
import * as actions from '../actions/auth';

const loginInitialState = {
  loading: false,
  error: null,
  authenticated: true,
  email: '',
};

const loginReducer = createReducer(loginInitialState)
  .handleAction([
    actions.login.request,
    actions.googleLogin.request,
    actions.appleLogin.request,
    actions.facebookLogin.request,
    actions.yandexLogin.request,
    actions.vkLogin.request,
  ], (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction([
    actions.login.success,
    actions.googleLogin.success,
    actions.appleLogin.success,
    actions.facebookLogin.success,
    actions.yandexLogin.success,
    actions.vkLogin.success,
  ], (state) => ({
    ...state,
    loading: false,
    authenticated: true,
  }))
  .handleAction([
    actions.login.failure,
    actions.googleLogin.failure,
    actions.appleLogin.failure,
    actions.facebookLogin.failure,
    actions.yandexLogin.failure,
    actions.vkLogin.failure,
  ], (state, { payload, meta }) => ({
    ...state,
    loading: false,
    authenticated: false,
    error: payload,
    email: meta.username,
  }))
  .handleAction([actions.registerPassword.success, actions.setInvitedUserPassword.success], (state) => ({
    ...state,
    authenticated: true,
  }))
  .handleAction(actions.unauthorized, (state) => ({
    ...state,
    authenticated: false,
  }))
  .handleAction(actions.setLoginEmail, (state, { payload }) => ({
    ...state,
    email: payload,
  }));

const registrationReducer = createReducer({ id: '', email: '' })
  .handleAction(actions.registerEmail.success, (state, { payload, meta }) => ({
    id: payload.id,
    email: meta.email,
  }));

const inviteTokenInfoReducer = createReducer({ invitedBy: '', companyNames: [], role: '' })
  .handleAction(actions.checkInviteToken.success, (state, { payload: { invitedBy, companyNames, role } }) => ({
    invitedBy,
    companyNames,
    role,
  }));

const oauthParamsReducer = createReducer({})
  .handleAction(actions.setOauthParams, (state, { payload }) => payload);

const isNewGoogleUserReducer = createReducer(false)
  .handleAction(actions.googleLogin.success, (state, { payload }) => !!payload.isNewUser);

const isNewAppleUserReducer = createReducer(false)
  .handleAction(actions.appleLogin.success, (state, { payload }) => !!payload.isNewUser);

const isNewYandexUserReducer = createReducer(false)
  .handleAction(actions.yandexLogin.success, (state, { payload }) => !!payload.isNewUser);

const yandexAuthTokenReducer = createReducer({ accessToken: '' })
  .handleAction(actions.yandexLogin.failure, (state, { meta }) => ({
    accessToken: meta.accessToken,
  }));

const yandexAuthIdReducer = createReducer({ id: '' })
  .handleAction(actions.registerEmail.success, (state, { meta: id }) => ({
    id,
  }));

const isNewVKUserReducer = createReducer(false)
  .handleAction(actions.vkLogin.success, (state, { payload }) => !!payload.isNewUser);

const vkAuthReducer = createReducer({ accessToken: '', uuid: '' })
  .handleAction(actions.vkLogin.failure, (state, { meta: { accessToken, uuid, otpId }, payload: { info } }) => ({
    accessToken,
    uuid,
    otpId: info?.otpId ?? otpId,
  }));

export default combineReducers({
  login: loginReducer,
  registration: registrationReducer,
  inviteTokenInfo: inviteTokenInfoReducer,
  oauthParams: oauthParamsReducer,
  isNewGoogleUser: isNewGoogleUserReducer,
  isNewAppleUser: isNewAppleUserReducer,
  isNewYandexUser: isNewYandexUserReducer,
  yandexAuthToken: yandexAuthTokenReducer,
  yandexAuthId: yandexAuthIdReducer,
  isNewVKUser: isNewVKUserReducer,
  vkAuth: vkAuthReducer,
  regEmailStatus: asyncReducerFactory(actions.registerEmail),
  googleLoginStatus: asyncReducerFactory(actions.googleLogin),
  appleLoginStatus: asyncReducerFactory(actions.appleLogin),
  facebookLoginStatus: asyncReducerFactory(actions.facebookLogin),
  yandexLoginStatus: asyncReducerFactory(actions.yandexLogin),
  vkLoginStatus: asyncReducerFactory(actions.vkLogin),
  regCodeStatus: asyncReducerFactory(actions.registerCode),
  regPassStatus: asyncReducerFactory(actions.registerPassword),
  recoverPassStatus: asyncReducerFactory(actions.recoverPassword),
  checkResetPasswordTokenStatus: asyncReducerFactory(actions.checkResetPasswordToken),
  resetAndChangePasswordStatus: asyncReducerFactory(actions.resetAndChangePassword),
  checkInviteTokenStatus: asyncReducerFactory(actions.checkInviteToken),
  setInvitedUserPasswordStatus: asyncReducerFactory(actions.setInvitedUserPassword),
  changePasswordStatus: asyncReducerFactory(actions.changePassword),
});
