import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanySubscriptionsAsync } from '../../../actions/companies';
import { currentSubscriptionsSelector } from '../../../selectors/companies';

/**
 * @typedef {Object} SubscriptionModel
 * @property {boolean} isActive
 * @property {boolean} isFreezed
 * @property {number} nextPaymentDate
 * @property {MoneyAmount} price
 * @property {'start'|'online'|'retail'|'plus'} tariffId
 * @property {string} tariffName
 * @property {PlanIntervalModel} interval
 * @property {'start'|'online'|'retail'|'plus'} nextTariffId
 * @property {string} nextTariffName
 * @property {PlanIntervalModel} nextTariffInterval
 * @property {number} trialEndDate
 * @property {string} companyId
 * @property {string} companyName
 * @property {string} companyColor
 */

/**
 * @returns {SubscriptionModel[]}
 */
function useCurrentSubscriptions() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanySubscriptionsAsync());
  }, []);

  return useSelector(currentSubscriptionsSelector);
}

export default useCurrentSubscriptions;
