import { useField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import ColorInput from '../inputs/ColorInput';

function ColorInputField(props) {
  const [field, meta] = useField(props.name);

  return (
    <ColorInput
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
    />
  );
}

ColorInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ColorInputField;
