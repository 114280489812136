import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotificationsAsync } from '../../../actions/notifications';
import { unreadNotificationsSelector } from '../../../selectors/notifications';

const NOTIFICATIONS_INTERVAL = 15000;
const MONGO_ZERO = '000000000000000000000000';

function useUserNotifications() {
  const dispatch = useDispatch();
  const { notifications, totalCount } = useSelector(unreadNotificationsSelector);
  const notificationsRef = useRef(notifications);

  notificationsRef.current = notifications;

  useEffect(() => {
    let pollingId;
    let isUnMounted = false;

    const poll = () => {
      requestAnimationFrame(() => { // skip execution if document is hidden
        if (isUnMounted) return;

        const params = {
          alreadyRead: false,
          firstId: notificationsRef.current[0] ? notificationsRef.current[0].id : MONGO_ZERO,
        }; // limit: 50

        dispatch(getUserNotificationsAsync(params));
        pollingId = setTimeout(poll, NOTIFICATIONS_INTERVAL);
      });
    };

    poll();

    return () => {
      isUnMounted = true;
      clearTimeout(pollingId);
    };
  }, []);

  return useMemo(() => ({ notifications, totalCount }), [notifications, totalCount]);
}

export default useUserNotifications;
