import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';

export const getReports = createAsyncAction(
  'REPORTS_GET_LIST_REQUEST',
  'REPORTS_GET_LIST_SUCCESS',
  'REPORTS_GET_LIST_FAIL',
)();

export function getReportsAsync(params) {
  return (dispatch) => {
    dispatch(getReports.request());

    ApiClient.reports.get(params)
      .then((data) => dispatch(getReports.success(data)))
      .catch((error) => dispatch(getReports.failure(error)));
  };
}

export const getReportTypes = createAsyncAction(
  'REPORTS_GET_TYPES_REQUEST',
  'REPORTS_GET_TYPES_SUCCESS',
  'REPORTS_GET_TYPES_FAIL',
)();

export function getReportTypesAsync() {
  return (dispatch) => {
    // if (getState().reports.getTypesStatus.state !== 'initial' && !force) {
    //   return;
    // }

    dispatch(getReportTypes.request());

    ApiClient.reports.getReportTypes()
      .then((data) => dispatch(getReportTypes.success(data.report_types)))
      .catch((error) => dispatch(getReportTypes.failure(error)));
  };
}

export const createReport = createAsyncAction(
  'REPORTS_CREATE_REQUEST',
  'REPORTS_CREATE_SUCCESS',
  'REPORTS_CREATE_FAIL',
)();

export function createReportAsync(report) {
  return (dispatch) => {
    dispatch(createReport.request());

    ApiClient.reports.create(report)
      .then((data) => dispatch(createReport.success(data)))
      .catch((error) => dispatch(createReport.failure(error)));
  };
}

export const restartReport = createAsyncAction(
  'REPORTS_RESTART_REQUEST',
  'REPORTS_RESTART_SUCCESS',
  'REPORTS_RESTART_FAIL',
)();

export function restartReportAsync(id) {
  return (dispatch) => {
    dispatch(restartReport.request());

    ApiClient.reports.restart(id)
      .then(() => {
        // TODO: i18n
        const notification = {
          type: 'success',
          message: 'Повторное формирование отчета успешно запущено',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(restartReport.success(id));
      })
      .catch((error) => dispatch(restartReport.failure(error)));
  };
}

export const saveReportTask = createAsyncAction(
  'REPORTS_SAVE_TASK_REQUEST',
  'REPORTS_SAVE_TASK_SUCCESS',
  'REPORTS_SAVE_TASK_FAIL',
)();

export function saveReportTaskAsync(task) {
  return (dispatch) => {
    dispatch(saveReportTask.request());

    ApiClient.reports.saveReportTask(task)
      .then((data) => dispatch(saveReportTask.success(data)))
      .catch((error) => dispatch(saveReportTask.failure(error)));
  };
}

export const deleteReportTask = createAsyncAction(
  'REPORTS_DELETE_TASK_REQUEST',
  'REPORTS_DELETE_TASK_SUCCESS',
  'REPORTS_DELETE_TASK_FAIL',
)();

export function deleteReportTaskAsync(id) {
  return (dispatch) => {
    dispatch(deleteReportTask.request());

    ApiClient.reports.deleteReportTask(id)
      .then((data) => dispatch(deleteReportTask.success(id)))
      .catch((error) => dispatch(deleteReportTask.failure(error)));
  };
}

export const getReportTasks = createAsyncAction(
  'REPORTS_GET_TASK_LIST_REQUEST',
  'REPORTS_GET_TASK_LIST_SUCCESS',
  'REPORTS_GET_TASK_LIST_FAIL',
)();

export function getReportTasksAsync() {
  return (dispatch) => {
    dispatch(getReportTasks.request());

    ApiClient.reports.getReportTasks()
      .then((data) => dispatch(getReportTasks.success(data)))
      .catch((error) => dispatch(getReportTasks.failure(error)));
  };
}
