import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { appleLoginAsync } from '../../../actions/auth';
import { getAuthRequestExtraData } from '../../../utils';

export const AppleAuthContext = React.createContext();

function loadAppleAuth() {
  if ('AppleID' in window) {
    return Promise.resolve();
  }

  const script = window.document.head.querySelector('script[data-appleauth]');

  if (!script) {
    return Promise.reject(new Error('AppleAuth script element not found (script[data-appleauth])'));
  }

  return new Promise((resolve) => {
    script.addEventListener('load', resolve);
  });
}

function AppleAuthProvider({ children }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState();

  const handleSignInOnSuccess = useCallback((data) => {
    dispatch(appleLoginAsync({ ...data.detail, ...getAuthRequestExtraData() }));
  }, []);

  const handleSignInOnFailure = useCallback((data) => {
    console.warn('handleSignInOnFailure: ', data);
  }, []);

  const initAppleAuth = useCallback(() => {
    window.AppleID.auth.init({
      clientId: 'io.teko',
      scope: 'name email',
      redirectURI: `${window.location.origin}/api/auth/appleLogin`,
      //state: 'abcd',
      nonce: `${Date.now()}`,
      usePopup: true,
    });

    setValue(window.AppleID);

    document.addEventListener('AppleIDSignInOnSuccess', handleSignInOnSuccess);
    document.addEventListener('AppleIDSignInOnFailure ', handleSignInOnFailure);
  }, []);

  useEffect(() => {
    loadAppleAuth()
      .then(initAppleAuth)
      .catch(console.warn);

    return () => {
      document.removeEventListener('AppleIDSignInOnSuccess', handleSignInOnSuccess);
      document.removeEventListener('AppleIDSignInOnFailure', handleSignInOnFailure);
    };
  }, []);

  return (
    <AppleAuthContext.Provider value={value}>
      {children}
    </AppleAuthContext.Provider>
  );
}

AppleAuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AppleAuthProvider;
