import classnames from 'classnames';
import { stringify } from 'qs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setOauthParams } from '../../actions/auth';
import { getReferrerUrlsAsync, getUserAsync } from '../../actions/users';
import ecommerceSrc1x from '../../assets/img/auth/ecommerce-intro.png';
import ecommerceSrcWebp1x from '../../assets/img/auth/ecommerce-intro.webp';
import ecommerceSrc2x from '../../assets/img/auth/ecommerce-intro@2x.png';
import ecommerceSrcWebp2x from '../../assets/img/auth/ecommerce-intro@2x.webp';
import poweredByTekoLogo1x from '../../assets/img/auth/powered-by.png';
import poweredByTekoLogo2x from '../../assets/img/auth/powered-by@2x.png';
import retailSrc1x from '../../assets/img/auth/retail-intro.png';
import retailSrcWebp1x from '../../assets/img/auth/retail-intro.webp';
import retailSrc2x from '../../assets/img/auth/retail-intro@2x.png';
import retailSrcWebp2x from '../../assets/img/auth/retail-intro@2x.webp';
import talktoIllustrationSrc1x from '../../assets/img/auth/talkto-image.png';
import talktoIllustrationSrcWebp1x from '../../assets/img/auth/talkto-image.webp';
import talktoIllustrationSrc2x from '../../assets/img/auth/talkto-image@2x.png';
import talktoIllustrationSrcWebp2x from '../../assets/img/auth/talkto-image@2x.webp';
import artLogoSrc from '../../assets/img/auth/art-logo.png';
import artLogoSrc2x from '../../assets/img/auth/art-logo@2x.png';
import PoweredByTekoSrc from '../../assets/img/powered-by-teko@3x.png';
import AtolLogo from '../../assets/svg/logos/atol.svg';
import TalktoLogo from '../../assets/svg/logos/talkto-new.svg';
// import TekoLogo from '../../assets/svg/teko-logo-big.svg';
import TekoLogo from '../../assets/svg/teko-logo-small.svg';
import TalktoBusinessLogo from '../../assets/svg/talkto-business-logo-small.svg';
import LangIcon from '../../assets/svg/union.svg';
import { getAuthRequestExtraData } from '../../utils';
import { getReferralByUrl } from '../../utils/getReferral';
import { useMetaViewport, usePage, useQueryParams, useThunderBot, useUser } from '../common/hooks';
import usePassQueryToSessionStorage from '../common/hooks/usePassQueryToSessionStorage';
import InformationModal from '../lk/InformationModal';
import AcceptNewUserForm from './AcceptNewUserForm';
import Processing from './Processing';
import RegistrationCompletedContext from './RegistrationCompletedContext';
import RegistrationFinalWithPlatformSelect from './RegistrationFinalWithPlatformSelect';
import RegistrationForm from './RegistrationForm';
import RememberForm from './RememberForm';
import ResetForm from './ResetForm';
import SignInForm from './SignInForm';

const privacyPolicyUrlByLang = {
  ru: 'https://teko.io/privacy',
  en: 'https://teko.io/en/privacy',
};

const routes = [
  { path: '/login', component: SignInForm },
  { path: '/auth/reset', component: ResetForm },
  { path: '/auth/restore', component: RememberForm },
  { path: '/auth/accept', component: AcceptNewUserForm },
  { path: '/register/processing', component: Processing },
  { path: '/register', component: RegistrationForm },
];

export const authPaths = routes.map((route) => route.path);

const LogoByBrand = {
  atol: AtolLogo,
  teko: TekoLogo,
  talkto: TalktoLogo,
  talktoBusiness: TalktoBusinessLogo,
};

const getUrlByBrand = (lang) => ({
  atol: 'https://atol.ru',
  teko: `https://teko.io/${lang}`,
  talkto: 'https://talkto.io',
  talktoBusiness: 'https://talkto.io',
});

function Auth() {
  const { t, i18n } = useTranslation('auth');
  const lang = i18n.language.split('-')[0];
  // const year = useMemo(() => moment().get('year'), []);
  const tb = useThunderBot();
  const dispatch = useDispatch();
  const user = useUser();
  const authenticated = useSelector((state) => state.auth.login.authenticated);
  const oauthParams = useSelector((state) => state.auth.oauthParams);
  const userFetchStatus = useSelector((state) => state.users.currentUserFetchStatus);
  const vkLoginFetchStatus = useSelector((state) => state.auth.vkLoginStatus);
  const initialUserFetchStatusRef = useRef(true);
  const [illustrationWrapStyle, setIllustrationWrapStyle] = useState(() => ({}));
  const [params] = useQueryParams();
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const registrationCompletedContextValue = useMemo(() => ({
    registrationCompleted,
    setRegistrationCompleted,
  }), [registrationCompleted]);
  // const FB = useThunderBot();
  usePassQueryToSessionStorage(params);
  useMetaViewport('width=device-width, initial-scale=1, user-scalable=no');

  if (!oauthParams.redirectUrl && params.redirectUrl) {
    dispatch(setOauthParams(params)); // moved in render to ensure brand is correctly determined from first render
  }

  const brand = getReferralByUrl() || 'teko';
  const Logo = LogoByBrand[brand] ?? TekoLogo;

  const handleHelpClick = (e) => {
    e.preventDefault();
    tb.openWidget();
  };

  const handleSwitchLanguageClick = (e) => {
    e.preventDefault();
    if (lang === 'ru') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('ru');
    }
  };

  const calcSideIllustrationHeight = useCallback((ref) => {
    if (!ref) return;
    if (!illustrationWrapStyle.height || ref.clientHeight > parseInt(illustrationWrapStyle.height)) {
      setIllustrationWrapStyle({ height: `${ref.clientHeight}px` });
    }
  }, []);

  usePage({ className: `page-auth _${brand}` });

  useEffect(() => {
    if (!authenticated) {
      dispatch(getReferrerUrlsAsync(getAuthRequestExtraData()));
    }
  }, [authenticated]);

  useEffect(() => {
    if (authenticated && !vkLoginFetchStatus?.error) { // no need to get user right after logout (if already got 401 code in any response)
      dispatch(getUserAsync());
    }
    initialUserFetchStatusRef.current = false;
  }, []);

  useEffect(() => {
    if (registrationCompleted && brand === 'talkto') {
      dispatch(getUserAsync());
    }
  }, [registrationCompleted, brand]);

  if (authenticated && user) {
    let to = '/lk';
    if (oauthParams.service) {
      to += `?${stringify(oauthParams)}`;
    }
    return (<Redirect to={to} />);
  }

  if (authenticated && (initialUserFetchStatusRef.current || userFetchStatus.state === 'loading')) {
    return null;
  }

  const authContent = (
    <div className="auth-content" ref={calcSideIllustrationHeight}>
      <Switch>
        {routes.map((route) => (
          <Route path={route.path} component={route.component} key={route.path} />
        ))}
      </Switch>
    </div>
  );

  const authFooterWithPoweredBy = (
    <div className="auth-footer">
      <img className="powered-by-logo" srcSet={`${poweredByTekoLogo1x} 1x, ${poweredByTekoLogo2x} 2x`} src={poweredByTekoLogo1x} alt="powered by Teko" />
      <div className="footer-links">
        <a className="footer-link" href={privacyPolicyUrlByLang[lang]} target="_blank" rel="noreferrer">{t('footer-privacy')}</a>
        {lang === 'ru' && (<a className="footer-link" href="#" onClick={handleHelpClick}>{t('footer-help')}</a>)}
        <a className="footer-link" href="#" onClick={handleSwitchLanguageClick}>{t('switch-lang')}<LangIcon /></a>
      </div>
    </div>
  );

  if (registrationCompleted && brand === 'teko') {
    return (
      <RegistrationFinalWithPlatformSelect />
    );
  }

  return (
    <>
      <RegistrationCompletedContext.Provider value={registrationCompletedContextValue}>
        <div className={classnames('page-auth-main', `_${brand}`)}>
          <div className="auth-container">
            <div className="auth-wrap">

              <div className="auth-header">
                <a href={getUrlByBrand(lang)[brand]} className="main-logo-link" target="_blank" rel="noreferrer noopener">
                  {brand !== 'art' && <Logo className={classnames('main-logo', `_${brand}`)} />}
                  {brand === 'art' && <img className="main-logo _art" srcSet={`${artLogoSrc} 1x, ${artLogoSrc2x} 2x`} src={artLogoSrc} alt="Art" /> }
                  {brand !== 'teko' && brand !== 'talkto' && brand !== 'art' && brand !== 'talktoBusiness' && <img className="powered-by-logo" src={PoweredByTekoSrc} alt="Powered by Teko" />}
                </a>
              </div>

              {brand === 'talkto' && (
                <div className="auth-illustration-and-content-wrap">
                  <div className="auth-illustration-wrap" style={illustrationWrapStyle}>
                    <picture>
                      <source srcSet={`${talktoIllustrationSrcWebp1x} 1x, ${talktoIllustrationSrcWebp2x} 2x`} type="image/webp" />
                      <img className="auth-illustration" srcSet={`${talktoIllustrationSrc1x} 1x, ${talktoIllustrationSrc2x} 2x`} src={talktoIllustrationSrc1x} alt="Talkto" />
                    </picture>
                  </div>
                  <div className="auth-content-wrap">
                    {authContent}
                  </div>
                </div>
              )}

              {brand !== 'talkto' && (
                <>
                  {authContent}

                  {brand === 'art' || brand === 'talktoBusiness' ? authFooterWithPoweredBy : (
                    <div className="auth-footer">
                      {brand !== 'teko' && (
                        <div className="footer-logo-wrap">
                          <a className="footer-logo-link" href={`https://teko.io/${lang}`} target="_blank" rel="noreferrer noopener">
                            <TekoLogo className="footer-logo" />
                          </a>
                        </div>
                      )}
                      <a className="footer-link" href={privacyPolicyUrlByLang[lang]} target="_blank" rel="noreferrer">{t('footer-privacy')}</a>
                      {lang === 'ru' && (<a className="footer-link" href="#" onClick={handleHelpClick}>{t('footer-help')}</a>)}
                      <a className="footer-link" href="#" onClick={handleSwitchLanguageClick}>{t('switch-lang')}<LangIcon /></a>
                      {/*<div className="footer-copyright">{t('footer-copy', { year })}</div>*/}
                    </div>
                  )}
                </>
              )}
            </div>

            {brand === 'talkto' && authFooterWithPoweredBy}

          </div>

          {(brand !== 'talkto' && brand !== 'art') && (
            <div className="auth-bg">
              <div className="auth-bg-container">
                <picture>
                  <source srcSet={`${ecommerceSrcWebp1x} 1x, ${ecommerceSrcWebp2x} 2x`} type="image/webp" />
                  <img className="ecommerce-img" srcSet={`${ecommerceSrc1x} 1x, ${ecommerceSrc2x} 2x`} src={ecommerceSrc1x} alt="Ecommerce" />
                </picture>
                <picture>
                  <source srcSet={`${retailSrcWebp1x} 1x, ${retailSrcWebp2x} 2x`} type="image/webp" />
                  <img className="retail-img" srcSet={`${retailSrc1x} 1x, ${retailSrc2x} 2x`} src={retailSrc1x} alt="Retail" />
                </picture>
              </div>
            </div>
          )}

        </div>
      </RegistrationCompletedContext.Provider>

      <InformationModal
        active={userFetchStatus.error?.code === 403 || userFetchStatus.error?.code === 301}
        type={userFetchStatus.error?.code === 301 ? 'wrong-origin' : 'access-denied'}
      />
    </>
  );
}

export default Auth;
