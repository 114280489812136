import { Scrollbar } from '@teko/ui-kit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../../actions/app';
import DeleteIcon from '../../../../assets/svg/circle-close.svg';
import ImgMock from '../../../../assets/svg/noimg.svg';
import PictureIcon from '../../../../assets/svg/picture.svg';
import { getAwizaImgSrc } from '../../../awiza/utils/imgSrc';

export const getFilesArrayFromValue = (value) => {
  // initial '' or null or undefined
  if (!value) {
    return [];
  }
  // manual handled multiple file input value
  if (Array.isArray(value)) {
    return value;
  }
  // manual handled single file input value
  if (typeof value === 'object' && !(value instanceof FileList)) {
    return [value];
  }
  // default FileList value
  return Array.from(value);
};

/**
 * @param {string} name
 * @param {FileList} value
 * @param {string} state
 * @param {boolean} multiple
 * @param {string} accept
 * @param {string} previewUrl
 * @param {function(FileList): void} uploader
 * @param {function(FileList): void} onChange
 * @returns {JSX.Element}
 */
function ImagesInput({ name, value, state, multiple, accept, previewUrl, uploader, onChange }) {
  const dispatch = useDispatch();
  const filesArray = useMemo(() => getFilesArrayFromValue(value), [value]);
  const [previewByFilename, setPreviewByFilename] = useState(() => ({}));
  const [isUploading, setIsUploading] = useState(false);
  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept,
    maxFiles: 10,
    maxSize: 10000000,
    multiple,
    disabled: !multiple && filesArray.length === 1,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const simpleStructuredAcceptedFiles = acceptedFiles.map(({ name }) => ({ name }));
      const simpleStructuredRejectedFiles = rejectedFiles.map(({ errors, file }) => ({ errors, name: file.name }));

      const newFiles = [...acceptedFiles.map((file) => ({ file })), ...rejectedFiles];
      const oldFiles = filesArray.filter(({ name }) => !newFiles.find(({ file: { name: newFileName } }) => newFileName === name));

      const newPreviewByFilename = newFiles.reduce((acc, { errors, file }) => {
        if (!errors || errors.every(({ code }) => code !== 'file-invalid-type')) {
          acc[file.name] = URL.createObjectURL(file);
        }
        return acc;
      }, {});

      setPreviewByFilename((prev) => ({ ...prev, ...newPreviewByFilename }));

      if (!acceptedFiles.length) {
        const value = multiple ? [...oldFiles, ...simpleStructuredRejectedFiles] : simpleStructuredRejectedFiles[0];
        onChange(value);
        return;
      }

      const formData = new FormData();

      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append('files', acceptedFiles[i]);
      }

      const value = multiple ? [...oldFiles, ...simpleStructuredAcceptedFiles, ...simpleStructuredRejectedFiles] : simpleStructuredAcceptedFiles[0];
      onChange(value); // to show preview immediately

      setIsUploading(true);

      uploader(formData)
        .then((attachedFiles) => {
          const mergedAcceptedAndAttachedFiles = simpleStructuredAcceptedFiles.map((file, i) => {
            const attachedFile = attachedFiles[i].name === file.name ? attachedFiles[i] : attachedFiles.find(({ name }) => name === file.name);
            return { ...file, ...attachedFile };
          });
          const value = multiple ? [...oldFiles, ...mergedAcceptedAndAttachedFiles, ...simpleStructuredRejectedFiles] : mergedAcceptedAndAttachedFiles[0];
          onChange(value);
          setIsUploading(false);
        })
        .catch((error) => {
          console.warn(error);
          const failedUploadNames = acceptedFiles.length === 1 ? `файла ${acceptedFiles[0].name}` : `файлов ${acceptedFiles.map(({ name }) => name).join(', ')}`;
          dispatch(showNotification({ type: 'error', message: `Ошибка при загрузке ${failedUploadNames}`, timeout: 5000 }));
          setIsUploading(false);
        });
    },
  });
  const handleRemove = useCallback((e, removingFile) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(Array.isArray(value) ? value.filter(({ name }) => name !== (removingFile.name ?? removingFile.file.name)) : null, true);
  }, [value, onChange]);

  return (
    <div className={classnames('images-input-wrap', { _filled: filesArray.length !== 0, _single: !multiple, _invalid: state === 'invalid' && !isUploading })}>
      <Scrollbar className="images-input-scroll-container">
        {filesArray.map((file, i) => (
          <div className={classnames('loaded-image-block', { _invalid: !!file.errors && !isUploading })} key={file.id || file.name || i}>
            <button className="loaded-image-button" type="button" onClick={(e) => { handleRemove(e, file); }}>
              <DeleteIcon className="loaded-image-icon" />
            </button>

            <div className="loaded-image-wrapper">
              {previewByFilename[file.name] ? (
                <img className="loaded-image" src={previewByFilename[file.name]} alt={file.name} />
              ) : (
                <>
                  {file.id ? (
                    <img className="loaded-image" src={previewUrl.replace('{{fileId}}', file.id)} alt={file.name} />
                  ) : (
                    <ImgMock className="loaded-image" alt={file.name} />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
        {(multiple || filesArray.length === 0) && (
          <div
            {...getRootProps({
              className: classnames('images-input-drag-n-drop-zone', { _drag: isDragActive }),
            })}
          >
            <PictureIcon className="upload-icon" />
            <span className="upload-label">Загрузить изображение</span>
            <input {...getInputProps({ name })} />
          </div>
        )}
      </Scrollbar>
      <div className="images-hint">
        до 10 МБ:<br />
        {accept}
      </div>
    </div>
  );
}

ImagesInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
  state: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  previewUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  uploader: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

ImagesInput.defaultProps = {
  state: 'default',
  multiple: true,
  accept: 'image/jpeg,image/png,.png,.jpg,.jpeg,.svg,.gif',
  previewUrl: getAwizaImgSrc('{{fileId}}'),
};

export default memo(ImagesInput);
