import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function usePagination({ limit = 20, selector, action, onPageFetch }) {
  const dispatch = useDispatch();
  const table = useSelector(selector);
  const handleFirst = useCallback(() => dispatch(action.first(limit)));
  const handlePrev = useCallback(() => dispatch(action.prev(limit)));
  const handleNext = useCallback(() => {
    if (table.last) {
      onPageFetch();
    } else {
      dispatch(action.next(limit));
    }
  }, [table.last, onPageFetch]);

  return useMemo(() => ({
    hasPrev: !table.first,
    hasNext: table.hasMore || !table.last,
    onFirst: handleFirst,
    onPrev: handlePrev,
    onNext: handleNext,
  }), [table, handleFirst, handlePrev, handleNext]);
}

export default usePagination;
