import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import classnames from 'classnames';
import * as yup from 'yup';
import { Button, FormControl } from '@teko/ui-kit';
import { checkInviteTokenAsync, setInvitedUserPasswordAsync } from '../../actions/auth';
import { getUserAsync } from '../../actions/users';
import EyeOffIcon from '../../assets/svg/eye-off.svg';
import EyeOnIcon from '../../assets/svg/eye.svg';
import compose from '../../utils/compose';
import { getPageTitleSuffixByReferral } from '../../utils/getReferral';
import withQueryParams from '../common/hoc/withQueryParams';
import { InputField } from '../common/forms/fields';
import StrictPasswordInputField from './StrictPasswordInputField';
import ExpandableHint from './ExpandableHint';

const initialValues = {
  password: '',
  password2: '',
};
const initialErrors = {
  password: 'required',
};
const validationSchema = yup.object().shape({
  password: yup.string().min(8).matches(/[A-Z]/g).matches(/[a-z]/g)
    .matches(/\d/g)
    .required(),
  password2: yup.string().when('password', {
    is: (val) => val?.length > 0,
    then: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required(),
  }),
});

class AcceptNewUserForm extends PureComponent {
  static propTypes = {
    params: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkInviteTokenStatus: PropTypes.object.isRequired,
    checkInviteToken: PropTypes.func.isRequired,
    setInvitedUserPasswordStatus: PropTypes.object.isRequired,
    currentUserFetchStatus: PropTypes.object.isRequired,
    setInvitedUserPassword: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    checkTokenPassed: false,
    newPasswordSent: false,
    password2Visible: false,
    initialCheckError: this.props.checkInviteTokenStatus.error,
    initialResetError: this.props.setInvitedUserPasswordStatus.error,
  };

  componentDidMount() {
    const { params, checkInviteToken, t } = this.props;
    window.document.getElementsByTagName('title')[0].innerText = `${t('activate-new-user-title')} | ${getPageTitleSuffixByReferral()}`;

    // if (params.token) {
    checkInviteToken(params.token);
    // }
  }

  componentDidUpdate(prevProps) {
    const { checkInviteTokenStatus, setInvitedUserPasswordStatus, getUser } = this.props;
    if (prevProps.checkInviteTokenStatus.success !== checkInviteTokenStatus.success && checkInviteTokenStatus.success) {
      this.handleCheckSuccess();
    }
    if (prevProps.setInvitedUserPasswordStatus.success !== setInvitedUserPasswordStatus.success && setInvitedUserPasswordStatus.success) {
      // updateParams({ origin: '/lk/store' });
      getUser(); // after getting will be redirected to LK main page (in Auth.jsx there is check for authenticated flag and user data)
      // this.handleSubmitSuccess();
    }
  }

  handleResetAgainClick = () => {
    this.props.history.push('/auth/restore');
  };

  handleGoToLKClick = () => {
    this.props.history.push('/auth/login');
  };

  handleSubmit = ({ password }) => {
    const { params: { token } } = this.props;
    this.props.setInvitedUserPassword({ token, password });
  };

  handleCheckSuccess = () => this.setState({ checkTokenPassed: true });

  // handleSubmitSuccess = () => this.setState({ newPasswordSent: true });

  handlePassword2VisibilityToggle = () => this.setState((prevState) => ({ password2Visible: !prevState.password2Visible }));

  renderForm() {
    const { setInvitedUserPasswordStatus, currentUserFetchStatus, t } = this.props;
    const { checkTokenPassed, initialResetError, password2Visible } = this.state;
    const hasError = setInvitedUserPasswordStatus.error && setInvitedUserPasswordStatus.error !== initialResetError;

    if (!checkTokenPassed) {
      return (
        <p className="reset-password-text">
          <Trans i18nKey="check-reset-token-loading" ns="auth" />
        </p>
      );
    }

    return (
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ isValid }) => (
          <Form className={classnames('reset-password-form', { 'has-error': hasError })}>
            <p className="activate-user-hint">
              <Trans i18nKey="activate-hint" ns="auth" />
            </p>

            <FormControl compact>
              <ExpandableHint />
            </FormControl>

            <FormControl label={t('password')}>
              <StrictPasswordInputField
                name="password"
              />
            </FormControl>

            <FormControl label={t('password-repeat')}>
              <InputField
                name="password2"
                type={password2Visible ? 'text' : 'password'}
                suffix={password2Visible ? <EyeOffIcon className="password-eye-icon" onClick={this.handlePassword2VisibilityToggle} /> : <EyeOnIcon className="password-eye-icon" onClick={this.handlePassword2VisibilityToggle} />}
              />
            </FormControl>

            <Button className="reset-password-btn" design="primary" size="l" loading={setInvitedUserPasswordStatus.loading || currentUserFetchStatus.loading} disabled={!isValid}>
              {t('common:save')}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  renderResult() {
    const { t } = this.props;

    return (
      <>
        <p className="remember-form-text">
          <Trans i18nKey="reset-form-result" ns="auth" />
        </p>

        <Button className="reset-password-btn" design="primary" size="l" onClick={this.handleGoToLKClick}>
          {t('reset-form-result-btn')}
        </Button>
      </>
    );
  }

  render() {
    const { t, checkInviteTokenStatus } = this.props;
    const { initialCheckError, newPasswordSent } = this.state;
    const hasCheckError = checkInviteTokenStatus.error && checkInviteTokenStatus.error !== initialCheckError;

    if (hasCheckError) {
      return (
        <div className="reset-password-form-wrap">
          <h1 className="reset-password-form-title" dangerouslySetInnerHTML={{ __html: t('check-reset-token-failure') }} />
          <p className="reset-password-text">
            <Trans i18nKey="activate-again-hint" ns="auth" />
          </p>
          {/*<Button className="reset-password-btn" design="primary" size="l" onClick={this.handleResetAgainClick}>*/}
          {/*  {t('activate-again-btn')}*/}
          {/*</Button>*/}
        </div>
      );
    }

    return (
      <div className="reset-password-form-wrap">
        <h1 className="reset-password-form-title">{t('activate-new-user-title')}</h1>
        {newPasswordSent ? this.renderResult() : this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkInviteTokenStatus: state.auth.checkInviteTokenStatus,
  setInvitedUserPasswordStatus: state.auth.setInvitedUserPasswordStatus,
  currentUserFetchStatus: state.users.currentUserFetchStatus,
});

const mapDispatchToProps = {
  checkInviteToken: checkInviteTokenAsync,
  setInvitedUserPassword: setInvitedUserPasswordAsync,
  getUser: getUserAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withQueryParams,
  withTranslation('auth'),
)(AcceptNewUserForm);
