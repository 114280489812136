import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';
// import { getAwizaCategoriesAsync } from './awiza';

export const getPosterProducts = createAsyncAction(
  'GET_POSTER_PRODUCTS_REQUEST',
  'GET_POSTER_PRODUCTS_SUCCESS',
  'GET_POSTER_PRODUCTS_FAIL',
)();

export function getPosterProductsAsync() {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    // if (getState().awiza.categoriesByCompany[companyId] && !force) {
    //   return;
    // }

    dispatch(getPosterProducts.request());

    ApiClient.poster.getProducts({ companyId })
      .then((data) => {
        const notification = {
          type: 'info',
          message: 'Начался импорт товаров', //TODO change message
          icon: true,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(getPosterProducts.success(data, { companyId }));
        // dispatch(getAwizaCategoriesAsync(true)); //TODO: move to awiza app check for force update based on some flag that poster imported
      })
      .catch((error) => dispatch(getPosterProducts.failure(error)));
  };
}
export const savePosterCompanyName = createAsyncAction(
  'SAVE_POSTER_COMPANY_NAME_REQUEST',
  'SAVE_POSTER_COMPANY_NAME_SUCCESS',
  'SAVE_POSTER_COMPANY_NAME_FAIL',
)();

export function savePosterCompanyNameAsync({ companyName }) {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    dispatch(savePosterCompanyName.request());

    ApiClient.poster.savePosterCompanyName({ posterCompanyId: companyName, companyId })
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Сохранено',
          icon: true,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(savePosterCompanyName.success(data, { companyId, companyName }));
      })
      .catch((error) => dispatch(savePosterCompanyName.failure(error)));
  };
}

export const savePosterToken = createAsyncAction(
  'SAVE_POSTER_TOKEN_REQUEST',
  'SAVE_POSTER_TOKEN_SUCCESS',
  'SAVE_POSTER_TOKEN_FAIL',
)();

export function savePosterTokenAsync(posterToken) {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    dispatch(savePosterToken.request());

    ApiClient.poster.savePosterToken({ posterToken, companyId })
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Сохранено',
          icon: true,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(savePosterToken.success(data, { posterToken, companyId }));
      })
      .catch((error) => dispatch(savePosterToken.failure(error)));
  };
}

export const getPosterInfo = createAsyncAction(
  'GET_POSTER_INFO_REQUEST',
  'GET_POSTER_INFO_SUCCESS',
  'GET_POSTER_INFO_FAIL',
)();

export function getPosterInfoAsync() {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    dispatch(getPosterInfo.request());

    ApiClient.poster.getPosterInfo({ companyId })
      .then((data) => {
        dispatch(getPosterInfo.success(data, { companyId }));
      })
      .catch((error) => dispatch(getPosterInfo.failure(error)));
  };
}

export const getPosterFullInfo = createAsyncAction(
  'GET_POSTER_FULL_INFO_REQUEST',
  'GET_POSTER_FULL_INFO_SUCCESS',
  'GET_POSTER_FULL_INFO_FAIL',
)();

export function getPosterFullInfoAsync(id) {
  return (dispatch, getState) => {
    if (getState().poster.fullInfoById[id]) {
      return;
    }

    dispatch(getPosterFullInfo.request());

    ApiClient.poster.getPosterFullInfo(id)
      .then((data) => {
        dispatch(getPosterFullInfo.success(data, id));
      })
      .catch((error) => dispatch(getPosterFullInfo.failure(error)));
  };
}

export const savePosterSettings = createAsyncAction(
  'SAVE_POSTER_SETTINGS_REQUEST',
  'SAVE_POSTER_SETTINGS_SUCCESS',
  'SAVE_POSTER_SETTINGS_FAIL',
)();

export function savePosterSettingsAsync({ posterCompanyUrl, productId }) {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    dispatch(savePosterSettings.request());

    ApiClient.poster.savePosterSettings({ companyId, posterCompanyUrl, productId })
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'URL cохранен',
          icon: true,
          timeout: 5000,
        };
        dispatch(showNotification(notification));

        dispatch(savePosterSettings.success(data, { companyId, posterCompanyUrl, productId }));
      })
      .catch((error) => dispatch(savePosterSettings.failure(error)));
  };
}

export const savePosterProductId = createAsyncAction(
  'SAVE_POSTER_PRODUCT_ID_REQUEST',
  'SAVE_POSTER_PRODUCT_ID_SUCCESS',
  'SAVE_POSTER_PRODUCT_ID_FAIL',
)();

export function savePosterProductIdAsync({ posterCompanyUrl, productId }) {
  return (dispatch, getState) => {
    const { currentCompanyId: companyId } = getState().app;

    dispatch(savePosterProductId.request());

    ApiClient.poster.savePosterSettings({ companyId, posterCompanyUrl, productId })
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Точка продаж cохранена',
          icon: true,
          timeout: 5000,
        };
        dispatch(showNotification(notification));

        dispatch(savePosterProductId.success(data, { companyId, posterCompanyUrl, productId }));
      })
      .catch((error) => dispatch(savePosterProductId.failure(error)));
  };
}

export const connectPoster = createAsyncAction(
  'CONNECT_POSTER_REQUEST',
  'CONNECT_POSTER_SUCCESS',
  'CONNECT_POSTER_FAIL',
)();

export function connectPosterAsync(companyId) {
  return (dispatch) => {
    dispatch(connectPoster.request());

    ApiClient.poster.connect({ companyId })
      .then((data) => {
        dispatch(connectPoster.success(data, { id: companyId }));
      })
      .catch((error) => dispatch(connectPoster.failure(error)));
  };
}

export const togglePosterLoader = createAction('TOGGLE_POSTER_LOADER')();
