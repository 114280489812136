import { useField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@teko/ui-kit';

function CheckboxField(props) {
  const [field, meta] = useField(props.name);

  return (
    <Checkbox
      {...field}
      {...props}
      checked={field.value}
    />
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CheckboxField;
