import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { stringify } from 'qs';
import { logoutAsync } from '../../actions/auth';
import { useQueryParams } from '../common/hooks';

function Logout() {
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.login.authenticated);
  const [params] = useQueryParams();

  useEffect(() => {
    dispatch(logoutAsync());
  }, []);

  if (!authenticated) {
    return <Redirect to={`/login?${stringify(params)}`} />;
  }

  return null;
}

export default Logout;
