import { useCallback } from 'react';

function useFetchData(onFetchData) {
  return useCallback((state) => {
    const sort = state.sorted[0];
    const params = {};

    if (state.showPagination) {
      params.count = state.pageSize;
      params.offset = state.page * state.pageSize;
    }

    if (sort) {
      params.sort = {
        sort: sort.id,
        order: sort.desc ? 'desc' : 'asc',
      };
    }

    onFetchData(params);
  }, [onFetchData]);
}

export default useFetchData;
