import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { loginAsync } from '../../actions/auth';
import { useQueryParams } from '../common/hooks';

function TrustedAuth() {
  const [params] = useQueryParams();
  const { token, signature, origin } = params;
  const authenticated = useSelector((state) => state.auth.login.authenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginAsync({ token, signature, type: 'trustedAuth' }));
  }, [token]);

  if (authenticated) {
    return <Redirect to={origin ?? '/lk'} />;
  }

  return null;
}

export default TrustedAuth;
