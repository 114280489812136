import i18next from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from './i18next-webpack-backend';

// import Cache from 'i18next-localstorage-cache';
// .use(Cache)
// cache: {
//   enabled: true, // process.env.NODE_ENV === 'production',
//   expirationTime: 24 * 60 * 60 * 1000, // 1 day,
//   versions: {
//     en: '2017.04.19',
//   },
// },

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    // have a common namespace used around the full app
    supportedLngs: ['en', 'ru'],
    ns: ['common', 'pricing'],
    defaultNS: 'common',
    load: 'languageOnly',

    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18next;
