import { useCallback, useMemo } from 'react';
// import { useSelector } from 'react-redux';
import usePaycafeOutlets from './usePaycafeOutlets';

function usePaycafeOutletsOptions(onlyAccepted = true) {
  const outlets = usePaycafeOutlets();
  const acceptedOutlets = useMemo(() => outlets.filter((outlet) => outlet.status === 'accepted'), [outlets]);
  const getOutletsOptions = useCallback((outlets) => outlets
    .map((outlet) => ({
      label: `${outlet.fullName}${outlet.extra?.siteUrl ? ` (${outlet.extra?.siteUrl})` : ''}`,
      value: outlet.id,
    })), []);
  // const companies = useSelector(companiesByIdSelector);

  return useMemo(
    () => getOutletsOptions(onlyAccepted ? acceptedOutlets : outlets),
      // .map((outlet) => ({
      //   value: outlet.id, // TODO: check if somewhere needed this version: `${outlet.globalId}|${outlet.id}`
      //   label: `${outlet.fullName}${outlet.extra?.siteUrl ? ` (${outlet.extra?.siteUrl})` : ''}`,
      //   search: `${outlet.fullName}${outlet.extra?.siteUrl ? ` (${outlet.extra?.siteUrl})` : ''}`,
      //   group: companies[outlet.globalId]?.name ?? outlet.globalId,
      // })),
    [outlets, acceptedOutlets],
  );
}

export default usePaycafeOutletsOptions;
