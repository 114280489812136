import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { Button, FormControl, H1, Input } from '@teko/ui-kit';
import { recoverPasswordAsync } from '../../actions/auth';
import compose from '../../utils/compose';
import { getPageTitleSuffixByReferral, getReferralByUrl } from '../../utils/getReferral';
import CollapsibleElement from '../common/ui/CollapsibleElement';
import ChevronLeftIcon from '../../assets/svg/chevron-left-white-30.svg';

const emailSchema = yup.string()
  .email('Invalid email')
  .required('Required');

class RememberForm extends PureComponent {
  static propTypes = {
    email: PropTypes.string,
    history: PropTypes.object.isRequired,
    recoverPassStatus: PropTypes.object.isRequired,
    recoverPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    email: this.props.email ?? '',
    emailSend: false,
    initialError: this.props.recoverPassStatus.error,
  };

  componentDidMount() {
    const { t } = this.props;
    window.document.getElementsByTagName('title')[0].innerText = `${t('remember-form-title')} | ${getPageTitleSuffixByReferral()}`;
  }

  componentDidUpdate(prevProps) {
    const { recoverPassStatus } = this.props;
    if (prevProps.recoverPassStatus.success !== recoverPassStatus.success && recoverPassStatus.success) {
      this.handleSubmitSuccess();
    }
  }

  handleEmailChange = (e) => this.setState({ email: e.target.value });

  handleBackClick = () => this.props.history.push(`/login${this.props.location.search}`);

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.recoverPassword(this.state.email);
  };

  handleSubmitSuccess = () => this.setState({ emailSend: true });

  renderForm() {
    const { recoverPassStatus, t } = this.props;
    const { email, initialError } = this.state;
    const hasError = recoverPassStatus.error && recoverPassStatus.error !== initialError;
    const valid = emailSchema.isValidSync(email);
    const brand = getReferralByUrl() || 'teko';

    const submitBtn = (
      <Button className="remember-submit" design="primary" size="l" loading={recoverPassStatus.loading} disabled={!valid}>
        {t('remember-submit')}
      </Button>
    );

    return (
      <form className="remember-form-wrap" onSubmit={this.handleSubmit}>
        <p className="remember-form-text">
          <Trans i18nKey="remember-form-text" ns="auth" />
        </p>

        <FormControl className="remember-form-email-input-wrap">
          <Input autoFocus type="email" name="email" value={email} state={hasError ? 'invalid' : null} placeholder={t('enter-email')} onChange={this.handleEmailChange} />
          <CollapsibleElement active={!!hasError}>
            <div className="input-error-msg">
              <Trans i18nKey="remember-form-error" ns="auth" />
            </div>
          </CollapsibleElement>
        </FormControl>

        <div className="remember-form-footer">
          {brand === 'talkto' && (
            <ThemeProvider theme={{ primary: '#452994' }}>
              {submitBtn}
            </ThemeProvider>
          )}
          {brand !== 'talkto' && submitBtn}
        </div>
      </form>
    );
  }

  renderResult() {
    const { t } = this.props;
    const brand = getReferralByUrl() || 'teko';

    const backBtn = (
      <Button type="button" className="remember-back-btn" design="primary" size="l" onClick={this.handleBackClick}>
        {t('remember-back-btn')}
      </Button>
    );

    return (
      <div className="remember-form-wrap">
        <p className="remember-form-text _email-sent">
          <Trans i18nKey="remember-form-result" ns="auth" />
        </p>

        {brand === 'talkto' && (
          <ThemeProvider theme={{ primary: '#452994' }}>
            {backBtn}
          </ThemeProvider>
        )}
        {brand !== 'talkto' && backBtn}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { emailSend } = this.state;

    return (
      <div className="remember-form">
        <div className="remember-form-title-wrap">
          {!emailSend && (
            <button type="button" className="auth-back-btn" onClick={this.handleBackClick}>
              <ChevronLeftIcon />
            </button>
          )}

          <H1 className="remember-form-title">{t('remember-form-title')}</H1>
        </div>

        {emailSend ? this.renderResult() : this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.auth.login.email,
  recoverPassStatus: state.auth.recoverPassStatus,
});

const mapDispatchToProps = {
  recoverPassword: recoverPasswordAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('auth'),
)(RememberForm);
