import React, { useCallback, useState, useLayoutEffect, useRef, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getReferral } from '../../utils/getReferral';
import CollapsibleElement from '../common/ui/CollapsibleElement';

/*
* component by Georgii and Aleksio
*
*/

const brandByReferral = {
  teko: 'Teko',
  talktoBusiness: 'Talkto Business',
};

const ExpandableHint = () => {
  const { t } = useTranslation('auth');
  const brand = brandByReferral[getReferral() || 'teko'] || 'Teko';

  const {
    invitedBy,
    companyNames,
    role,
  } = useSelector((state) => state.auth.inviteTokenInfo);

  const translatedRole = t(`common:role.${role}`);

  const [isAllCompaniesShow, setIsAllCompaniesShow] = useState(false);
  const [initialHeight, setInitialHeight] = useState();

  const collapsibleTextRef = useRef(null);

  const clickHandle = useCallback(() => {
    setIsAllCompaniesShow(true);
  }, []);

  useLayoutEffect(() => {
    if (collapsibleTextRef.current) {
      setInitialHeight(collapsibleTextRef.current.offsetHeight);
    }
  }, []);

  if (!companyNames || companyNames.length === 0) {
    return (
      <div className="invite-hint" dangerouslySetInnerHTML={{ __html: t('invite-hint-default', { brand }) }} />
    );
  }

  return (
    <div className="invite-hint">
      <div className="collapsible-div" ref={collapsibleTextRef}>
        <CollapsibleElement initialHeight={initialHeight} active={isAllCompaniesShow} mountOnEnter={false} unmountOnExit={false}>
          <Trans i18nKey="invite-hint-dynamic" ns="auth">
            Вы получили приглашение от <strong>{{ invitedBy }}</strong> на подключение к
          </Trans> {t(companyNames.length === 1 ? 'to-one-project' : 'to-many-projects')}
          <span className="invite-companies-list"> {companyNames.slice(0, isAllCompaniesShow ? companyNames.length : 6).map((name) => `«${name}»`).join(', ')} </span>
          {companyNames.length < 7 && (
            <Trans i18nKey="invite-hint-static" ns="auth">
              в качестве {{ translatedRole }}. Создайте учетную запись, чтобы войти в личный кабинет.
            </Trans>
          )}
          {!isAllCompaniesShow && companyNames.length > 6 && (
            <span>
              <span>... </span>
              <span className="show-more-btn" onClick={clickHandle} dangerouslySetInnerHTML={{ __html: t('show-all') }} />
            </span>
          )}
        </CollapsibleElement>
      </div>

      {companyNames.length > 6 && (
        <div>
          <Trans i18nKey="invite-hint-static" ns="auth">
            в качестве {{ translatedRole }}. Создайте учетную запись, чтобы войти в личный кабинет.
          </Trans>
        </div>
      )}
    </div>
  );
};

export default memo(ExpandableHint);
