import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertManager, Portal } from '@teko/ui-kit';
import { hideNotification } from '../../../actions/app';

const NotificationContainer = memo(() => {
  const notifications = useSelector((state) => state.app.notifications);
  const dispatch = useDispatch();
  const handleClose = useCallback(
    (notification) => dispatch(hideNotification(notification)),
    [dispatch],
  );

  return (
    <Portal id="__system-notifications-container">
      <AlertManager
        alerts={notifications}
        onClose={handleClose}
      />
    </Portal>
  );
});

export default NotificationContainer;
