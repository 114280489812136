import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/billing';
import { asyncReducerFactory, getFailureState, getRequestState, getSuccessState } from '../utils/redux-utils';

const methodsByCompanyIdReducer = createReducer({})
  .handleAction(actions.getBillingMethods.success, (state, { payload, meta }) => (
    payload.methods.reduce((acc, method) => {
      if (!acc[method.companyId]) {
        acc[method.companyId] = {};
      }

      method.brand = method.brand.split(' ').join('-');

      if (method.status === 'primary') {
        acc[method.companyId].main = method;
      } else {
        if (!acc[method.companyId].reserve) {
          acc[method.companyId].reserve = [];
        }
        acc[method.companyId].reserve.push(method);
      }

      return acc;
    }, {})
  ))
  .handleAction(actions.deleteBillingMethod.success, (state, { meta }) => {
    const newCompanyMethodsState = { ...state[meta.companyId] };
    if (meta.status === 'primary') {
      delete newCompanyMethodsState.main;

      if (newCompanyMethodsState.reserve?.[0]) {
        // [newState.main] = newState.reserve --- not obvious, so shut up, eslint
        // eslint-disable-next-line prefer-destructuring
        newCompanyMethodsState.main = newCompanyMethodsState.reserve[0];
        newCompanyMethodsState.reserve.shift();
      }
    } else {
      newCompanyMethodsState.reserve = newCompanyMethodsState.reserve.filter(({ id }) => id !== meta.id);
    }
    return {
      ...state,
      [meta.companyId]: newCompanyMethodsState,
    };
  })
  .handleAction(actions.setMainCard.success, (state, { meta }) => {
    const newCompanyMethodsState = { ...state[meta.companyId] };
    const newMainCard = newCompanyMethodsState.reserve.find(({ id }) => id === meta.id);

    newCompanyMethodsState.reserve = [...newCompanyMethodsState.reserve.filter(({ id }) => id !== meta.id), { ...newCompanyMethodsState.main, status: 'reserve' }];
    newCompanyMethodsState.main = newMainCard;

    return {
      ...state,
      [meta.companyId]: newCompanyMethodsState,
    };
  });

const tmpBindCardUrlReducer = createReducer(null)
  .handleAction(actions.bindCard.success, (state, { payload }) => payload.url + payload.token)
  .handleAction(actions.resetBindCardUrl, () => null);

// const methodsFetchStatusByCompanyIdReducer = createReducer({})
//   .handleAction(actions.getBillingMethods.request, (state, { meta }) => ({ ...state, [meta.companyId]: getRequestState(state[meta.companyId]) }))
//   .handleAction(actions.getBillingMethods.success, (state, { meta }) => ({ ...state, [meta.companyId]: getSuccessState(state[meta.companyId]) }))
//   .handleAction(actions.getBillingMethods.failure, (state, { payload, meta }) => ({ ...state, [meta.companyId]: getFailureState(state[meta.companyId], { payload }) }));

export default combineReducers({
  tmpBindCardUrl: tmpBindCardUrlReducer,
  methodsByCompanyId: methodsByCompanyIdReducer,
  methodsFetchStatus: asyncReducerFactory(actions.getBillingMethods),
  // methodsFetchStatusByCompanyId: methodsFetchStatusByCompanyIdReducer,
  bindCardFetchStatus: asyncReducerFactory(actions.bindCard),
  deleteMethodFetchStatus: asyncReducerFactory(actions.deleteBillingMethod),
  setMainCardFetchStatus: asyncReducerFactory(actions.setMainCard),
  getPaymentReceiptFetchStatus: asyncReducerFactory(actions.getPaymentReceipt),
});
