import { useEffect, useRef } from 'react';

/**
 * @param {boolean} disabled
 * @returns {HTMLFormElement} formRef
 */
function useFormDisable(disabled) {
  const formRef = useRef();
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (disabled) {
      [...formRef.current.elements].forEach((el) => {
        el.tabIndex = -1;
      });
    } else if (isMountedRef.current) {
      [...formRef.current.elements].forEach((el) => {
        el.tabIndex = 0;
      });
    }
    isMountedRef.current = true;
  }, [disabled]);

  return formRef;
}
export default useFormDisable;
