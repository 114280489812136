import { createAsyncAction } from 'typesafe-actions';
import i18n from '@i18n';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';
import { getEmployeesCreateNotification, getNewUserNotificationsNotification } from '../utils/notification-utils';
import { readUserNotificationAsync } from './notifications';

const t = (key, options) => i18n.t(key, { ns: ['boxretail'], ...options });

export const getEmployees = createAsyncAction(
  'EMPLOYEES_GET_REQUEST',
  'EMPLOYEES_GET_SUCCESS',
  'EMPLOYEES_GET_FAIL',
)();

export function getEmployeesAsync(params = {}) {
  return (dispatch, getState) => {
    if (getState().employees.alreadyFetchedCompanyIds[params.companyId]) {
      return;
    }

    dispatch(getEmployees.request());

    ApiClient.cashiers.get(params)
      .then((data) => dispatch(getEmployees.success(data, params)))
      .catch((error) => dispatch(getEmployees.failure(error)));
  };
}

export const getEmployeesTable = createAsyncAction(
  'EMPLOYEES_GET_TABLE_REQUEST',
  'EMPLOYEES_GET_TABLE_SUCCESS',
  'EMPLOYEES_GET_TABLE_FAIL',
)();

export function getEmployeesTableAsync(params = {}) {
  return (dispatch, getState) => {
    // if (getState().employees.alreadyFetchedCompanyIds[params.companyId]) {
    //   return;
    // }

    dispatch(getEmployeesTable.request());

    ApiClient.cashiers.get(params)
      .then((data) => dispatch(getEmployeesTable.success(data, params)))
      .catch((error) => dispatch(getEmployeesTable.failure(error)));
  };
}

export const createEmployees = createAsyncAction(
  'EMPLOYEES_CREATE_REQUEST',
  'EMPLOYEES_CREATE_SUCCESS',
  'EMPLOYEES_CREATE_FAIL',
)();

export function createEmployeesAsync(employees) {
  return (dispatch, getState) => {
    dispatch(createEmployees.request());

    ApiClient.cashiers.create(employees)
      .then((data) => {
        if (data.length === 1) {
          const isSuccess = data.some(({ isCreated }) => isCreated);
          const errorMessage = t([`common:errors.${data[0].error?.code ?? 'default'}`, 'common:errors.default']);
          const notifications = [{
            type: isSuccess ? 'success' : 'error',
            message: isSuccess ? t('employee-successfully-created') : errorMessage,
            icon: true,
            tag: 'boxretail',
          }];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        } else {
          const created = data.filter(({ isCreated }) => isCreated).map(({ login }) => `<strong>${login}</strong>`);
          const failed = data.filter(({ isCreated }) => !isCreated).map(({ login }) => `<strong>${login}</strong>`);
          dispatch(showNotification(getEmployeesCreateNotification(t, created, failed)));
        }

        dispatch(createEmployees.success(data, employees));
      })
      .catch((error) => dispatch(createEmployees.failure(error)));
  };
}

export const updateEmployee = createAsyncAction(
  'EMPLOYEES_UPDATE_REQUEST',
  'EMPLOYEES_UPDATE_SUCCESS',
  'EMPLOYEES_UPDATE_FAIL',
)();

export function updateEmployeeAsync(employee) {
  return (dispatch, getState) => {
    dispatch(updateEmployee.request());

    ApiClient.cashiers.update(employee)
      .then((data) => {
        const notifications = [
          {
            type: 'success',
            message: 'Данные сотрудника отредактированы',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateEmployee.success(data, employee));
      })
      .catch((error) => dispatch(updateEmployee.failure(error)));
  };
}

export const changeEmployeePassword = createAsyncAction(
  'EMPLOYEES_CHANGE_PASSWORD_REQUEST',
  'EMPLOYEES_CHANGE_PASSWORD_SUCCESS',
  'EMPLOYEES_CHANGE_PASSWORD_FAIL',
)();

export function changeEmployeePasswordAsync(employee) {
  return (dispatch) => {
    dispatch(changeEmployeePassword.request());

    ApiClient.cashiers.changePassword(employee)
      .then((data) => {
        dispatch(changeEmployeePassword.success(data, employee));
        dispatch(showNotification({
          type: 'success',
          message: 'Пароль успешно изменен',
          timeout: 5000,
        }));
      })
      .catch((error) => dispatch(changeEmployeePassword.failure(error)));
  };
}

export const acceptEmployee = createAsyncAction(
  'EMPLOYEES_ACCEPT_EMPLOYEE_REQUEST',
  'EMPLOYEES_ACCEPT_EMPLOYEE_SUCCESS',
  'EMPLOYEES_ACCEPT_EMPLOYEE_FAIL',
)();

export function acceptEmployeeAsync(model) {
  return (dispatch, getState) => {
    const prevState = getState();
    const prevStatus = prevState.employees.byId[model.id].status;

    dispatch(acceptEmployee.request(null, model));

    return ApiClient.cashiers.accept(model)
      .then((response) => {
        if (model.status !== prevStatus && model.status === 'accepted') {
          const notification = {
            type: 'success',
            message: 'Сотрудник успешно акцептован',
            timeout: 5000,
          };

          dispatch(showNotification(notification));
        }
        dispatch(acceptEmployee.success(response, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptEmployee.failure(error, { id: model.id, status: prevStatus }));
      });
  };
}

export const getEmployeeHistory = createAsyncAction(
  'EMPLOYEES_GET_EMPLOYEE_HISTORY_REQUEST',
  'EMPLOYEES_GET_EMPLOYEE_HISTORY_SUCCESS',
  'EMPLOYEES_GET_EMPLOYEE_HISTORY_FAIL',
)();

export function getEmployeeHistoryAsync(id) {
  return (dispatch) => {
    dispatch(getEmployeeHistory.request());

    ApiClient.cashiers.getHistory(id)
      .then((response) => dispatch(getEmployeeHistory.success(response, { id })))
      .catch((error) => dispatch(getEmployeeHistory.failure(error)));
  };
}
