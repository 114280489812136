import React, { useEffect } from 'react';
import { getOrigin } from '../../utils';
import { loadScript } from '../../utils/loadScript';

function YandexAuth() {
  useEffect(() => {
    loadScript('https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js', 'yandex-suggest-token')
      .then(() => {
        const origin = getOrigin();

        window.YaSendSuggestToken(origin);
      });
  }, []);

  return null;
}

export default YandexAuth;
