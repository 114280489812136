import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/employees';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const dataReducer = createReducer({})
  .handleAction([actions.getEmployees.success, actions.getEmployeesTable.success], (state, { payload }) => {
    const employeesWithExtraAsArr = payload.map((employee) => ({
      ...employee,
      extra: Object.entries(employee.extra).map(([title, value]) => ({ title, value })),
    }));
    const employeesById = normalize(employeesWithExtraAsArr);

    return {
      ...state,
      ...employeesById,
    };
  })
  .handleAction([actions.createEmployees.success, actions.updateEmployee.success], (state, { payload, meta }) => {
    let newEmployees;
    if (Array.isArray(payload)) {
      newEmployees = payload.filter(({ isCreated }) => isCreated)
        .reduce((acc, { id, login, isCreated, ...employee }) => {
          const employeeData = meta.find((emp) => login === emp.login);
          acc[id] = {
            ...employeeData,
            ...employee,
            extra: Object.entries(employeeData.extra ?? {}).map(([title, value]) => ({ title, value })),
            status: employeeData.status ?? 'accepted',
            id,
          };
          return acc;
        }, {});
    } else {
      newEmployees = {
        [payload.id]: {
          ...payload,
          ...meta,
          extra: Object.entries(meta.extra ?? {}).map(([title, value]) => ({ title, value })),
          status: meta.status ?? 'accepted',
        },
      };
    }
    return {
      ...state,
      ...newEmployees,
    };
  });

const tableReducer = createReducer([])
  .handleAction(actions.getEmployeesTable.success, (state, { payload }) => payload.map((item) => item.id))
  .handleAction(actions.createEmployees.success, (state, { payload }) => ([...state, ...payload.map((item) => item.id)]));

const historyReducer = createReducer({})
  .handleAction(actions.getEmployeeHistory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload.filter(({ change: { newValue } }) => Object.keys(newValue).length),
  }));

const alreadyFetchedReducer = createReducer({})
  .handleAction([actions.getEmployees.success, actions.getEmployeesTable.success], (state, { meta }) => ({
    ...state,
    [meta.companyId]: true,
  }));

const justCreatedEmployeesReducer = createReducer([])
  .handleAction(actions.createEmployees.success, (state, { payload }) => payload);

export default combineReducers({
  byId: dataReducer,
  table: tableReducer,
  history: historyReducer,
  alreadyFetchedCompanyIds: alreadyFetchedReducer,
  justCreatedEmployees: justCreatedEmployeesReducer,
  fetchStatus: asyncReducerFactory(actions.getEmployees),
  tableFetchStatus: asyncReducerFactory(actions.getEmployeesTable),
  createStatus: asyncReducerFactory(actions.createEmployees),
  updateStatus: asyncReducerFactory(actions.updateEmployee),
  changeEmployeePasswordStatus: asyncReducerFactory(actions.changeEmployeePassword),
  acceptEmployeeStatus: asyncReducerFactory(actions.acceptEmployee),
  getEmployeeHistoryStatus: asyncReducerFactory(actions.getEmployeeHistory),
});
