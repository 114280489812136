import Bowser from 'bowser';

let browser;

export default function getFingerprint() {
  const NOT_AVAILABLE = 'not available';

  if (!browser) {
    browser = Bowser.getParser(window.navigator.userAgent);
  }

  function each(obj, iterator) {
    if (Array.prototype.forEach && obj.forEach === Array.prototype.forEach) {
      obj.forEach(iterator);
    } else if (obj.length === +obj.length) {
      for (let i = 0, l = obj.length; i < l; i++) {
        iterator(obj[i], i, obj);
      }
    } else {
      Object.keys(obj).forEach((key) => iterator(obj[key], key, obj));
    }
  }
  function map(obj, iterator) {
    const results = [];
    if (obj == null) {
      return results;
    }
    if (Array.prototype.map && obj.map === Array.prototype.map) { return obj.map(iterator); }
    each(obj, (value, index, list) => {
      results.push(iterator(value, index, list));
    });
    return results;
  }

  function getLanguage() {
    return navigator.language || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage || NOT_AVAILABLE;
  }
  function getWindowResolution() {
    return [window.innerWidth, window.innerHeight];
  }
  function getScreenResolution() {
    return [window.screen.width, window.screen.height];
  }
  function getAvailableScreenResolution() {
    if (window.screen.availWidth && window.screen.availHeight) {
      const available = [window.screen.availWidth, window.screen.availHeight];
      return available;
    }
    // headless browsers
    return NOT_AVAILABLE;
  }
  function getTimezone(done, options) {
    if (window.Intl && window.Intl.DateTimeFormat) {
      return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return NOT_AVAILABLE;
  }
  function hasSessionStorage() {
    try {
      return !!window.sessionStorage;
    } catch (e) {
      return options.ERROR; // SecurityError when referencing it means it exists
    }
  }
  // https://bugzilla.mozilla.org/show_bug.cgi?id=781447
  function hasLocalStorage() {
    try {
      return !!window.localStorage;
    } catch (e) {
      return options.ERROR; // SecurityError when referencing it means it exists
    }
  }
  function hasIndexedDB() {
    try {
      return !!window.indexedDB;
    } catch (e) {
      return options.ERROR; // SecurityError when referencing it means it exists
    }
  }
  function getHardwareConcurrency() {
    if (navigator.hardwareConcurrency) {
      return navigator.hardwareConcurrency;
    }
    return NOT_AVAILABLE;
  }
  function getNavigatorCpuClass() {
    return navigator.cpuClass || NOT_AVAILABLE;
  }
  function getNavigatorPlatform() {
    if (navigator.platform) {
      return navigator.platform;
    }
    return NOT_AVAILABLE;
  }
  function getDoNotTrack() {
    if (navigator.doNotTrack) {
      return navigator.doNotTrack;
    } if (navigator.msDoNotTrack) {
      return navigator.msDoNotTrack;
    } if (window.doNotTrack) {
      return window.doNotTrack;
    }
    return NOT_AVAILABLE;
  }
  function getRegularPlugins() {
    if (navigator.plugins == null) {
      return NOT_AVAILABLE;
    }

    const plugins = [];

    for (let i = 0, l = navigator.plugins.length; i < l; i++) {
      if (navigator.plugins[i]) { plugins.push(navigator.plugins[i]); }
    }

    return map(plugins, (p) => {
      const mimeTypes = map(p, (mt) => [mt.type, mt.suffixes]);
      return [p.name, p.description, mimeTypes];
    });
  }
  function getAdBlock() {
    const ads = document.createElement('div');
    ads.innerHTML = '&nbsp;';
    ads.className = 'adsbox';
    let result = false;
    try {
      // body may not exist, that's why we need try/catch
      document.body.appendChild(ads);
      result = document.getElementsByClassName('adsbox')[0].offsetHeight === 0;
      document.body.removeChild(ads);
    } catch (e) {
      result = false;
    }
    return result;
  }
  function getTouchSupport() {
    let maxTouchPoints = 0;
    let touchEvent;
    if (typeof navigator.maxTouchPoints !== 'undefined') {
      maxTouchPoints = navigator.maxTouchPoints;
    } else if (typeof navigator.msMaxTouchPoints !== 'undefined') {
      maxTouchPoints = navigator.msMaxTouchPoints;
    }
    try {
      document.createEvent('TouchEvent');
      touchEvent = true;
    } catch (_) {
      touchEvent = false;
    }
    const touchStart = 'ontouchstart' in window;
    return [maxTouchPoints, touchEvent, touchStart];
  }
  function getConnection() {
    const { connection } = navigator;
    if (connection) {
      return {
        type: connection.effectiveType,
        rtt: connection.rtt,
        downlink: connection.downlink,
        saveData: connection.saveData,
      };
    }
    return NOT_AVAILABLE;
  }

  const osPlatformBrowser = browser.getResult();

  return {
    browser: osPlatformBrowser.browser,
    platform: osPlatformBrowser.platform,
    engine: osPlatformBrowser.engine,
    os: osPlatformBrowser.os,
    userAgent: navigator.userAgent,
    language: getLanguage(),
    deviceMemory: navigator.deviceMemory || NOT_AVAILABLE,
    hardwareConcurrency: getHardwareConcurrency(),
    connection: getConnection(),
    windowResolution: getWindowResolution(),
    screenResolution: getScreenResolution(),
    availableScreenResolution: getAvailableScreenResolution(),
    timezoneOffset: new Date().getTimezoneOffset(),
    timezone: getTimezone(),
    sessionStorage: hasSessionStorage(),
    localStorage: hasLocalStorage(),
    indexedDB: hasIndexedDB(),
    cpuClass: getNavigatorCpuClass(),
    doNotTrack: getDoNotTrack(),
    plugins: getRegularPlugins(),
    adBlock: getAdBlock(),
    touchSupport: getTouchSupport(),
  };
}
