import { useAsyncStatus } from '@common/hooks/index';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAwizaCategoriesAsync } from '../../../actions/awiza';
import { categoriesSelector, categoriesWithParentSelector } from '../../../selectors/awiza';

/**
 * @returns {[{}]}
 */
function useAwizaCategories(parent = false, withoutDefaultCategory = true) {
  const dispatch = useDispatch();
  const cats = parent ? useSelector(categoriesWithParentSelector) : useSelector(categoriesSelector);
  const currentCompanyId = useSelector((state) => state.app.currentCompanyId);
  const editCategoryPositionsStatus = useAsyncStatus((state) => state.awiza.editAwizaCategoryPositionsStatus);

  useEffect(() => dispatch(getAwizaCategoriesAsync()), [currentCompanyId, editCategoryPositionsStatus.success]);

  return useMemo(() => (
    withoutDefaultCategory ? cats.filter(({ id }) => id !== 'default') : cats),
  [withoutDefaultCategory, cats]);
}

export default useAwizaCategories;
