import { PeriodModel } from '@teko/ui-kit';
import moment from 'moment';
import { useMemo } from 'react';

function useShortPeriodPresets(withHourPeriods = true) {
  return useMemo(() => ([
    { label: 'today', value: moment().startOf('day').toDate() },
    ...(withHourPeriods ? [{
      label: 'hour',
      count: 3,
      value: new PeriodModel({
        start: moment().endOf('minute').subtract(3, 'hour'),
        end: moment().endOf('minute'),
      }, PeriodModel.Type.sliding),
    }] : []),
    ...(withHourPeriods ? [{
      label: 'hour',
      count: 12,
      value: new PeriodModel({
        start: moment().endOf('minute').subtract(12, 'hour'),
        end: moment().endOf('minute'),
      }, PeriodModel.Type.sliding),
    }] : []),
    {
      label: 'days',
      count: 7,
      value: new PeriodModel({
        start: moment().startOf('day').subtract(7, 'day'),
        end: moment().endOf('day'),
      }, PeriodModel.Type.slidingDay),
    },
    {
      label: 'months',
      count: 3,
      value: new PeriodModel({
        start: moment().startOf('day').subtract(3, 'month'),
        end: moment().endOf('day'),
      }, PeriodModel.Type.slidingDay),
    },
    ...(!withHourPeriods ? [{
      label: 'all-time',
      value: new PeriodModel({
        start: moment(0, 'x').startOf('day'),
        end: moment().endOf('day'),
      }, PeriodModel.Type.slidingDay),
    }] : []),

  ]), [withHourPeriods]);
}

export default useShortPeriodPresets;
