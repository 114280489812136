import { Button, CodeVerificationInput, FormControl, Hint, Input } from '@teko/ui-kit';
import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { loginAsync, setLoginEmail } from '../../actions/auth';
import MonkeyEmojiSrc from '../../assets/img/auth/monkeyemoji.png';
import ChevronLeftIcon from '../../assets/svg/chevron-left-white-30.svg';
import { getAuthRequestExtraData } from '../../utils';
import compose from '../../utils/compose';
import { getPageTitleSuffixByReferral, getReferralByUrl } from '../../utils/getReferral';
import withQueryParams from '../common/hoc/withQueryParams';
import CollapsibleElement from '../common/ui/CollapsibleElement';
import { triggerAllMetricsEvents } from '../common/utils/metrics';
import AppleLoginButton from './AppleLoginButton';
import GoogleLoginButton from './GoogleLoginButton';
import RegistrationCompletedContext from './RegistrationCompletedContext';
import YandexLoginButton from './YandexLoginButton';
import VKLoginButton from './VKLoginButton';

const validationSchema = yup.object().shape({
  password: yup.string().required('Required'),
  username: yup.string().required('Required'),
  token: yup.string(),
});

class SignInForm extends PureComponent {
  static propTypes = {
    email: PropTypes.string,
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    authenticated: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    setLoginEmail: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isNewUserFromSocials: PropTypes.bool,
  };

  static contextType = RegistrationCompletedContext;

  state = {
    initialError: this.props.error,
    authValues: {},
    show2FA: false,
    codeValue: '',
    codeInputDisabled: false,
    codeErrorHintVisible: false,
  };

  componentDidMount() {
    const { t } = this.props;
    window.document.getElementsByTagName('title')[0].innerText = `${t('authorization')} | ${getPageTitleSuffixByReferral()}`;
  }

  componentDidUpdate(prevProps, prevState) {
    const { authenticated, error, params, t } = this.props;

    if (prevProps.t !== t) {
      window.document.getElementsByTagName('title')[0].innerText = `${this.props.t('authorization')} | ${getPageTitleSuffixByReferral()}`;
    }

    if (!prevProps.authenticated && authenticated) {
      setTimeout(() => {
        if (this.props.isNewUserFromSocials) {
          this.context.setRegistrationCompleted(true);
        } else {
          this.props.history.push(params.origin ?? '/lk');
        }
      }, 800);
    }

    // TODO: check if it's still actual in future
    if (prevProps.error !== error && error?.code === 10401) {
      this.props.history.push('/register/processing');
    }

    if (prevProps.error !== error && error?.code === 1499 && !prevState.show2FA) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(() => ({
        show2FA: true,
      }));
    }

    if (prevProps.error !== error && error && prevState.show2FA) {
      this.setState({ codeErrorHintVisible: true });
      setTimeout(() => {
        this.setState({ codeValue: '', codeInputDisabled: false });

        setTimeout(() => {
          this.setState({ codeErrorHintVisible: false });
        }, 1000);
      }, 800);
    }
  }

  handleSubmit = (values) => {
    const authValues = {
      username: values.username,
      password: values.password,
      type: 'password',
      ...getAuthRequestExtraData(false),
    };

    this.props.login(authValues);
    this.setState({ authValues });
  };

  handle2FACodeChange = (e) => {
    this.setState(() => ({
      codeValue: e.target.value,
    }));
  };

  handle2FASubmit = () => {
    const { authValues, codeValue } = this.state;
    this.props.login({
      ...authValues,
      token: codeValue,
      type: 'passwordWithToken',
    });
    this.setState({ codeInputDisabled: true });
  };

  handleBackClick = () => {
    this.setState(() => ({
      authValues: {},
      codeValue: '',
      show2FA: false,
      initialError: this.props.error,
    }));
  };

  onClickRememberLink = (username) => {
    this.props.setLoginEmail(username);
    this.props.history.push(`/auth/restore${this.props.location.search}`);
  }

  renderForm() {
    const { t, loading, email, error } = this.props;
    const { initialError } = this.state;
    const hasError = error && error !== initialError && error.code !== 1499;
    const brand = getReferralByUrl() || 'teko';

    const submitBtn = (
      <Button className="sign-in-submit" design="primary" size="l" loading={loading}>
        {t('enter')}
      </Button>
    );

    return (
      <Formik
        initialValues={{ username: email ?? '', password: '', token: '' }}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ errors, touched, values, submitCount }) => (
          <Form className={classnames('sign-in-form', { 'has-error': hasError })}>
            <h1 className="sign-in-title" dangerouslySetInnerHTML={{ __html: t([`authorization-${brand}`, 'authorization-teko']) }} />

            <CollapsibleElement active={!!hasError}>
              {hasError && (
                <div className="sign-in-api-error-wrap">
                  <Hint type="error" className="error-auth-hint">
                    {t([`common:errors.${error?.code}`, 'common:sign-in-error'])}
                  </Hint>
                </div>
              )}
            </CollapsibleElement>

            <FormControl className="signin-first-form-control">
              <Field name="username">
                {({ field }) => (
                  <Input
                    {...field}
                    type="email"
                    placeholder={t('enter-email')}
                    autoComplete="username"
                    state={errors[field.name] && touched[field.name] && submitCount > 0 ? 'invalid' : undefined}
                    autoFocus
                  />
                )}
              </Field>
            </FormControl>

            <FormControl className="pwd-form-control">
              <Field name="password">
                {({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    placeholder={t('enter-password')}
                    autoComplete="current-password"
                    state={errors[field.name] && touched[field.name] && submitCount > 0 ? 'invalid' : undefined}
                  />
                )}
              </Field>
              <button type="button" className="remember-link" tabIndex="-1" onClick={() => this.onClickRememberLink(values.username)}>
                <span>{t('forgot')}</span> <img src={MonkeyEmojiSrc} alt="monkey-eyes-closing" />
              </button>
            </FormControl>

            <div className="sign-in-footer">
              {brand === 'talkto' && (
                <ThemeProvider theme={{ primary: '#452994' }}>
                  {submitBtn}
                </ThemeProvider>
              )}
              {brand !== 'talkto' && submitBtn}

              <div className="no-account">
                <span>{t('no-account-question')}</span>
                <Link to="/register" className="registration-link" onClick={() => triggerAllMetricsEvents('regstep1')}>
                  {t('register-call')}
                </Link>
              </div>

              <div className="social-auth-divider">
                <span>{t('or-enter-via')}</span>
              </div>

              <div className="social-auth">
                <GoogleLoginButton />
                {/*<FacebookLoginButton />*/}
                <AppleLoginButton />
                <YandexLoginButton />
                <VKLoginButton />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  render2FAForm() {
    const { t, error, loading } = this.props;
    const { codeValue, initialError, codeInputDisabled, codeErrorHintVisible } = this.state;
    const hasError = error && error !== initialError && error.code !== 1499;
    const inputStateVisible = !loading && codeInputDisabled;
    const inputState = hasError ? 'invalid' : 'valid';

    return (
      <div className="sign-in-form _2fa">
        <button type="button" className="auth-back-btn" onClick={this.handleBackClick}>
          <ChevronLeftIcon />
        </button>

        <h1 className="sign-in-title">{t('2FA')}</h1>
        <p className="sign-in-description">
          {t('2FA-description')}
        </p>

        <CodeVerificationInput
          value={codeValue}
          disabled={codeInputDisabled}
          state={inputStateVisible ? inputState : undefined}
          onChange={this.handle2FACodeChange}
          submitForm={this.handle2FASubmit}
        />

        <CollapsibleElement active={codeErrorHintVisible}>
          {codeErrorHintVisible && (
            <div className="sign-in-api-error-wrap">
              <Hint type="error" className="error-auth-hint">
                {t('code-is-not-valid')}
              </Hint>
            </div>
          )}
        </CollapsibleElement>
      </div>
    );
  }

  render() {
    const { show2FA } = this.state;

    return (
      show2FA ? this.render2FAForm() : this.renderForm()
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth.login,
  isNewUserFromSocials: state.auth.isNewGoogleUser || state.auth.isNewAppleUser || state.auth.isNewYandexUser || state.auth.isNewVKUser,
});

const mapDispatchToProps = {
  login: loginAsync,
  setLoginEmail,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withQueryParams,
  withTranslation('auth'),
)(SignInForm);
