import { Input } from '@teko/ui-kit';
import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import IMask from 'imask';

const mask = {
  mask: 'HH{:}mm',
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 23,
    },
    mm: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
    },
  },
};
// const mask = [
  // {
  //   mask: 'HH:MM',
  //   blocks: {
  //     HH: {
  //       mask: IMask.MaskedRange,
  //       from: 0,
  //       to: 24,
  //     },
  //     MM: {
  //       mask: IMask.MaskedRange,
  //       from: 0,
  //       to: 59,
  //     },
  //   },
  // },
  // {
  //   mask: 'HH `{ч} `MM `{мин}',
  //   blocks: {
  //     HH: {
  //       mask: IMask.MaskedRange,
  //       from: 0,
  //       to: 24,
  //     },
  //     MM: {
  //       mask: IMask.MaskedRange,
  //       from: 0,
  //       to: 59,
  //     },
  //   },
  // },
// ];

const toMasked = IMask.createPipe({
  ...mask,
});
const toUnmasked = IMask.createPipe({
  ...mask,
}, IMask.PIPE_TYPE.MASKED, IMask.PIPE_TYPE.UNMASKED);

const TimeDurationInput = ({ value, onChange, ...props }) => {
  const handleChange = useCallback((e) => {
    let { value } = e.target;

    if (value.length === 1 && +value > '2') {
      value = `0${value}`;
    }

    onChange(
      toUnmasked(value),
    );
  }, [onChange]);

  return (
    <Input
      {...props}
      value={toMasked(value)}
      onChange={handleChange}
    />
  );
};

function TimeDurationInputField(props) {
  const [field, meta, helpers] = useField(props.name);
  const handleChange = useCallback((value) => helpers.setValue(value), []);

  return (
    <TimeDurationInput
      {...field}
      {...props}
      state={props.error || (meta.error && meta.touched) ? 'invalid' : undefined}
      onChange={handleChange}
    />
  );
}

TimeDurationInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(TimeDurationInputField);
