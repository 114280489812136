import { transliterate } from 'transliteration';
import { createAction, createAsyncAction } from 'typesafe-actions';
import i18n from '@i18n';
import { ApiClient } from '../utils/api-client';
import getFingerprint from '../utils/getFingerprint';
// import colorGenerator from '../utils/color-generator';
import { getNewUserNotificationsNotification } from '../utils/notification-utils';
import { isActiveCompanyStatus } from '../utils/statuses';
import { setCompanyId, showNotification } from './app';
import { readUserNotificationAsync } from './notifications';

const t = (key, props) => i18n.t(key, { ns: ['settings', 'cdek'], ...props }); // TODO don't forget expand ns as array if necessary

const lastRequestTimeByName = {
  getCompanies: 0,
};

export const getCompanies = createAsyncAction(
  'COMPANIES_GET_REQUEST',
  'COMPANIES_GET_SUCCESS',
  'COMPANIES_GET_FAIL',
)();

export function getCompaniesAsync({ initial } = {}) {
  return (dispatch, getState) => {
    const now = Date.now();

    if (now < lastRequestTimeByName.getCompanies + 2000) return;

    lastRequestTimeByName.getCompanies = now;

    dispatch(getCompanies.request());

    ApiClient.companies.get()
      .then((data) => {
        const state = getState();
        const userId = state.users.currentUser.id;
        // const prevCompaniesById = state.companies.byId;
        const { companies } = data;
        const acceptedCompanies = companies.filter(({ status }) => status === 'accepted');
        // const gen = colorGenerator();

        // for (let i = 0; i < companies.length; i++) {
        // companies[i].color = (initial || !prevCompaniesById[companies[i].id])?gen.next().value:prevCompaniesById[companies[i].id].color;
        // }

        const userLocalstoredOptions = JSON.parse(localStorage.getItem(`options:${userId}`)) ?? {};
        const localstoredCompanyId = userLocalstoredOptions['app:companyId'];

        // null check for auto setting only for first time, than we hold chosen version
        if (localstoredCompanyId !== null) {
          if (companies.find(({ id }) => id === localstoredCompanyId)?.status !== 'accepted') {
            dispatch(setCompanyId('', state.users.currentUser.id));
          } else if (acceptedCompanies.length === 1) {
            const companyId = acceptedCompanies[0].id;
            dispatch(setCompanyId(companyId, state.users.currentUser.id));
            dispatch(getCompanyInfoAsync(companyId));
          }
        }

        dispatch(getCompanies.success(companies));
      })
      .catch((error) => dispatch(getCompanies.failure(error)));
  };
}

export const getBalance = createAsyncAction(
  'COMPANIES_GET_BALANCE_REQUEST',
  'COMPANIES_GET_BALANCE_SUCCESS',
  'COMPANIES_GET_BALANCE_FAIL',
)();

export function getBalanceAsync(id) {
  return (dispatch, getState) => {
    if (getState().companies.balanceByCompany[id]) {
      return;
    }

    dispatch(getBalance.request());

    ApiClient.companies.balance(id)
      .then((balance) => dispatch(getBalance.success({ id, balance })))
      .catch((error) => dispatch(getBalance.failure(error)));
  };
}

export const getOutlets = createAsyncAction(
  'COMPANIES_GET_OUTLETS_REQUEST',
  'COMPANIES_GET_OUTLETS_SUCCESS',
  'COMPANIES_GET_OUTLETS_FAIL',
)();

export function getOutletsAsync(force = false) {
  return (dispatch, getState) => {
    if (getState().companies.outletsFetchStatus.state !== 'initial' && !force) {
      return;
    }

    dispatch(getOutlets.request());

    ApiClient.companies.outlets()
      .then((data) => dispatch(getOutlets.success(data)))
      .catch((error) => dispatch(getOutlets.failure(error)));
  };
}

export const getOutletsTable = createAsyncAction(
  'COMPANIES_GET_OUTLETS_TABLE_REQUEST',
  'COMPANIES_GET_OUTLETS_TABLE_SUCCESS',
  'COMPANIES_GET_OUTLETS_TABLE_FAIL',
)();

// export function getOutletsTableAsync(params) {
//   return (dispatch) => {
//     dispatch(getOutletsTable.request());
//
//     ApiClient.companies.outlets(params)
//       .then((data) => dispatch(getOutletsTable.success(data)))
//       .catch((error) => dispatch(getOutletsTable.failure(error)));
//   };
// }

export const createOutlet = createAsyncAction(
  'COMPANIES_CREATE_OUTLET_REQUEST',
  'COMPANIES_CREATE_OUTLET_SUCCESS',
  'COMPANIES_CREATE_OUTLET_FAIL',
)();

/**
 * @param {CreateOutletModel|CreateOutletModel[]} outlet
 */
export function createOutletAsync(outlet) {
  return (dispatch, getState) => {
    if (getState().companies.outletCreateStatus.state === 'loading') {
      return;
    }
    if (!Array.isArray(outlet)) {
      outlet = [outlet]; // eslint-disable-line no-param-reassign
    }

    dispatch(createOutlet.request());

    ApiClient.companies.createOutlets(outlet)
      .then((data) => dispatch(createOutlet.success(data, outlet)))
      .catch((error) => dispatch(createOutlet.failure(error)));
  };
}

export const updateOutlet = createAsyncAction(
  'COMPANIES_UPDATE_OUTLET_REQUEST',
  'COMPANIES_UPDATE_OUTLET_SUCCESS',
  'COMPANIES_UPDATE_OUTLET_FAIL',
)();

export function updateOutletAsync(outlet) {
  return (dispatch, getState) => {
    if (getState().companies.outletUpdateStatus.state === 'loading') {
      return;
    }

    dispatch(updateOutlet.request());

    ApiClient.companies.updateOutlet(outlet)
      .then((data) => {
        const notifications = [
          {
            type: 'success',
            message: 'Точка продаж отредактирована',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateOutlet.success(data, outlet));
      })
      .catch((error) => dispatch(updateOutlet.failure(error)));
  };
}

export const updateOutletPaymentMethods = createAsyncAction(
  'UPDATE_OUTLET_PAYMENT_METHODS_REQUEST',
  'UPDATE_OUTLET_PAYMENT_METHODS_SUCCESS',
  'UPDATE_OUTLET_PAYMENT_METHODS_FAIL',
)();

export function updateOutletPaymentMethodsAsync({ id, globalId, paymentMethods }) {
  return (dispatch, getState) => {
    dispatch(updateOutletPaymentMethods.request());

    ApiClient.companies.updateOutletPaymentMethods({ id, paymentMethods })
      .then((response) => {
        const notifications = [
          {
            type: 'success',
            message: 'Точка продаж успешно изменена',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateOutletPaymentMethods.success(response, { id, globalId, paymentMethods }));
      })
      .catch((error) => {
        const notifications = [
          {
            type: 'error',
            message: 'Нельзя отключить все способы оплат в точке',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        if (error.code === 2131) {
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        }
        dispatch(updateOutletPaymentMethods.failure(error));
      });
  };
}

export const updateOrderAutoRelease = createAsyncAction(
  'UPDATE_ORDER_AUTO_RELEASE_REQUEST',
  'UPDATE_ORDER_AUTO_RELEASE_SUCCESS',
  'UPDATE_ORDER_AUTO_RELEASE_FAIL',
)();

export function updateOrderAutoReleaseAsync(outlet) {
  return (dispatch, getState) => {
    dispatch(updateOrderAutoRelease.request());

    ApiClient.companies.updateOrderAutoReleaseStatus(outlet)
      .then((data) => {
        const notifications = [
          {
            type: 'success',
            message: 'Точка продаж отредактирована',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateOrderAutoRelease.success(data, outlet));
      })
      .catch((error) => dispatch(updateOrderAutoRelease.failure(error)));
  };
}

export const deleteOutlet = createAsyncAction(
  'COMPANIES_DELETE_OUTLET_REQUEST',
  'COMPANIES_DELETE_OUTLET_SUCCESS',
  'COMPANIES_DELETE_OUTLET_FAIL',
)();

export const getOutletStock = createAsyncAction(
  'COMPANIES_GET_OUTLET_STOCK_REQUEST',
  'COMPANIES_GET_OUTLET_STOCK_SUCCESS',
  'COMPANIES_GET_OUTLET_STOCK_FAIL',
)();

export function getOutletStockAsync(posId) {
  return (dispatch) => {
    dispatch(getOutletStock.request());

    ApiClient.companies.outletStock({ posId })
      .then((data) => dispatch(getOutletStock.success(data.stockInfo)))
      .catch((error) => dispatch(getOutletStock.failure(error)));
  };
}

export const getSubjectMerchantCredentials = createAsyncAction(
  'COMPANIES_GET_SUBJECT_MERCHANT_CREDENTIALS_REQUEST',
  'COMPANIES_GET_SUBJECT_MERCHANT_CREDENTIALS_SUCCESS',
  'COMPANIES_GET_SUBJECT_MERCHANT_CREDENTIALS_FAIL',
)();

export function getSubjectMerchantCredentialsAsync(globalId) {
  return (dispatch) => {
    dispatch(getSubjectMerchantCredentials.request());

    ApiClient.companies.subjectMerchantCredentials(globalId)
      .then((data) => dispatch(getSubjectMerchantCredentials.success(data.merchantCredentials, { globalId })))
      .catch((error) => dispatch(getSubjectMerchantCredentials.failure(error)));
  };
}

export const getOutletMerchantCredentials = createAsyncAction(
  'COMPANIES_GET_OUTLET_MERCHANT_CREDENTIALS_REQUEST',
  'COMPANIES_GET_OUTLET_MERCHANT_CREDENTIALS_SUCCESS',
  'COMPANIES_GET_OUTLET_MERCHANT_CREDENTIALS_FAIL',
)();

export function getOutletMerchantCredentialsAsync(globalId, id) {
  return (dispatch) => {
    dispatch(getOutletMerchantCredentials.request());

    ApiClient.companies.outletMerchantCredentials(globalId, id)
      .then((data) => dispatch(getOutletMerchantCredentials.success(data.merchantCredentials, { globalId, id })))
      .catch((error) => dispatch(getOutletMerchantCredentials.failure(error)));
  };
}

export const acceptOutlet = createAsyncAction(
  'COMPANIES_ACCEPT_OUTLET_REQUEST',
  'COMPANIES_ACCEPT_OUTLET_SUCCESS',
  'COMPANIES_ACCEPT_OUTLET_FAIL',
)();

export function acceptOutletAsync(model) {
  return (dispatch, getState) => {
    dispatch(acceptOutlet.request());

    ApiClient.companies.acceptOutlet(model)
      .then((data) => {
        if (model.status === 'accepted') {
          const state = getState();
          const notifications = [
            {
              type: 'success',
              message: state.companies.outletsByCompany[model.globalId][model.id].status === 'accepted' ? 'Подключение точки продаж обновлено' : 'Точка успешно акцептована',
              icon: true,
              timeout: 5000,
              tag: 'boxretail',
            },
          ];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
        }
        dispatch(acceptOutlet.success(data, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptOutlet.failure(error));
      });
  };
}

export const createProject = createAsyncAction(
  'COMPANIES_CREATE_PROJECT_REQUEST',
  'COMPANIES_CREATE_PROJECT_SUCCESS',
  'COMPANIES_CREATE_PROJECT_FAIL',
)();

export function createProjectAsync(model) {
  return (dispatch) => {
    dispatch(createProject.request());

    const fingerPrint = transliterate(JSON.stringify(getFingerprint()));

    ApiClient.companies.createProject(model, fingerPrint)
      .then((response) => {
        dispatch(showNotification({
          type: 'success',
          title: 'Добавлен проект',
          message: `Добавлен новый проект ${model.name}`,
          timeout: 5000,
        }));
        dispatch(createProject.success(response, model));
        // в LK.jsx при изменении users.currentUser диспатчится этот экшен
        // (а currentUser меняется т.к. к нему добавляется новая компания в редьюсере в ответ на createProject.success)
        // dispatch(getCompaniesAsync());
      })
      .catch((error) => dispatch(createProject.failure(error)));
  };
}

export const updateProject = createAsyncAction(
  'COMPANIES_UPDATE_PROJECT_REQUEST',
  'COMPANIES_UPDATE_PROJECT_SUCCESS',
  'COMPANIES_UPDATE_PROJECT_FAIL',
)();

export function updateProjectAsync(model) {
  return (dispatch) => {
    dispatch(updateProject.request());

    ApiClient.companies.updateProject(model)
      .then((response) => dispatch(updateProject.success(response, model)))
      .catch((error) => dispatch(updateProject.failure(error)));
  };
}

export const updateCompany = createAsyncAction(
  'COMPANIES_UPDATE_COMPANY_REQUEST',
  'COMPANIES_UPDATE_COMPANY_SUCCESS',
  'COMPANIES_UPDATE_COMPANY_FAIL',
)();

export function updateCompanyAsync(model, edit) {
  return (dispatch) => {
    dispatch(updateCompany.request());

    ApiClient.companies.updateCompany(model)
      .then((response) => {
        if (edit) {
          dispatch(showNotification({
            type: 'success',
            title: 'Юридическое лицо добавлено',
            message: `Данные по юридическому лицу ${model.companyName} отредактированы`,
            timeout: 5000,
          }));
        } else {
          dispatch(showNotification({
            type: 'success',
            title: 'Юридическое лицо добавлено',
            message: `Для проекта ${model.name} добавлено юридическое лицо ${model.companyName}`,
            timeout: 5000,
          }));
        }
        dispatch(updateCompany.success(response, model));
      })
      .catch((error) => dispatch(updateCompany.failure(error)));
  };
}

export const deleteProject = createAsyncAction(
  'COMPANIES_DELETE_COMPANY_REQUEST',
  'COMPANIES_DELETE_COMPANY_SUCCESS',
  'COMPANIES_DELETE_COMPANY_FAIL',
)();

/**
 * @param {string} id
 * @param {string} password
 */
export function deleteProjectAsync(id, password) {
  return (dispatch, getState) => {
    // const prevCompanyStatus = getState().companies.byId[id].companyStatus;
    const prevStatus = getState().companies.byId[id].status;
    const { name } = getState().companies.byId[id];
    // const status = prevCompanyStatus === 'accepted' ? 'blocked' : 'removed';
    const status = 'blocked';
    dispatch(deleteProject.request(null, { id }));

    const fingerPrint = transliterate(JSON.stringify(getFingerprint()));

    ApiClient.companies.deleteProject(id, password, fingerPrint)
      .then((response) => {
        dispatch(showNotification({
          type: 'success',
          title: 'Заблокирован проект',
          message: `Заблокирован проект ${name}`,
          timeout: 5000,
        }));
        dispatch(deleteProject.success(response, { id, status, prevStatus }));
      })
      .catch((error) => dispatch(deleteProject.failure(error, { id, status: prevStatus })));
  };
}

export const acceptCompany = createAsyncAction(
  'COMPANIES_ACCEPT_COMPANY_REQUEST',
  'COMPANIES_ACCEPT_COMPANY_SUCCESS',
  'COMPANIES_ACCEPT_COMPANY_FAIL',
)();

export function acceptCompanyAsync({ company, status, notes = {}, filesOfContracts }) {
  return (dispatch, getState) => {
    const { id } = company;
    const prevState = getState();
    const model = { id, status, notes, ...(filesOfContracts ? { filesOfContracts } : null) };

    dispatch(acceptCompany.request(null, model));

    return ApiClient.companies.acceptCompany(model)
      .then((response) => {
        let notification = null;

        switch (status) {
          case 'accepted':
            notification = {
              type: 'success',
              message: 'Юридическое лицо одобрено',
              timeout: 5000,
            };
            break;
          case 'declined':
            notification = {
              type: 'success',
              message: 'Юридическое лицо отклонено',
              timeout: 5000,
            };
            break;
          case 'spam':
            notification = {
              type: 'success',
              message: 'Юридическое лицо отправлено в спам',
              timeout: 5000,
            };
            break;
          case 'info':
            notification = {
              type: 'success',
              message: 'Статус юридического лица изменен',
              timeout: 5000,
            };
            break;

          default:
            if (status !== company.status) {
              notification = {
                type: 'success',
                title: 'Договор отправлен на подписание',
                message: `Для юридического лица ${company.companyName} договор отправлен на подписание`,
                timeout: 5000,
              };
            }
        }
        if (notification) dispatch(showNotification(notification));

        dispatch(acceptCompany.success(response, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptCompany.failure(error, { id, status: prevState.companies.byId[id].status }));
      });
  };
}

export const acceptCompanyKYC = createAsyncAction(
  'COMPANIES_ACCEPT_COMPANY_KYC_REQUEST',
  'COMPANIES_ACCEPT_COMPANY_KYC_SUCCESS',
  'COMPANIES_ACCEPT_COMPANY_KYC_FAIL',
)();

export function acceptCompanyKYCAsync({ company, status, notes = {}, application, edit }) {
  return (dispatch, getState) => {
    const { id } = company;
    const prevState = getState();
    const meta = { id, status, notes };

    dispatch(acceptCompanyKYC.request(null, meta));

    return ApiClient.companies.acceptKYC({ companyId: id, status, notes, application })
      .then((response) => {
        let notification = null;

        if (company.kycInfoStatus === 'accepted') {
          notification = {
            type: 'success',
            message: 'Анкета КУС одобрена',
            timeout: 5000,
          };
        } else if (company.kycInfoStatus === 'pending') {
          notification = {
            type: 'success',
            message: edit ? 'Анкета КУС отправлена на уточнение' : 'Анкета KYC запрошена',
            timeout: 5000,
          };
        }
        if (notification) dispatch(showNotification(notification));

        dispatch(acceptCompanyKYC.success(response, meta));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptCompanyKYC.failure(error, { id, status: prevState.companies.companyInfoById[id].kycInfoStatus }));
      });
  };
}

export const getCompanyInfo = createAsyncAction(
  'COMPANIES_GET_COMPANY_INFO_REQUEST',
  'COMPANIES_GET_COMPANY_INFO_SUCCESS',
  'COMPANIES_GET_COMPANY_INFO_FAIL',
)();

export function getCompanyInfoAsync(id) {
  return (dispatch, getState) => {
    const now = Date.now();

    if (
      getState().companies.companyInfoFetchStatuses[id]?.loading ||
      now < (lastRequestTimeByName[`getCompanyInfo:${id}`] ?? 0) + 5000
    ) {
      return;
    }

    lastRequestTimeByName[`getCompanyInfo:${id}`] = now;

    dispatch(getCompanyInfo.request(null, { id }));

    ApiClient.companies.getCompanyInfo(id)
      .then((response) => {
        dispatch(getCompanyInfo.success(response));
        const state = getState();
        // TODO: check it
        if (id === state.app.currentCompanyId && !isActiveCompanyStatus(response.status)) {
          const otherAcceptedCompany = Object.values(state.companies.byId).find((company) => company.id !== id && company.status === 'accepted');
          if (otherAcceptedCompany) {
            dispatch(setCompanyId(otherAcceptedCompany.id, state.users.currentUser.id));
          }
        }
      })
      .catch((error) => dispatch(getCompanyInfo.failure(error, { id })));
  };
}

export const getCompanyHistory = createAsyncAction(
  'COMPANIES_GET_COMPANY_HISTORY_REQUEST',
  'COMPANIES_GET_COMPANY_HISTORY_SUCCESS',
  'COMPANIES_GET_COMPANY_HISTORY_FAIL',
)();

export function getCompanyHistoryAsync(id) {
  return (dispatch) => {
    dispatch(getCompanyHistory.request());

    ApiClient.companies.getProjectHistory(id)
      .then((response) => dispatch(getCompanyHistory.success(response, { id })))
      .catch((error) => dispatch(getCompanyHistory.failure(error)));
  };
}

export const getOutletHistory = createAsyncAction(
  'COMPANIES_GET_OUTLET_HISTORY_REQUEST',
  'COMPANIES_GET_OUTLET_HISTORY_SUCCESS',
  'COMPANIES_GET_OUTLET_HISTORY_FAIL',
)();

export function getOutletHistoryAsync(id) {
  return (dispatch) => {
    dispatch(getOutletHistory.request());

    ApiClient.companies.getOutletHistory(id)
      .then((response) => dispatch(getOutletHistory.success(response, { id })))
      .catch((error) => dispatch(getOutletHistory.failure(error)));
  };
}

export const sendKYC = createAsyncAction(
  'COMPANIES_SEND_KYC_REQUEST',
  'COMPANIES_SEND_KYC_SUCCESS',
  'COMPANIES_SEND_KYC_FAIL',
)();

export const sendKYCFiles = createAsyncAction(
  'COMPANIES_SEND_KYC_FILES_REQUEST',
  'COMPANIES_SEND_KYC_FILES_SUCCESS',
  'COMPANIES_SEND_KYC_FILES_FAIL',
)();

// /**
//  * @param {string} companyId
//  * @param {FormData} formData
//  * @returns {*}
//  */
// export function sendKYCFilesAsync(companyId, formData) {
//   return (dispatch) => {
//     dispatch(sendKYCFiles.request());
//
//     ApiClient.companies.sendFiles(companyId, formData)
//       .then((response) => dispatch(sendKYCFiles.success(response)))
//       .catch((error) => dispatch(sendKYCFiles.failure(error)));
//   };
// }

/**
 * @param {KYCModel} model
 * @param {object} company
 * @param {string} application
 * @param {boolean} edit
 */
export function sendKYCFormAsync(model, company, application, edit) {
  return (dispatch) => {
    dispatch(sendKYC.request());

    ApiClient.companies.sendKYC({ ...model, application })
      .then((response) => {
        dispatch(showNotification({
          type: 'success',
          message: edit ? 'Данные КУС отредактированы' : 'Анкета KYC отправлена на проверку ',
          timeout: 5000,
        }));
        dispatch(sendKYC.success(response, { ...company, ...model, application }));
        // dispatch(sendKYCFilesAsync(model.companyId, files));
      })
      .catch((error) => dispatch(sendKYC.failure(error)));
  };
}

export const sendContractFiles = createAsyncAction(
  'COMPANIES_SEND_CONTRACT_FILES_REQUEST',
  'COMPANIES_SEND_CONTRACT_FILES_SUCCESS',
  'COMPANIES_SEND_CONTRACT_FILES_FAIL',
)();

/**
 * @param {object} data
 * @param {object} company
 * @returns {*}
 */
export function sendContractFilesAsync(data, company) {
  return (dispatch) => {
    dispatch(sendContractFiles.request());

    ApiClient.companies.sendContract(data)
      .then((response) => {
        dispatch(showNotification({
          type: 'success',
          title: 'Договор отправлен на проверку',
          message: `Для юридического лица ${company.companyName} документы отправлены на проверку`,
          timeout: 5000,
        }));
        dispatch(sendContractFiles.success(response, { ...company, ...data, status: 'contractInProgress' }));
      })
      .catch((error) => dispatch(sendContractFiles.failure(error)));
  };
}

export const saveMultipleOutlets = createAsyncAction(
  'COMPANIES_SAVE_MULTIPLE_OUTLETS_REQUEST',
  'COMPANIES_SAVE_MULTIPLE_OUTLETS_SUCCESS',
  'COMPANIES_SAVE_MULTIPLE_OUTLETS_FAIL',
)();

/**
 * @param {FormData} model
 * @param {string} companyId
 * @param {string} type
 */
export function saveMultipleOutletsAsync(model, companyId, type) {
  return (dispatch) => {
    dispatch(saveMultipleOutlets.request());
    ApiClient.companies.saveMultipleOutlets(model, companyId, type)
      .then((response) => dispatch(saveMultipleOutlets.success(response)))
      .catch((error) => dispatch(saveMultipleOutlets.failure(error)));
  };
}

export const saveMultipleEmployees = createAsyncAction(
  'COMPANIES_SAVE_MULTIPLE_EMPLOYEES_REQUEST',
  'COMPANIES_SAVE_MULTIPLE_EMPLOYEES_SUCCESS',
  'COMPANIES_SAVE_MULTIPLE_EMPLOYEES_FAIL',
)();

/**
 * @param {FormData} model
 * @param {string} companyId
 * @param {string} type
 */
export function saveMultipleEmployeesAsync(model, companyId, type) {
  return (dispatch) => {
    dispatch(saveMultipleEmployees.request());
    dispatch(saveMultipleOutlets.request());
    ApiClient.companies.saveMultipleEmployees(model, companyId, type)
      .then((response) => dispatch(saveMultipleEmployees.success(response)))
      .catch((error) => dispatch(saveMultipleEmployees.failure(error)));
  };
}

export const getSecretKey = createAsyncAction(
  'COMPANIES_GET_SECRET_KEY_REQUEST',
  'COMPANIES_GET_SECRET_KEY_SUCCESS',
  'COMPANIES_GET_SECRET_KEY_FAIL',
)();

export function getSecretKeyAsync(id) {
  return (dispatch) => {
    dispatch(getSecretKey.request());

    ApiClient.companies.getSecretKey(id)
      .then((response) => dispatch(getSecretKey.success(response, { id })))
      .catch((error) => dispatch(getSecretKey.failure(error)));
  };
}

export const refreshSecretKey = createAsyncAction(
  'COMPANIES_REFRESH_SECRET_KEY_REQUEST',
  'COMPANIES_REFRESH_SECRET_KEY_SUCCESS',
  'COMPANIES_REFRESH_SECRET_KEY_FAIL',
)();

export function refreshSecretKeyAsync(companyId, password) {
  return (dispatch) => {
    dispatch(refreshSecretKey.request());

    ApiClient.companies.refreshSecretKey(companyId, password)
      .then((response) => dispatch(refreshSecretKey.success(response, { id: companyId })))
      .catch((error) => dispatch(refreshSecretKey.failure(error)));
  };
}

export const deleteSecretKey = createAsyncAction(
  'COMPANIES_DELETE_SECRET_KEY_REQUEST',
  'COMPANIES_DELETE_SECRET_KEY_SUCCESS',
  'COMPANIES_DELETE_SECRET_KEY_FAIL',
)();

export function deleteSecretKeyAsync(companyId, password) {
  return (dispatch) => {
    dispatch(deleteSecretKey.request());

    ApiClient.companies.deleteSecretKey(companyId, password)
      .then((response) => dispatch(deleteSecretKey.success(response, { id: companyId })))
      .catch((error) => dispatch(deleteSecretKey.failure(error)));
  };
}

export const getCompanySubscriptions = createAsyncAction(
  'COMPANIES_GET_SUBSCRIPTIONS_REQUEST',
  'COMPANIES_GET_SUBSCRIPTIONS_SUCCESS',
  'COMPANIES_GET_SUBSCRIPTIONS_FAIL',
)();

export function getCompanySubscriptionsAsync(force = true) {
  return (dispatch, getState) => {
    if (getState().companies.getSubscriptionsStatus.state !== 'initial' && !force) {
      return;
    }

    dispatch(getCompanySubscriptions.request());

    ApiClient.billing.getSubscriptions()
      .then((response) => dispatch(getCompanySubscriptions.success(response)))
      .catch((error) => dispatch(getCompanySubscriptions.failure(error)));
  };
}

export const getPayments = createAsyncAction(
  'COMPANIES_GET_PAYMENTS_REQUEST',
  'COMPANIES_GET_PAYMENTS_SUCCESS',
  'COMPANIES_GET_PAYMENTS_FAIL',
)();

export function getPaymentsAsync(params) {
  return (dispatch, getState) => {
    dispatch(getPayments.request());
    const { currentCompanyId } = getState().app;
    const paramsWithCompanyId = {
      ...params,
      companyId: params?.companyId ?? currentCompanyId,
    };

    return ApiClient.billing.getPayments(paramsWithCompanyId)
      .then((response) => dispatch(getPayments.success(response, paramsWithCompanyId)))
      .catch((error) => dispatch(getPayments.failure(error)));
  };
}

export const getLastPayment = createAsyncAction(
  'COMPANIES_GET_LAST_PAYMENT_REQUEST',
  'COMPANIES_GET_LAST_PAYMENT_SUCCESS',
  'COMPANIES_GET_LAST_PAYMENT_FAIL',
)();

export function getLastPaymentAsync({ companyId, linkId }) {
  return (dispatch) => {
    const meta = { companyId, linkId };
    dispatch(getLastPayment.request(null, meta));

    ApiClient.billing.getLastPayment(companyId, linkId)
      .then((response) => {
        dispatch(getLastPayment.success(response, meta));
      })
      .catch((error) => dispatch(getLastPayment.failure(error, meta)));
  };
}

export const changeTariff = createAsyncAction(
  'COMPANIES_CHANGE_TARIFF_REQUEST',
  'COMPANIES_CHANGE_TARIFF_SUCCESS',
  'COMPANIES_CHANGE_TARIFF_FAIL',
)();

export const resetChangedSubscription = createAction('COMPANIES_RESET_CHANGED_SUBSCRIPTION')();

export function changeTariffAsync({ tariffName, ...data }) {
  return (dispatch) => {
    const { companyId } = data;
    const meta = {
      ...data,
      nextTariffId: data.tariffId,
      nextTariffName: tariffName,
      nextTariffInterval: data.interval,
    };
    dispatch(changeTariff.request(null, meta));

    ApiClient.billing.changeTariff(companyId, data)
      .then((response) => {
        dispatch(changeTariff.success(response, meta));

        if (!response.linkUrl) {
          const notification = {
            type: 'success',
            message: i18n.t('alerts.subscription-changed', { tariffName }),
            timeout: 5000,
          };

          dispatch(showNotification(notification));
          dispatch(resetChangedSubscription());
          // dispatch(getPaymentsAsync());
        }
      })
      .catch((error) => dispatch(changeTariff.failure(error, meta)));
  };
}

export const setNextTariff = createAction('COMPANIES_SET_NEXT_TARIFF')();

export const cancelTariff = createAsyncAction(
  'COMPANIES_CANCEL_TARIFF_REQUEST',
  'COMPANIES_CANCEL_TARIFF_SUCCESS',
  'COMPANIES_CANCEL_TARIFF_FAIL',
)();

export function cancelTariffAsync(companyId) {
  return (dispatch) => {
    const meta = { companyId };
    dispatch(cancelTariff.request(null, meta));

    ApiClient.billing.cancelTariff(companyId)
      .then((response) => dispatch(cancelTariff.success(response, meta)))
      .catch((error) => dispatch(cancelTariff.failure(error, meta)));
  };
}

export const resumeTariff = createAsyncAction(
  'COMPANIES_RESUME_TARIFF_REQUEST',
  'COMPANIES_RESUME_TARIFF_SUCCESS',
  'COMPANIES_RESUME_TARIFF_FAIL',
)();

export function resumeTariffAsync(companyId) {
  return (dispatch) => {
    let resumeResponse;
    const meta = { companyId };
    dispatch(resumeTariff.request(null, meta));

    ApiClient.billing.resumeTariff(companyId)
      .then((response) => {
        resumeResponse = response;
        return dispatch(getPaymentsAsync());
      })
      .then(() => {
        const notification = {
          type: 'success',
          message: t('subscription-successfully-resumed'),
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(resumeTariff.success(resumeResponse, meta));
      })
      .catch((error) => dispatch(resumeTariff.failure(error, meta)));
  };
}

export const cancelChangeTariff = createAsyncAction(
  'COMPANIES_CANCEL_CHANGE_TARIFF_REQUEST',
  'COMPANIES_CANCEL_CHANGE_TARIFF_SUCCESS',
  'COMPANIES_CANCEL_CHANGE_TARIFF_FAIL',
)();

export function cancelChangeTariffAsync(companyId) {
  return (dispatch) => {
    const meta = { companyId };
    dispatch(cancelChangeTariff.request());

    ApiClient.billing.cancelChangeTariff(companyId)
      .then((response) => dispatch(cancelChangeTariff.success(response, meta)))
      .catch((error) => dispatch(cancelChangeTariff.failure(error, meta)));
  };
}

export const getAppConfigs = createAsyncAction(
  'GET_APP_CONFIGS_REQUEST',
  'GET_APP_CONFIGS_SUCCESS',
  'GET_APP_CONFIGS_FAIL',
)();

export function getAppConfigsAsync() {
  return (dispatch) => {
    dispatch(getAppConfigs.request());

    ApiClient.companies.getAppsConfig()
      .then((response) => dispatch(getAppConfigs.success(response)))
      .catch((error) => dispatch(getAppConfigs.failure(error)));
  };
}

export const getTariffConfigs = createAsyncAction(
  'GET_TARIFF_CONFIGS_REQUEST',
  'GET_TARIFF_CONFIGS_SUCCESS',
  'GET_TARIFF_CONFIGS_FAIL',
)();

export function getTariffConfigsAsync(companyId) {
  return (dispatch) => {
    dispatch(getTariffConfigs.request(null, { companyId }));

    ApiClient.companies.getTariffConfigs(companyId)
      .then((response) => dispatch(getTariffConfigs.success(response, { companyId })))
      .catch((error) => dispatch(getTariffConfigs.failure(error, { companyId })));
  };
}

export const getTariffAdditionalPrice = createAsyncAction(
  'GET_TARIFF_ADDITIONAL_PRICE_REQUEST',
  'GET_TARIFF_ADDITIONAL_PRICE_SUCCESS',
  'GET_TARIFF_ADDITIONAL_PRICE_FAIL',
)();

export function getTariffAdditionalPriceAsync({ planId, companyId, ...data }) {
  return (dispatch) => {
    dispatch(getTariffAdditionalPrice.request(null, { planId }));

    ApiClient.companies.tariffAdditionalPrice(companyId, data)
      .then((response) => {
        dispatch(getTariffAdditionalPrice.success(response, { planId, interval: data.interval }));
      })
      .catch((error) => dispatch(getTariffAdditionalPrice.failure(error, { planId })));
  };
}

export const resetAdditionalPrice = createAction('COMPANIES_RESET_ADDITIONAL_PRICE')();

export const resetUrlChangeTariffNow = createAction('RESET_URL_CHANGE_TARIFF_NOW_REQUEST')();

export const bindCard = createAsyncAction(
  'COMPANIES_GET_INVOICE_URL_REQUEST',
  'COMPANIES_GET_INVOICE_URL_SUCCESS',
  'COMPANIES_GET_INVOICE_URL_FAIL',
)();

export const resetBindCardUrl = createAction('COMPANIES_RESET_BIND_CARD_URL')();

export function bindCardAsync(companyId) {
  return (dispatch) => {
    dispatch(bindCard.request());

    ApiClient.billing.bindCard(companyId)
      .then((response) => dispatch(bindCard.success(response)))
      .catch((error) => dispatch(bindCard.failure(error)));
  };
} //TODO is this method necessary?

export const setCashierMigration = createAsyncAction(
  'SET_CASHIER_MIGRATION_REQUEST',
  'SET_CASHIER_MIGRATION_SUCCESS',
  'SET_CASHIER_MIGRATION_FAIL',
)();

export function setCashierMigrationAsync(migrationData) {
  return (dispatch, getState) => {
    dispatch(setCashierMigration.request());

    ApiClient.cashiers.setMigration(migrationData)
      .then((response) => {
        const notifications = [
          {
            type: 'success',
            message: migrationData.isActive ? 'Миграция кассиров включена' : 'Миграция кассиров выключена',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));

        dispatch(setCashierMigration.success(response, migrationData));
      })
      .catch((error) => dispatch(setCashierMigration.failure(error)));
  };
}

export const updateShortProjectId = createAsyncAction(
  'UPDATE_SHORT_PROJECT_ID_REQUEST',
  'UPDATE_SHORT_PROJECT_ID_SUCCESS',
  'UPDATE_SHORT_PROJECT_ID_FAIL',
)();

export function updateShortProjectIdAsync(data) {
  return (dispatch, getState) => {
    dispatch(updateShortProjectId.request());

    const { companyId } = data;
    const { boxRetailCustomProjectId } = getState().companies.companyInfoById[companyId];
    ApiClient.boxretail.updateShortProjectId(data)
      .then((response) => {
        const notifications = [
          {
            type: 'success',
            message: !boxRetailCustomProjectId ? 'Короткое имя проекта создано' : 'Короткое имя проекта отредактировано',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));

        dispatch(updateShortProjectId.success(response, data));
      })
      .catch((error) => dispatch(updateShortProjectId.failure(error)));
  };
}

export const setConnectionType = createAsyncAction(
  'SET_CONNECTION_TYPE_REQUEST',
  'SET_CONNECTION_TYPE_SUCCESS',
  'SET_CONNECTION_TYPE_FAIL',
)();

export function setConnectionTypeAsync(data, companyId) {
  return (dispatch, getState) => {
    dispatch(setConnectionType.request());

    const state = getState();
    const { isActive, id } = data;
    const prevIsActive = state.companies.outletsByCompany[companyId][id].kktInfo?.isActive;

    ApiClient.boxretail.setConnectionType(data)
      .then((response) => {
        const notifications = [
          {
            type: 'success',
            message: isActive === prevIsActive ? 'Изменен тип подключения ККТ' : (isActive ? 'Касса ККТ включена' : 'Касса ККТ выключена'),
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(setConnectionType.success(response, data));
      })
      .catch((error) => dispatch(setConnectionType.failure(error)));
  };
}

export const reissueLicenseKey = createAsyncAction(
  'REISSUE_LICENSE_KEY_REQUEST',
  'REISSUE_LICENSE_KEY_SUCCESS',
  'REISSUE_LICENSE_KEY_FAIL',
)();

export function reissueLicenseKeyAsync(key) {
  return (dispatch, getState) => {
    dispatch(reissueLicenseKey.request());

    ApiClient.boxretail.reissueLicenseKey(key)
      .then((response) => {
        const notifications = [
          {
            type: 'success',
            message: 'Лицензионный ключ перевыпущен',
            icon: true,
            timeout: 5000,
            tag: 'boxretail',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(reissueLicenseKey.success(response));
      })
      .catch((error) => {
        if (error.description === 'License not found') {
          const notifications = [
            {
              type: 'error',
              message: 'Лицензионный ключ не найден',
              icon: true,
              timeout: 5000,
              tag: 'boxretail',
            },
          ];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        }
        dispatch(reissueLicenseKey.failure(error));
      });
  };
}

export const editContract = createAsyncAction(
  'CRM_EDIT_CONTRACT_REQUEST',
  'CRM_EDIT_CONTRACT_SUCCESS',
  'CRM_EDIT_CONTRACT_FAIL',
)();

export function editContractAsync(data) {
  return (dispatch) => {
    dispatch(editContract.request());

    ApiClient.companies.editContract(data)
      .then((res) => {
        dispatch(editContract.success(res, data));

        const notification = {
          type: 'success',
          message: 'Договор изменен',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(editContract.failure(error, data)));
  };
}

export const updateContactSettings = createAsyncAction(
  'UPDATE_CONTACT_SETTINGS_REQUEST',
  'UPDATE_CONTACT_SETTINGS_SUCCESS',
  'UPDATE_CONTACT_SETTINGS_FAIL',
)();

export function updateContactSettingsAsync(companyId, data) {
  return (dispatch, getState) => {
    dispatch(updateContactSettings.request());

    const { contactInfo, name } = getState().companies.companyInfoById[companyId];
    const hasAnySetting = contactInfo?.accNumber || contactInfo?.pointCode || contactInfo?.contractNumber;

    ApiClient.companies.updateContactSettings(companyId, data)
      .then((res) => {
        const successNotification = {
          type: 'success',
          title: hasAnySetting ? 'Данные успешно изменены' : 'Данные успешно сохранены',
          message: hasAnySetting ? `Для проекта ${name} изменены данные связи с платежной системой` : `Для проекта сохранены ${name} данные связи с платежной системой`,
          timeout: 5000,
        };
        dispatch(showNotification(successNotification));
        dispatch(updateContactSettings.success(res, { companyId, data }));
      })
      .catch((error) => {
        const errorNotification = {
          type: 'error',
          title: 'Ошибка при изменении данных',
          message: 'Попробуйте повторить позднее',
          timeout: 5000,
        };
        dispatch(showNotification(errorNotification));
        dispatch(updateContactSettings.failure(error));
      });
  };
}

export const saveCdekSettings = createAsyncAction(
  'SAVE_CDEK_SETTINGS_REQUEST',
  'SAVE_CDEK_SETTINGS_SUCCESS',
  'SAVE_CDEK_SETTINGS_FAIL',
)();

export function saveCdekSettingsAsync({ status = 'accepted', ...model }) {
  return (dispatch, getState) => {
    const { currentCompanyId: projectId } = getState().app;

    dispatch(saveCdekSettings.request());

    ApiClient.cdek.saveSettings({ ...model, projectId, status })
      .then((data) => {
        const notification = {
          type: 'success',
          message: t('cdek-settings-saved'),
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(saveCdekSettings.success(data, { ...model, projectId, status }));
      })
      .catch((error) => dispatch(saveCdekSettings.failure(error)));
  };
}

export const changeSubscriptionPaymentMethod = createAsyncAction(
  'SUBSCRIPTIONS_CHANGE_SUBSCRIPTION_PAYMENT_METHOD_REQUEST',
  'SUBSCRIPTIONS_CHANGE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS',
  'SUBSCRIPTIONS_CHANGE_SUBSCRIPTION_PAYMENT_METHOD_FAIL',
)();

export function changeSubscriptionPaymentMethodAsync(data) {
  return (dispatch) => {
    dispatch(changeSubscriptionPaymentMethod.request());

    ApiClient.companies.changeSubscriptionPaymentMethod(data)
      .then((response) => {
        dispatch(changeSubscriptionPaymentMethod.success(response, data));

        const method = t(`by-${data.paymentMethod}`).toLocaleLowerCase();

        const notification = {
          type: 'success',
          title: t('payment-method-changed'),
          message: t('payment-method-changed-to', { method }),
          timeout: 5000,
        };

        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(changeSubscriptionPaymentMethod.failure(error)));
  };
}

export const createInvoice = createAsyncAction(
  'COMPANIES_CREATE_INVOICE_REQUEST',
  'COMPANIES_CREATE_INVOICE_SUCCESS',
  'COMPANIES_CREATE_INVOICE_FAIL',
)();

export function createInvoiceAsync({ id, ...data }) {
  return (dispatch) => {
    dispatch(createInvoice.request(null, { id, ...data }));

    ApiClient.companies.createInvoice(data)
      .then((response) => dispatch(createInvoice.success(response, { id, ...data })))
      .catch((error) => dispatch(createInvoice.failure(error, { id, ...data })));
  };
}

export const connectYabusiness = createAsyncAction(
  'CONNECT_YA_BUSINESS_REQUEST',
  'CONNECT_YA_BUSINESS_SUCCESS',
  'CONNECT_YA_BUSINESS_FAIL',
)();

export function connectYabusinessAsync(selectedProjectId) {
  return (dispatch, getState) => {
    const { currentCompanyId } = getState().app;
    const projectId = selectedProjectId || currentCompanyId;

    dispatch(connectYabusiness.request());

    ApiClient.yabusiness.connect({ projectId })
      .then((payload) => dispatch(connectYabusiness.success(payload, { projectId })))
      .catch((error) => dispatch(connectYabusiness.failure(error)));
  };
}
