import IMask from 'imask';
import React, { useEffect, useRef } from 'react';
import { Input } from '@teko/ui-kit';

function MaskInput(props) {
  const { mask, ...rest } = props;
  const inputRef = useRef(null);
  const maskRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      maskRef.current = IMask(inputRef.current, mask);
    }
  }, []);

  useEffect(() => {
    if (maskRef.current.updateValue) {
      maskRef.current.updateValue();
    }
  }, [props.value]);

  return (
    <Input
      ref={inputRef}
      {...rest}
    />
  );
}

export default MaskInput;
