const awizaCdnUrl = process.env.AWIZA_CDN_URL;
const logoCdnUrl = process.env.CUSTOM_LOGO_CDN_URL;

export function getAwizaImgSrc(id) {
  // return `${axios.defaults.baseURL}/awiza/images/${id}/preview`;
  return `${awizaCdnUrl}/${id}`;
}

export function getLogoImgSrc(id) {
  // return `${axios.defaults.baseURL}/awiza/images/${id}/preview`;
  return `${logoCdnUrl}/${id}`;
}

export function getAwizaImgPreviewSrc(id) {
  // return `${axios.defaults.baseURL}/awiza/images/preview_${id}/preview`;
  return `${awizaCdnUrl}/preview_${id}`;
}
