import { useCallback, useRef, useState } from 'react';
import useUser from './useUser';

// TODO: move to redux
function useStorableState(key, defaultValue = undefined, storage = localStorage) {
  const ref = useRef();
  const userId = useUser()?.id;
  const [value, _setValue] = useState(() => {
    const options = JSON.parse(storage.getItem(`options:${userId}`)) ?? {};
    return options[key] ?? (typeof defaultValue === 'function' ? defaultValue() : defaultValue);
  });

  ref.current = { value };

  const setValue = useCallback((nextValue) => {
    const options = JSON.parse(storage.getItem(`options:${userId}`)) ?? {};

    _setValue(nextValue);
    options[key] = typeof nextValue === 'function' ? nextValue(ref.current.value) : nextValue;
    storage.setItem(`options:${userId}`, JSON.stringify(options));
  }, []);

  return [value, setValue];
}

export default useStorableState;
