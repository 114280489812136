import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { getNewUserNotificationsNotification } from '../utils/notification-utils';
import { createTableAction } from '../utils/redux-utils';
import { showNotification } from './app';

export const getUserNotifications = createAsyncAction(
  'NOTIFICATIONS_GET_REQUEST',
  'NOTIFICATIONS_GET_SUCCESS',
  'NOTIFICATIONS_GET_FAIL',
)();

export function getUserNotificationsAsync(params, isReadAllClicked = false) {
  return (dispatch, getState) => {
    dispatch(getUserNotifications.request());

    ApiClient.notifications.getUserNotifications(params)
      .then((data) => {
        const { notifications } = data;
        dispatch(getUserNotifications.success(notifications, { isReadAllClicked }));
        if (notifications.length) {
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        }
      })
      .catch((error) => dispatch(getUserNotifications.failure(error)));
  };
}

export const readUserNotification = createAsyncAction(
  'NOTIFICATIONS_READ_REQUEST',
  'NOTIFICATIONS_READ_SUCCESS',
  'NOTIFICATIONS_READ_FAIL',
)();

export function readUserNotificationAsync(id) {
  return (dispatch) => {
    const meta = { id };
    dispatch(readUserNotification.request(null, meta));

    ApiClient.notifications.readUserNotification(id)
      .then(() => dispatch(readUserNotification.success(null, meta)))
      .catch((error) => dispatch(readUserNotification.failure(error, meta)));
  };
}

export const readAllUserNotifications = createAsyncAction(
  'NOTIFICATIONS_READ_ALL_REQUEST',
  'NOTIFICATIONS_READ_ALL_SUCCESS',
  'NOTIFICATIONS_READ_ALL_FAIL',
)();

export function readAllUserNotificationsAsync(companyId, startT, finishT) {
  return (dispatch, getState) => {
    dispatch(readAllUserNotifications.request());

    const state = getState();
    const { allIds } = state.notifications.table;
    const params = {
      alreadyRead: false,
      firstId: '000000000000000000000000',
    };

    ApiClient.notifications.readAllUserNotifications(companyId, startT, finishT)
      .then(() => {
        dispatch(readAllUserNotifications.success(null, { allIds }));
        dispatch(getUserNotificationsAsync(params, true));
      })
      .catch((error) => dispatch(readAllUserNotifications.failure(error)));
  };
}

// for notifications page
export const getAllUserNotifications = createAsyncAction(
  'NOTIFICATIONS_GET_ALL_REQUEST',
  'NOTIFICATIONS_GET_ALL_SUCCESS',
  'NOTIFICATIONS_GET_ALL_FAIL',
)();

// for notifications page
export const allUserNotificationsTable = createTableAction(
  'NOTIFICATIONS_ALL_TABLE_ADD',
  'NOTIFICATIONS_ALL_TABLE_PREV',
  'NOTIFICATIONS_ALL_TABLE_NEXT',
  'NOTIFICATIONS_ALL_TABLE_FIRST',
);

// for notifications page
export function getAllUserNotificationsAsync(params, append) {
  return (dispatch) => {
    dispatch(getAllUserNotifications.request());

    ApiClient.notifications.getUserNotifications(params)
      .then((data) => {
        const { notifications, hasMore } = data;
        const meta = { append };
        const ids = notifications.map((item) => item.id);

        dispatch(getAllUserNotifications.success(notifications, meta));
        dispatch(allUserNotificationsTable.add({ ids, hasMore }, meta));
      })
      .catch((error) => dispatch(getAllUserNotifications.failure(error)));
  };
}
