import { DateInput } from '@teko/ui-kit';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function DateInputField(props) {
  const { t } = useTranslation();
  const [field, meta] = useField(props.name);

  const translatedPresets = useMemo(
    () => props.presets?.map((p) => ({
      value: p.value,
      label: t(p.label, { count: p.count }),
    })),
    [props.presets, t],
  );

  return (
    <DateInput
      {...field}
      {...props}
      presets={translatedPresets}
      state={meta.error && meta.touched ? 'invalid' : undefined}
    />
  );
}

DateInputField.propTypes = {
  name: PropTypes.string.isRequired,
  presets: PropTypes.array,
};

export default DateInputField;
