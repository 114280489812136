import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/paycafe';
import normalize from '../utils/normalize';
import { asyncReducerFactory, tableReducerFactory } from '../utils/redux-utils';

const outletsReducer = createReducer({})
  .handleAction(actions.getPaycafeOutlets.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload),
    // ...normalize(payload.map(it => ({ ...it, merchants: ['testovaya_kompaniya'] }))), // for tests
  }))
  .handleAction(actions.createPaycafeOutlets.success, (state, { payload, meta }) =>
  // const createdIds = new Set(
  //   payload.filter(({ isCreated }) => isCreated).map(({ id }) => id),
  // );

    ({
      ...state,
      ...normalize(payload.filter(({ isCreated }) => isCreated)),
      // ...normalize(
      //   meta.filter(({ id }) => createdIds.has(id)).map((outlet) => ({
      //     extra: {
      //       ...outlet.extra,
      //       isAccepted: false,
      //     },
      //     id: outlet.id,
      //     fullName: outlet.fullName,
      //     merchants: [outlet.globalId],
      //   })),
      // ),
    }))
  .handleAction(
    [
      actions.acceptPaycafeOutlet.success,
      actions.updatePaycafeOutlet.success,
    ],
    (state, { payload, meta }) => ({
      ...state,
      [meta.id]: {
        ...state[meta.id],
        ...meta,
        ...payload,
      },
    }),
  )
  .handleAction(actions.updatePaycafeOutletSources.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...payload,
    },
  }))
  .handleAction(actions.connectOnlineCashbox.success, (state, { payload }) => ({
    ...state,
    [payload.id]: { ...state[payload.id], ...payload },
  }))
  .handleAction(actions.disconnectOnlineCashbox.success, (state, { payload }) => {
    const modifiedPaymentPoint = { ...state[payload.id] };
    delete modifiedPaymentPoint.atolCashRegisterId;

    return ({
      ...state,
      [payload.id]: modifiedPaymentPoint,
    });
  })
  .handleAction(actions.yoomoneyAuth.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      sources: {
        ...state[meta.id]?.sources,
        yoomoney: {
          ...state[meta.id]?.sources?.yoomoney,
          isActive: true,
        },
      },
      extra: {
        ...state[meta.id]?.extra,
        paymentMethods: [...(state[meta.id]?.extra?.paymentMethods.filter((method) => method !== 'yoomoney')), 'yoomoney'],
      },
    },
  }));

const failedOutletsReducer = createReducer([])
  .handleAction(actions.createPaycafeOutlets.success, (state, { payload }) => payload.filter(({ isCreated }) => !isCreated));

const formsByCompanyReducer = createReducer({})
  .handleAction(actions.getPaycafeForms.success, (state, { payload, meta }) => ({ ...state, [meta.initiatorId]: payload.settings }))
  .handleAction(actions.savePaycafeForm.success, (state, { payload }) => {
    let found = false;
    const arr = state[payload.initiatorId].map((form) => {
      if (form.showcaseId === payload.showcaseId) {
        found = true;
        return payload;
      }

      return form;
    });

    if (!found) {
      arr.push(payload);
    }

    return {
      ...state,
      [payload.initiatorId]: arr,
    };
  })
  .handleAction(actions.deletePaycafeForm.success, (state, { meta }) => {
    const { initiatorId, showcaseId } = meta;

    return {
      ...state,
      [initiatorId]: state[initiatorId].filter((form) => form.initiatorId !== initiatorId || form.showcaseId !== showcaseId),
    };
  });

const tmpUrlReducer = createReducer(null)
  .handleAction(actions.getPaycafeTmpUrl.success, (state, { payload }) => payload.url);

const outletHistoryReducer = createReducer({})
  .handleAction(actions.getPaycafeOutletHistory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload.filter(({ change: { newValue } }) => Object.keys(newValue).length),
  }));

const lastCreatedInvoiceByCompanyReducer = createReducer({})
  .handleAction(actions.createPaycafeInvoice.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: {
      ...meta,
      url: payload.url,
    },
  }));

const repeatInvoiceReducer = createReducer(null)
  .handleAction(actions.setRepeatInvoiceData, (state, { payload }) => payload)
  .handleAction(actions.clearRepeatInvoiceData, () => null);

const invoicesByIdReducer = createReducer({})
  .handleAction(actions.getPaycafeInvoices.success, (state, { payload }) => ({ ...state, ...normalize(payload) }))
  .handleAction(actions.getPaycafeInvoiceById.success, (state, { payload }) => ({ ...state, [payload.id]: payload }))
  .handleAction(actions.disablePaycafeInvoice.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      status: payload.status || 'blocked',
    },
  }));

// { testQr: { name: 'testName', id: 'testQr', link: 'https://teko.io', companyId: 'lirota9508__hagendes_com_buran', outletId: 'lirota9508__hagendes_com_buran_eryx_bb_andrew' } }
const staticQrCodesByIdReducer = createReducer({})
  .handleAction(actions.getPaycafeStaticQrCodes.success, (state, { payload }) => ({ ...state, ...normalize(payload) }))
  .handleAction(actions.createPaycafeStaticQrCode.success, (state, { payload }) => ({ ...state, [payload.id]: payload }))
  .handleAction(actions.updatePaycafeStaticQrCode.success, (state, { payload }) => ({ ...state, [payload.id]: payload }))
  .handleAction(actions.deletePaycafeStaticQrCode.success, (state, { meta }) => {
    const newState = { ...state };
    delete newState[meta.id];
    return newState;
  });

const onlineCashboxesByIdReducer = createReducer({})
  .handleAction(actions.getOnlineCashboxes.success, (state, { payload }) => {
    const onlineCashboxes = Array.isArray(payload) ? payload : [payload];
    return normalize(onlineCashboxes);
  })
  .handleAction(actions.toggleStatusOnlineCashbox.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], status: meta.status },
  }));

const tochkaBankReducer = createReducer({})
  .handleAction(actions.registerMerchantInSBPTochkaBank.success, (state, { payload, meta }) => ({
    ...state,
    ...meta,
    status: payload.status,
    redirectTo: payload.redirectTo,
    merchantId: payload.merchantId,
  }))
  .handleAction(actions.registerMerchantInAcquiringTochkaBank.success, (state, { payload, meta }) => ({
    ...state,
    ...meta,
    status: payload.status,
    redirectTo: payload.redirectTo,
    merchantId: payload.merchantId,
  }))
  .handleAction(actions.enrollmentSbpStatusInTochka.request, (state) => ({
    ...state,
    enrollmentSbpStatus: 'loading',
  }))
  .handleAction(actions.enrollmentSbpStatusInTochka.success, (state, { payload }) => ({
    ...state,
    enrollmentSbpStatus: payload.status,
  }))
  .handleAction(actions.enrollmentSbpStatusInTochka.failure, (state) => ({
    ...state,
    enrollmentSbpStatus: 'failed',
  }))
  .handleAction(actions.enrollmentAcqStatusInTochka.request, (state) => ({
    ...state,
    enrollmentAcqStatus: 'loading',
  }))
  .handleAction(actions.enrollmentAcqStatusInTochka.success, (state, { payload }) => ({
    ...state,
    enrollmentAcqStatus: payload.status,
    enrollmentAcqError: payload.error,
  }))
  .handleAction(actions.enrollmentAcqStatusInTochka.failure, (state) => ({
    ...state,
    enrollmentAcqStatus: 'failed',
  }));

export default combineReducers({
  outlets: outletsReducer,
  failedOutlets: failedOutletsReducer, // TODO: refactor?
  formsByCompany: formsByCompanyReducer,
  lastCreatedInvoiceByCompany: lastCreatedInvoiceByCompanyReducer,
  repeatInvoice: repeatInvoiceReducer,
  invoicesById: invoicesByIdReducer,
  invoicesTable: tableReducerFactory(actions.paycafeInvoicesTable),
  tmpUrl: tmpUrlReducer,
  outletHistory: outletHistoryReducer,
  staticQrCodesById: staticQrCodesByIdReducer,
  onlineCashboxesById: onlineCashboxesByIdReducer,
  tochkaBank: tochkaBankReducer,
  fetchStatus: asyncReducerFactory(actions.getPaycafeForms), // TODO: rename
  saveStatus: asyncReducerFactory(actions.savePaycafeForm), // TODO: rename
  deleteStatus: asyncReducerFactory(actions.deletePaycafeForm),
  tmpUrlFetchStatus: asyncReducerFactory(actions.getPaycafeTmpUrl),
  paycafeOutletsFetchStatus: asyncReducerFactory(actions.getPaycafeOutlets),
  createPaycafeOutletsStatus: asyncReducerFactory(actions.createPaycafeOutlets),
  updatePaycafeOutletStatus: asyncReducerFactory(actions.updatePaycafeOutlet),
  registerMerchantInSBPStatus: asyncReducerFactory(actions.registerMerchantInSBPTochkaBank),
  updatePaycafeOutletSourcesStatus: asyncReducerFactory(actions.updatePaycafeOutletSources),
  changeDefaultPaycafeOutletFetchStatus: asyncReducerFactory(actions.changeDefaultPaycafeOutlet),
  acceptPaycafeOutletStatus: asyncReducerFactory(actions.acceptPaycafeOutlet),
  getOutletHistoryStatus: asyncReducerFactory(actions.getPaycafeOutletHistory),
  createPaycafeInvoiceStatus: asyncReducerFactory(actions.createPaycafeInvoice),
  getInvoicesStatus: asyncReducerFactory(actions.getPaycafeInvoices),
  getInvoiceByIdStatus: asyncReducerFactory(actions.getPaycafeInvoiceById),
  disableInvoiceStatus: asyncReducerFactory(actions.disablePaycafeInvoice),
  getPaycafeStaticQrCodesStatus: asyncReducerFactory(actions.getPaycafeStaticQrCodes),
  createPaycafeStaticQrCodeStatus: asyncReducerFactory(actions.createPaycafeStaticQrCode),
  updatePaycafeStaticQrCodeStatus: asyncReducerFactory(actions.updatePaycafeStaticQrCode),
  deletePaycafeStaticQrCodeStatus: asyncReducerFactory(actions.deletePaycafeStaticQrCode),
  atolAuthStatus: asyncReducerFactory(actions.atolAuth),
  addOnlineCashboxStatus: asyncReducerFactory(actions.addOnlineCashbox),
  connectOnlineCashboxStatus: asyncReducerFactory(actions.connectOnlineCashbox),
  editOnlineCashboxStatus: asyncReducerFactory(actions.editOnlineCashbox),
  toggleStatusOnlineCashboxStatus: asyncReducerFactory(actions.toggleStatusOnlineCashbox),
  getOnlineCashboxesStatus: asyncReducerFactory(actions.getOnlineCashboxes),
  disconnectOnlineCashboxStatus: asyncReducerFactory(actions.disconnectOnlineCashbox),
  yoomoneyAuthStatus: asyncReducerFactory(actions.yoomoneyAuth),
});
