import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { getAwizaCategories } from '../actions/awiza';
import * as actions from '../actions/poster';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

// in poster can be created new category, so when we go to awiza app we need to update categories dictionary
const awizaCategoriesNeedForceUpdateReducer = createReducer(false)
  .handleAction(actions.getPosterProducts.success, () => true)
  .handleAction(getAwizaCategories.request, () => false);

const posterInfoReducer = createReducer([])
  .handleAction(actions.getPosterInfo.success, (state, { payload }) => payload);

const posterFullIinfoByIdReducer = createReducer({})
  .handleAction(actions.getPosterFullInfo.success, (state, { payload }) => ({
    ...state,
    [payload.id]: {
      ...payload,
      ...normalize(payload),
    },
  }));

const importPosterInProgressReducer = createReducer(false)
  .handleAction(actions.togglePosterLoader, (state, { payload }) => payload)
  .handleAction(actions.getPosterProducts.request, () => true)
  .handleAction(actions.getPosterProducts.failure, () => false);

export default combineReducers({
  products: posterInfoReducer,
  fullInfoById: posterFullIinfoByIdReducer,
  importPosterInProgress: importPosterInProgressReducer,
  awizaCategoriesNeedForceUpdate: awizaCategoriesNeedForceUpdateReducer,
  getProductsFetchStatus: asyncReducerFactory(actions.getPosterProducts),
  getSaveTokenFetchStatus: asyncReducerFactory(actions.savePosterToken),
  getFullInfoFetchStatus: asyncReducerFactory(actions.getPosterFullInfo),
  savePosterSettingsFetchStatus: asyncReducerFactory(actions.savePosterSettings),
  savePosterProductIdFetchStatus: asyncReducerFactory(actions.savePosterProductId),
  connectPosterFetchStatus: asyncReducerFactory(actions.connectPoster),
  productsFetchStatus: asyncReducerFactory(actions.getPosterInfo),
});
