import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useEffect } from 'react';
// import { getPositionsAsync } from '../../../actions/dictionaries';

const selector = (state) => state.dictionaries.positions;

function usePositionOptions() {
  // const dispatch = useDispatch();
  const positions = useSelector(selector);

  // useEffect(() => dispatch(getPositionsAsync()), []);

  return useMemo(() => positions.map(({ id, name }) => ({ value: id, label: name })), [positions]);
}

export default usePositionOptions;
