import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { stringify } from 'qs';
import { setCompanyId } from '../../actions/app';
import { getAwizaMarketplacesAsync } from '../../actions/awiza';
import { getCompaniesAsync, getCompanyInfoAsync, getOutletsAsync } from '../../actions/companies';
import { getPaycafeOutletsAsync } from '../../actions/paycafe';
import { getUserAsync } from '../../actions/users';
import { activeCompaniesSelector } from '../../selectors/companies';
import { isAuthVersion } from '../../utils/isAuthVersion';
import {
  useQueryParams,
  useUser,
  PageContext,
  useUpdateNotifications,
  useMetaViewport,
} from '../common/hooks';
import useHeadTitle from '../common/hooks/useHeadTitle';
import InformationModal from './InformationModal';

const LKLazyLoadedContent = React.lazy(() => import('./LKLazyLoadedContent'));

function LK() {
  const dispatch = useDispatch();
  const user = useUser();
  const activeCompanies = useSelector(activeCompaniesSelector);

  useMetaViewport();
  useUpdateNotifications();
  // usePosterLoadingCompletionNotification();

  useEffect(() => {
    const userLocalstoredOptions = JSON.parse(localStorage.getItem(`options:${user.id}`)) ?? {};
    const localstoredCompanyId = userLocalstoredOptions['app:companyId'];
    if (localstoredCompanyId) {
      dispatch(setCompanyId(localstoredCompanyId));
      dispatch(getCompanyInfoAsync(localstoredCompanyId));
    }
    dispatch(getCompaniesAsync({ initial: true }));
    // для определения статусов приложений необходима информация по точкам
    dispatch(getOutletsAsync());
    dispatch(getPaycafeOutletsAsync());
    dispatch(getAwizaMarketplacesAsync());
  }, []);

  useEffect(() => {
    if (activeCompanies.length === 1 && activeCompanies[0].status !== 'accepted') {
      dispatch(getCompanyInfoAsync(activeCompanies[0].id));
    }
  }, [activeCompanies]);

  return (
    <LKLazyLoadedContent />
  );
}

const MOBILE_WIDTH = 768;

function isMobile() {
  return window.screen.width < MOBILE_WIDTH || window.innerWidth < MOBILE_WIDTH;
}

function GetUserWrap(props) {
  const { location } = props;
  const [title, setTitle] = useState('Dashboard');
  const context = useMemo(() => ({ setTitle }), []);
  useHeadTitle(title);
  const dispatch = useDispatch();
  const [params] = useQueryParams();
  const user = useUser();
  const { loading: fetchStatusLoading, error: fetchStatusError } = useSelector((state) => state.users.currentUserFetchStatus);
  const authenticated = useSelector((state) => state.auth.login.authenticated);
  const [modalActive, setModalActive] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const mobileViewHintIsClosed = localStorage.getItem('mobile-view-hint-is-closed');

  useEffect(() => {
    if (!user && !fetchStatusLoading) {
      dispatch(getUserAsync());
    }
  }, []);

  if (!authenticated) {
    if (isAuthVersion() || process.env.NODE_ENV === 'development') {
      const { token, signature = '', ...rest } = params;

      if (token) {
        const origin = encodeURIComponent(`${location.pathname}?${stringify(rest)}`);

        return (<Redirect to={`/trustedauth?origin=${origin}&${stringify({ token, signature })}`} />);
      }

      return (<Redirect to={`/login?origin=${encodeURIComponent(`${location.pathname}${location.search}`)}`} />);
    }
    return null;
  }

  if (fetchStatusError?.code === 403 || fetchStatusError?.code === 301) {
    return (
      <InformationModal
        active={modalActive}
        setActive={setModalActive}
        type={fetchStatusError.code === 301 ? 'wrong-origin' : 'access-denied'}
      />
    );
  }

  if (isMobile() && !mobileViewHintIsClosed && !hideModal && !fetchStatusLoading) {
    return <InformationModal active={modalActive} setActive={setModalActive} setHideModal={setHideModal} type="mobile-view" />;
  }

  if (!user || isAuthVersion()) return null;

  return (
    <PageContext.Provider value={context}>
      <LK {...props} />
    </PageContext.Provider>
  );
}

export default GetUserWrap;
