import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useQueryParams from '../hooks/useQueryParams';

export const ThunderBotContext = React.createContext();

function loadThunderbotChat() {
  if ('ThunderBot' in window) {
    return Promise.resolve();
  }

  const script = window.document.head.querySelector('script[data-thunderbot]');

  if (!script) {
    return Promise.reject(new Error('ThunderBot script element not found (script[data-thunderbot])'));
  }

  return new Promise((resolve) => {
    script.addEventListener('load', resolve);
  });
}

function initThunderbotChat() {
  const thunderBot = new window.ThunderBot();

  const isMobile = window.innerWidth < 600;

  return thunderBot.init({ withButton: false, position: { bottom: isMobile ? 20 : 40, right: isMobile ? 20 : 40 } })
    .then(() => thunderBot);
}

function ThunderBotProvider({ children }) {
  const [value, setValue] = useState();
  const [params, updateParams] = useQueryParams();

  useEffect(() => {
    loadThunderbotChat()
      .then(initThunderbotChat)
      .then((tb) => setValue(tb))
      .catch(console.warn);
  }, []);

  useEffect(() => {
    if ('chat' in params && value) {
      value.openWidget();
    }
  }, [params, value]);

  return (
    <ThunderBotContext.Provider value={value}>
      {children}
    </ThunderBotContext.Provider>
  );
}

ThunderBotProvider.propTypes = {
  children: PropTypes.node,
};

export default ThunderBotProvider;
