import React from 'react';
import { createAction, createAsyncAction } from 'typesafe-actions';
import i18n from '@i18n';
import { ApiClient } from '../../utils/api-client';
import { getNewUserNotificationsNotification } from '../../utils/notification-utils';
import { showNotification } from '../app';
import { readUserNotificationAsync } from '../notifications';

export * from './promotions';

const t = (key) => i18n.t(key, { ns: 'awiza' });

export const getAwizaCategories = createAsyncAction(
  'GET_AWIZA_CATEGORIES_REQUEST',
  'GET_AWIZA_CATEGORIES_SUCCESS',
  'GET_AWIZA_CATEGORIES_FAIL',
)();

export function getAwizaCategoriesAsync(force = false) {
  return (dispatch, getState) => {
    const { app: { currentCompanyId: companyId } } = getState();

    // awizaCategoriesNeedForceUpdate - in poster can be created new category, so when we go to awiza app we need to update categories dictionary
    if (getState().awiza.awizaCategoriesFetchStatus[companyId]?.loading && !force) {
      return;
    }
    dispatch(getAwizaCategories.request(null, { companyId }));

    ApiClient.awiza.getCategories({ companyId })
      .then((data) => dispatch(getAwizaCategories.success(data, { companyId })))
      .catch((error) => dispatch(getAwizaCategories.failure(error, { companyId })));
  };
}

export const getAwizaCategoryFields = createAsyncAction(
  'GET_AWIZA_CATEGORY_FIELDS_REQUEST',
  'GET_AWIZA_CATEGORY_FIELDS_SUCCESS',
  'GET_AWIZA_CATEGORY_FIELDS_FAIL',
)();

export function getAwizaCategoryFieldsAsync(id, force = false) {
  return (dispatch, getState) => {
    if (!id) {
      return;
    }
    if (getState().awiza.fieldsByCategoryId[id] && !force) {
      return;
    }

    dispatch(getAwizaCategoryFields.request());

    ApiClient.awiza.getCategoryFields(id)
      .then((data) => dispatch(getAwizaCategoryFields.success(data, { id })))
      // .catch((error) => dispatch(getAwizaCategoryFields.failure(mock, { id }))) //TODO: use next line
      .catch((error) => dispatch(getAwizaCategoryFields.failure(error)));
  };
}

export const addAwizaCategory = createAsyncAction(
  'ADD_AWIZA_CATEGORY_REQUEST',
  'ADD_AWIZA_CATEGORY_SUCCESS',
  'ADD_AWIZA_CATEGORY_FAIL',
)();

export function addAwizaCategoryAsync(category) {
  return (dispatch) => {
    dispatch(addAwizaCategory.request());

    return ApiClient.awiza.addCategory(category)
      .then((data) => {
        const notification = {
          type: 'success',
          message: t('category-added-successfully'),
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        return dispatch(addAwizaCategory.success(data, category));
      })
      .catch((error) => dispatch(addAwizaCategory.failure(error)));
  };
}

export const editAwizaCategory = createAsyncAction(
  'EDIT_AWIZA_CATEGORY_REQUEST',
  'EDIT_AWIZA_CATEGORY_SUCCESS',
  'EDIT_AWIZA_CATEGORY_FAIL',
)();

export function editAwizaCategoryAsync(category) {
  return (dispatch) => {
    dispatch(editAwizaCategory.request());

    return ApiClient.awiza.editCategory(category)
      .then((data) => dispatch(editAwizaCategory.success(data)))
      .catch((error) => dispatch(editAwizaCategory.failure(error)));
  };
}

export const deleteCategory = createAsyncAction(
  'DELETE_AWIZA_CATEGORY_REQUEST',
  'DELETE_AWIZA_CATEGORY_SUCCESS',
  'DELETE_AWIZA_CATEGORY_FAIL',
)();

export function deleteCategoryAsync(data) {
  return (dispatch) => {
    dispatch(deleteCategory.request());

    ApiClient.awiza.deleteCategory(data)
      .then((data) => dispatch(deleteCategory.success(data)))
      .catch((error) => dispatch(deleteCategory.failure(error)));
  };
}

export const editAwizaCategoryPositions = createAsyncAction(
  'EDIT_AWIZA_CATEGORY_POSITIONS_REQUEST',
  'EDIT_AWIZA_CATEGORY_POSITIONS_SUCCESS',
  'EDIT_AWIZA_CATEGORY_POSITIONS_FAIL',
)();

export function editCategoryPositionsAsync(data, currentCompanyId) {
  return (dispatch) => {
    dispatch(editAwizaCategoryPositions.request());

    ApiClient.awiza.editCategoryPositions(data, currentCompanyId)
      .then((data) => dispatch(editAwizaCategoryPositions.success(data)))
      .catch((error) => dispatch(editAwizaCategoryPositions.failure(error)));
  };
}

export const uploadAwizaImages = createAsyncAction(
  'UPLOAD_AWIZA_IMAGES_REQUEST',
  'UPLOAD_AWIZA_IMAGES_SUCCESS',
  'UPLOAD_AWIZA_IMAGES_FAIL',
)();

export function uploadAwizaImagesAsync(companyId, formData) {
  return (dispatch) => {
    dispatch(uploadAwizaImages.request());

    ApiClient.awiza.uploadImages(formData)
      .then((response) => dispatch(uploadAwizaImages.success(response)))
      .catch((error) => dispatch(uploadAwizaImages.failure(error)));
  };
}

export const uploadAwizaTemplate = createAsyncAction(
  'UPLOAD_AWIZA_TEMPLATE_REQUEST',
  'UPLOAD_AWIZA_TEMPLATE_SUCCESS',
  'UPLOAD_AWIZA_TEMPLATE_FAIL',
)();

export function uploadAwizaTemplateAsync(formData) {
  return (dispatch, getState) => {
    dispatch(uploadAwizaTemplate.request());
    const { currentCompanyId } = getState().app;

    ApiClient.awiza.uploadTemplate(formData, currentCompanyId)
      .then((response) => dispatch(uploadAwizaTemplate.success(response)))
      .catch((error) => dispatch(uploadAwizaTemplate.failure(error)));
  };
}

export const getAwizaWarehouses = createAsyncAction(
  'GET_AWIZA_WAREHOUSES_REQUEST',
  'GET_AWIZA_WAREHOUSES_SUCCESS',
  'GET_AWIZA_WAREHOUSES_FAIL',
)();

export function getAwizaWarehousesAsync(force = false, params) {
  return (dispatch, getState) => {
    const { currentCompanyId } = getState().app;
    if (!currentCompanyId) return;
    if (getState().awiza.warehousesByCompany[currentCompanyId] && !force) {
      return;
    }
    dispatch(getAwizaWarehouses.request());

    ApiClient.awiza.getWarehouses({ ...params, companyId: currentCompanyId })
      .then((data) => dispatch(getAwizaWarehouses.success(data, { companyId: currentCompanyId })))
      .catch((error) => dispatch(getAwizaWarehouses.failure(error)));
  };
}

export const getSimilarOrRelatedProducts = createAsyncAction(
  'GET_UP_SALE_OR_CROSS_SALE_PRODUCTS_REQUEST',
  'GET_UP_SALE_OR_CROSS_SALE_PRODUCTS_SUCCESS',
  'GET_UP_SALE_OR_CROSS_SALE_PRODUCTS_FAIL',
)();

export const getExistingSimilarProducts = createAsyncAction(
  'GET_EXISTING_UP_SALE_PRODUCTS_REQUEST',
  'GET_EXISTING_UP_SALE_PRODUCTS_SUCCESS',
  'GET_EXISTING_UP_SALE_PRODUCTS_FAIL',
)();

export const getExistingRelatedProducts = createAsyncAction(
  'GET_EXISTING_CROSS_SALE_PRODUCTS_REQUEST',
  'GET_EXISTING_CROSS_SALE_PRODUCTS_SUCCESS',
  'GET_EXISTING_CROSS_SALE_PRODUCTS_FAIL',
)();

export const getAwizaProducts = createAsyncAction(
  'GET_AWIZA_PRODUCTS_REQUEST',
  'GET_AWIZA_PRODUCTS_SUCCESS',
  'GET_AWIZA_PRODUCTS_FAIL',
)();

const getExtraActionForGetProductsAsyncAction = ({ isSimilarProductsFetch, isRelatedProductsFetch, productIds }) => {
  let extraAction;
  if (isSimilarProductsFetch) {
    extraAction = productIds ? getExistingSimilarProducts : getSimilarOrRelatedProducts;
  } else if (isRelatedProductsFetch) {
    extraAction = productIds ? getExistingRelatedProducts : getSimilarOrRelatedProducts;
  }
  return extraAction;
};

export function getAwizaProductsAsync({ isSimilarProductsFetch, isRelatedProductsFetch, ...params }) {
  return (dispatch, getState) => {
    dispatch(getAwizaProducts.request());
    const extraAction = getExtraActionForGetProductsAsyncAction({ isSimilarProductsFetch, isRelatedProductsFetch, ...params });
    if (extraAction) dispatch(extraAction.request());

    const { currentCompanyId } = getState().app;

    ApiClient.awiza.products({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getAwizaProducts.success(data, params));
        if (extraAction) dispatch(extraAction.success());
      })
      .catch((error) => {
        dispatch(getAwizaProducts.failure(error));
        if (extraAction) dispatch(extraAction.failure());
      });
  };
}

export const clearAwizaProductsTable = createAction('CLEAR_AWIZA_PRODUCTS_TABLE')();

export const getAwizaProductsToPublish = createAsyncAction(
  'GET_AWIZA_PRODUCTS_TO_PUBLISH_REQUEST',
  'GET_AWIZA_PRODUCTS_TO_PUBLISH_SUCCESS',
  'GET_AWIZA_PRODUCTS_TO_PUBLISH_FAIL',
)();

export function getAwizaProductsToPublishAsync(params) {
  return (dispatch, getState) => {
    dispatch(getAwizaProductsToPublish.request());
    const { currentCompanyId } = getState().app;
    ApiClient.awiza.productsToPublish({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getAwizaProductsToPublish.success(data));
      })
      .catch((error) => dispatch(getAwizaProductsToPublish.failure(error)));
  };
}

export const getPublishedProducts = createAsyncAction(
  'GET_PUBLISHED_PRODUCTS_REQUEST',
  'GET_PUBLISHED_PRODUCTS_SUCCESS',
  'GET_PUBLISHED_PRODUCTS_FAIL',
)();

export function getPublishedProductsAsync(params) {
  return (dispatch, getState) => {
    dispatch(getPublishedProducts.request());
    const { currentCompanyId } = getState().app;
    const meta = { platform: params.platform, warehouse: params.warehouse };
    ApiClient.awiza.publishedProducts({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getPublishedProducts.success(data, meta));
      })
      .catch((error) => dispatch(getPublishedProducts.failure(error, meta)));
  };
}

export const getPublishedWarehouses = createAsyncAction(
  'GET_PUBLISHED_WAREHOUSES_REQUEST',
  'GET_PUBLISHED_WAREHOUSES_SUCCESS',
  'GET_PUBLISHED_WAREHOUSES_FAIL',
)();

export function getPublishedWarehousesAsync(params) {
  return (dispatch, getState) => {
    dispatch(getPublishedWarehouses.request());
    const { currentCompanyId } = getState().app;
    ApiClient.awiza.publishedWarehouses({ companyId: currentCompanyId, ...params })
      .then((data) => {
        dispatch(getPublishedWarehouses.success(data, { platform: params.platform }));
      })
      .catch((error) => dispatch(getPublishedWarehouses.failure(error)));
  };
}

export const addAwizaProduct = createAsyncAction(
  'ADD_AWIZA_PRODUCT_REQUEST',
  'ADD_AWIZA_PRODUCT_SUCCESS',
  'ADD_AWIZA_PRODUCT_FAIL',
)();

export function addAwizaProductAsync(product) {
  return (dispatch) => {
    dispatch(addAwizaProduct.request());

    ApiClient.awiza.addProduct(product)
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Товар успешно добавлен',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(addAwizaProduct.success(data));
      })
      .catch((error) => dispatch(addAwizaProduct.failure(error)));
  };
}

export const duplicateAwizaProduct = createAsyncAction(
  'DUPLICATE_AWIZA_PRODUCT_REQUEST',
  'DUPLICATE_AWIZA_PRODUCT_SUCCESS',
  'DUPLICATE_AWIZA_PRODUCT_FAIL',
)();

export function duplicateAwizaProductAsync(product, withImages) {
  return (dispatch) => {
    dispatch(duplicateAwizaProduct.request());

    ApiClient.awiza.duplicateAwizaProduct(product, withImages)
      .then((data) => {
        const notification = {
          type: 'success',
          message: t('product-copied'),
          timeout: 5000,
        };
        dispatch(showNotification(notification));
        dispatch(duplicateAwizaProduct.success(data));
      })
      .catch((error) => dispatch(duplicateAwizaProduct.failure(error)));
  };
}

export const deleteAwizaProducts = createAsyncAction(
  'DELETE_AWIZA_PRODUCTS_REQUEST',
  'DELETE_AWIZA_PRODUCTS_SUCCESS',
  'DELETE_AWIZA_PRODUCTS_FAIL',
)();

export function deleteAwizaProductsAsync(data) {
  return (dispatch) => {
    dispatch(deleteAwizaProducts.request());

    ApiClient.awiza.deleteProducts(data)
      .then((data) => dispatch(deleteAwizaProducts.success(data)))
      .catch((error) => dispatch(deleteAwizaProducts.failure(error)));
  };
}

export const getAwizaProductsExtra = createAsyncAction(
  'GET_AWIZA_PRODUCT_EXTRA_REQUEST',
  'GET_AWIZA_PRODUCT_EXTRA_SUCCESS',
  'GET_AWIZA_PRODUCT_EXTRA_FAIL',
)();

export function getAwizaProductsExtraAsync(id, params) {
  return (dispatch) => {
    dispatch(getAwizaProductsExtra.request());

    ApiClient.awiza.getProductsExtra(id, params)
      .then((data) => dispatch(getAwizaProductsExtra.success(data, { id })))
      .catch((error) => dispatch(getAwizaProductsExtra.failure(error)));
  };
}

export const getAwizaProductDetails = createAsyncAction(
  'GET_AWIZA_PRODUCT_DETAILS_REQUEST',
  'GET_AWIZA_PRODUCT_DETAILS_SUCCESS',
  'GET_AWIZA_PRODUCT_DETAILS_FAIL',
)();

export function getAwizaProductDetailsAsync(id, params) {
  return (dispatch) => {
    dispatch(getAwizaProductDetails.request());

    ApiClient.awiza.productDetails(id, params)
      .then((data) => dispatch(getAwizaProductDetails.success(data, { id })))
      .catch((error) => dispatch(getAwizaProductDetails.failure(error)));
  };
}

export const getAwizaProductStock = createAsyncAction(
  'GET_AWIZA_PRODUCT_STOCK_REQUEST',
  'GET_AWIZA_PRODUCT_STOCK_SUCCESS',
  'GET_AWIZA_PRODUCT_STOCK_FAIL',
)();

export function getAwizaProductStockAsync(id, params) {
  return (dispatch) => {
    dispatch(getAwizaProductStock.request());

    ApiClient.awiza.productStock(id, params)
      .then((data) => dispatch(getAwizaProductStock.success(data)))
      .catch((error) => dispatch(getAwizaProductStock.failure(error)));
  };
}

export const createBoxretailWarehouse = createAsyncAction(
  'CREATE_BOXRETAIL_WAREHOUSE_REQUEST',
  'CREATE_BOXRETAIL_WAREHOUSE_SUCCESS',
  'CREATE_BOXRETAIL_WAREHOUSE_FAIL',
)();

export function createBoxretailWarehouseAsync(data) {
  return (dispatch) => {
    dispatch(createBoxretailWarehouse.request());

    return ApiClient.awiza.newBoxretailWarehouse(data)
      .then((response) => dispatch(createBoxretailWarehouse.success(response, data)))
      .catch((error) => dispatch(createBoxretailWarehouse.failure(error)));
  };
}

export const createWarehouse = createAsyncAction(
  'CREATE_WAREHOUSE_REQUEST',
  'CREATE_WAREHOUSE_SUCCESS',
  'CREATE_WAREHOUSE_FAIL',
)();

export function createWarehouseAsync(data) {
  return (dispatch) => {
    dispatch(createWarehouse.request());

    return ApiClient.awiza.newWarehouse(data)
      .then((response) => dispatch(createWarehouse.success(response, data)))
      .catch((error) => {
        const notification = {
          type: 'error',
          message: error.code === 574 ? 'Склад с таким названием уже существует' : 'Ошибка при выполнении запроса. Попробуйте повторить запрос позднее или обратитесь к менеджеру.',
          timeout: 5000,
        };
        dispatch(showNotification(notification));

        return dispatch(createWarehouse.failure(error));
      });
  };
}

export const editWarehouse = createAsyncAction(
  'EDIT_WAREHOUSE_REQUEST',
  'EDIT_WAREHOUSE_SUCCESS',
  'EDIT_WAREHOUSE_FAIL',
)();

export function editWarehouseAsync(data, isRecovery) {
  return (dispatch) => {
    dispatch(editWarehouse.request());

    ApiClient.awiza.editWarehouse(data)
      .then((data) => {
        const notification = {
          type: 'success',
          message: `Склад ${isRecovery ? 'восстановлен' : 'отредактирован'}`,
          timeout: 5000,
        };

        dispatch(editWarehouse.success(data));
        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(editWarehouse.failure(error)));
  };
}

export const deleteWarehouse = createAsyncAction(
  'DELETE_WAREHOUSE_REQUEST',
  'DELETE_WAREHOUSE_SUCCESS',
  'DELETE_WAREHOUSE_FAIL',
)();

export function deleteWarehouseAsync(data) {
  return (dispatch) => {
    dispatch(deleteWarehouse.request());

    const { companyId, warehouseId } = data;

    ApiClient.awiza.deleteWarehouse(data)
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Склад архивирован',
          timeout: 5000,
        };

        dispatch(deleteWarehouse.success(data, { companyId, warehouseId }));
        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(deleteWarehouse.failure(error)));
  };
}

export const connectAwiza = createAsyncAction(
  'CONNECT_AWIZA_REQUEST',
  'CONNECT_AWIZA_SUCCESS',
  'CONNECT_AWIZA_FAIL',
)();

export function connectAwizaAsync(companyId) {
  return (dispatch) => {
    dispatch(connectAwiza.request());

    ApiClient.awiza.connect({ companyId })
      .then((data) => {
        dispatch(connectAwiza.success(data, { id: companyId }));
      })
      .catch((error) => dispatch(connectAwiza.failure(error)));
  };
}

export const updateStockInfo = createAsyncAction(
  'UPDATE_STOCK_INFO_REQUEST',
  'UPDATE_STOCK_INFO_SUCCESS',
  'UPDATE_STOCK_INFO_FAIL',
)();

export function updateStockInfoAsync(data, id) {
  return (dispatch) => {
    dispatch(updateStockInfo.request());

    ApiClient.awiza.updateStockInfo(data, id)
      .then((data) => {
        dispatch(updateStockInfo.success(data));
      })
      .catch((error) => dispatch(updateStockInfo.failure(error)));
  };
}

export const publishProducts = createAsyncAction(
  'PUBLISH_PRODUCTS_REQUEST',
  'PUBLISH_PRODUCTS_SUCCESS',
  'PUBLISH_PRODUCTS_FAIL',
)();

export function publishProductsAsync(data) {
  return (dispatch) => {
    dispatch(publishProducts.request());
    ApiClient.awiza.publishProducts(data)
      .then((data) => {
        dispatch(publishProducts.success(data));
      })
      .catch((error) => dispatch(publishProducts.failure(error)));
  };
}

export const unpublishProducts = createAsyncAction(
  'UNPUBLISH_PRODUCTS_REQUEST',
  'UNPUBLISH_PRODUCTS_SUCCESS',
  'UNPUBLISH_PRODUCTS_FAIL',
)();

export function unpublishProductsAsync(data) {
  return (dispatch) => {
    dispatch(unpublishProducts.request());
    ApiClient.awiza.unpublishProducts(data)
      .then((data) => {
        dispatch(unpublishProducts.success(data));
      })
      .catch((error) => dispatch(unpublishProducts.failure(error)));
  };
}

export const editProduct = createAsyncAction(
  'EDIT_PRODUCT_REQUEST',
  'EDIT_PRODUCT_SUCCESS',
  'EDIT_PRODUCT_FAIL',
)();

export function editProductAsync(data) {
  return (dispatch) => {
    dispatch(editProduct.request());
    ApiClient.awiza.editProduct(data)
      .then((data) => {
        dispatch(editProduct.success(data));
      })
      .catch((error) => dispatch(editProduct.failure(error)));
  };
}

export const updateProductProperties = createAsyncAction(
  'UPDATE_PRODUCT_PROPERTIES_REQUEST',
  'UPDATE_PRODUCT_PROPERTIES_SUCCESS',
  'UPDATE_PRODUCT_PROPERTIES_FAIL',
)();

export function updateProductPropertiesAsync(data) {
  return (dispatch) => {
    dispatch(updateProductProperties.request());
    ApiClient.awiza.updateProductProperties(data)
      .then((data) => {
        dispatch(updateProductProperties.success(data));
      })
      .catch((error) => dispatch(updateProductProperties.failure(error)));
  };
}

export const updateProductsStocks = createAsyncAction(
  'AWIZA_UPDATE_PRODUCTS_STOCKS_REQUEST',
  'AWIZA_UPDATE_PRODUCTS_STOCKS_SUCCESS',
  'AWIZA_UPDATE_PRODUCTS_STOCKS_FAIL',
)();

export function updateProductsStocksAsync(dataToSend, productById) {
  return (dispatch) => {
    dispatch(updateProductsStocks.request());

    ApiClient.awiza.updateProductsStocks(dataToSend)
      .then((response) => {
        dispatch(showNotification({
          type: 'success',
          message: t('multiple-update-stocks-notification'),
          timeout: 5000,
        }));
        dispatch(updateProductsStocks.success(response, { dataToSend, productById }));
      })
      .catch((error) => dispatch(updateProductsStocks.failure(error)));
  };
}

export const getProductsStocks = createAsyncAction(
  'AWIZA_GET_PRODUCTS_STOCKS_REQUEST',
  'AWIZA_GET_PRODUCTS_STOCKS_SUCCESS',
  'AWIZA_GET_PRODUCTS_STOCKS_FAIL',
)();

export function getProductsStocksAsync(data) {
  return (dispatch) => {
    dispatch(getProductsStocks.request());

    ApiClient.awiza.getProductsStocks(data)
      .then((response) => dispatch(getProductsStocks.success(response, data)))
      .catch((error) => dispatch(getProductsStocks.failure(error)));
  };
}

export const getVkGroups = createAsyncAction(
  'GET_VK_GROUPS_REQUEST',
  'GET_VK_GROUPS_SUCCESS',
  'GET_VK_GROUPS_FAIL',
)();

export function getVkGroupsAsync(model) {
  return (dispatch) => {
    dispatch(getVkGroups.request());
    ApiClient.awiza.getVkGroups(model)
      .then((data) => dispatch(getVkGroups.success(data, model)))
      .catch((error) => dispatch(getVkGroups.failure(error)));
  };
}

export const getVkPublishedProducts = createAsyncAction(
  'GET_VK_PUBLISHED_PRODUCTS_REQUEST',
  'GET_VK_PUBLISHED_PRODUCTS_SUCCESS',
  'GET_VK_PUBLISHED_PRODUCTS_FAIL',
)();

export function getVkPublishedProductsAsync() {
  return (dispatch, getState) => {
    dispatch(getVkPublishedProducts.request());
    const params = { companyId: getState().app.currentCompanyId };
    ApiClient.awiza.getVkPublishedProducts(params)
      .then((data) => dispatch(getVkPublishedProducts.success(data, params)))
      .catch((error) => dispatch(getVkPublishedProducts.failure(error)));
  };
}

export const saveVKSettings = createAsyncAction(
  'SAVE_VK_SETTINGS_REQUEST',
  'SAVE_VK_SETTINGS_SUCCESS',
  'SAVE_VK_SETTINGS_FAIL',
)();

export function saveVKSettingsAsync(data) {
  return (dispatch) => {
    dispatch(saveVKSettings.request());
    ApiClient.awiza.saveVKSettings(data)
      .then((data) => dispatch(saveVKSettings.success(data)))
      .catch((error) => dispatch(saveVKSettings.failure(error)));
  };
}

export const changeVKSettingStatus = createAsyncAction(
  'UPDATE_VK_SETTING_STATUS_REQUEST',
  'UPDATE_VK_SETTING_STATUS_SUCCESS',
  'UPDATE_VK_SETTING_STATUS_FAIL',
)();

export function changeVKSettingStatusAsync({ status, ...model }, newStatus, noNotification) {
  return (dispatch, getState) => {
    const meta = { ...model, status, marketplaceId: 'vk', newStatus };

    dispatch(changeVKSettingStatus.request(null, { ...meta, status: 'loading' }));

    ApiClient.awiza.changeVKSettingStatus({ ...model, status: newStatus })
      .then((data) => {
        dispatch(changeVKSettingStatus.success(data, meta));

        if (noNotification) return;

        const notification = {
          type: 'success',
          message: `Сообщество ${newStatus === 'completed' ? 'восстановлено' : 'приостановлено'}`,
          icon: true,
          timeout: 5000,
          tag: 'awiza',
        };
        dispatch(showNotification(getNewUserNotificationsNotification([notification], dispatch, getState(), readUserNotificationAsync)));
      })
      .catch((error) => dispatch(changeVKSettingStatus.failure(error, { ...meta, newStatus: status })));
  };
}

export const getVkCategoriesMatch = createAsyncAction(
  'GET_VK_CATEGORIES_MATCH_REQUEST',
  'GET_VK_CATEGORIES_MATCH_SUCCESS',
  'GET_VK_CATEGORIES_MATCH_FAIL',
)();

export function getVkCategoriesMatchAsync(idsToMatch) {
  return (dispatch, getState) => {
    dispatch(getVkCategoriesMatch.request());
    const params = { companyId: getState().app.currentCompanyId, categoryIds: idsToMatch };
    ApiClient.awiza.getVkCategoriesMatch(params)
      .then((data) => dispatch(getVkCategoriesMatch.success(data, params)))
      .catch((error) => dispatch(getVkCategoriesMatch.failure(error)));
  };
}

export const saveVkCategoriesMatch = createAsyncAction(
  'SAVE_VK_CATEGORIES_MATCH_REQUEST',
  'SAVE_VK_CATEGORIES_MATCH_SUCCESS',
  'SAVE_VK_CATEGORIES_MATCH_FAIL',
)();

export function saveVkCategoriesMatchAsync(data) {
  return (dispatch) => {
    dispatch(saveVkCategoriesMatch.request());
    ApiClient.awiza.saveVkCategoriesMatch(data)
      .then((data) => dispatch(saveVkCategoriesMatch.success(data)))
      .catch((error) => dispatch(saveVkCategoriesMatch.failure(error)));
  };
}

export const saveGoodsSettings = createAsyncAction(
  'SAVE_GOODS_SETTINGS_REQUEST',
  'SAVE_GOODS_SETTINGS_SUCCESS',
  'SAVE_GOODS_SETTINGS_FAIL',
)();

export function saveGoodsSettingsAsync(data) {
  return (dispatch) => {
    dispatch(saveGoodsSettings.request());
    ApiClient.awiza.saveGoodsSettings(data)
      .then((response) => {
        dispatch(saveGoodsSettings.success(response, data));
      })
      .catch((error) => {
        if (error.code === 399) {
          const notification = {
            type: 'error',
            title: 'Ошибка подключения',
            message: <span>Подключение продавца невозможно. <a href="mailto:support@teko.io" target="_blank" rel="noreferrer noopener">Напишите нам </a>для решения проблемы</span>,
            timeout: 5000,
          };

          dispatch(showNotification(notification));
        }

        dispatch(saveGoodsSettings.failure(error));
      });
  };
}

export const getSbermegamarketSettings = createAsyncAction(
  'GET_SBERMEGAMARKET_SETTINGS_REQUEST',
  'GET_SBERMEGAMARKET_SETTINGS_SUCCESS',
  'GET_SBERMEGAMARKET_SETTINGS_FAIL',
)();

export function getSbermegamarketSettingsAsync() {
  return (dispatch, getState) => {
    const { currentCompanyId } = getState().app;
    if (getState().awiza.marketplacesByCompanyId[currentCompanyId].sbermegamarket?.settings[0]?.feedUrl) {
      return;
    }
    dispatch(getSbermegamarketSettings.request());
    ApiClient.awiza.getSbermegamarketSettings({ companyId: currentCompanyId })
      .then((data) => {
        dispatch(getSbermegamarketSettings.success(data));
      })
      .catch((error) => dispatch(getSbermegamarketSettings.failure(error)));
  };
}

export const getAwizaMarketplaces = createAsyncAction(
  'AWIZA_GET_MARKETPLACES_REQUEST',
  'AWIZA_GET_MARKETPLACES_SUCCESS',
  'AWIZA_GET_MARKETPLACES_FAIL',
)();

export function getAwizaMarketplacesAsync(params) {
  return (dispatch) => {
    // if (getState().awiza.marketplacesFetchStatus.state !== 'initial' && !force) {
    //   return;
    // }

    dispatch(getAwizaMarketplaces.request());

    ApiClient.awiza.getMarketplaces(params)
      .then((data) => dispatch(getAwizaMarketplaces.success(data)))
      .catch((error) => dispatch(getAwizaMarketplaces.failure(error)));
  };
}

export const getProductsCount = createAsyncAction(
  'GET_PRODUCTS_COUNT_REQUEST',
  'GET_PRODUCTS_COUNT_SUCCESS',
  'GET_PRODUCTS_COUNT_FAIL',
)();

export function getProductsCountAsync(params) {
  return (dispatch) => {
    dispatch(getProductsCount.request(null, params));

    ApiClient.awiza.getProductsCount(params)
      .then((response) => dispatch(getProductsCount.success(response, params)))
      .catch((error) => dispatch(getProductsCount.failure(error, params)));
  };
}

export const getAvailableWarehouses = createAsyncAction(
  'GET_AVAILABLE_WAREHOUSES_REQUEST',
  'GET_AVAILABLE_WAREHOUSES_SUCCESS',
  'GET_AVAILABLE_WAREHOUSES_FAIL',
)();

export function getAvailableWarehousesAsync(params) {
  return (dispatch, getState) => {
    if (getState().awiza.getAvailableWarehousesFetchStatus.loading) return;

    dispatch(getAvailableWarehouses.request(null, params));

    ApiClient.awiza.getAvailableWarehouses(params)
      .then((response) => dispatch(getAvailableWarehouses.success(response, params)))
      .catch((error) => dispatch(getAvailableWarehouses.failure(error, params)));
  };
}

export const checkOzonToken = createAsyncAction(
  'CHECK_OZON_TOKEN_REQUEST',
  'CHECK_OZON_TOKEN_SUCCESS',
  'CHECK_OZON_TOKEN_FAIL',
)();

export function checkOzonTokenAsync(params) {
  return (dispatch) => {
    dispatch(checkOzonToken.request());

    ApiClient.awiza.checkOzonToken(params)
      .then((res) => dispatch(checkOzonToken.success(res)))
      .catch((error) => dispatch(checkOzonToken.failure(error)));
  };
}

export const saveOzonSettings = createAsyncAction(
  'SAVE_OZON_SETTINGS_REQUEST',
  'SAVE_OZON_SETTINGS_SUCCESS',
  'SAVE_OZON_SETTINGS_FAIL',
)();

export function saveOzonSettingsAsync(data) {
  return (dispatch, getState) => {
    dispatch(saveOzonSettings.request());

    const { currentCompanyId } = getState().app;

    ApiClient.awiza.saveOzonSettings({ ...data, companyId: currentCompanyId })
      .then((res) => {
        const notifications = [
          {
            type: 'success',
            title: 'API Ozon успешно подключен',
            message: 'Осталось настроить склады и разместить товары на маркетплейсе',
            icon: true,
            timeout: 5000,
            tag: 'awiza',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(saveOzonSettings.success(res, { data, companyId: currentCompanyId }));
      })
      .catch((error) => {
        const notifications = [
          {
            type: 'error',
            title: 'Ошибка подключения к API',
            message: 'API key или Client Id указаны неверно. Исправьте данные и попробуйте снова',
            icon: true,
            timeout: 5000,
            tag: 'awiza',
          },
        ];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(saveOzonSettings.failure(error));
      });
  };
}

export const getOzonWarehouses = createAsyncAction(
  'GET_OZON_WAREHOUSES_REQUEST',
  'GET_OZON_WAREHOUSES_SUCCESS',
  'GET_OZON_WAREHOUSES_FAIL',
)();

export function getOzonWarehousesAsync(params) {
  return (dispatch, getState) => {
    dispatch(getOzonWarehouses.request());

    const { currentCompanyId } = getState().app;

    ApiClient.awiza.getOzonWarehouses(params)
      .then((res) => dispatch(getOzonWarehouses.success(res, { companyId: currentCompanyId })))
      .catch((error) => dispatch(getOzonWarehouses.failure(error)));
  };
}

export const saveOzonWarehousesMatch = createAsyncAction(
  'SAVE_OZON_WAREHOUSES_MATCH_REQUEST',
  'SAVE_OZON_WAREHOUSES_MATCH_SUCCESS',
  'SAVE_OZON_WAREHOUSES_MATCH_FAIL',
)();

export function saveOzonWarehousesMatchAsync(data) {
  return (dispatch) => {
    dispatch(saveOzonWarehousesMatch.request());

    ApiClient.awiza.saveOzonWarehousesMatch(data)
      .then((res) => dispatch(saveOzonWarehousesMatch.success(res)))
      .catch((error) => dispatch(saveOzonWarehousesMatch.failure(error)));
  };
}
