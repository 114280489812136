import { Input } from '@teko/ui-kit';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo } from 'react';

const InputField = forwardRef((props, ref) => {
  const [field, meta] = useField(props.name);
  const state = useMemo(() => {
    if (props.state) return props.state;
    if (meta.error && meta.touched) return 'invalid';
    return undefined;
  }, [meta.error, meta.touched, props.state]);

  const handleBlur = useCallback((...args) => {
    field.onBlur(...args);
    props.onBlur?.(...args);
  }, [props.onBlur]);

  return useMemo(() => (
    <Input
      {...field}
      {...props}
      onBlur={handleBlur}
      value={field.value || ''}
      state={state}
      ref={ref}
    />
  ), [field.value, props.disabled, props.type, props.style, props.suffix, props.onBlur, state]);
});

InputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default InputField;
