import React from 'react';
import moment from 'moment';
import i18n from '@i18n';
import OrderSentIcon from '../assets/svg/orders/statuses/sent.svg';
import OrderSuccessIcon from '../assets/svg/orders/statuses/sucess.svg';
import OrderErrorIcon from '../assets/svg/orders/statuses/error.svg';
import OrderPendingIcon from '../assets/svg/orders/statuses/pending.svg';
import OrderRefundIcon from '../assets/svg/orders/statuses/refund.svg';

export const getCrmClientOrOutletStatusClassAndLabel = (status) => {
  switch (status) {
    case 'inProgress':
      return { cls: '_warning', label: 'Ожидает проверку' };
    case 'pending':
      return { cls: '_info', label: 'В процессе' };
    case 'declined': // temporary unused
      return { cls: '_error', label: 'Отказано' };
    case 'blocked':
      return { cls: '_error', label: 'Отключена' };
    case 'finished':
    case 'accepted': // temporary unused
      return { cls: '_success', label: 'Готово' };
    case 'loading':
      return { cls: '_info', label: 'Загрузка...' };
    default:
      console.warn(`[Status] Unknown status: ${status}`);
      return { cls: 'info', label: 'Неизвестно' };
  }
};

// export const getCrmBoxretailServiceClassAndLabel = (status) => {
//   switch (status) {
//     case 'created':
//       return { cls: '_warning', label: 'Требуется договор' }; // клиент запросил, но еще не получил договор для подписи
//     case 'pending':
//       return { cls: '_info', label: 'В процессе' }; // клиент должен подписать отправленный договор
//     case 'inProgress':
//       return { cls: '_warning', label: 'Ожидает проверку' }; // клиент отправил подписанный договор, его необходимо проверить менеджеру
//     case 'declined': // ?
//       return { cls: '_error', label: 'Отключена' }; // провайдер отключил услугу
//     case 'finished':
//     case 'accepted': // temporary unused
//     default:
//       return { cls: '_success', label: 'Подключена' }; // клиент подписал договор
//   }
// };

export const getCrmRequestStatusClassAndLabel = (status) => {
  switch (status) {
    case 'created':
      return { cls: '_warning', label: 'Ожидает проверку' };
    case 'inProgress':
      return { cls: '_info', label: 'Частично подтверждено' };
    case 'declined':
      return { cls: '_error', label: 'Отказано' };
    case 'finished':
    default:
      return { cls: '_success', label: 'Готово' };
  }
};

export const getCrmBoxretailServicesRequestStatusClassAndLabel = (status) => {
  switch (status) {
    case 'pending':
      return { cls: '_info', label: 'На подписании у клиента' };
    case 'inProgress':
      return { cls: '_warning', label: 'Ожидает проверку' };
    case 'accepted':
    default:
      return { cls: '_success', label: 'Готово' };
  }
};

export const getCrmBoxretailServicesContractsRequestStatusClassAndLabel = (status) => {
  switch (status) {
    case 'pending':
      return { cls: '_info', label: 'На подписании' };
    case 'inProgress':
      return { cls: '_warning', label: 'Ожидает проверку' };
    case 'accepted':
    default:
      return { cls: '_success', label: 'Акцептован' };
  }
};

export const getCrmCompanyStep = (company) => {
  const { status, companyStatus } = company ?? {};

  if (!companyStatus) {
    return 0;
  }
  if (companyStatus === 'info' || companyStatus === 'infoInProgress' || companyStatus === 'new' || status === 'contract') {
    return 1;
  }
  if (status === 'contractInProgress') {
    return 2;
  }
  return null;
};

export const getCompanyStep = (company) => {
  const { status, companyStatus } = company ?? {};

  if (!status) {
    return 0;
  }
  if (!companyStatus || companyStatus === 'info' || companyStatus === 'infoInProgress') {
    return 1;
  }
  if (companyStatus === 'contract' || companyStatus === 'contractInProgress') {
    return 2;
  }
  if (companyStatus === 'accepted') return 3;
  return null;
};

export const getOutletStatusClassAndLabel = (status) => {
  switch (status) {
    case 'inProgress':
      return { cls: '_warning', label: 'На проверке' };
    case 'pending':
      return { cls: '_warning', label: 'Требуются уточнения' };
    case 'declined': // temporary unused
      return { cls: '_error', label: 'Отказано' };
    case 'blocked':
      return { cls: '_error', label: 'Заблокирована' };
    case 'finished':
    case 'accepted': // temporary unused
    default:
      return { cls: '_success', label: 'Активна' };
  }
};

export const getBoxretailServiceClassAndLabel = (status) => {
  switch (status) {
    case 'created':
      return { cls: '_info', label: 'На проверке' }; // клиент запросил, но еще не получил договор для подписи
    case 'pending':
      return { cls: '_warning', label: 'Подпишите договор' }; // клиент должен подписать отправленный договор
    case 'inProgress':
      return { cls: '_info', label: 'На проверке' }; // клиент отправил подписанный договор, его необходимо проверить менеджеру
    case 'declined': // ?
      return { cls: '_error', label: 'Отключена' }; // провайдер отключил услугу
    case 'finished':
    case 'accepted': // temporary unused
    default:
      return { cls: '_success', label: 'Активна' }; // клиент подписал договор
  }
};

export const getClientAppStatusClassAndLabel = (status, companyHasNotes) => {
  switch (status) {
    case 'created':
    case 'inProgress': // (??) old comment: for client it means that app is already installed
      return { cls: '_warning', label: 'На проверке' };
    case 'blocked': // ?
      return { cls: '_error', label: 'Заблокировано' };
    case 'active':
    default:
      return { cls: '_success', label: 'Подключено' };
      // return companyHasNotes ? { cls: '_warning', label: 'На проверке' } : { cls: '_success', label: 'Подключено' };
  }
};

export const getSubscriptionStatusClassAndLabel = (status) => {
  switch (status) {
    case 'created':
      return { cls: '_success', label: 'Создана' };
    case 'semiExpired':
      return { cls: '_error', label: 'Ограничена' };
    case 'expired':
      return { cls: '_error', label: 'Истекла' };
    case 'canceled':
      return { cls: '_error', label: 'Отключена' };
    case 'ok':
    default:
      return { cls: '_success', label: 'Активна' };
  }
};

export const getPosterStatusClassAndLabel = (status) => {
  switch (status) {
    case 'inProgress':
      return { cls: '_warning', label: 'В процессе' };
    case 'failed': // temporary unused
      return { cls: '_error', label: 'Ошибка' };
    case 'finished':
    case 'accepted': // temporary unused
    default:
      return { cls: '_success', label: 'Перенесено' };
  }
};

export const getLicenseKeyStatusClassAndLabel = (status) => {
  switch (status) {
    case 'inactive':
      return { cls: 'disabled', label: 'Не активирован' };
    case 'revoked':
      return { cls: 'revoked', label: 'Отозван' };
    case 'reissued':
      return { cls: 'pending', label: 'Перевыпущен' };
    default:
      return { cls: 'enabled', label: 'Активирован' };
  }
};

export const getSmsProjectStatusClassAndLabel = (isActive) => {
  if (isActive) {
    return { cls: '_success', label: 'Активен' };
  }
  return { cls: '_error', label: 'Отключен' };
};

export const getEmployeeStatusClassAndLabel = (status) => {
  switch (status) {
    case 'blocked':
      return { cls: '_archive', label: 'Уволен' };
    default:
      return { cls: '_success', label: 'Активен' };
  }
};

// export const getSubscriptionStatusClassAndLabel = (isActive) => {
//   switch (isActive) {
//     case 'active':
//     default:
//       return { cls: '_success', label: 'Активна' };
//     case 'deactivated':
//       return { cls: '_error', label: 'Отключена' };
//   }
// };

export const getAwizaVkSettingStatusClassAndLabel = (status) => {
  if (status === 'completed') {
    return { cls: '_success', label: 'Работает' };
  }
  if (status === 'suspended') {
    return { cls: '_warning', label: 'Приостановлен' };
  }
  // removed
  return { cls: '_error', label: 'Заблокирован в ВК' };
};

export const getAwizaWarehouseStatus = (status) => {
  if (status === 'active') {
    return { cls: '_success', label: 'Активный' };
  }

  return { cls: '_archive', label: 'Архивный' };
};

export const isSubscriptionTrial = (subscription) => {
  if (typeof subscription.trialEndDate === 'undefined') return false;

  const trialEndMoment = moment(subscription.trialEndDate);

  return moment().isBefore(trialEndMoment.endOf('day')) && moment(subscription.nextPaymentDate).isSame(trialEndMoment, 'day');
};

/**
 * Является ли статус компании одним из активных
 * @param {CompanyStatus} status
 * @return {boolean}
 */
export const isActiveCompanyStatus = (status) => (status ? !['blocked', 'declined', 'removed'].includes(status) : false);

/**
 * Является ли статус аутлета принятым
 * @param {'inProgress'|'pending'|'finished'} status
 * @return {boolean}
 */
export const isActiveOutletStatus = (status) => status === 'finished' || status === 'accepted';

export const getExternalOrdersStatuses = ({ status, shopId } = {}) => {
  const orderOrRecord = shopId === 'my_clients' ? 'record' : 'order';
  const lcStatus = status && typeof status === 'string' && status.toLowerCase().replace(/_/g, '-');
  const t = (key) => i18n.t(key, { ns: 'orders' });

  switch (status) {
    case 'CONFIRMED':
      return { cls: '_info', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderSentIcon /> };
    case 'SOLD':
      return { cls: '_success', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderSuccessIcon /> };
    case 'CUSTOMER_REFUSE':
      return { cls: '_error', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderErrorIcon /> };
    case 'CANCELED_MERCHANT':
      return { cls: '_error', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderErrorIcon /> };
    case 'CANCEL_PROCESS':
      return { cls: '_error', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderErrorIcon /> };
    case 'CART':
      return { cls: '_success', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderSuccessIcon /> };
    case 'EXPIRED_CART':
      return { cls: '_error', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderErrorIcon /> };
    case 'DELIVERY':
      return { cls: '_info', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderSentIcon /> };
    case 'REFUND':
      return { cls: '_error', label: t(`${orderOrRecord}-statuses.${lcStatus}`), icon: <OrderRefundIcon /> };
    default:
      return { cls: '_warning', label: t(`${orderOrRecord}-statuses.pending`), icon: <OrderPendingIcon /> };
  }
};

export const getExternalOrdersPaymentStatuses = ({ payment: { paymentStatus: status }, shopId } = { payment: {} }) => {
  const orderOrRecord = shopId === 'my_clients' ? 'record' : 'order';
  const lcStatus = status && status.toLowerCase().replace(/_/g, '-');
  const t = (key) => i18n.t(key, { ns: 'orders' });

  switch (status) {
    case 'awaiting_payment':
      return { cls: '_archived', label: t(`${orderOrRecord}-payment-statuses.${lcStatus}`) };
    case 'paid_full':
      return { cls: '_success', label: t(`${orderOrRecord}-payment-statuses.${lcStatus}`) };
    case 'overpaid':
      return { cls: '_error', label: t(`${orderOrRecord}-payment-statuses.${lcStatus}`) };
    case 'paid_part':
      return { cls: '_warning', label: t(`${orderOrRecord}-payment-statuses.${lcStatus}`) };
    case 'refunded': {
      return { cls: '_archive', label: t(`${orderOrRecord}-payment-statuses.${lcStatus}`) };
    }
    default:
      return { cls: '_archived', label: t(`${orderOrRecord}-payment-statuses.not-paid`) };
  }
};

export const getDeliveryStatus = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return { cls: '_warning', label: 'Принят' };
    case 'CREATED':
      return { cls: '_warning', label: 'Создан' };
    case 'RECEIVED_AT_SHIPMENT_WAREHOUSE':
      return { cls: '_info', label: 'Принят на склад отправителя' };
    case 'READY_FOR_SHIPMENT_IN_SENDER_CITY':
      return { cls: '_info', label: 'Выдан на отправку в г. отправителе' };
    case 'RETURNED_TO_SENDER_CITY_WAREHOUSE':
      return { cls: '_info', label: 'Возвращен на склад отправителя' };
    case 'TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY':
      return { cls: '_info', label: 'Сдан перевозчику в г. отправителе' };
    case 'SENT_TO_TRANSIT_CITY':
      return { cls: '_info', label: 'Отправлен в г. транзит' };
    case 'ACCEPTED_IN_TRANSIT_CITY':
      return { cls: '_info', label: 'Встречен в г. транзите' };
    case 'ACCEPTED_AT_TRANSIT_WAREHOUSE':
      return { cls: '_info', label: 'Принят на склад транзита' };
    case 'RETURNED_TO_TRANSIT_WAREHOUSE':
      return { cls: '_info', label: 'Возвращен на склад транзита' };
    case 'READY_FOR_SHIPMENT_IN_TRANSIT_CITY':
      return { cls: '_info', label: 'Выдан на отправку в г. транзите' };
    case 'TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY':
      return { cls: '_info', label: 'Сдан перевозчику в г. транзите' };
    case 'SENT_TO_SENDER_CITY':
      return { cls: '_info', label: 'Отправлен в г. отправитель' };
    case 'SENT_TO_RECIPIENT_CITY':
      return { cls: '_info', label: 'Отправлен в г. получатель' };
    case 'ACCEPTED_IN_SENDER_CITY':
      return { cls: '_info', label: 'Встречен в г. отправителе' };
    case 'ACCEPTED_IN_RECIPIENT_CITY':
      return { cls: '_info', label: 'Встречен в г. получателе' };
    case 'ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE':
      return { cls: '_info', label: 'Принят на склад доставки' };
    case 'ACCEPTED_AT_PICK_UP_POINT':
      return { cls: '_info', label: 'Принят на склад до востребования' };
    case 'TAKEN_BY_COURIER':
      return { cls: '_info', label: 'Выдан на доставку' };
    case 'RETURNED_TO_RECIPIENT_CITY_WAREHOUSE':
      return { cls: '_info', label: 'Возвращен на склад доставки' };
    case 'DELIVERED':
      return { cls: '_success', label: 'Вручен' };
    case 'NOT_DELIVERED':
      return { cls: '_error', label: 'Не вручен' };
    case 'INVALID':
      return { cls: '_error', label: 'Некорректный заказ' };
    case 'IN_CUSTOMS_INTERNATIONAL':
      return { cls: '_info', label: 'Таможенное оформление в стране отправления' };
    case 'SHIPPED_TO_DESTINATION':
      return { cls: '_info', label: 'Отправлено в страну назначения' };
    case 'PASSED_TO_TRANSIT_CARRIER':
      return { cls: '_info', label: 'Передано транзитному перевозчику' };
    case 'IN_CUSTOMS_LOCAL':
      return { cls: '_info', label: 'Таможенное оформление в стране назначения' };
    case 'CUSTOMS_COMPLETE':
      return { cls: '_info', label: 'Таможенное оформление завершено' };
    case 'POSTOMAT_POSTED':
      return { cls: '_info', label: 'Заложен в постамат' };
    case 'POSTOMAT_SEIZED':
      return { cls: '_error', label: 'Изъят из постамата курьером' };
    case 'POSTOMAT_RECEIVED':
      return { cls: '_success', label: 'Изъят из постамата клиентом' };
    default:
      return { cls: '_archived', label: 'Не создана' };
  }
};

export const outletStatusFilterCategory = {
  id: 'status',
  label: 'Статус',
  search: { enabled: false },
  items: [
    { id: 'accepted', label: 'Активна' },
    { id: 'inProgress', label: 'На проверке' },
    { id: 'pending', label: 'Требуются уточнения' },
    { id: 'blocked', label: 'Заблокирована' },
  ],
};

export const employeesStatusFilterCategory = {
  id: 'status',
  label: 'Статус',
  search: { enabled: false },
  items: [
    { id: 'accepted', label: 'Активен' },
    { id: 'blocked', label: 'Уволен' },
  ],
};

export const awizaWarehousesStatusFilterCategory = {
  id: 'status',
  label: 'Статус',
  search: { enabled: false },
  items: [
    { id: 'active', label: 'Активный' },
    { id: 'deleted', label: 'Архивный' },
  ],
};

export const getPaycafeOnlineCashboxStatus = (status) => {
  if (status === 'active') {
    return { cls: '_success', label: 'Активный' };
  }

  return { cls: '_error', label: 'Заблокирована' };
};

export const getMyclientsWidgetStatusClassAndLabel = (status) => {
  if (status === 'active') {
    return { cls: '_success', label: 'Активен' }; //TODO add translate
  }

  return { cls: '_error', label: 'Заблокирован' };
};

export const getSubscriptionPaymentsStatuses = ({ payment, changedSubscription }) => {
  const t = (key) => i18n.t(key, { ns: 'payment' });

  if (changedSubscription?.companyId === payment.companyId && payment.status === 'pending') return { cls: '_archive', label: t('statuses.pending') };
  // if (loadingPayments[bill.companyId + bill.orderId]) return 'Обновление...';
  // const periodEndMoment = moment(bill.payedPeriodEnd).startOf('day');
  // const todayMoment = moment().startOf('day');
  // if (periodEndMoment.isBefore(todayMoment)) return 'Просрочен';
  if (payment.status === 'expired') return { cls: '_archive', label: t('statuses.expired') };
  if (payment.status === 'failed') return { cls: '_error', label: t('statuses.failed') };
  if (payment.status === 'success') return { cls: '_success', label: t('statuses.success') };

  return { cls: '_info', label: t('statuses.payment-needed') };
};

export const getPromocodeStatusClassAndLabel = ({ status } = {}) => {
  const t = (key) => i18n.t(key, { ns: 'awiza' });

  switch (status) {
    case 'active':
      return { cls: '_success', label: t('promotions.promocode-status.active') };
    case 'planned':
      return { cls: '_info', label: t('promotions.promocode-status.planned') };
    case 'paused':
      return { cls: '_error', label: t('promotions.promocode-status.paused') };
    case 'ended':
      return { cls: '_archive', label: t('promotions.promocode-status.ended') };
    case 'redeemed':
      return { cls: '_archive', label: t('promotions.promocode-status.redeemed') };
    default:
      console.warn(`[Status] Unknown status: ${status}`);
      return { cls: '_archive', label: '—' };
  }
};

export const getDiscountStatusClassAndLabel = ({ status } = {}) => {
  const t = (key) => i18n.t(key, { ns: 'awiza' });

  switch (status) {
    case 'active':
      return { cls: '_success', label: t('promotions.discount-status.active') };
    case 'planned':
      return { cls: '_info', label: t('promotions.discount-status.planned') };
    case 'paused':
      return { cls: '_error', label: t('promotions.discount-status.paused') };
    case 'ended':
      return { cls: '_archive', label: t('promotions.discount-status.ended') };
    default:
      console.warn(`[Status] Unknown status: ${status}`);
      return { cls: '_archive', label: '—' };
  }
};
