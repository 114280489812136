import { createSelector } from 'reselect';
import normalize from '../utils/normalize';

export const boxretailNotificationsTableSelector = createSelector(
  (state) => state.boxretail.notifications.table.ids,
  (state) => state.boxretail.notifications.byId,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const boxretailServiceCategoriesSelector = createSelector(
  (state) => state.services.servicesTree,
  (servicesTree) => normalize(servicesTree),
);

export const boxretailServicesSelector = createSelector(
  (state) => state.services.servicesTree,
  boxretailServiceCategoriesSelector,
  (servicesTree, categories) => servicesTree.flatMap((cat) => cat.products).map((service) => ({ ...service, catName: categories[service.cat]?.fullName })),
);

export const boxretailServicesByIdSelector = createSelector(
  boxretailServicesSelector,
  (services) => normalize(services),
);

export const boxretailProvidersByServiceSelector = createSelector(
  boxretailServicesSelector,
  (services) => services.reduce((acc, item) => ({
    ...acc,
    [item.id]: item.merchants,
  }), {}),
);

export const boxretailServicesByProviderSelector = createSelector(
  boxretailServicesSelector,
  (services) => services.reduce((acc, item) => {
    item.merchants.forEach((provider) => {
      if (!acc[provider]) {
        acc[provider] = [];
      }
      acc[provider].push(item);
    });
    return acc;
  }, {}),
);

export const boxretailServicesContractsRequestsByCompanyIdSelector = createSelector(
  (state) => state.boxretail.servicesRequestsByCompanyId,
  (state) => state.companies.byId,
  (state) => state.companies.companyInfoById,
  (servicesRequestsByCompanyId, companiesById, companyInfoById) => Object.entries(servicesRequestsByCompanyId)
    .reduce((acc, [companyId, requestsById]) => ({
      ...acc,
      [companyId]: Object.values(requestsById).map((request) => ({
        ...request,
        merchantName: companiesById[request.merchantId]?.name ?? request.merchantId,
        hasMerchantContract: Object.keys(companyInfoById[request.companyId]?.serviceMerchants?.[request.merchantId]?.contractInfo?.filesOfContractPhotocopies ?? {}).length > 0,
        hasServicesContract: Object.keys(request.servicesContractInfo.filesOfContractPhotocopies).length > 0,
      })),
    }), {}),
);

export const allBoxretailOutletsSelector = createSelector(
  (state) => state.companies.outletsByCompany,
  (outletsByCompany) => Object.values(outletsByCompany).reduce((result, companyOutletsById) => {
    Object.keys(companyOutletsById).forEach((outletId) => {
      result.push(companyOutletsById[outletId]);
    });
    return result;
  }, []),
);

export const allBoxretailOutletsByIdSelector = createSelector(
  allBoxretailOutletsSelector,
  (outlets) => normalize(outlets),
);

// export const boxretailCompanySelectedServicesSelector = createSelector(
//   boxretailServicesSelector,
//   (services) => normalize(services),
// );
