import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { transliterate } from '../utils/format';
import getFingerprint from '../utils/getFingerprint';
import { getNewUserNotificationsNotification } from '../utils/notification-utils';
import { createTableAction } from '../utils/redux-utils';
import { showNotification } from './app';
import { readUserNotificationAsync } from './notifications';

export const savePaycafeForm = createAsyncAction(
  'PAYCAFE_SAVE_FORM_REQUEST',
  'PAYCAFE_SAVE_FORM_SUCCESS',
  'PAYCAFE_SAVE_FORM_FAIL',
)();

/**
 * @param {PaycafeFormModel} create
 * @param {{ initiatorId: string, showcaseId: string }} [remove]
 */
export function savePaycafeFormAsync(create, remove = undefined) {
  return (dispatch) => {
    dispatch(savePaycafeForm.request());

    ApiClient.paycafe.savePaycafeForm(create)
      .then(({ linkId, linkUrl }) => {
        dispatch(savePaycafeForm.success({ ...create, linkId, linkUrl }));
        // if (remove) {
        //   dispatch(deletePaycafeFormAsync(remove));
        // }
      })
      .catch((error) => dispatch(savePaycafeForm.failure(error)));
  };
}

/**
 * @param {string} initiatorId
 * @param {string} showcaseId
 */
export function copyPaycafeFormAsync({ initiatorId, showcaseId }) {
  return (dispatch, getState) => {
    /** @type {PaycafeFormModel[]} */
    const forms = getState().paycafe.formsByCompany[initiatorId];
    const { linkId, linkUrl, ...form } = forms.find((form) => form.showcaseId === showcaseId);
    const copy = {
      ...form,
      ...(() => {
        let i = 0;
        let name = form.showcaseName.replace(/(.+)/, '$1 (Копия)');
        let id = transliterate(name);
        const hasDuplicate = () => forms.some((form) => form.showcaseId === id);

        while (hasDuplicate()) {
          name = form.showcaseName.replace(/(.+)/, `$1 (Копия ${++i})`);
          id = transliterate(name);
        }

        return {
          showcaseId: id,
          showcaseName: name,
        };
      })(),
    };

    dispatch(savePaycafeFormAsync(copy));
  };
}

export const getPaycafeForms = createAsyncAction(
  'PAYCAFE_GET_FORMS_REQUEST',
  'PAYCAFE_GET_FORMS_SUCCESS',
  'PAYCAFE_GET_FORMS_FAIL',
)();

export function getPaycafeFormsAsync(initiatorId) {
  return (dispatch, getState) => {
    const { paycafe } = getState();
    if (paycafe.fetchStatus.loading || paycafe.formsByCompany[initiatorId]) {
      return;
    }

    dispatch(getPaycafeForms.request());

    ApiClient.paycafe.getPaycafeForms(initiatorId)
      .then((data) => dispatch(getPaycafeForms.success(data, { initiatorId })))
      .catch((error) => dispatch(getPaycafeForms.failure(error)));
  };
}

export const getPaycafeUrls = createAsyncAction(
  'PAYCAFE_GET_URLS_REQUEST',
  'PAYCAFE_GET_URLS_SUCCESS',
  'PAYCAFE_GET_URLS_FAIL',
)();

export function getPaycafeUrlsAsync({ initiatorId, showcaseId }) {
  return (dispatch) => {
    dispatch(getPaycafeUrls.request());

    ApiClient.paycafe.getPaycafeUrls({ initiatorId, showcaseId })
      .then((data) => dispatch(getPaycafeUrls.success(data, { initiatorId, showcaseId })))
      .catch((error) => dispatch(getPaycafeUrls.failure(error)));
  };
}

export const deletePaycafeForm = createAsyncAction(
  'PAYCAFE_DELETE_FORM_REQUEST',
  'PAYCAFE_DELETE_FORM_SUCCESS',
  'PAYCAFE_DELETE_FORM_FAIL',
)();

/**
 * @param {string} initiatorId
 * @param {string} showcaseId
 */
export function deletePaycafeFormAsync({ initiatorId, showcaseId }) {
  return (dispatch) => {
    dispatch(deletePaycafeForm.request());

    ApiClient.paycafe.deletePaycafeForm({ initiatorId, showcaseId })
      .then(() => dispatch(deletePaycafeForm.success(null, { initiatorId, showcaseId })))
      .catch((error) => dispatch(deletePaycafeForm.failure(error)));
  };
}

export const getPaycafeTmpUrl = createAsyncAction(
  'PAYCAFE_GET_TMP_URL_REQUEST',
  'PAYCAFE_GET_TMP_URL_SUCCESS',
  'PAYCAFE_GET_TMP_URL_FAIL',
)();

export function getPaycafeTmpUrlAsync(data) {
  return (dispatch) => {
    dispatch(getPaycafeTmpUrl.request());

    ApiClient.paycafe.getPaycafeTmpUrl(data)
      .then((data) => dispatch(getPaycafeTmpUrl.success(data)))
      .catch((error) => dispatch(getPaycafeTmpUrl.failure(error)));
  };
}

export const getPaycafeOutlets = createAsyncAction(
  'PAYCAFE_GET_OUTLETS_REQUEST',
  'PAYCAFE_GET_OUTLETS_SUCCESS',
  'PAYCAFE_GET_OUTLETS_FAIL',
)();

export function getPaycafeOutletsAsync(force = false) {
  return (dispatch, getState) => {
    if (getState().paycafe.paycafeOutletsFetchStatus.state !== 'initial' && !force) {
      return;
    }

    dispatch(getPaycafeOutlets.request());

    ApiClient.paycafe.getOutlets()
      .then((data) => dispatch(getPaycafeOutlets.success(data)))
      .catch((error) => dispatch(getPaycafeOutlets.failure(error)));
  };
}

export const createPaycafeOutlets = createAsyncAction(
  'PAYCAFE_CREATE_OUTLETS_REQUEST',
  'PAYCAFE_CREATE_OUTLETS_SUCCESS',
  'PAYCAFE_CREATE_OUTLETS_FAIL',
)();

/**
 * @param {PaycafeOutletCreateModel|PaycafeOutletCreateModel[]} outlet
 */
export function createPaycafeOutletsAsync(outlet) {
  return (dispatch, getState) => {
    if (getState().paycafe.createPaycafeOutletsStatus.state === 'loading') {
      return;
    }

    if (!Array.isArray(outlet)) {
      outlet = [outlet]; // eslint-disable-line no-param-reassign
    }

    dispatch(createPaycafeOutlets.request());

    ApiClient.paycafe.createOutlets(outlet)
      .then((data) => dispatch(createPaycafeOutlets.success(data, outlet)))
      .catch((error) => dispatch(createPaycafeOutlets.failure(error)));
  };
}

export const updatePaycafeOutlet = createAsyncAction(
  'PAYCAFE_UPDATE_OUTLET_REQUEST',
  'PAYCAFE_UPDATE_OUTLET_SUCCESS',
  'PAYCAFE_UPDATE_OUTLET_FAIL',
)();

/**
 * @param {PaycafeOutletCreateModel} model
 * @param {boolean} defaultOutletChanged
 */
export function updatePaycafeOutletAsync(model, defaultOutletChanged = false) {
  return (dispatch, getState) => {
    if (getState().paycafe.updatePaycafeOutletStatus.state === 'loading') {
      return;
    }

    dispatch(updatePaycafeOutlet.request());

    ApiClient.paycafe.updateOutlet(model)
      .then((data) => {
        if (defaultOutletChanged) {
          const notifications = [
            {
              type: 'success',
              title: ' Точка продаж "по умолчанию" успешно изменена',
              message: 'Может использоваться в Terminal или Talkto для оплаты и предоплаты заказов',
              icon: true,
              timeout: 5000,
              tag: 'paycafe',
            },
          ];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        }

        dispatch(updatePaycafeOutlet.success(data, model));
      })
      .catch((error) => dispatch(updatePaycafeOutlet.failure(error)));
  };
}

export const changeDefaultPaycafeOutlet = createAsyncAction(
  'PAYCAFE_CHANGE_DEFAULT_OUTLET_REQUEST',
  'PAYCAFE_CHANGE_DEFAULT_OUTLET_SUCCESS',
  'PAYCAFE_CHANGE_DEFAULT_OUTLET_FAIL',
)();

export function changeDefaultPaycafeOutletAsync(data) {
  return (dispatch) => {
    dispatch(changeDefaultPaycafeOutlet.request());

    ApiClient.paycafe.changeDefaultOutlet(data)
      .then((response) => dispatch(changeDefaultPaycafeOutlet.success(response)))
      .catch((error) => dispatch(changeDefaultPaycafeOutlet.failure(error)));
  };
}

export const registerMerchantInSBPTochkaBank = createAsyncAction(
  'REGISTER_MERCHANT_IN_SBP_TOCHKA_BANK_REQUEST',
  'REGISTER_MERCHANT_IN_SBP_TOCHKA_BANK_SUCCESS',
  'REGISTER_MERCHANT_IN_SBP_TOCHKA_BANK_FAIL',
)();

export function registerMerchantInSBPTochkaBankAsync(model, sourceName) {
  return (dispatch) => {
    dispatch(registerMerchantInSBPTochkaBank.request());

    ApiClient.paycafe.registerMerchantInSBP(model)
      .then((data) => {
        dispatch(registerMerchantInSBPTochkaBank.success(data, { ...model, sourceName }));
      })
      .catch((error) => dispatch(registerMerchantInSBPTochkaBank.failure(error)));
  };
}

export const registerMerchantInAcquiringTochkaBank = createAsyncAction(
  'REGISTER_MERCHANT_IN_ACQUIRING_TOCHKA_BANK_REQUEST',
  'REGISTER_MERCHANT_IN_ACQUIRING_TOCHKA_BANK_SUCCESS',
  'REGISTER_MERCHANT_IN_ACQUIRING_TOCHKA_BANK_FAIL',
)();

export function registerMerchantInAcquiringTochkaBankAsync(model) {
  return (dispatch) => {
    dispatch(registerMerchantInAcquiringTochkaBank.request());

    ApiClient.paycafe.registerMerchantInAcquiring(model)
      .then((data) => {
        dispatch(registerMerchantInAcquiringTochkaBank.success(data, { model }));
      })
      .catch((error) => dispatch(registerMerchantInAcquiringTochkaBank.failure(error)));
  };
}

export const enrollmentSbpStatusInTochka = createAsyncAction(
  'ENROLLMENT_STATUS_IN_TOCHKA_REQUEST',
  'ENROLLMENT_STATUS_IN_TOCHKA_SUCCESS',
  'ENROLLMENT_STATUS_IN_TOCHKA_FAIL',
)();

export function getEnrollmentSbpStatusInTochkaAsync(model) {
  return (dispatch) => {
    dispatch(enrollmentSbpStatusInTochka.request());

    ApiClient.paycafe.enrollmentSbpStatusInTochka(model)
      .then((data) => dispatch(enrollmentSbpStatusInTochka.success(data, model)))
      .catch((error) => dispatch(enrollmentSbpStatusInTochka.failure(error)));
  };
}

export const enrollmentAcqStatusInTochka = createAsyncAction(
  'PAYCAFE_ENROLLMENT_ACQ_STATUS_IN_TOCHKA_REQUEST',
  'PAYCAFE_ENROLLMENT_ACQ_STATUS_IN_TOCHKA_SUCCESS',
  'PAYCAFE_ENROLLMENT_ACQ_STATUS_IN_TOCHKA_FAIL',
)();

export function getEnrollmentAcqStatusInTochkaAsync(data) {
  return (dispatch) => {
    dispatch(enrollmentAcqStatusInTochka.request());

    ApiClient.paycafe.enrollmentAcqStatusInTochka(data)
      .then((response) => dispatch(enrollmentAcqStatusInTochka.success(response, data)))
      .catch((error) => dispatch(enrollmentAcqStatusInTochka.failure(error)));
  };
}

export const updatePaycafeOutletSources = createAsyncAction(
  'PAYCAFE_UPDATE_OUTLET_SOURCES_REQUEST',
  'PAYCAFE_UPDATE_OUTLET_SOURCES_SUCCESS',
  'PAYCAFE_UPDATE_OUTLET_SOURCES_FAIL',
)();

/**
 * @param {PaycafeOutletSourcesUpdateModel} model
 */
export function updatePaycafeOutletSourcesAsync(model) {
  return (dispatch, getState) => {
    if (getState().paycafe.updatePaycafeOutletSourcesStatus.state === 'loading') {
      return;
    }

    dispatch(updatePaycafeOutletSources.request());

    ApiClient.paycafe.updateOutletSources(model)
      .then((data) => dispatch(updatePaycafeOutletSources.success(data, model)))
      .catch((error) => dispatch(updatePaycafeOutletSources.failure(error)));
  };
}

export const acceptPaycafeOutlet = createAsyncAction(
  'PAYCAFE_ACCEPT_OUTLET_REQUEST',
  'PAYCAFE_ACCEPT_OUTLET_SUCCESS',
  'PAYCAFE_ACCEPT_OUTLET_FAIL',
)();

export function acceptPaycafeOutletAsync(model, { password, isActionFromCrm = false } = {}) {
  return (dispatch, getState) => {
    dispatch(acceptPaycafeOutlet.request());

    const dataToSend = { ...model };

    if (password) {
      dataToSend.password = password;
    }

    const state = getState();
    const { paycafe: { outlets } } = state;
    const prevOutletStatus = outlets[model.id].status;
    const fingerPrint = transliterate(JSON.stringify(getFingerprint()));

    ApiClient.paycafe.acceptOutlet(dataToSend, fingerPrint)
      .then((data) => {
        let message;

        if (model.status === 'accepted') {
          const restoredOrAcceptedMessage = prevOutletStatus === 'blocked' ? 'Точка продаж восстановлена' : 'Точка успешно акцептована';
          const crmMessage = prevOutletStatus === 'accepted' ? 'Подключение точки продаж обновлено' : restoredOrAcceptedMessage;
          const regularMessage = 'Точка продаж восстановлена';
          message = isActionFromCrm ? crmMessage : regularMessage;
        } else if (model.status === 'blocked') {
          message = 'Точка продаж заблокирована';
        }

        if (message) {
          const notifications = [{ type: 'success', message, icon: true, timeout: 5000, tag: 'paycafe' }];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
        }

        dispatch(acceptPaycafeOutlet.success(data, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptPaycafeOutlet.failure(error));
      });
  };
}

export const getPaycafeOutletHistory = createAsyncAction(
  'PAYCAFE_GET_OUTLET_HISTORY_REQUEST',
  'PAYCAFE_GET_OUTLET_HISTORY_SUCCESS',
  'PAYCAFE_GET_OUTLET_HISTORY_FAIL',
)();

export function getPaycafeOutletHistoryAsync(id) {
  return (dispatch) => {
    dispatch(getPaycafeOutletHistory.request());

    ApiClient.paycafe.getOutletHistory(id)
      .then((response) => dispatch(getPaycafeOutletHistory.success(response, { id })))
      .catch((error) => dispatch(getPaycafeOutletHistory.failure(error)));
  };
}

export const createPaycafeInvoice = createAsyncAction(
  'PAYCAFE_CREATE_INVOICE_REQUEST',
  'PAYCAFE_CREATE_INVOICE_SUCCESS',
  'PAYCAFE_CREATE_INVOICE_FAIL',
)();

export function createPaycafeInvoiceAsync(model) {
  return (dispatch) => {
    dispatch(createPaycafeInvoice.request());

    ApiClient.paycafe.createInvoice(model)
      .then((data) => {
        // if (data.sessionUrl) {
        //   const notification = {
        //     type: 'success',
        //     message: 'Счет успешно выставлен',
        //     icon: true,
        //     timeout: 5000,
        //   };
        //
        //   dispatch(showNotification(notification));
        // }
        dispatch(createPaycafeInvoice.success(data, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(createPaycafeInvoice.failure(error));
      });
  };
}

export const getPaycafeInvoices = createAsyncAction(
  'PAYCAFE_GET_INVOICES_REQUEST',
  'PAYCAFE_GET_INVOICES_SUCCESS',
  'PAYCAFE_GET_INVOICES_FAIL',
)();

export const paycafeInvoicesTable = createTableAction(
  'PAYCAFE_INVOICES_TABLE_ADD',
  'PAYCAFE_INVOICES_TABLE_PREV',
  'PAYCAFE_INVOICES_TABLE_NEXT',
  'PAYCAFE_INVOICES_TABLE_FIRST',
);

export function getPaycafeInvoicesAsync(params, append) {
  return (dispatch) => {
    dispatch(getPaycafeInvoices.request());

    ApiClient.paycafe.getInvoices(params)
      .then((data) => {
        const { items, hasMore } = data;
        const meta = { append };
        const ids = items.map((item) => item.id);

        dispatch(getPaycafeInvoices.success(items, meta));
        dispatch(paycafeInvoicesTable.add({ ids, hasMore }, meta));
      })
      .catch((error) => dispatch(getPaycafeInvoices.failure(error)));
  };
}

export const getPaycafeInvoiceById = createAsyncAction(
  'PAYCAFE_GET_INVOICE_BY_ID_REQUEST',
  'PAYCAFE_GET_INVOICE_BY_ID_SUCCESS',
  'PAYCAFE_GET_INVOICE_BY_ID_FAIL',
)();

export function getPaycafeInvoiceByIdAsync(params) {
  return (dispatch) => {
    dispatch(getPaycafeInvoiceById.request());

    ApiClient.paycafe.getInvoices(params)
      .then((data) => dispatch(getPaycafeInvoiceById.success(data, params)))
      .catch((error) => dispatch(getPaycafeInvoiceById.failure(error)));
  };
}

export const disablePaycafeInvoice = createAsyncAction(
  'PAYCAFE_DISABLE_INVOICE_REQUEST',
  'PAYCAFE_DISABLE_INVOICE_SUCCESS',
  'PAYCAFE_DISABLE_INVOICE_FAIL',
)();

export function disablePaycafeInvoiceAsync(id) {
  return (dispatch) => {
    dispatch(disablePaycafeInvoice.request());

    ApiClient.paycafe.disableInvoice(id)
      .then((data) => dispatch(disablePaycafeInvoice.success(data, { id })))
      .catch((error) => dispatch(disablePaycafeInvoice.failure(error)));
  };
}

export const setRepeatInvoiceData = createAction('PAYCAFE_SET_REPEAT_INVOICE_DATA')();
export const clearRepeatInvoiceData = createAction('PAYCAFE_CLEAR_REPEAT_INVOICE_DATA')();

export const getPaycafeStaticQrCodes = createAsyncAction(
  'PAYCAFE_GET_STATIC_QR_CODES_REQUEST',
  'PAYCAFE_GET_STATIC_QR_CODES_SUCCESS',
  'PAYCAFE_GET_STATIC_QR_CODES_FAIL',
)();

export function getPaycafeStaticQrCodesAsync(params) {
  return (dispatch) => {
    dispatch(getPaycafeStaticQrCodes.request());

    ApiClient.paycafe.getStaticQrCodes(params)
      .then((data) => dispatch(getPaycafeStaticQrCodes.success(data, params)))
      .catch((error) => dispatch(getPaycafeStaticQrCodes.failure(error)));
  };
}

export const createPaycafeStaticQrCode = createAsyncAction(
  'PAYCAFE_CREATE_STATIC_QR_CODE_REQUEST',
  'PAYCAFE_CREATE_STATIC_QR_CODE_SUCCESS',
  'PAYCAFE_CREATE_STATIC_QR_CODE_FAIL',
)();

export function createPaycafeStaticQrCodeAsync(model) {
  return (dispatch) => {
    dispatch(createPaycafeStaticQrCode.request());

    ApiClient.paycafe.createStaticQrCode(model)
      .then((data) => dispatch(createPaycafeStaticQrCode.success(data, model)))
      .catch((error) => dispatch(createPaycafeStaticQrCode.failure(error)));
  };
}

export const updatePaycafeStaticQrCode = createAsyncAction(
  'PAYCAFE_UPDATE_STATIC_QR_CODE_REQUEST',
  'PAYCAFE_UPDATE_STATIC_QR_CODE_SUCCESS',
  'PAYCAFE_UPDATE_STATIC_QR_CODE_FAIL',
)();

export function updatePaycafeStaticQrCodeAsync(model, prevOutletId) {
  return (dispatch) => {
    dispatch(updatePaycafeStaticQrCode.request());

    ApiClient.paycafe.updateStaticQrCode(model)
      .then((data) => dispatch(updatePaycafeStaticQrCode.success(data, { ...model, prevOutletId })))
      .catch((error) => dispatch(updatePaycafeStaticQrCode.failure(error)));
  };
}

export const deletePaycafeStaticQrCode = createAsyncAction(
  'PAYCAFE_DELETE_STATIC_QR_CODE_REQUEST',
  'PAYCAFE_DELETE_STATIC_QR_CODE_SUCCESS',
  'PAYCAFE_DELETE_STATIC_QR_CODE_FAIL',
)();

export function deletePaycafeStaticQrCodeAsync({ id, password, outletId, companyId }) {
  return (dispatch) => {
    const model = { id, password };
    if (outletId) {
      model.outletId = outletId;
    }
    dispatch(deletePaycafeStaticQrCode.request());

    ApiClient.paycafe.deleteStaticQrCode(model)
      .then((data) => dispatch(deletePaycafeStaticQrCode.success(data, { ...model, companyId })))
      .catch((error) => dispatch(deletePaycafeStaticQrCode.failure(error)));
  };
}

export const atolAuth = createAsyncAction(
  'ATOL_AUTH_REQUEST',
  'ATOL_AUTH_SUCCESS',
  'ATOL_AUTH_FAIL',
)();

export function atolAuthAsync(data) {
  return (dispatch, getState) => {
    dispatch(atolAuth.request());

    const state = getState();
    ApiClient.paycafe.atolAuth(data)
      .then((response) => dispatch(atolAuth.success(response, data)))
      .catch((error) => {
        dispatch(atolAuth.failure(error));

        if (error.code === 1332) {
          const notifications = [{
            type: 'error',
            title: 'Ошибка подключения к АТОЛ',
            message: 'Логин, пароль или формат фискальных данных указаны неверно. Исправьте данные и попробуйте снова',
            icon: true,
            timeout: 5000,
            tag: 'paycafe',
          }];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
        }
      });
  };
}

export const addOnlineCashbox = createAsyncAction(
  'ADD_ONLINE_CASHBOX_REQUEST',
  'ADD_ONLINE_CASHBOX_SUCCESS',
  'ADD_ONLINE_CASHBOX_FAIL',
)();

export function addOnlineCashboxAsync(data) {
  return (dispatch, getState) => {
    dispatch(addOnlineCashbox.request());

    const state = getState();
    ApiClient.paycafe.addOnlineCashbox(data)
      .then((response) => {
        dispatch(addOnlineCashbox.success(response, data));

        const notifications = [{
          type: 'success',
          title: 'Онлайн-касса подключена',
          message: 'Теперь, подключите онлайн-кассу к точкам продаж Paycafe и фискализируйте платежи',
          icon: true,
          timeout: 5000,
          tag: 'paycafe',
        }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
      })
      .catch((error) => dispatch(addOnlineCashbox.failure(error)));
  };
}

export const editOnlineCashbox = createAsyncAction(
  'EDIT_ONLINE_CASHBOX_REQUEST',
  'EDIT_ONLINE_CASHBOX_SUCCESS',
  'EDIT_ONLINE_CASHBOX_FAIL',
)();

export function editOnlineCashboxAsync(data) {
  return (dispatch, getState) => {
    dispatch(editOnlineCashbox.request());

    const state = getState();
    ApiClient.paycafe.editOnlineCashbox(data)
      .then((response) => {
        dispatch(editOnlineCashbox.success(response, data));

        const notifications = [{
          type: 'success',
          message: 'Настройки онлайн-кассы успешно изменены',
          icon: true,
          timeout: 5000,
          tag: 'paycafe',
        }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
      })
      .catch((error) => dispatch(editOnlineCashbox.failure(error)));
  };
}

export const toggleStatusOnlineCashbox = createAsyncAction(
  'TOGGLE_STATUS_ONLINE_CASHBOX_REQUEST',
  'TOGGLE_STATUS_ONLINE_CASHBOX_SUCCESS',
  'TOGGLE_STATUS_ONLINE_CASHBOX_FAIL',
)();

export function toggleStatusOnlineCashboxAsync(data) {
  return (dispatch, getState) => {
    dispatch(toggleStatusOnlineCashbox.request());

    const state = getState();
    ApiClient.paycafe.toggleStatusOnlineCashbox(data)
      .then((response) => {
        dispatch(toggleStatusOnlineCashbox.success(response, data));

        const notifications = [{
          type: 'success',
          message: `Онлайн-касса ${data.status === 'active' ? 'активирована' : 'заблокирована'}`,
          icon: true,
          timeout: 5000,
          tag: 'paycafe',
        }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
      })
      .catch((error) => dispatch(toggleStatusOnlineCashbox.failure(error)));
  };
}

export const getOnlineCashboxes = createAsyncAction(
  'GET_ONLINE_CASHBOX_REQUEST',
  'GET_ONLINE_CASHBOX_SUCCESS',
  'GET_ONLINE_CASHBOX_FAIL',
)();

export function getOnlineCashboxesAsync(params) {
  return (dispatch) => {
    dispatch(getOnlineCashboxes.request());

    ApiClient.paycafe.getOnlineCashboxes(params)
      .then((response) => dispatch(getOnlineCashboxes.success(response, params)))
      .catch((error) => dispatch(getOnlineCashboxes.failure(error)));
  };
}

export const connectOnlineCashbox = createAsyncAction(
  'CONNECT_ONLINE_CASHBOX_REQUEST',
  'CONNECT_ONLINE_CASHBOX_SUCCESS',
  'CONNECT_ONLINE_CASHBOX_FAIL',
)();

export function connectOnlineCashboxAsync(data) {
  return (dispatch, getState) => {
    dispatch(connectOnlineCashbox.request());

    const state = getState();
    const outlet = state.paycafe.outlets[data.paymentPointId];

    ApiClient.paycafe.connectOnlineCashbox(data)
      .then((response) => {
        dispatch(connectOnlineCashbox.success(response, data));

        const notifications = [{
          type: 'success',
          title: 'Изменены настройки',
          message: `Онлайн-касса подключена к точке продаж ${outlet.fullName}`,
          icon: true,
          timeout: 5000,
          tag: 'paycafe',
        }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
      })
      .catch((error) => dispatch(connectOnlineCashbox.failure(error)));
  };
}

export const disconnectOnlineCashbox = createAsyncAction(
  'DISCONNECT_ONLINE_CASHBOX_REQUEST',
  'DISCONNECT_ONLINE_CASHBOX_SUCCESS',
  'DISCONNECT_ONLINE_CASHBOX_FAIL',
)();

export function disconnectOnlineCashboxAsync(data) {
  return (dispatch, getState) => {
    dispatch(disconnectOnlineCashbox.request());

    const state = getState();
    const outlet = state.paycafe.outlets[data.paymentPointId];

    ApiClient.paycafe.disconnectOnlineCashbox(data)
      .then((response) => {
        dispatch(disconnectOnlineCashbox.success(response, data));

        const notifications = [{
          type: 'success',
          title: 'Изменены настройки',
          message: `Онлайн-касса отключена от точки продаж ${outlet.fullName}`,
          icon: true,
          timeout: 5000,
          tag: 'paycafe',
        }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
      })
      .catch((error) => dispatch(disconnectOnlineCashbox.failure(error)));
  };
}

export const yoomoneyAuth = createAsyncAction(
  'YOOMONEY_AUTH_REQUEST',
  'YOOMONEY_AUTH_SUCCESS',
  'YOOMONEY_AUTH_FAIL',
)();

export function yoomoneyAuthAsync({ outletId, ...params }) {
  return (dispatch) => {
    dispatch(yoomoneyAuth.request());

    ApiClient.paycafe.yoomoney(params)
      .then((data) => {
        dispatch(yoomoneyAuth.success(data, { id: outletId }));
      })
      .catch((error) => {
        dispatch(yoomoneyAuth.failure(error));
      });
  };
}
