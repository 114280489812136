import { createSelector } from 'reselect';
import normalize from '../utils/normalize';

export const warehousesSelector = createSelector(
  (state) => state.awiza.warehousesByCompany,
  (state) => state.app.currentCompanyId,
  (warehousesByCompany, companyId) => warehousesByCompany[companyId] ?? [],
);

export const warehousesByIdSelector = createSelector(
  warehousesSelector,
  (warehouses) => normalize(warehouses ?? {}),
);

export const categoriesSelector = createSelector(
  (state) => state.awiza.categoriesByCompany,
  (state) => state.app.currentCompanyId,
  (categories, companyId) => categories[companyId] ?? [],
);

const recursiveChildrenExtractToObject = (categories, resultObject, parentId = null) => categories.reduce((acc, cat) => {
  acc[cat.id] = { ...cat, parentId };

  if (cat.subCategories.length) {
    recursiveChildrenExtractToObject(cat.subCategories, acc, cat.id);
  }

  return acc;
}, resultObject);

export const categoriesByIdSelector = createSelector(
  (state) => state.awiza.categoriesByCompany,
  (state) => state.app.currentCompanyId,
  (categories, companyId) => recursiveChildrenExtractToObject((categories[companyId] ?? []), {}),
);

const recursiveEnrichChildrenWithParentId = (categories = [], parentId = null) => {
  const categoriesWithParentId = categories.map((cat) => ({
    ...cat,
    parentId,
    ...(cat.subCategories ? { subCategories: recursiveEnrichChildrenWithParentId(cat.subCategories, cat.id) } : null),
  }));

  return categoriesWithParentId;
};

export const categoriesWithParentSelector = createSelector(
  (state) => state.awiza.categoriesByCompany,
  (state) => state.app.currentCompanyId,
  (categories, companyId) => recursiveEnrichChildrenWithParentId(categories[companyId]),
);

export const productsSelector = createSelector(
  (state) => state.awiza.productsTable.ids,
  (state) => state.awiza.productsById,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const productsToPublishSelector = createSelector(
  (state) => state.awiza.productsToPublishById,
  (byId) => Object.values(byId),
);

export const productStockSelector = createSelector(
  (state) => state.awiza.stockByWarehouseId,
  (byId) => Object.values(byId),
);

export const vkGroupsSelector = createSelector(
  (state) => state.awiza.vkAuthSessionDataByCompanyId,
  (state) => state.app.currentCompanyId,
  (groupsByCompanyId, companyId) => (groupsByCompanyId[companyId]?.groups ?? []).filter(({ name }) => name),
);

export const currentInstalledMarketplacesByIdSelector = createSelector(
  (state) => state.awiza.marketplacesByCompanyId,
  (state) => state.app.currentCompanyId,
  (marketplacesByCompanyId, companyId) => Object.keys(marketplacesByCompanyId[companyId] ?? {})
    .filter((id) => marketplacesByCompanyId[companyId][id].settings.find((setting) => setting.status !== 'pending') || id === 'sbermegamarket')
    .reduce((acc, id) => {
      acc[id] = marketplacesByCompanyId[companyId][id];
      return acc;
    }, {}),
);

export const vkPublishedProductsSelector = createSelector(
  (state) => state.awiza.vkPublishedProductsByCompanyId,
  (state) => state.app.currentCompanyId,
  (vkPublishedProductsByCompanyId, companyId) => Object.values(vkPublishedProductsByCompanyId[companyId] ?? {}),
);

export const awizaWarehouseByIdByOzonWarehouseSelector = createSelector(
  (state) => state.awiza.warehousesByCompany,
  (state) => state.app.currentCompanyId,
  (warehousesByCompany, companyId) => warehousesByCompany[companyId]?.map((warehouse) => ({
    awziaWarehouse: warehouse.id,
    ozonWarehouse: warehouse.extra?.ozonWareHouseId,
  })),
);

export const ozonWarehousesMatchingAwizaWarehouses = createSelector(
  awizaWarehouseByIdByOzonWarehouseSelector,
  (state) => state.awiza.ozonWarehousesByCompanyId,
  (state) => state.app.currentCompanyId,
  (awizaWarehouses, ozonWarehouses, companyId) => Object.keys(ozonWarehouses ? ozonWarehouses[companyId] : {}).map((warehouse) => {
    const matchingAwizaWarehouse = awizaWarehouses.find((item) => item.ozonWarehouse === warehouse);
    return {
      ozonWarehouse: warehouse,
      awizaWarehouse: matchingAwizaWarehouse?.awizaWarehouse || '',
    };
  }),
);

export const awizaPromocodesTableSelector = createSelector(
  (state) => state.awiza.promotions.promocodes.table.ids,
  (state) => state.awiza.promotions.promocodes.byId,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const awizaDiscountsTableSelector = createSelector(
  (state) => state.awiza.promotions.discounts.table.ids,
  (state) => state.awiza.promotions.discounts.byId,
  (ids, byId) => ids.map((id) => byId[id]),
);
