import { useRef } from 'react';
import { useSelector } from 'react-redux';

function useAsyncData(selector, initial = null) {
  const data = useSelector(selector);
  const ref = useRef(data);

  return ref.current === data ? initial : data;
}

export default useAsyncData;
