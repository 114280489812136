import { createSelector } from 'reselect';
import { outletsSortByStatusAndCreatedAt } from '../utils/sorts';
import { isActiveCompanyStatus, isSubscriptionTrial } from '../utils/statuses';

export const unsortedCompaniesSelector = createSelector(
  (state) => state.companies.byId,
  (byId) => Object.values(byId),
);

export const companiesSelector = createSelector(
  (state) => state.companies.byId,
  (byId) => Object.keys(byId).map((id) => byId[id]).sort((a, b) => {
    if (!isActiveCompanyStatus(b.status)) return -1;
    return 1;
  }),
);

export const companiesByIdSelector = (state) => state.companies.byId;

export const allCompaniesByIdSelector = createSelector(
  (state) => state.companies.byId,
  (state) => state.companies.removedById,
  (byId, removedById) => ({ ...byId, ...removedById }),
);

export const activeCompaniesSelector = createSelector(
  companiesSelector,
  (companies) => companies.filter(({ status }) => isActiveCompanyStatus(status)),
);

export const acceptedCompaniesSelector = createSelector(
  companiesSelector,
  (companies) => companies.filter(({ status }) => status === 'accepted'),
);

export const allCompaniesWithAcceptedFirstSelector = createSelector(
  acceptedCompaniesSelector,
  companiesSelector,
  (acceptedCompanies, companies) => [...acceptedCompanies, ...companies.filter(({ status }) => status !== 'accepted')],
);

export const currentCompanyIdSelector = (state) => state.app.currentCompanyId;

export const currentCompanySelector = createSelector(
  (state) => state.companies.byId,
  (state) => state.app.currentCompanyId,
  (byId, id) => (id ? byId[id] : null),
);

export const currentCompanyInfoSelector = createSelector(
  (state) => state.companies.companyInfoById,
  (state) => state.app.currentCompanyId,
  (companyInfoById, id) => (id ? companyInfoById[id] : null),
);

export const companiesSelectOptionsSelector = createSelector(
  (state) => state.companies.byId,
  (byId) => Object.values(byId).map((item) => ({
    label: item.name,
    value: item.id,
  })),
);

export const outletsSelector = createSelector(
  (state) => state.companies.outletsByCompany,
  (outlets) => Object.keys(outlets).reduce((result, globalId) => {
    result[globalId] = Object.keys(outlets[globalId]).map((id) => outlets[globalId][id]);

    return result;
  }, {}),
);

export const outletsTableSelector = createSelector(
  (state) => state.companies.outletsByCompany,
  (state) => state.app.currentCompanyId,
  (outlets, currentCompanyId) => Object.values(outlets[currentCompanyId]).sort(outletsSortByStatusAndCreatedAt),
);

// export const outletsTableSelector = createSelector(
//   (state) => state.companies.byId,
//   (state) => state.companies.outletsByCompany,
//   (state) => state.companies.outletsTable,
//   (state) => state.app.currentCompanyId,
//   (companies, outlets, table, currentCompanyId) => Object.entries(table).reduce((result, entry) => {
//     const [companyId, outletsId] = entry;
//     const company = companies[companyId];
//
//     if (!currentCompanyId || companyId === currentCompanyId) {
//       for (const id of outletsId) {
//         result.push({
//           company,
//           outlet: outlets[companyId][id],
//         });
//       }
//     }
//
//     return result;
//   }, []),
// );

export const mapOutletsSelector = createSelector(
  (state) => state.companies.outletsByCompany,
  (outletsByCompany) => {
    const outlets = [];

    for (const globalId of Object.keys(outletsByCompany)) {
      for (const id of Object.keys(outletsByCompany[globalId])) {
        const outlet = outletsByCompany[globalId][id];
        const { latitude, longitude } = outlet.extra;

        if (!outlet?.paymentPoint?.extra?.['1c_point_code'] || !latitude || !longitude) continue;

        outlets.push(outlet);
      }
    }

    return outlets;
  },
);

export const currentOutletsByIdSelector = createSelector(
  (state) => state.app.currentCompanyId,
  (state) => state.companies.outletsByCompany,
  (companyId, outletsByCompanyId) => (
    companyId ? outletsByCompanyId[companyId] : Object.values(outletsByCompanyId).reduce((acc, companyOutletsById) => {
      Object.values(companyOutletsById).forEach((outlet) => {
        acc[outlet.id] = outlet;
      });
      // no { ...acc } destruction to save memory
      return acc;
    }, {})
  ),
);

export const currentOutletsSelector = createSelector(
  outletsSelector,
  companiesSelector,
  (state) => state.app.currentCompanyId,
  (outlets, companies, currentCompanyId) => {
    if (currentCompanyId) {
      return [...(outlets[currentCompanyId] ?? [])].sort(outletsSortByStatusAndCreatedAt);
    }

    return companies.reduce((union, company) => union.concat(outlets[company.id] ?? []), []).sort((a, b) => {
      if (a.status === b.status) return b.createdAt - a.createdAt;
      if (b.status === 'blocked') return -1;
      if (a.status === 'blocked') return 1;
      return b.createdAt - a.createdAt;
    });
  },
);

export const currentOutletsSelectOptionsSelector = createSelector(
  currentOutletsSelector,
  (outlets) => outlets.map((outlet) => ({
    value: outlet.id,
    label: outlet.fullName,
  })),
);

export const makeCompanyOutletsSelector = () => createSelector(
  outletsSelector,
  companiesSelector,
  (state, companyId) => companyId || state.app.currentCompanyId,
  (outlets, companies, currentCompanyId) => {
    if (currentCompanyId && currentCompanyId !== 'all') {
      return outlets[currentCompanyId] ?? [];
    }

    return companies.reduce((union, company) => union.concat(outlets[company.id] ?? []), []);
  },
);

export const currentActiveOutletsSelector = createSelector(
  currentOutletsSelector,
  (outlets) => outlets.filter((outlet) => outlet.status === 'accepted'),
);

export const allOutletsSelector = createSelector(
  outletsSelector,
  companiesSelector,
  (outlets, companies) => companies.reduce((union, company) => union.concat(outlets[company.id] ?? []), []),
);

export const outletsByPosIdSelector = createSelector(
  mapOutletsSelector,
  (outlets) => outlets.reduce((result, outlet) => {
    result[outlet?.paymentPoint?.extra?.['1c_point_code']] = outlet;
    return result;
  }, {}),
);

export const subscriptionsSelector = createSelector(
  (state) => state.companies.subscriptionsByCompany,
  (byId) => Object.keys(byId)
    .map((id) => ({ ...byId[id], _status: getSubStatus(byId[id]) }))
    .sort((a, b) => {
      if (!b.isActive || b.isFreezed) return -1;
      return 1;
    }),
);

export const currentSubscriptionsSelector = createSelector(
  subscriptionsSelector,
  (state) => state.app.currentCompanyId,
  (state) => state.companies.byId,
  (subs, currentCompanyId, companiesById) => (currentCompanyId ? subs.filter(({ companyId }) => companyId === currentCompanyId) : subs.filter(({ companyId }) => companiesById[companyId]))
    .filter((sub) => isActiveCompanyStatus(companiesById[sub.companyId].status))
    .map((sub) => ({ ...sub, companyName: companiesById[sub.companyId].name, companyColor: companiesById[sub.companyId].color })),
);

export const currentPaymentsSelector = createSelector(
  (state) => state.companies.paymentsByCompany,
  (state) => state.app.currentCompanyId,
  (state) => state.companies.byId,
  (paymentsByCompany, currentCompanyId, companiesById) => {
    const payments = Object.values(paymentsByCompany[currentCompanyId] ?? {});
    // const filtered = currentCompanyId ? payments.filter(({ companyId }) => companyId === currentCompanyId) : payments.filter(({ companyId }) => companiesById[companyId]); // second filter for deleted companies (they are stored in another object)
    return payments.map((payment) => ({ ...payment, companyName: companiesById[payment.companyId].name })).sort((a, b) => b.createdAt - a.createdAt);
    // return filtered.flatMap(({ companyId, successPayments, nextPayment }) => [...successPayments.map((payment) => ({ ...payment, successfullyPayed: true })), nextPayment].map((payment) => ({ ...payment, companyId, companyName: companiesById[companyId].name })));
  },
);

function getSubStatus(sub) {
  if (sub.status !== 'canceled' && isSubscriptionTrial(sub)) {
    return 'trial';
  }
  if (sub.status === 'expired' || sub.status === 'semiExpired') {
    return 'expired';
  }
  if (sub.status === 'created' || sub.status === 'ok') {
    return 'active';
  }
  return sub.status; // canceled left
}
