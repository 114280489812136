import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/dictionaries';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const productsReducer = createReducer([])
  .handleAction(actions.getProducts.success, (state, { payload }) => payload.map((item) => {
    if (typeof item === 'string') { // old api format workaround
      return {
        id: item,
        name: item,
      };
    }

    return item;
  }));

// TODO
const boxretailProductsReducer = createReducer([
  { id: 'trade', name: 'Торговля' },
  { id: 'transfer', name: 'Денежные переводы, пополнение счетов, оплата кредитов' },
  { id: 'insurance', name: 'Страховые продукты' },
  { id: 'mobile', name: 'Оплата мобильной связи' },
  { id: 'tourism', name: 'Туризм (авиа/жд билеты)' },
  { id: 'other', name: 'Прочие финансовые операции' },
]);

const sourcesReducer = createReducer([])
  .handleAction(actions.getSources.success, (state, { payload }) => payload.map((item) => {
    if (typeof item === 'string') { // old api format workaround
      return {
        id: item,
        fullName: item,
      };
    }

    return item;
  }));

const providersReducer = createReducer({})
  .handleAction(actions.getProviders.success, (state, { payload, meta }) => ({
    ...state,
    [meta.source]: payload.providers,
  }));

const banksReducer = createReducer([])
  .handleAction(actions.getBanks.success, (state, { payload }) => payload);

const bpaBanksReducer = createReducer([])
  .handleAction(actions.getBpaBanks.success, (state, { payload }) => payload);

const merchantsReducer = createReducer([])
  .handleAction(actions.getMerchants.success, (state, { payload }) => payload);

const paycafeCategoriesReducer = createReducer([])
  .handleAction(actions.getPaycafeCategories.success, (state, { payload }) => payload);

const contractsReducer = createReducer([])
  .handleAction(actions.getContracts.success, (state, { payload }) => payload);

const positionsReducer = createReducer([{ id: 'cashier', name: 'Кассир' }]);

const plansReducer = createReducer({})
  .handleAction(actions.getPlansDictionary.success, (state, { payload }) => normalize(payload));

const vkCategoriesReducer = createReducer([])
  .handleAction(actions.getVkCategories.success, (state, { payload }) => payload);

export default combineReducers({
  products: productsReducer,
  boxretailProducts: boxretailProductsReducer,
  sources: sourcesReducer,
  providers: providersReducer,
  banks: banksReducer,
  bpaBanks: bpaBanksReducer,
  merchants: merchantsReducer,
  paycafeCategories: paycafeCategoriesReducer,
  contracts: contractsReducer,
  positions: positionsReducer,
  plans: plansReducer,
  vkCategories: vkCategoriesReducer,
  productsFetchStatus: asyncReducerFactory(actions.getProducts),
  sourcesFetchStatus: asyncReducerFactory(actions.getSources),
  providersFetchStatus: asyncReducerFactory(actions.getProviders),
  banksFetchStatus: asyncReducerFactory(actions.getBanks),
  bpaBanksFetchStatus: asyncReducerFactory(actions.getBpaBanks),
  merchantsFetchStatus: asyncReducerFactory(actions.getMerchants),
  paycafeCategoriesFetchStatus: asyncReducerFactory(actions.getPaycafeCategories),
  contractsFetchStatus: asyncReducerFactory(actions.getContracts),
  plansFetchStatus: asyncReducerFactory(actions.getPlansDictionary),
  vkCategoriesFetchStatus: asyncReducerFactory(actions.getVkCategories),
});
