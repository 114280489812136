import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyInfoAsync } from '../../../actions/companies';

/**
 * @param {string} id
 * @returns {CompanyInfoModel}
 */
export default function useCompanyInfo(id) {
  const dispatch = useDispatch();
  const companyInfo = useSelector((state) => (id && id !== 'new' ? state.companies.companyInfoById[id] : null));

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(
        getCompanyInfoAsync(id),
      );
    }
  }, [id]);

  return companyInfo;
}
