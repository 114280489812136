import { AutoComplete } from '@teko/ui-kit';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { debounceWithAsyncFunc } from '../../../../utils/debounce';
import { useAsyncCallback } from '../../hooks';

const recursiveCloneWithEmptyValues = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    switch (typeof obj[key]) {
      case 'object':
        acc[key] = recursiveCloneWithEmptyValues(obj[key]);
        break;
      case 'string':
        acc[key] = '';
        break;
      default:
        acc[key] = null;
    }
    return acc;
  }, {});
};

export function useAutoCompleteField(name, getDataset) {
  const [_field, meta, helpers] = useField(name);
  const [dataset, setDataset] = useState([]);

  const getDatasetAsync = useAsyncCallback(getDataset, (dataset) => setDataset(dataset), [getDataset]);
  const onSelect = useCallback(({ value }) => helpers.setValue(value), []);
  const onSearch = useCallback(debounceWithAsyncFunc((value) => {
    const search = value.trim().toLowerCase();
    return getDatasetAsync(search);
  }, 300), [getDatasetAsync]);
  const onReset = useCallback(() => {
    let emptyValue;
    if (typeof meta.initialValue === 'object') {
      emptyValue = recursiveCloneWithEmptyValues(meta.initialValue);
    } else {
      emptyValue = typeof meta.initialValue === 'string' ? '' : null;
    }
    helpers.setValue(emptyValue);
  }, []);

  const field = useMemo(() => ({
    dataset,
    onSelect,
    onSearch,
    onReset,
    name,
    onBlur: _field.onBlur,
    state: meta.error && meta.touched ? 'invalid' : undefined,
  }), [dataset, meta.error, meta.touched, onSearch, onReset]);

  return [field, meta, helpers];
}

function AutoCompleteField({ name, getDataset, ...props }) {
  const [field] = useAutoCompleteField(name, getDataset);

  return (
    <AutoComplete
      {...field}
      {...props}
    />
  );
}

AutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  getDataset: PropTypes.func.isRequired,
};

export default AutoCompleteField;
