import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/notifications';
import normalize from '../utils/normalize';
import { asyncReducerFactory, tableReducerFactory } from '../utils/redux-utils';

const dataReducer = createReducer({})
  .handleAction([actions.getUserNotifications.success, actions.getAllUserNotifications.success], (state, { payload }) => ({
    ...state,
    ...normalize(payload),
  }))
  .handleAction(actions.readUserNotification.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      alreadyRead: true,
    },
  }))
  .handleAction(actions.readAllUserNotifications.success, (state, { meta: { allIds } }) => {
    const newState = { ...state };
    allIds.forEach((id) => {
      newState[id].alreadyRead = true;
    });
    return newState;
  });

const unreadReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getUserNotifications.success, (state, { payload, meta: { isReadAllClicked } }) => ({
    ids: isReadAllClicked ? [...payload.map(({ id }) => id)] : [...payload.map(({ id }) => id), ...state.ids],
    totalCount: payload.length + (isReadAllClicked ? 0 : state.totalCount),
  }))
  .handleAction(actions.readUserNotification.success, (state, { meta }) => ({
    ids: state.ids.filter((id) => id !== meta.id),
    totalCount: state.totalCount - 1,
  }));

const readLoadingByIdReducer = createReducer({})
  .handleAction(actions.readUserNotification.request, (state, { meta }) => ({
    ...state,
    [meta.id]: true,
  }))
  .handleAction([actions.readUserNotification.success, actions.readUserNotification.failure], (state, { meta }) => ({
    ...state,
    [meta.id]: false,
  }));

const lastFetchedNotificationsReducer = createReducer({ array: [], fetchCount: 0 })
  .handleAction(actions.getUserNotifications.success, (state, { payload }) => ({
    array: state.fetchCount > 0 ? payload : [], // skip first fetch
    fetchCount: state.fetchCount + 1,
  }));

export default combineReducers({
  byId: dataReducer,
  unread: unreadReducer,
  readLoadingById: readLoadingByIdReducer,
  lastFetchedNotifications: lastFetchedNotificationsReducer,
  table: tableReducerFactory(actions.allUserNotificationsTable),
  fetchStatus: asyncReducerFactory(actions.getAllUserNotifications),
  readAllStatus: asyncReducerFactory(actions.readAllUserNotifications),
});
