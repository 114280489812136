import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/rates';
import { asyncReducerFactory } from '../utils/redux-utils';

const schemesReducer = createReducer({})
  .handleAction(actions.getSchemes.success, (state, { payload, meta }) => ({
    ...state,
    [JSON.stringify(meta.params)]: payload.schemes,
  }));

const productsByMerchantReducer = createReducer({})
  .handleAction(actions.getProductsByMerchant.success, (state, { payload, meta }) => {
    const { merchant } = meta;

    return {
      ...state,
      [merchant]: payload.products,
    };
  });

const calculationReducer = createReducer({})
  .handleAction(actions.calculate.success, (state, { payload }) => payload.payments);

export default combineReducers({
  schemes: schemesReducer,
  productsByMerchant: productsByMerchantReducer,
  calculation: calculationReducer,
  saveRatesStatus: asyncReducerFactory(actions.saveRates),
  calculateStatus: asyncReducerFactory(actions.calculate),
  saveSchemeStatus: asyncReducerFactory(actions.saveScheme),
});
