import { AutoComplete } from '@teko/ui-kit';
import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ApiClient } from '../../../../utils/api-client';
import { useAutoCompleteField } from './AutoCompleteField';
import FieldError from '../../ui/FieldError';

function AddressAutocompleteField({ name, disabled, extraFields }) {
  const addressInputRef = useRef();
  const { t } = useTranslation();
  const getDataset = useCallback((search) => ApiClient.utils.suggest.address(search, extraFields).then(
    ({ suggestions }) => suggestions.map(
      (item, i) => ({
        id: i.toString(),
        label: item.address,
        value: item,
      }),
    ),
  ), [extraFields]);

  const [field, meta] = useAutoCompleteField(name, getDataset);
  const isLevelError = (meta.error === 'not-street-level' || meta.error === 'not-house-level');

  return (
    <>
      <AutoComplete
        {...field}
        inputRef={addressInputRef}
        disabled={disabled}
        className="address-autocomplete"
        initialValue={meta.initialValue?.address || meta.value?.address}
      />
      <FieldError shown={!!meta.error && meta.touched}>
        {meta.error === 'not-street-level' && t('common:field-errors.not-street-level')}
        {meta.error === 'not-house-level' && t('common:field-errors.not-house-level')}
        {!isLevelError && (addressInputRef.current?.value ? (
          t('common:field-errors.wrong-input-format')
        ) : (
          t('common:field-errors.no-address')
        ))}
      </FieldError>
    </>
  );
}

AddressAutocompleteField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AddressAutocompleteField;
