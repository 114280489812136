import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getSchemes = createAsyncAction(
  'COMPANIES_GET_SCHEMES_REQUEST',
  'COMPANIES_GET_SCHEMES_SUCCESS',
  'COMPANIES_GET_SCHEMES_FAIL',
)();

/**
 * @param {SchemeRequestParams} params
 */
export function getSchemesAsync(params) {
  return (dispatch) => {
    dispatch(getSchemes.request());

    ApiClient.companies.getSchemes(params)
      .then((data) => dispatch(getSchemes.success(data, { params })))
      .catch((error) => dispatch(getSchemes.failure(error)));
  };
}

export const getProductsByMerchant = createAsyncAction(
  'COMPANIES_GET_PRODUCTS_BY_MERCHANT_REQUEST',
  'COMPANIES_GET_PRODUCTS_BY_MERCHANT_SUCCESS',
  'COMPANIES_GET_PRODUCTS_BY_MERCHANT_FAIL',
)();

/**
 * @param {string} merchant
 */
export function getProductsByMerchantAsync(merchant) {
  return (dispatch, getState) => {
    const { productsByMerchant } = getState().rates;
    if (productsByMerchant[merchant]) {
      return;
    }

    dispatch(getProductsByMerchant.request());

    ApiClient.companies.getProductsByMerchant(merchant)
      .then((data) => dispatch(getProductsByMerchant.success(data, { merchant })))
      .catch((error) => dispatch(getProductsByMerchant.failure(error)));
  };
}

export const saveRates = createAsyncAction(
  'COMPANIES_SAVE_RATES_REQUEST',
  'COMPANIES_SAVE_RATES_SUCCESS',
  'COMPANIES_SAVE_RATES_FAIL',
)();

/**
 * @param {SaveRatesRequestModel} data
 */
export function saveRatesAsync(data) {
  return (dispatch) => {
    dispatch(saveRates.request());

    ApiClient.companies.saveRates(data)
      .then((data) => dispatch(saveRates.success(data)))
      .catch((error) => dispatch(saveRates.failure(error)));
  };
}

export const calculate = createAsyncAction(
  'COMPANIES_CALCULATE_RATES_REQUEST',
  'COMPANIES_CALCULATE_RATES_SUCCESS',
  'COMPANIES_CALCULATE_RATES_FAIL',
)();

/**
 * @param {CalculateRequestParams} params
 */
export function calculateAsync(params) {
  return (dispatch) => {
    dispatch(calculate.request());

    ApiClient.companies.calculate(params)
      .then((data) => dispatch(calculate.success(data)))
      .catch((error) => dispatch(calculate.failure(error)));
  };
}

export const saveScheme = createAsyncAction(
  'COMPANIES_SAVE_SCHEME_REQUEST',
  'COMPANIES_SAVE_SCHEME_SUCCESS',
  'COMPANIES_SAVE_SCHEME_FAIL',
)();

/**
 * @param {SaveSchemeModel} data
 */
export function saveSchemeAsync(data) {
  return (dispatch) => {
    dispatch(saveScheme.request());

    ApiClient.companies.saveScheme(data)
      .then((data) => dispatch(saveScheme.success(data)))
      .catch((error) => dispatch(saveScheme.failure(error)));
  };
}
