import moment from 'moment';
import { useMemo } from 'react';
import { FAST_START_HIDE_DELAY } from '../../../utils/constants';
import useUser from './useUser';

function useFastStartVisible() {
  const { guideFinishedAt } = useUser();

  return useMemo(() => {
    if (typeof guideFinishedAt === 'undefined') return true;
    if (guideFinishedAt === 0) return false;
    return moment().isBefore(moment(guideFinishedAt).add(FAST_START_HIDE_DELAY, 'days'));
  }, [guideFinishedAt]);
}

export default useFastStartVisible;
