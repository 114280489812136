import { createAction } from 'typesafe-actions';

export const showNotification = createAction('APP_NOTIFICATION_SHOW')();
export const hideNotification = createAction('APP_NOTIFICATION_HIDE')();
export const setCompanyId = createAction('APP_SET_COMPANY_ID', (id, userId) => {
  if (userId) {
    const options = JSON.parse(localStorage.getItem(`options:${userId}`)) ?? {};
    options['app:companyId'] = id;
    localStorage.setItem(`options:${userId}`, JSON.stringify(options));
  }
  return id;
})();

export const showModal = createAction('APP_MODAL_SHOW')();
export const hideModal = createAction('APP_MODAL_HIDE')();
