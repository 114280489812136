import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/app';

const initialState = {
  nId: 0,
  notifications: [],
  modal: {},
  currentCompanyId: null,
};

const appReducer = createReducer(initialState)
  .handleAction(actions.showNotification, (state, { payload }) => ({
    ...state,
    nId: state.nId + (payload.id ? 0 : 1),
    notifications: state.notifications.concat({
      ...payload,
      id: payload.id ?? state.nId,
    }),
  }))
  .handleAction(actions.hideNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.filter((item) => item.id !== payload.id),
  }))
  .handleAction(actions.setCompanyId, (state, { payload }) => ({
    ...state,
    currentCompanyId: payload,
  }))
  .handleAction(actions.showModal, (state, { payload }) => ({
    ...state,
    modal: payload,
  }))
  .handleAction(actions.hideModal, (state) => ({
    ...state,
    modal: {},
  }));

export default appReducer;
