import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getClients = createAsyncAction(
  'GET_CLIENTS_REQUEST',
  'GET_CLIENTS_SUCCESS',
  'GET_CLIENTS_FAIL',
)();

export function getClientsAsync(params) {
  return (dispatch) => {
    dispatch(getClients.request());

    ApiClient.clients.getClients(params)
      .then((clients) => dispatch(getClients.success(clients)))
      .catch((error) => dispatch(getClients.failure(error, params)));
  };
}

export const updateClient = createAsyncAction(
  'UPDATE_CLIENT_REQUEST',
  'UPDATE_CLIENT_SUCCESS',
  'UPDATE_CLIENT_FAIL',
)();

export function updateClientAsync(data) {
  return (dispatch) => {
    dispatch(updateClient.request());

    ApiClient.clients.updateClient(data)
      .then((client) => dispatch(updateClient.success(client)))
      .catch((error) => dispatch(updateClient.failure(error)));
  };
}

export const getClientDetailsById = createAsyncAction(
  'GET_CLIENT_DETAILS_BY_ID_REQUEST',
  'GET_CLIENT_DETAILS_BY_ID_SUCCESS',
  'GET_CLIENT_DETAILS_BY_ID_FAIL',
)();

export function getClientDetailsByIdAsyns(id) {
  return (dispatch) => {
    dispatch(getClientDetailsById.request());

    ApiClient.clients.getClientDetailsById(id)
      .then((client) => dispatch(getClientDetailsById.success(client)))
      .catch((error) => dispatch(getClientDetailsById.failure(error)));
  };
}

export const getClientPayments = createAsyncAction(
  'GET_CLIENT_PAYMENTS_REQUEST',
  'GET_CLIENT_PAYMENTS_SUCCESS',
  'GET_CLIENT_PAYMENTS_FAIL',
)();

export function getClientPaymentsAsync(params) {
  return (dispatch) => {
    dispatch(getClientPayments.request());

    ApiClient.clients.getClientPayments(params)
      .then((payments) => dispatch(getClientPayments.success(payments, params)))
      .catch((error) => dispatch(getClientPayments.failure(error)));
  };
}

export const searchClients = createAsyncAction(
  'SEARCH_CLIENTS_REQUEST',
  'SEARCH_CLIENTS_SUCCESS',
  'SEARCH_CLIENTS_FAIL',
)();

export function searchClientsAsync(params) {
  return (dispatch) => {
    dispatch(searchClients.request());

    ApiClient.clients.getClients(params)
      .then((clients) => dispatch(searchClients.success(clients)))
      .catch((error) => dispatch(searchClients.failure(error)));
  };
}

export const clearClientsSearch = createAction('CLEAR_CLIENTS_SEARCH')();
