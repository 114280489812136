import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@teko/ui-kit';
import { ThemeProvider } from 'styled-components';
import { getUserAsync } from '../../actions/users';
import successIconSrc from '../../assets/img/auth/check_green.png';
import successIcon2xSrc from '../../assets/img/auth/check_green@2x.png';
import { getReferralByUrl } from '../../utils/getReferral';

function RegistrationFormStepFour() {
  const { t } = useTranslation('auth');
  const { loading } = useSelector((state) => state.users.currentUserFetchStatus);
  const dispatch = useDispatch();
  const brand = getReferralByUrl() || 'teko';

  const handleGoToLKClick = useCallback(() => {
    dispatch(getUserAsync());
  }, []);

  const goToLkBtn = (
    <Button
      className="registration-form-step-btn"
      design="primary"
      size="l"
      loading={loading}
      onClick={handleGoToLKClick}
    >
      {t('to-lk')}
    </Button>
  );

  return (
    <div className="registration-form-step _step-four">
      {/*<div className="registration-form-step-hint" dangerouslySetInnerHTML={{ __html: t('registration-step-four-hint') }} />*/}
      <div className="success-icon-wrap">
        <img className="success-img" srcSet={`${successIconSrc} 1x, ${successIcon2xSrc} 2x`} src={successIconSrc} alt="Success registration" />
      </div>

      <div className="registration-form-step-footer">
        {brand === 'talkto' && (
          <ThemeProvider theme={{ primary: '#452994' }}>
            {goToLkBtn}
          </ThemeProvider>
        )}
        {brand !== 'talkto' && goToLkBtn}
      </div>
    </div>
  );
}

export default memo(RegistrationFormStepFour);
