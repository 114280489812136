import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';

export const sendSms = createAsyncAction(
  'CRM_SEND_SMS_REQUEST',
  'CRM_SEND_SMS_SUCCESS',
  'CRM_SEND_SMS_FAIL',
)();

export function sendSmsAsync(data) {
  return (dispatch) => {
    dispatch(sendSms.request());

    ApiClient.crm.sendSms(data)
      .then((res) => dispatch(sendSms.success(res, { data })))
      .catch((error) => dispatch(sendSms.failure(error)));
  };
}

export const getSmsProjects = createAsyncAction(
  'CRM_GET_SMS_PROJECTS_REQUEST',
  'CRM_GET_SMS_PROJECTS_SUCCESS',
  'CRM_GET_SMS_PROJECTS_FAIL',
)();

export function getSmsProjectsAsync() {
  return (dispatch) => {
    dispatch(getSmsProjects.request());

    ApiClient.crm.getSmsProjects()
      .then((res) => dispatch(getSmsProjects.success(res)))
      .catch((error) => dispatch(getSmsProjects.failure(error)));
  };
}

export const updateSmsProject = createAsyncAction(
  'CRM_UPDATE_SMS_PROJECT_REQUEST',
  'CRM_UPDATE_SMS_PROJECT_SUCCESS',
  'CRM_UPDATE_SMS_PROJECT_FAIL',
)();

export function updateSmsProjectAsync(data) {
  return (dispatch) => {
    dispatch(updateSmsProject.request());

    ApiClient.crm.updateSmsProject(data)
      .then((res) => {
        dispatch(updateSmsProject.success(res, { data }));

        const notification = {
          type: 'success',
          message: 'СМС-проект отредактирован',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(updateSmsProject.failure(error)));
  };
}

export const createSmsProject = createAsyncAction(
  'CRM_CREATE_SMS_PROJECT_REQUEST',
  'CRM_CREATE_SMS_PROJECT_SUCCESS',
  'CRM_CREATE_SMS_PROJECT_FAIL',
)();

export function createSmsProjectAsync(data) {
  return (dispatch) => {
    dispatch(createSmsProject.request());

    ApiClient.crm.createSmsProject(data)
      .then((res) => {
        dispatch(createSmsProject.success(res, { data }));

        const notification = {
          type: 'success',
          message: 'СМС-проект добавлен',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(createSmsProject.failure(error)));
  };
}

export const updateSmsProjectStatus = createAsyncAction(
  'CRM_UPDATE_SMS_PROJECT_STATUS_REQUEST',
  'CRM_UPDATE_SMS_PROJECT_STATUS_SUCCESS',
  'CRM_UPDATE_SMS_PROJECT_STATUS_FAIL',
)();

export function updateSmsProjectStatusAsync(data) {
  return (dispatch) => {
    dispatch(updateSmsProjectStatus.request());

    ApiClient.crm.updateSmsProjectStatus(data)
      .then((res) => {
        dispatch(updateSmsProjectStatus.success(res, data));

        const notification = {
          type: 'success',
          message: `СМС-проект ${data?.isActive ? 'активирован' : 'отключен'}`,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
      })
      .catch((error) => dispatch(updateSmsProjectStatus.failure(error)));
  };
}

export const getSmppConfig = createAsyncAction(
  'CRM_GET_SMPP_CONFIG_REQUEST',
  'CRM_GET_SMPP_CONFIG_SUCCESS',
  'CRM_GET_SMPP_CONFIG_FAIL',
)();

export function getSmppConfigAsync() {
  return (dispatch) => {
    dispatch(getSmppConfig.request());

    ApiClient.crm.getSmppConfig()
      .then((res) => dispatch(getSmppConfig.success(res)))
      .catch((error) => dispatch(getSmppConfig.failure(error)));
  };
}

export const getSmsProjectInfo = createAsyncAction(
  'CRM_GET_SMS_PROJECT_INFO_REQUEST',
  'CRM_GET_SMS_PROJECT_INFO_SUCCESS',
  'CRM_GET_SMS_PROJECT_INFO_FAIL',
)();

export function getSmsProjectInfoAsync(params) {
  return (dispatch) => {
    dispatch(getSmsProjectInfo.request());

    ApiClient.crm.getSmsProjectInfo(params)
      .then((res) => dispatch(getSmsProjectInfo.success(res)))
      .catch((error) => dispatch(getSmsProjectInfo.failure(error)));
  };
}

export const changeTariffCrm = createAsyncAction(
  'CHANGE_TARIFF_CRM_REQUEST',
  'CHANGE_TARIFF_CRM_SUCCESS',
  'CHANGE_TARIFF_CRM_FAIL',
)();

export function changeTariffCrmAsync(data, companyId, tariffName) {
  return (dispatch, getState) => {
    dispatch(changeTariffCrm.request());

    const { name } = getState().companies.companyInfoById[companyId];

    ApiClient.crm.changeTariffCrm(data)
      .then((res) => {
        const notification = {
          type: 'success',
          title: `Подключен тариф ${tariffName}`,
          message: `Для проекта ${name} подключен тариф ${tariffName}`,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(changeTariffCrm.success(res, data));
      })
      .catch((error) => dispatch(changeTariffCrm.failure(error)));
  };
}

export const updateTrialSubscription = createAsyncAction(
  'CRM_UPDATE_TRIAL_SUBSCRIPTION_REQUEST',
  'CRM_UPDATE_TRIAL_SUBSCRIPTION_SUCCESS',
  'CRM_UPDATE_TRIAL_SUBSCRIPTION_FAIL',
)();

export function updateTrialSubscriptionAsync(data, companyId) {
  return (dispatch, getState) => {
    dispatch(updateTrialSubscription.request());

    const { name } = getState().companies.companyInfoById[companyId];
    const { tariffName } = getState().companies.subscriptionsByCompany[companyId];

    ApiClient.crm.updateTrialSubscription(data)
      .then((res) => {
        const notification = {
          type: 'success',
          title: 'Подписка продлена',
          message: `Для проекта ${name} продлена подписка ${tariffName} (пробный) `,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(updateTrialSubscription.success(res, data));
      })
      .catch((error) => dispatch(updateTrialSubscription.failure(error)));
  };
}

export const deleteTrialSubscription = createAsyncAction(
  'CRM_DELETE_TRIAL_SUBSCRIPTION_REQUEST',
  'CRM_DELETE_TRIAL_SUBSCRIPTION_SUCCESS',
  'CRM_DELETE_TRIAL_SUBSCRIPTION_FAIL',
)();

export function deleteTrialSubscriptionAsync(data, companyId) {
  return (dispatch, getState) => {
    dispatch(deleteTrialSubscription.request());

    const { name } = getState().companies.companyInfoById[companyId];
    const { tariffName } = getState().companies.subscriptionsByCompany[companyId];

    ApiClient.crm.deleteTrialSubscription(data)
      .then((res) => {
        const notification = {
          type: 'success',
          title: 'Пробная подписка отключена',
          message: `Для проекта ${name} отключена подписка ${tariffName} (пробный) `,
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(deleteTrialSubscription.success(res, data));
      })
      .catch((error) => dispatch(deleteTrialSubscription.failure(error)));
  };
}

export const getSubscriptionsHistory = createAsyncAction(
  'CRM_GET_SUBSCRIPTION_HISTORY_REQUEST',
  'CRM_GET_SUBSCRIPTION_HISTORY_SUCCESS',
  'CRM_GET_SUBSCRIPTION_HISTORY_FAIL',
)();

export function getSubscriptionsHistoryAsync(companyId) {
  return (dispatch) => {
    dispatch(getSubscriptionsHistory.request());

    ApiClient.crm.getSubscriptionsHistory(companyId)
      .then((res) => {
        dispatch(getSubscriptionsHistory.success(res, companyId));
      })
      .catch((error) => dispatch(getSubscriptionsHistory.failure(error)));
  };
}
