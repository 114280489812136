import { PeriodModel } from '@teko/ui-kit';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';
import useUser from './useUser';

// TODO: move to redux
function useStorablePeriodState(key, defaultValue = undefined, storage = localStorage) {
  const ref = useRef();
  const userId = useUser()?.id;
  const [value, _setValue] = useState(() => {
    const options = JSON.parse(storage.getItem(`options:${userId}`)) ?? {};
    if (options[key]) {
      return new PeriodModel({ start: moment(options[key].start), end: moment(options[key].end) }, options[key].type).refresh();
    }
    return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
  });

  ref.current = { value };

  const setValue = useCallback((nextValue) => {
    const options = JSON.parse(storage.getItem(`options:${userId}`)) ?? {};

    _setValue(nextValue);
    options[key] = typeof nextValue === 'function' ? nextValue(ref.current.value) : nextValue;
    storage.setItem(`options:${userId}`, JSON.stringify(options));
  }, []);

  return [value, setValue];
}

export default useStorablePeriodState;
