import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../actions/app';
import { defaultErrorDesc } from '../../../utils/api-client';

function useErrorNotification(error) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      const notification = {
        type: 'error',
        message: error.description ?? defaultErrorDesc,
        timeout: 5000,
      };

      dispatch(showNotification(notification));
    }
  }, [error]);
}

export default useErrorNotification;
