import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeCompanyOutletsSelector } from '../../../selectors/companies';

/**
 * @returns {{ allBoxretailOutlets: object[], activeBoxretailOutlets: object[] }}
 */
function useBoxretailOutlets(companyId) {
  const currentOutletsSelector = useMemo(makeCompanyOutletsSelector, []);
  const allBoxretailOutlets = useSelector((state) => currentOutletsSelector(state, companyId));

  return useMemo(() => ({
    allBoxretailOutlets,
    activeBoxretailOutlets: allBoxretailOutlets.filter((outlet) => outlet.status === 'accepted'),
  }), [allBoxretailOutlets]);

  // const outletsByCompanyId = useSelector(outletsSelector);
  // const currentCompanyId = useSelector(currentCompanyIdSelector);
  // const companyId = propsCompanyId || currentCompanyId;
  // return useMemo(() => ({
  //   allBoxretailOutlets: companyId && companyId !== 'all' ? (outletsByCompanyId[companyId] ?? []) : Object.values(outletsByCompanyId).flat(),
  //   activeBoxretailOutlets: companyId && companyId !== 'all' ? (outletsByCompanyId[companyId] ?? []).filter((outlet) => outlet.status === 'accepted') : ,
  // }), [outletsByCompanyId, companyId]);
}

export default useBoxretailOutlets;
