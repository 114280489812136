import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar } from '@teko/ui-kit';

const ProgressBarContainer = () => {
  const requests = useSelector((state) => state.requests);

  return (
    <ProgressBar className="progress-bar" active={requests > 0} />
  );
};

export default memo(ProgressBarContainer);
