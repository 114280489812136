const globalMemoCache = new Map();

/**
 * @param {string} key
 * @param {function(): *} create
 * @param {Array<*>} inputs
 */
function useGlobalMemo(key, create, inputs) {
  let cacheEntry = globalMemoCache.get(key);

  if (!cacheEntry) {
    cacheEntry = { value: create(), inputs };
    globalMemoCache.set(key, cacheEntry);
  } else {
    if (inputs.length !== cacheEntry.inputs.length) throw new Error('[useGlobalMemo] Input length differ!');
    if (inputs.length !== 0) {
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i] !== cacheEntry.inputs[i]) {
          cacheEntry.value = create();
          cacheEntry.inputs = inputs;
          break;
        }
      }
    }
  }

  return cacheEntry.value;
}

export default useGlobalMemo;
