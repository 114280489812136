import { useField } from 'formik';
import React, { forwardRef, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@teko/ui-kit';

const GeneratedInputField = forwardRef(({ generate, ...props }, ref) => {
  const [field, meta] = useField(props.name);
  const [dirty, setDirty] = useState(false);

  return (
    <Input
      {...field}
      {...props}
      ref={ref}
      value={dirty ? field.value : generate()}
      state={meta.error && meta.touched ? 'invalid' : undefined}
      onChange={(e) => {
        field.onChange(e);
        setDirty(true);
      }}
    />
  );
});

GeneratedInputField.propTypes = {
  name: PropTypes.string.isRequired,
  generate: PropTypes.func.isRequired,
};

export default memo(GeneratedInputField);
