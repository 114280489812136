import '@teko/ui-kit/styles.css';
import 'core-js/stable';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-hot-loader';
import App from './components/App';
import './styles/index.scss';
import getPlatform from './utils/getPlatform';

moment.locale('ru');

function canUseWebP() {
  const elem = document.createElement('canvas');

  if (elem.getContext && elem.getContext('2d')) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  return false;
}

function isTouchDevice() {
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}

const platform = getPlatform();

// TODO: if find better solution - refactor this shit (it's for making page scalable on ios devices in "desktop mode")

const iOSAgent = window.navigator.userAgent.match(/iPhone|iPod|iPad/);
const iOSPlatform = window.navigator.platform && (
  window.navigator.platform.match(/iPhone|iPod|iPad/) ||
  (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
);
const iOSRequestDesktop = (!iOSAgent && iOSPlatform);

if (iOSRequestDesktop) {
  document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1024');
}

window.document.documentElement.classList.add(isTouchDevice() ? 'touch' : 'no-touch');
window.document.documentElement.classList.add(canUseWebP() ? 'webp' : 'no-webp');
window.document.documentElement.classList.add(platform);

ReactDOM.render(<App />, window.document.getElementById('app'));

console.log(`
 /$$        /$$$$$$  /$$   /$$ /$$$$$$
| $$       /$$__  $$| $$  /$$/|_  $$_/
| $$      | $$  \\ $$| $$ /$$/   | $$
| $$      | $$  | $$| $$$$$/    | $$
| $$      | $$  | $$| $$  $$    | $$
| $$      | $$  | $$| $$\\  $$   | $$
| $$$$$$$$|  $$$$$$/| $$ \\  $$ /$$$$$$
|________/ \\______/ |__/  \\__/|______/
`);
console.log(`Version: ${process.env.VERSION}`);
