import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import ImagesInput from '../inputs/ImagesInput';

function ImagesInputField({ name, ...props }) {
  const [field, meta, helpers] = useField(name);

  const handleChange = useCallback((value) => {
    helpers.setTouched(true);
    field.onChange({
      target: { name, value },
    });
  }, [name]);

  return (
    <ImagesInput
      {...field}
      {...props}
      name={name}
      state={meta.error && meta.touched ? 'invalid' : undefined}
      onChange={handleChange}
    />
  );
}

ImagesInputField.propTypes = {
  name: PropTypes.string.isRequired,
  previewUrl: PropTypes.string, // # getAwizaImgSrc('{{fileId}}'),
  uploader: PropTypes.func.isRequired, // # ApiClient.awiza.uploadImages
  multiple: PropTypes.bool,
  accept: PropTypes.string,
};

ImagesInputField.defaultProps = {
  multiple: true,
  accept: '.png,.jpg,.jpeg,.svg,.gif',
};

export default ImagesInputField;
