import { createAction, createAsyncAction } from 'typesafe-actions';
import i18n from '@i18n';
import { ApiClient } from '../utils/api-client';
import { getNewUserNotificationsNotification } from '../utils/notification-utils';
import { showNotification } from './app';
import { readUserNotificationAsync } from './notifications';

const t = (key) => i18n.t(key, { ns: 'myclients' });

export const connectMyclients = createAsyncAction(
  'MYCLIENTS_CONNECT_REQUEST',
  'MYCLIENTS_CONNECT_SUCCESS',
  'MYCLIENTS_CONNECT_FAIL',
)();

export function connectMyclientsAsync(projectId) {
  return (dispatch) => {
    dispatch(connectMyclients.request());

    ApiClient.myclients.connect({ projectId })
      .then((data) => dispatch(connectMyclients.success(data, { id: projectId })))
      .catch((error) => dispatch(connectMyclients.failure(error)));
  };
}

export const getMyclientsServices = createAsyncAction(
  'MYCLIENTS_GET_SERVICES_REQUEST',
  'MYCLIENTS_GET_SERVICES_SUCCESS',
  'MYCLIENTS_GET_SERVICES_FAIL',
)();

export function getMyclientsServicesAsync(params) {
  return (dispatch, getState) => {
    dispatch(getMyclientsServices.request());
    const projectId = getState().app.currentCompanyId;

    ApiClient.myclients.getServices({ ...params, projectId })
      .then((response) => dispatch(getMyclientsServices.success(response, params)))
      .catch((error) => dispatch(getMyclientsServices.failure(error)));
  };
}

export const getMyclientsShortServices = createAsyncAction(
  'MYCLIENTS_GET_SHORT_SERVICES_REQUEST',
  'MYCLIENTS_GET_SHORT_SERVICES_SUCCESS',
  'MYCLIENTS_GET_SHORT_SERVICES_FAIL',
)();

export function getMyclientsShortServicesAsync(params) {
  return (dispatch) => {
    // const { myclients: { services: { getAllShortFetchStatus } } } = getState();
    // if (getAllShortFetchStatus.loading || getAllShortFetchStatus.success) {
    //   return;
    // }

    dispatch(getMyclientsShortServices.request());

    ApiClient.myclients.getShortServices(params)
      .then((response) => dispatch(getMyclientsShortServices.success(response, params)))
      .catch((error) => dispatch(getMyclientsShortServices.failure(error)));
  };
}

export const createMyclientsService = createAsyncAction(
  'MYCLIENTS_CREATE_SERVICE_REQUEST',
  'MYCLIENTS_CREATE_SERVICE_SUCCESS',
  'MYCLIENTS_CREATE_SERVICE_FAIL',
)();

export function createMyclientsServiceAsync(data) {
  return (dispatch, getState) => {
    dispatch(createMyclientsService.request());

    ApiClient.myclients.createService(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('services.create-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(createMyclientsService.success(response, data));
      })
      .catch((error) => dispatch(createMyclientsService.failure(error)));
  };
}

export const updateMyclientsService = createAsyncAction(
  'MYCLIENTS_UPDATE_SERVICE_REQUEST',
  'MYCLIENTS_UPDATE_SERVICE_SUCCESS',
  'MYCLIENTS_UPDATE_SERVICE_FAIL',
)();

export function updateMyclientsServiceAsync(data) {
  return (dispatch, getState) => {
    dispatch(updateMyclientsService.request());

    ApiClient.myclients.updateService(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('services.edit-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateMyclientsService.success(response, data));
      })
      .catch((error) => dispatch(updateMyclientsService.failure(error)));
  };
}

export const deleteMyclientsService = createAsyncAction(
  'MYCLIENTS_DELETE_SERVICE_REQUEST',
  'MYCLIENTS_DELETE_SERVICE_SUCCESS',
  'MYCLIENTS_DELETE_SERVICE_FAIL',
)();

export function deleteMyclientsServiceAsync(serviceId) {
  return (dispatch, getState) => {
    dispatch(deleteMyclientsService.request());
    const projectId = getState().app.currentCompanyId;

    ApiClient.myclients.deleteService(serviceId, { projectId })
      .then((response) => {
        const notifications = [{ type: 'success', message: t('services.delete-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(deleteMyclientsService.success(response));
      })
      .catch((error) => dispatch(deleteMyclientsService.failure(error)));
  };
}

export const getMyclientsServiceCategories = createAsyncAction(
  'MYCLIENTS_GET_SERVICE_CATEGORIES_REQUEST',
  'MYCLIENTS_GET_SERVICE_CATEGORIES_SUCCESS',
  'MYCLIENTS_GET_SERVICE_CATEGORIES_FAIL',
)();

export function getMyclientsServiceCategoriesAsync({ force, ...params }) {
  return (dispatch, getState) => {
    const { myclients: { services: { getCategoriesFetchStatus } } } = getState();
    if ((getCategoriesFetchStatus.loading || getCategoriesFetchStatus.success) && !force) {
      return;
    }
    dispatch(getMyclientsServiceCategories.request());

    ApiClient.myclients.getServiceCategories(params)
      .then((response) => dispatch(getMyclientsServiceCategories.success(response, params)))
      .catch((error) => dispatch(getMyclientsServiceCategories.failure(error)));
  };
}

export const createMyclientsServiceCategory = createAsyncAction(
  'MYCLIENTS_CREATE_SERVICE_CATEGORY_REQUEST',
  'MYCLIENTS_CREATE_SERVICE_CATEGORY_SUCCESS',
  'MYCLIENTS_CREATE_SERVICE_CATEGORY_FAIL',
)();

export function createMyclientsServiceCategoryAsync(data) {
  return (dispatch) => {
    dispatch(createMyclientsServiceCategory.request());

    ApiClient.myclients.createServiceCategory(data)
      .then((response) => dispatch(createMyclientsServiceCategory.success(response, data)))
      .catch((error) => dispatch(createMyclientsServiceCategory.failure(error)));
  };
}

export const updateMyclientsServiceCategory = createAsyncAction(
  'MYCLIENTS_UPDATE_SERVICE_CATEGORY_REQUEST',
  'MYCLIENTS_UPDATE_SERVICE_CATEGORY_SUCCESS',
  'MYCLIENTS_UPDATE_SERVICE_CATEGORY_FAIL',
)();

export function updateMyclientsServiceCategoryAsync(data) {
  return (dispatch) => {
    dispatch(updateMyclientsServiceCategory.request());

    ApiClient.myclients.updateServiceCategory(data)
      .then((response) => dispatch(updateMyclientsServiceCategory.success(response, data)))
      .catch((error) => dispatch(updateMyclientsServiceCategory.failure(error)));
  };
}

export const deleteMyclientsServiceCategory = createAsyncAction(
  'MYCLIENTS_DELETE_SERVICE_CATEGORY_REQUEST',
  'MYCLIENTS_DELETE_SERVICE_CATEGORY_SUCCESS',
  'MYCLIENTS_DELETE_SERVICE_CATEGORY_FAIL',
)();

export function deleteMyclientsServiceCategoryAsync(categoryId, projectId) {
  return (dispatch) => {
    dispatch(deleteMyclientsServiceCategory.request());

    ApiClient.myclients.deleteServiceCategory(categoryId, projectId)
      .then((response) => dispatch(deleteMyclientsServiceCategory.success(response, { categoryId, projectId })))
      .catch((error) => dispatch(deleteMyclientsServiceCategory.failure(error)));
  };
}

export const getMyclientsLocations = createAsyncAction(
  'MYCLIENTS_GET_LOCATIONS_REQUEST',
  'MYCLIENTS_GET_LOCATIONS_SUCCESS',
  'MYCLIENTS_GET_LOCATIONS_FAIL',
)();

export function getMyclientsLocationsAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsLocations.request());

    ApiClient.myclients.getLocations(params)
      .then((response) => dispatch(getMyclientsLocations.success(response, params)))
      .catch((error) => dispatch(getMyclientsLocations.failure(error)));
  };
}

export const getMyclientsShortLocations = createAsyncAction(
  'MYCLIENTS_GET_SHORT_LOCATIONS_REQUEST',
  'MYCLIENTS_GET_SHORT_LOCATIONS_SUCCESS',
  'MYCLIENTS_GET_SHORT_LOCATIONS_FAIL',
)();

export function getMyclientsShortLocationsAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsShortLocations.request());

    ApiClient.myclients.getShortLocations(params)
      .then((response) => dispatch(getMyclientsShortLocations.success(response, params)))
      .catch((error) => dispatch(getMyclientsShortLocations.failure(error)));
  };
}

export const createMyclientsLocation = createAsyncAction(
  'MYCLIENTS_CREATE_LOCATION_REQUEST',
  'MYCLIENTS_CREATE_LOCATION_SUCCESS',
  'MYCLIENTS_CREATE_LOCATION_FAIL',
)();

export function createMyclientsLocationAsync(data) {
  return (dispatch, getState) => {
    dispatch(createMyclientsLocation.request());

    ApiClient.myclients.createLocation(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('locations.create-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(createMyclientsLocation.success(response, data));
      })
      .catch((error) => dispatch(createMyclientsLocation.failure(error)));
  };
}

export const updateMyclientsLocation = createAsyncAction(
  'MYCLIENTS_UPDATE_LOCATION_REQUEST',
  'MYCLIENTS_UPDATE_LOCATION_SUCCESS',
  'MYCLIENTS_UPDATE_LOCATION_FAIL',
)();

export function updateMyclientsLocationAsync(data, isRestore) {
  return (dispatch, getState) => {
    dispatch(updateMyclientsLocation.request());

    ApiClient.myclients.updateLocation(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t(`locations.${isRestore ? 'restore-message' : 'edit-message'}`), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateMyclientsLocation.success(response, data));
      })
      .catch((error) => dispatch(updateMyclientsLocation.failure(error)));
  };
}

export const archiveMyclientsLocation = createAsyncAction(
  'MYCLIENTS_ARCHIVE_LOCATION_REQUEST',
  'MYCLIENTS_ARCHIVE_LOCATION_SUCCESS',
  'MYCLIENTS_ARCHIVE_LOCATION_FAIL',
)();

export function archiveMyclientsLocationAsync(locationId) {
  return (dispatch, getState) => {
    const projectId = getState().app.currentCompanyId;
    dispatch(archiveMyclientsLocation.request());

    ApiClient.myclients.archiveLocation(locationId, { projectId })
      .then((response) => {
        const notifications = [{ type: 'success', message: t('locations.archive-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(archiveMyclientsLocation.success(response));
      })
      .catch((error) => dispatch(archiveMyclientsLocation.failure(error)));
  };
}

export const getMyclientsEmployees = createAsyncAction(
  'MYCLIENTS_GET_EMPLOYEES_REQUEST',
  'MYCLIENTS_GET_EMPLOYEES_SUCCESS',
  'MYCLIENTS_GET_EMPLOYEES_FAIL',
)();

export function getMyclientsEmployeesAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsEmployees.request());

    ApiClient.myclients.getEmployees(params)
      .then((response) => dispatch(getMyclientsEmployees.success(response, params)))
      .catch((error) => dispatch(getMyclientsEmployees.failure(error)));
  };
}

export const getMyclientsShortEmployees = createAsyncAction(
  'MYCLIENTS_GET_SHORT_EMPLOYEES_REQUEST',
  'MYCLIENTS_GET_SHORT_EMPLOYEES_SUCCESS',
  'MYCLIENTS_GET_SHORT_EMPLOYEES_FAIL',
)();

export function getMyclientsShortEmployeesAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsShortEmployees.request());

    ApiClient.myclients.getShortEmployees(params)
      .then((response) => dispatch(getMyclientsShortEmployees.success(response, params)))
      .catch((error) => dispatch(getMyclientsShortEmployees.failure(error)));
  };
}

export const createMyclientsEmployee = createAsyncAction(
  'MYCLIENTS_CREATE_EMPLOYEE_REQUEST',
  'MYCLIENTS_CREATE_EMPLOYEE_SUCCESS',
  'MYCLIENTS_CREATE_EMPLOYEE_FAIL',
)();

export function createMyclientsEmployeeAsync(data) {
  return (dispatch, getState) => {
    dispatch(createMyclientsEmployee.request());

    ApiClient.myclients.createEmployee(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('employees.create-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(createMyclientsEmployee.success(response, data));
      })
      .catch((error) => dispatch(createMyclientsEmployee.failure(error)));
  };
}

export const updateMyclientsEmployee = createAsyncAction(
  'MYCLIENTS_UPDATE_EMPLOYEE_REQUEST',
  'MYCLIENTS_UPDATE_EMPLOYEE_SUCCESS',
  'MYCLIENTS_UPDATE_EMPLOYEE_FAIL',
)();

export function updateMyclientsEmployeeAsync(data, isRestore) {
  return (dispatch, getState) => {
    dispatch(updateMyclientsEmployee.request());

    ApiClient.myclients.updateEmployee(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t(`employees.${isRestore ? 'restore-message' : 'edit-message'}`), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateMyclientsEmployee.success(response, data));
      })
      .catch((error) => dispatch(updateMyclientsEmployee.failure(error)));
  };
}

export const archiveMyclientsEmployee = createAsyncAction(
  'MYCLIENTS_ARCHIVE_EMPLOYEE_REQUEST',
  'MYCLIENTS_ARCHIVE_EMPLOYEE_SUCCESS',
  'MYCLIENTS_ARCHIVE_EMPLOYEE_FAIL',
)();

export function archiveMyclientsEmployeeAsync(employeeId) {
  return (dispatch, getState) => {
    const projectId = getState().app.currentCompanyId;
    dispatch(archiveMyclientsEmployee.request());

    ApiClient.myclients.archiveEmployee(employeeId, { projectId })
      .then((response) => {
        const notifications = [{ type: 'success', message: t('employees.archive-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(archiveMyclientsEmployee.success(response));
      })
      .catch((error) => dispatch(archiveMyclientsEmployee.failure(error)));
  };
}

export const getMyclientsSchedules = createAsyncAction(
  'MYCLIENTS_GET_SCHEDULES_REQUEST',
  'MYCLIENTS_GET_SCHEDULES_SUCCESS',
  'MYCLIENTS_GET_SCHEDULES_FAIL',
)();

export function getMyclientsSchedulesAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsSchedules.request());

    ApiClient.myclients.getSchedules(params)
      .then((response) => dispatch(getMyclientsSchedules.success(response, params)))
      .catch((error) => dispatch(getMyclientsSchedules.failure(error)));
  };
}

export const createMyclientsSchedule = createAsyncAction(
  'MYCLIENTS_CREATE_SCHEDULE_REQUEST',
  'MYCLIENTS_CREATE_SCHEDULE_SUCCESS',
  'MYCLIENTS_CREATE_SCHEDULE_FAIL',
)();

export function createMyclientsScheduleAsync(data) {
  return (dispatch, getState) => {
    dispatch(createMyclientsSchedule.request());

    ApiClient.myclients.createSchedule(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('schedule.create-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(createMyclientsSchedule.success(response, data));
      })
      .catch((error) => dispatch(createMyclientsSchedule.failure(error)));
  };
}

export const updateMyclientsSchedule = createAsyncAction(
  'MYCLIENTS_UPDATE_SCHEDULE_REQUEST',
  'MYCLIENTS_UPDATE_SCHEDULE_SUCCESS',
  'MYCLIENTS_UPDATE_SCHEDULE_FAIL',
)();

export function updateMyclientsScheduleAsync(data) {
  return (dispatch, getState) => {
    dispatch(updateMyclientsSchedule.request());

    ApiClient.myclients.updateSchedule(data)
      .then((response) => {
        const notifications = [{ type: 'success', message: t('schedule.edit-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(updateMyclientsSchedule.success(response, data));
      })
      .catch((error) => dispatch(updateMyclientsSchedule.failure(error)));
  };
}

export const archiveMyclientsScheduleByEntity = createAsyncAction(
  'MYCLIENTS_ARCHIVE_SCHEDULE_BY_ENTITY_REQUEST',
  'MYCLIENTS_ARCHIVE_SCHEDULE_BY_ENTITY_SUCCESS',
  'MYCLIENTS_ARCHIVE_SCHEDULE_BY_ENTITY_FAIL',
)();

export function archiveScheduleByEntityAsync(params) {
  return (dispatch) => {
    dispatch(archiveMyclientsScheduleByEntity.request());

    ApiClient.myclients.archiveScheduleByEntity(params)
      .then((response) => dispatch(archiveMyclientsScheduleByEntity.success(response, params)))
      .catch((error) => dispatch(archiveMyclientsScheduleByEntity.failure(error)));
  };
}

export const getMyclientsWidgets = createAsyncAction(
  'MYCLIENTS_GET_WIDGETS_REQUEST',
  'MYCLIENTS_GET_WIDGETS_SUCCESS',
  'MYCLIENTS_GET_WIDGETS_FAIL',
)();

export function getMyclientsWidgetsAsync(params) {
  return (dispatch) => {
    dispatch(getMyclientsWidgets.request());

    ApiClient.myclients.getWidgets(params)
      .then((response) => dispatch(getMyclientsWidgets.success(response, params)))
      .catch((error) => dispatch(getMyclientsWidgets.failure(error)));
  };
}

export const createMyclientsWidget = createAsyncAction(
  'MYCLIENTS_CREATE_WIDGET_REQUEST',
  'MYCLIENTS_CREATE_WIDGET_SUCCESS',
  'MYCLIENTS_CREATE_WIDGET_FAIL',
)();

export function createMyclientsWidgetAsync(data) {
  return (dispatch) => {
    dispatch(createMyclientsWidget.request());

    ApiClient.myclients.createWidget(data)
      .then((response) => dispatch(createMyclientsWidget.success(response, { data })))
      .catch((error) => dispatch(createMyclientsWidget.failure(error)));
  };
}

export const getMyclientsTmpUrl = createAsyncAction(
  'MYCLIENTS_GET_TMP_URL_REQUEST',
  'MYCLIENTS_GET_TMP_URL_SUCCESS',
  'MYCLIENTS_GET_TMP_URL_FAIL',
)();

export function getMyclientsTmpUrlAsync({ alias, notificationsEmail, prepayment, ...data }) {
  return (dispatch) => {
    dispatch(getMyclientsTmpUrl.request());

    ApiClient.myclients.createWidget({ ...data, isTemp: true })
      .then((response) => dispatch(getMyclientsTmpUrl.success(response, { data })))
      .catch((error) => dispatch(getMyclientsTmpUrl.failure(error)));
  };
}

export const updateMyclientsWidget = createAsyncAction(
  'MYCLIENTS_UPDATE_WIDGET_REQUEST',
  'MYCLIENTS_UPDATE_WIDGET_SUCCESS',
  'MYCLIENTS_UPDATE_WIDGET_FAIL',
)();

export function updateMyclientsWidgetAsync(data) {
  return (dispatch) => {
    dispatch(updateMyclientsWidget.request());

    ApiClient.myclients.updateWidget(data)
      .then((response) => dispatch(updateMyclientsWidget.success(response, { data })))
      .catch((error) => dispatch(updateMyclientsWidget.failure(error)));
  };
}

export const deleteMyclientsWidget = createAsyncAction(
  'MYCLIENTS_DELETE_EMPLOYEE_REQUEST',
  'MYCLIENTS_DELETE_EMPLOYEE_SUCCESS',
  'MYCLIENTS_DELETE_EMPLOYEE_FAIL',
)();

export function deleteMyclientsWidgetAsync(widgetId) {
  return (dispatch, getState) => {
    const projectId = getState().app.currentCompanyId;
    dispatch(deleteMyclientsWidget.request());

    ApiClient.myclients.deleteWidget(widgetId, { projectId })
      .then((response) => {
        const notifications = [{ type: 'success', message: t('widgets.delete-message'), icon: true, timeout: 5000, tag: 'myclients' }];
        dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, getState(), readUserNotificationAsync)));
        dispatch(deleteMyclientsWidget.success(response));
      })
      .catch((error) => dispatch(deleteMyclientsWidget.failure(error)));
  };
}

export const checkMyclientsWidgetAlias = createAsyncAction(
  'MYCLIENTS_CHECK_WIDGET_ALIAS_REQUEST',
  'MYCLIENTS_CHECK_WIDGET_ALIAS_SUCCESS',
  'MYCLIENTS_CHECK_WIDGET_ALIAS_FAIL',
)();

export function checkMyclientsWidgetAliasAsync(data) {
  return (dispatch) => {
    dispatch(checkMyclientsWidgetAlias.request());

    ApiClient.myclients.checkWidgetAlias(data)
      .then((response) => dispatch(checkMyclientsWidgetAlias.success(response, { data })))
      .catch((error) => dispatch(checkMyclientsWidgetAlias.failure(error)));
  };
}

export const setMountedWidgetStages = createAction('MYCLIENTS_SET_MOUNTED_WIDGET_STAGES')();
