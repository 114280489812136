import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getCdekShipmentPoints = createAsyncAction(
  'CDEK_GET_CDEK_SHIPMENT_POINTS_REQUEST',
  'CDEK_GET_CDEK_SHIPMENT_POINTS_SUCCESS',
  'CDEK_GET_CDEK_SHIPMENT_POINTS_FAIL',
)();

export function getCdekShipmentPointsAsync(cityId) {
  return (dispatch) => {
    dispatch(getCdekShipmentPoints.request());

    const params = { cityId, type: 'PVZ', isReception: true };

    ApiClient.cdek.getCdekShipmentPoints(params)
      .then((response) => dispatch(getCdekShipmentPoints.success(response, params)))
      .catch((error) => dispatch(getCdekShipmentPoints.failure(error)));
  };
}
