import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getRules = createAsyncAction(
  'BLACKLIST_GET_RULES_REQUEST',
  'BLACKLIST_GET_RULES_SUCCESS',
  'BLACKLIST_GET_RULES_FAIL',
)();

export function getRulesAsync(params) {
  return (dispatch) => {
    dispatch(getRules.request());

    ApiClient.blacklist.getRules(params)
      .then((data) => dispatch(getRules.success(data)))
      .catch((error) => dispatch(getRules.failure(error)));
  };
}

export const getRuleById = createAsyncAction(
  'BLACKLIST_GET_RULE_BY_ID_REQUEST',
  'BLACKLIST_GET_RULE_BY_ID_SUCCESS',
  'BLACKLIST_GET_RULE_BY_ID_FAIL',
)();

export function getRuleByIdAsync(id) {
  return (dispatch) => {
    dispatch(getRuleById.request());

    ApiClient.blacklist.getRuleById(id)
      .then((data) => dispatch(getRuleById.success(data)))
      .catch((error) => dispatch(getRuleById.failure(error)));
  };
}

export const createRule = createAsyncAction(
  'BLACKLIST_CREATE_RULE_REQUEST',
  'BLACKLIST_CREATE_RULE_SUCCESS',
  'BLACKLIST_CREATE_RULE_FAIL',
)();

export function createRuleAsync(model) {
  return (dispatch) => {
    dispatch(createRule.request());

    ApiClient.blacklist.createRule(model)
      .then((data) => dispatch(createRule.success(data)))
      .catch((error) => dispatch(createRule.failure(error)));
  };
}

export const updateRule = createAsyncAction(
  'BLACKLIST_UPDATE_RULE_REQUEST',
  'BLACKLIST_UPDATE_RULE_SUCCESS',
  'BLACKLIST_UPDATE_RULE_FAIL',
)();

export function updateRuleAsync(id, model) {
  return (dispatch) => {
    dispatch(updateRule.request());

    ApiClient.blacklist.updateRule(id, model)
      .then((data) => dispatch(updateRule.success(data)))
      .catch((error) => dispatch(updateRule.failure(error)));
  };
}
