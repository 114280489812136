import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useEntity(id, selector, action) {
  const [timestamp, updateTimestamp] = useState(null);
  const dispatch = useDispatch();
  const map = useSelector(selector);
  const entity = map[id];
  const refresh = useCallback(() => updateTimestamp(Date.now()), []);

  useEffect(
    () => {
      if (id === 'new') return;

      if (!entity || timestamp !== null) {
        dispatch(action(id));
      }
    },
    [id, timestamp],
  );

  if (id === 'new') return undefined;

  return [entity, refresh];
}

export default useEntity;
