import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/users';
import * as authActions from '../actions/auth';
import * as companiesActions from '../actions/companies';
import * as analyticsActions from '../actions/analytics';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const currentUserReducer = createReducer(null)
  .handleAction(actions.getUser.success, (state, { payload: user }) => ({
    ...user,
    supportMonitoringSettings: user.supportMonitoringSettings?.reduce((acc, item) => ({
      ...acc,
      [item.widgetId]: item,
    }), {}),
    isCrmAdmin: user.roles.some((role) => role.toLowerCase() === 'crmadmin'),
    isPrimaryOwner: user.roles.some((role) => role.toLowerCase() === 'primaryowner'),
    isTrustedApi: user.roles.some((role) => role.toLowerCase() === 'trustedapi'),
  }))
  .handleAction(authActions.unauthorized, () => null)
  .handleAction(companiesActions.createProject.success, (state, { meta }) => ({
    ...state,
    companies: [...state.companies, meta.id],
  }))
  .handleAction(actions.updateCurrentUser.success, (state, { meta: updatedUserData }) => ({
    ...state,
    ...updatedUserData,
  }))
  .handleAction(actions.skipQuickStart.success, (state) => ({ ...state, guideFinishedAt: 0 }))
  .handleAction(actions.finishOnboarding.success, (state) => ({ ...state, onboardingFinishedAt: 0 }))
  .handleAction(analyticsActions.deleteChart.success, (state, { meta }) => {
    const deletedWidgetId = meta.widgetId;
    const { [deletedWidgetId]: deletedWidget, ...restWidgets } = state.supportMonitoringSettings;
    return {
      ...state,
      supportMonitoringSettings: restWidgets,
    };
  })
  .handleAction(analyticsActions.updateChart.success, (state, { payload }) => ({
    ...state,
    supportMonitoringSettings: {
      ...state.supportMonitoringSettings,
      [payload.widgetId]: {
        ...payload,
      },
    },
  }));

const dataReducer = createReducer({})
  .handleAction(actions.getUsers.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.users),
  }))
  .handleAction(
    [
      actions.getUserById.success,
      actions.createUser.success,
      actions.updateUser.success,
    ],
    (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  );

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getUsers.success, (state, { payload }) => ({
    ids: payload.users.map((user) => user.id),
    totalCount: payload.totalCount,
  }))
  .handleAction(actions.createUser.success, (state, { payload }) => ({
    ids: [...state.ids, payload.id],
    totalCount: state.totalCount + 1,
  }));

const userInvitesByIdReducer = createReducer({})
  .handleAction(actions.getUserInvites.success, (state, { payload }) => normalize(payload));

const availableRolesReducer = createReducer([])
  .handleAction(actions.getAvailableUserRoles.success, (state, { payload }) => payload);

const justInvitedUsersReducer = createReducer([])
  .handleAction(actions.inviteUsers.success, (state, { payload }) => payload);

const loginHistoryReducer = createReducer({ logins: [], totalCount: 0 })
  .handleAction(actions.getLoginHistory.success, (state, { payload }) => payload);

const referrerUrlsReducer = createReducer({})
  .handleAction(actions.getReferrerUrls.success, (state, { payload }) => payload.urls || {})
  .handleAction(actions.getUser.success, (state, { payload }) => ({
    ...state,
    ...payload.referrerUrls,
  }))
  .handleAction(actions.getUser.failure, (state, { payload }) => ({
    ...state,
    ...payload.info,
  }));

export default combineReducers({
  currentUser: currentUserReducer,
  byId: dataReducer,
  table: tableReducer,
  userInvitesById: userInvitesByIdReducer,
  availableRoles: availableRolesReducer,
  justInvitedUsers: justInvitedUsersReducer,
  loginHistory: loginHistoryReducer,
  referrerUrls: referrerUrlsReducer,
  currentUserFetchStatus: asyncReducerFactory(actions.getUser),
  fetchStatus: asyncReducerFactory(actions.getUsers),
  fetchByIdStatus: asyncReducerFactory(actions.getUserById),
  createStatus: asyncReducerFactory(actions.createUser),
  updateStatus: asyncReducerFactory(actions.updateUser),
  getUserInvitesStatus: asyncReducerFactory(actions.getUserInvites),
  inviteUsersStatus: asyncReducerFactory(actions.inviteUsers),
  getAvailableUserRolesStatus: asyncReducerFactory(actions.getAvailableUserRoles),
  inviteUserAgainStatus: asyncReducerFactory(actions.inviteUserAgain),
  deleteUserInviteStatus: asyncReducerFactory(actions.deleteUserInvite),
  validateMFAStatus: asyncReducerFactory(actions.validateMFA),
  disableMFAStatus: asyncReducerFactory(actions.disableMFA),
  updateCurrentUserStatus: asyncReducerFactory(actions.updateCurrentUser),
  getLoginHistoryStatus: asyncReducerFactory(actions.getLoginHistory),
  skipQuickStartStatus: asyncReducerFactory(actions.skipQuickStart),
});
