import useGlobalMemo from './useGlobalMemo';
import useSources from './useSources';

function useSourcesFilter() {
  const sources = useSources();

  return useGlobalMemo(
    'useSourcesFilter',
    () => ({
      id: 'sources',
      label: 'Источники средств',
      items: sources.map((source) => ({
        id: source.id,
        label: source.fullName || source.id,
      })),
    }),
    [sources],
  );
}

export default useSourcesFilter;
