import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorFallback from '../ErrorFallback';

class ErrorBoundary extends Component {
  static propTypes = {
    errorFallback: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    errorFallback: <ErrorFallback />,
  };

  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { errorFallback, className, children } = this.props;

    const errorFallbackWithProps = React.cloneElement(errorFallback, { error, className });

    if (error) {
      return errorFallbackWithProps;
    }

    return children;
  }
}

export default ErrorBoundary;
