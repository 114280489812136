import { createAsyncAction } from 'typesafe-actions';
import i18n from '@i18n';
import { ApiClient } from '../utils/api-client';
import { transliterate } from '../utils/format';
import getFingerprint from '../utils/getFingerprint';
import { getNewUserNotificationsNotification } from '../utils/notification-utils';
import { createTableAction } from '../utils/redux-utils';
import { showNotification } from './app';
import { getCompanyInfoAsync } from './companies';
import { readUserNotificationAsync } from './notifications';

const t = (key) => i18n.t(key, { ns: 'common' }); //TODO don't forget expand ns as array if necessary

export const getBoxretailNotifications = createAsyncAction(
  'BOXRETAIL_NOTIFICATIONS_GET_REQUEST',
  'BOXRETAIL_NOTIFICATIONS_GET_SUCCESS',
  'BOXRETAIL_NOTIFICATIONS_GET_FAIL',
)();

export const boxretailNotificationsTable = createTableAction(
  'BOXRETAIL_NOTIFICATIONS_TABLE_ADD',
  'BOXRETAIL_NOTIFICATIONS_TABLE_PREV',
  'BOXRETAIL_NOTIFICATIONS_TABLE_NEXT',
  'BOXRETAIL_NOTIFICATIONS_TABLE_FIRST',
);

export function getBoxretailNotificationsAsync(params, append) {
  return (dispatch) => {
    dispatch(getBoxretailNotifications.request());

    ApiClient.boxretail.notifications.get(params)
      .then((data) => {
        const { notifications, hasMore } = data;
        const meta = { append };
        const ids = notifications.map((item) => item.id);

        dispatch(getBoxretailNotifications.success(notifications, meta));
        dispatch(boxretailNotificationsTable.add({ ids, hasMore }, meta));
      })
      .catch((error) => dispatch(getBoxretailNotifications.failure(error)));
  };
}

export const createBoxretailNotification = createAsyncAction(
  'BOXRETAIL_NOTIFICATIONS_CREATE_REQUEST',
  'BOXRETAIL_NOTIFICATIONS_CREATE_SUCCESS',
  'BOXRETAIL_NOTIFICATIONS_CREATE_FAIL',
)();

export function createBoxretailNotificationAsync(notification) {
  return (dispatch) => {
    dispatch(createBoxretailNotification.request());

    ApiClient.boxretail.notifications.create(notification)
      .then((data) => dispatch(createBoxretailNotification.success(data)))
      .catch((error) => dispatch(createBoxretailNotification.failure(error)));
  };
}

export const removeBoxretailNotification = createAsyncAction(
  'BOXRETAIL_NOTIFICATIONS_REMOVE_REQUEST',
  'BOXRETAIL_NOTIFICATIONS_REMOVE_SUCCESS',
  'BOXRETAIL_NOTIFICATIONS_REMOVE_FAIL',
)();

export function removeBoxretailNotificationAsync(id) {
  return (dispatch) => {
    dispatch(removeBoxretailNotification.request());

    ApiClient.boxretail.notifications.remove(id)
      .then((data) => dispatch(removeBoxretailNotification.success(data)))
      .catch((error) => dispatch(removeBoxretailNotification.failure(error)));
  };
}

export const saveBoxretailServices = createAsyncAction(
  'BOXRETAIL_SERVICES_SAVE_REQUEST',
  'BOXRETAIL_SERVICES_SAVE_SUCCESS',
  'BOXRETAIL_SERVICES_SAVE_FAIL',
)();

export function saveBoxretailServicesAsync(model) {
  return (dispatch) => {
    dispatch(saveBoxretailServices.request());

    ApiClient.boxretail.services.save(model)
      .then((data) => dispatch(saveBoxretailServices.success(data)))
      .catch((error) => dispatch(saveBoxretailServices.failure(error)));
  };
}

export const sendServicesContractsRequest = createAsyncAction(
  'BOXRETAIL_SERVICES_SEND_CONTRACT_REQUEST_REQUEST',
  'BOXRETAIL_SERVICES_SEND_CONTRACT_REQUEST_SUCCESS',
  'BOXRETAIL_SERVICES_SEND_CONTRACT_REQUEST_FAIL',
)();

export function sendServicesContractsRequestAsync(model) {
  return (dispatch) => {
    dispatch(sendServicesContractsRequest.request());

    ApiClient.boxretail.services.sendContractsRequest(model)
      .then((data) => dispatch(sendServicesContractsRequest.success(data)))
      .catch((error) => dispatch(sendServicesContractsRequest.failure(error)));
  };
}

export const getBoxretailServicesContractsRequests = createAsyncAction(
  'BOXRETAIL_SERVICES_GET_CONTRACTS_REQUESTS_REQUEST',
  'BOXRETAIL_SERVICES_GET_CONTRACTS_REQUESTS_SUCCESS',
  'BOXRETAIL_SERVICES_GET_CONTRACTS_REQUESTS_FAIL',
)();

export function getBoxretailServicesContractsRequestsAsync(params) {
  return (dispatch) => {
    dispatch(getBoxretailServicesContractsRequests.request());

    ApiClient.boxretail.services.getContractsRequests(params)
      .then((data) => dispatch(getBoxretailServicesContractsRequests.success(data, params)))
      .catch((error) => dispatch(getBoxretailServicesContractsRequests.failure(error)));
  };
}

export const sendBoxretailServicesContractsFiles = createAsyncAction(
  'BOXRETAIL_SERVICES_SEND_CONTRACTS_FILES_REQUEST',
  'BOXRETAIL_SERVICES_SEND_CONTRACTS_FILES_SUCCESS',
  'BOXRETAIL_SERVICES_SEND_CONTRACTS_FILES_FAIL',
)();

export function sendBoxretailServicesContractsFilesAsync(requestsWithNewContractPhotocopies) {
  return (dispatch) => {
    dispatch(sendBoxretailServicesContractsFiles.request());

    ApiClient.boxretail.services.sendContractFiles(requestsWithNewContractPhotocopies)
      .then((responses) => dispatch(sendBoxretailServicesContractsFiles.success(responses)))
      .catch((error) => dispatch(sendBoxretailServicesContractsFiles.failure(error)));
  };
}

export const acceptBoxretailServicesContractsRequest = createAsyncAction(
  'BOXRETAIL_SERVICES_ACCEPT_CONTRACTS_REQUEST_REQUEST',
  'BOXRETAIL_SERVICES_ACCEPT_CONTRACTS_REQUEST_SUCCESS',
  'BOXRETAIL_SERVICES_ACCEPT_CONTRACTS_REQUEST_FAIL',
)();

export function acceptBoxretailServicesContractsRequestAsync({ companyId, ...model }) {
  return (dispatch) => {
    dispatch(acceptBoxretailServicesContractsRequest.request());

    ApiClient.boxretail.services.acceptContractsRequest(model)
      .then((data) => {
        if (model.status === 'accepted') {
          const notification = {
            type: 'success',
            message: 'Запрос документов успешно акцептован',
            timeout: 5000,
          };

          dispatch(showNotification(notification));
        }
        dispatch(acceptBoxretailServicesContractsRequest.success(data, { companyId, ...model }));
        dispatch(getCompanyInfoAsync(companyId)); // to update serviceMerchants field
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptBoxretailServicesContractsRequest.failure(error));
      });
  };
}

export const updateTaxInfo = createAsyncAction(
  'BOXRETAIL_UPDATE_TAX_INFO_REQUEST',
  'BOXRETAIL_UPDATE_TAX_INFO_SUCCESS',
  'BOXRETAIL_UPDATE_TAX_INFO_FAIL',
)();

export function updateTaxInfoAsync(data, outletId) {
  return (dispatch) => {
    dispatch(updateTaxInfo.request());

    ApiClient.boxretail.updateTaxInfo(data)
      .then((response) => {
        const notification = {
          type: 'success',
          message: t('settings-saved'),
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(updateTaxInfo.success(response, { ...data, outletId }));
      })
      .catch((error) => dispatch(updateTaxInfo.failure(error)));
  };
}

export const acceptBoxretailOutlet = createAsyncAction(
  'BOXRETAIL_ACCEPT_OUTLET_REQUEST',
  'BOXRETAIL_ACCEPT_OUTLET_SUCCESS',
  'BOXRETAIL_ACCEPT_OUTLET_FAIL',
)();

export function acceptBoxretailOutletAsync(model, { password, isActionFromCrm = false } = {}) {
  return (dispatch, getState) => {
    dispatch(acceptBoxretailOutlet.request());

    const dataToSend = { ...model };

    if (password) {
      dataToSend.password = password;
    }

    const state = getState();
    const { companies: { outletsByCompany }, app: { currentCompanyId } } = state;
    const prevOutletStatus = outletsByCompany[currentCompanyId]?.[model.id].status;
    const fingerPrint = transliterate(JSON.stringify(getFingerprint()));

    ApiClient.boxretail.acceptOutlet(dataToSend, fingerPrint)
      .then((data) => {
        let message;

        if (model.status === 'accepted') {
          const restoredOrAcceptedMessage = prevOutletStatus === 'blocked' ? 'Точка продаж восстановлена' : 'Точка успешно акцептована';
          const crmMessage = prevOutletStatus === 'accepted' ? 'Подключение точки продаж обновлено' : restoredOrAcceptedMessage;
          const regularMessage = 'Точка продаж восстановлена';
          message = isActionFromCrm ? crmMessage : regularMessage;
        } else if (model.status === 'blocked') {
          message = 'Точка продаж заблокирована';
        }

        if (message) {
          const notifications = [{ type: 'success', message, icon: true, timeout: 5000, tag: 'boxretail' }];
          dispatch(showNotification(getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync)));
        }

        dispatch(acceptBoxretailOutlet.success(data, model));
      })
      .catch((error) => {
        dispatch(showNotification({ type: 'error', message: 'Ошибка при выполнении запроса', timeout: 5000 }));
        dispatch(acceptBoxretailOutlet.failure(error));
      });
  };
}

export const setOrderNotifications = createAsyncAction(
  'BOXRETAIL_SET_ORDER_NOTIFICATIONS_REQUEST',
  'BOXRETAIL_SET_ORDER_NOTIFICATIONS_SUCCESS',
  'BOXRETAIL_SET_ORDER_NOTIFICATIONS_FAIL',
)();

export function setOrderNotificationsAsync({ companyId, ...data }) {
  return (dispatch) => {
    dispatch(setOrderNotifications.request());

    ApiClient.boxretail.setOrderNotifications(data)
      .then((response) => dispatch(setOrderNotifications.success(response, { companyId, ...data })))
      .catch((error) => dispatch(setOrderNotifications.failure(error)));
  };
}
