import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../../utils/api-client';

export const getAwizaPromocodes = createAsyncAction(
  'AWIZA_GET_PROMOCODES_REQUEST',
  'AWIZA_GET_PROMOCODES_SUCCESS',
  'AWIZA_GET_PROMOCODES_FAIL',
)();

export function getAwizaPromocodesAsync(params) {
  return (dispatch) => {
    dispatch(getAwizaPromocodes.request());

    ApiClient.awiza.promotions.getPromocodes(params)
      .then((response) => dispatch(getAwizaPromocodes.success(response, params)))
      .catch((error) => dispatch(getAwizaPromocodes.failure(error)));
  };
}

export const getAwizaPromocodeById = createAsyncAction(
  'AWIZA_GET_PROMOCODE_BY_ID_REQUEST',
  'AWIZA_GET_PROMOCODE_BY_ID_SUCCESS',
  'AWIZA_GET_PROMOCODE_BY_ID_FAIL',
)();

export function getAwizaPromocodeByIdAsync(params) {
  return (dispatch) => {
    dispatch(getAwizaPromocodeById.request());

    ApiClient.awiza.promotions.getPromocodeById(params)
      .then((response) => dispatch(getAwizaPromocodeById.success(response, params)))
      .catch((error) => dispatch(getAwizaPromocodeById.failure(error)));
  };
}

export const createAwizaPromocode = createAsyncAction(
  'AWIZA_CREATE_PROMOCODE_REQUEST',
  'AWIZA_CREATE_PROMOCODE_SUCCESS',
  'AWIZA_CREATE_PROMOCODE_FAIL',
)();

export function createAwizaPromocodeAsync(params) {
  return (dispatch) => {
    dispatch(createAwizaPromocode.request());

    ApiClient.awiza.promotions.createPromocode(params)
      .then((response) => dispatch(createAwizaPromocode.success(response, params)))
      .catch((error) => dispatch(createAwizaPromocode.failure(error)));
  };
}

export const updateAwizaPromocode = createAsyncAction(
  'AWIZA_UPDATE_PROMOCODE_REQUEST',
  'AWIZA_UPDATE_PROMOCODE_SUCCESS',
  'AWIZA_UPDATE_PROMOCODE_FAIL',
)();

export function updateAwizaPromocodeAsync(params) {
  return (dispatch) => {
    dispatch(updateAwizaPromocode.request());

    ApiClient.awiza.promotions.updatePromocode(params)
      .then((response) => dispatch(updateAwizaPromocode.success(response, params)))
      .catch((error) => dispatch(updateAwizaPromocode.failure(error)));
  };
}

export const updatePartPromocode = createAsyncAction(
  'PROMOTIONS_UPDATE_PART_PROMOCODE_REQUEST',
  'PROMOTIONS_UPDATE_PART_PROMOCODE_SUCCESS',
  'PROMOTIONS_UPDATE_PART_PROMOCODE_FAIL',
)();

export function updatePartPromocodeAsync(data) {
  return (dispatch) => {
    dispatch(updatePartPromocode.request());

    ApiClient.awiza.promotions.updatePartPromocode(data)
      .then((response) => dispatch(updatePartPromocode.success(response, data)))
      .catch((error) => dispatch(updatePartPromocode.failure(error)));
  };
}

export const deletePromocode = createAsyncAction(
  'PROMOTIONS_DELETE_PROMOCODE_REQUEST',
  'PROMOTIONS_DELETE_PROMOCODE_SUCCESS',
  'PROMOTIONS_DELETE_PROMOCODE_FAIL',
)();

export function deletePromocodeAsync(data) {
  return (dispatch) => {
    dispatch(deletePromocode.request());

    ApiClient.awiza.promotions.deletePromocode(data)
      .then((response) => dispatch(deletePromocode.success(response, data)))
      .catch((error) => dispatch(deletePromocode.failure(error)));
  };
}

export const checkPromocode = createAsyncAction(
  'PROMOTIONS_CHECK_PROMOCODE_REQUEST',
  'PROMOTIONS_CHECK_PROMOCODE_SUCCESS',
  'PROMOTIONS_CHECK_PROMOCODE_FAIL',
)();

export function checkPromocodeAsync(params) {
  return (dispatch) => {
    dispatch(checkPromocode.request(params));

    ApiClient.awiza.promotions.checkPromocode(params)
      .then((response) => dispatch(checkPromocode.success(response, params)))
      .catch((error) => dispatch(checkPromocode.failure(error, params)));
  };
}

export const getAwizaDiscounts = createAsyncAction(
  'AWIZA_GET_DISCOUNTS_REQUEST',
  'AWIZA_GET_DISCOUNTS_SUCCESS',
  'AWIZA_GET_DISCOUNTS_FAIL',
)();

export function getAwizaDiscountsAsync(params) {
  return (dispatch) => {
    dispatch(getAwizaDiscounts.request());

    ApiClient.awiza.promotions.getDiscounts(params)
      .then((response) => dispatch(getAwizaDiscounts.success(response, params)))
      .catch((error) => dispatch(getAwizaDiscounts.failure(error)));
  };
}

export const getAwizaDiscountById = createAsyncAction(
  'AWIZA_GET_DISCOUNT_BY_ID_REQUEST',
  'AWIZA_GET_DISCOUNT_BY_ID_SUCCESS',
  'AWIZA_GET_DISCOUNT_BY_ID_FAIL',
)();

export function getAwizaDiscountByIdAsync(params) {
  return (dispatch) => {
    dispatch(getAwizaDiscountById.request());

    ApiClient.awiza.promotions.getDiscountById(params)
      .then((response) => dispatch(getAwizaDiscountById.success(response, params)))
      .catch((error) => dispatch(getAwizaDiscountById.failure(error)));
  };
}

export const createAwizaDiscount = createAsyncAction(
  'AWIZA_CREATE_DISCOUNT_REQUEST',
  'AWIZA_CREATE_DISCOUNT_SUCCESS',
  'AWIZA_CREATE_DISCOUNT_FAIL',
)();

export function createAwizaDiscountAsync(params) {
  return (dispatch) => {
    dispatch(createAwizaDiscount.request());

    ApiClient.awiza.promotions.createDiscount(params)
      .then((response) => dispatch(createAwizaDiscount.success(response, params)))
      .catch((error) => dispatch(createAwizaDiscount.failure(error)));
  };
}

export const updateAwizaDiscount = createAsyncAction(
  'AWIZA_UPDATE_DISCOUNT_REQUEST',
  'AWIZA_UPDATE_DISCOUNT_SUCCESS',
  'AWIZA_UPDATE_DISCOUNT_FAIL',
)();

export function updateAwizaDiscountAsync(params) {
  return (dispatch) => {
    dispatch(updateAwizaDiscount.request());

    ApiClient.awiza.promotions.updateDiscount(params)
      .then((response) => dispatch(updateAwizaDiscount.success(response, params)))
      .catch((error) => dispatch(updateAwizaDiscount.failure(error)));
  };
}
