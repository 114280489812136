import classnames from 'classnames';
import React, { memo, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Tooltip, Input } from '@teko/ui-kit';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import withViewport from '../common/viewport/withViewport';
import compose from '../../utils/compose';
import EyeOffIcon from '../../assets/svg/eye-off.svg';
import EyeOnIcon from '../../assets/svg/eye.svg';

const popperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [0, 8],
    },
  },
];

const passwordRules = [
  {
    name: 'passwordLength',
    validation: yup.string().min(8),
  },
  {
    name: 'capitalLetter',
    validation: yup.string().matches(/[A-Z]/g),
  },
  {
    name: 'lowercaseLetter',
    validation: yup.string().matches(/[a-z]/g),
  },
  {
    name: 'digit',
    validation: yup.string().matches(/\d/g),
  },
];

function StrictPasswordInputField({ viewport, ...props }) {
  const { t } = useTranslation('auth');
  const [isValidByRuleName, setIsValidByRuleName] = useState(() => passwordRules.map((name) => ({ [name]: false })));
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [field, meta] = useField(props.name);

  const tooltipPlacement = useMemo(() => (viewport.size === 'MOBILE' || viewport.size === 'SMALL-TABLET' ? 'top' : 'right'), [viewport]);

  const handleBlur = useCallback((...args) => {
    field.onBlur(...args);
    props.onBlur?.(...args);
    setIsTooltipOpened(false);
  }, [props.onBlur]);

  const handleFocus = useCallback((...args) => {
    props.onFocus?.(...args);
    setIsTooltipOpened(true);
  }, [props.onFocus]);

  const passwordContent = useMemo(() => (
    <ul className="registration-form-tooltip">
      {passwordRules.map(({ name }) => (
        <li key={name} className={classnames('registration-form-tooltip-el', { active: isValidByRuleName[name] })}>{t(`password-rules.${name}`)}</li>
      ))}
    </ul>
  ), [isValidByRuleName]);

  const validatePassword = useCallback((value) => {
    setIsValidByRuleName(passwordRules.reduce((activeRules, { name, validation }) => {
      activeRules[name] = validation.isValidSync(value);

      return activeRules;
    }, {}));
  }, []);

  const handleChange = useCallback((e) => {
    validatePassword(e.target.value);
    field.onChange(e);
  }, []);

  const handleShowPassClick = useCallback(() => {
    setPasswordVisible((prev) => !prev);
  }, []);

  return (
    <Tooltip
      opened={isTooltipOpened}
      content={passwordContent}
      placement={tooltipPlacement}
      size="s"
      popperModifiers={popperModifiers}
    >
      {(ref) => (
        <Input
          {...field}
          {...props}
          onBlur={handleBlur}
          value={field.value || ''}
          state={meta.error && meta.touched ? 'invalid' : undefined}
          ref={ref}
          onChange={handleChange}
          autoComplete="password"
          onFocus={handleFocus}
          type={passwordVisible ? 'text' : 'password'}
          suffix={passwordVisible ? <EyeOffIcon className="password-eye-icon" onClick={handleShowPassClick} /> : <EyeOnIcon className="password-eye-icon" onClick={handleShowPassClick} />}
        />
      )}
    </Tooltip>
  );
}

StrictPasswordInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default compose(memo, withViewport([{ key: 'SMALL-TABLET', max: 799 }]))(StrictPasswordInputField);
