import { useSelector } from 'react-redux';
import useGlobalMemo from './useGlobalMemo';
import usePaycafeOutlets from './usePaycafeOutlets';

function useProductsFilter() {
  // const products = useProducts();
  const products = usePaycafeOutlets();
  const status = useSelector((state) => state.paycafe.paycafeOutletsFetchStatus);

  return useGlobalMemo(
    'useProductsFilter',
    () => ({
      id: 'destinations',
      label: 'Товары',
      loading: status.loading,
      items: products.map((product) => ({
        id: product.id,
        label: product.fullName || product.id,
        search: [product.fullName, product.id],
      })),
    }),
    [products],
  );
}

export default useProductsFilter;
