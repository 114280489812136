import { getOrigin } from '../../../utils';

export const YandexAuthService = {
  lastPendingPromises: [Promise.resolve()],

  init() {
    const initPromise = window.YaAuthSuggest.init(
      {
        client_id: process.env.YANDEX_AUTH_CLIENT_ID,
        response_type: 'token',
        redirect_uri: `${getOrigin()}/yandexauth`,
      },
      origin,
      {
        view: 'button',
        parentId: 'ya-login-button-container',
        buttonSize: 'm',
        buttonView: 'icon',
        buttonTheme: 'light',
        buttonBorderRadius: '18',
        buttonIcon: 'ya',
      },
    )
      .finally(() => {
        const i = this.lastPendingPromises.findIndex((prom) => prom === initPromise);
        this.lastPendingPromises.splice(i, 1);
      })
      .catch((e) => {
        console.log(e);
      });

    this.lastPendingPromises.push(initPromise);

    return initPromise;
  },
};
