import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setOauthParams, vkLoginAsync } from '../../actions/auth';
import { updateQuery } from '../../utils/url';
import { getAuthRequestExtraData } from '../../utils';
import { useAsyncStatus, useQueryParams } from '../common/hooks';

function VKAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [params] = useQueryParams();
  const { success } = useAsyncStatus((state) => state.auth.vkLoginStatus);
  const { error } = useAsyncStatus((state) => state.auth.vkLoginStatus);

  const data = JSON.parse(params.payload);

  useEffect(() => {
    const authParams = JSON.parse(window.localStorage.getItem('vkAuthInitialParams'));
    if (authParams && authParams.redirectUrl) {
      dispatch(setOauthParams(authParams));
      window.localStorage.removeItem('vkAuthInitialParams');
    }

    const oldParams = JSON.parse(sessionStorage.getItem('socialAuthParams'));
    sessionStorage.setItem('socialAuthParams', JSON.stringify({
      ...oldParams,
      accessToken: data.token,
    }));

    dispatch(vkLoginAsync({ accessToken: data.token, uuid: data.uuid, ...getAuthRequestExtraData() }));
  }, []);

  useEffect(() => {
    if (error?.code === 581) {
      history.push({ pathname: '/register', search: updateQuery(history.location.search, { nonEmail: true, authType: 'vk' }) });
    } else if (error?.code === 582) {
      history.push({ pathname: '/register', search: updateQuery(history.location.search, { haveAccount: true }) });
    } else if (error) {
      history.push({ pathname: '/register', search: updateQuery(history.location.search, { vkError: true }) });
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      sessionStorage.removeItem('authQueryParams');
      history.push(params.origin ?? '/lk');
    }
  }, [success]);

  return null;
}

export default VKAuth;
