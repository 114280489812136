import { useSelector } from 'react-redux';
import { currentOutletsSelector } from '../../../selectors/companies';
import useGlobalMemo from './useGlobalMemo';

function useOutletsFilter() {
  const outlets = useSelector(currentOutletsSelector);
  const status = useSelector((state) => state.companies.outletsFetchStatus);

  return useGlobalMemo(
    'useOutletsFilter',
    () => ({
      id: 'agents',
      label: 'Точки продаж',
      loading: status.loading,
      items: outlets.map((outlet) => ({
        id: `${outlet.globalId}|${outlet.id}`,
        label: outlet.fullName || outlet.id,
        search: [
          outlet.fullName,
          outlet.id,
          ...(outlet?.paymentPoint?.extra?.['1c_point_code'] ?? []),
        ], //`${outlet.fullName} ${outlet.id}`,
      })),
    }),
    [outlets, status.loading],
  );
}

export default useOutletsFilter;
