import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/subscriptions';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getSubscriptions.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.clients),
  }));

const tableReducer = createReducer({ clients: [], messages: [] })
  .handleAction(actions.getSubscriptions.success, (state, { payload }) => payload);

export default combineReducers({
  byId: byIdReducer,
  table: tableReducer,
  fetchStatus: asyncReducerFactory(actions.getSubscriptions),
});
