import { useEffect, useMemo, useState } from 'react';
import { globalCache } from '../../../utils/globalCache';

export default function useCountriesOptions() {
  const [countries, setCountries] = useState(() => globalCache.get('countriesOptions') ?? []);
  const [loading, setLoading] = useState(countries.length === 0);

  useEffect(() => {
    if (countries.length === 0) {
      import('../../../utils/countries.json').then((module) => {
        const countriesOptions = module.default.map((country) => ({
          value: country.name, // country.alpha2,
          label: country.name,
        }));

        globalCache.set('countriesOptions', countriesOptions);
        setCountries(countriesOptions);
        setLoading(false);
      });
    }
  }, []);

  return useMemo(() => ({
    countries,
    loading,
  }), [countries, loading]);
}
