import { useSelector } from 'react-redux';
import { companiesByIdSelector, currentCompanyIdSelector } from '../../../selectors/companies';

/**
 * @returns {CompanyModel}
 */
export default function useCurrentCompany() {
  const currentCompanyId = useSelector(currentCompanyIdSelector);
  const companiesById = useSelector(companiesByIdSelector);

  return companiesById[currentCompanyId];
}
