import AwizaIcon from '../assets/svg/apps/awiza-nav.svg';
import PaycafeIcon from '../assets/svg/apps/paycafe.svg';
import BoxretailIcon from '../assets/svg/apps/boxretail.svg';
import TalkToIcon from '../assets/svg/apps/talkto.svg';
// import PosterIcon from '../assets/svg/nav-menu/poster-logo.svg';
import CdekIcon from '../assets/svg/apps/cdek.svg';
import MyclientsIcon from '../assets/svg/apps/myclients.svg';
import YabusinessIcon from '../assets/svg/apps/ya-business.svg';
import PaycafeDefaultOgImage from '../assets/img/paycafe/paycafe-og-image.png';

export const DEFAULT_PAGE_SIZE = 20;

export const FAST_START_HIDE_DELAY = process.env.APP_ENV === 'development' ? 1 : 15;

export const MobileMaxWidth = 599;

export const iconByAppId = {
  awiza: AwizaIcon,
  paycafe: PaycafeIcon,
  boxretail: BoxretailIcon,
  talkto: TalkToIcon,
  // poster: PosterIcon,
  cdek: CdekIcon,
  yabusiness: YabusinessIcon,
  myclients: MyclientsIcon,
};

export const titleByAppId = {
  paycafe: 'Paycafe',
  boxretail: 'Boxretail',
  awiza: 'Awiza',
  poster: 'Poster',
  cdek: 'CDEK',
  yabusiness: 'Яндекс.Бизнес',
  myclients: 'Myclients',
};

export const DEFAULT_PAYCAFE_FORM_THEME = 'light';
export const DEFAULT_PAYCAFE_THEME_BG = '#3d5afe';
export const DEFAULT_PAYCAFE_THEME_TEXT = '#ffffff';
export const DEFAULT_PAYCAFE_INPUT_STYLE = 'underlined';
export const DEFAULT_PAYCAFE_PAYMENT_METHODS_STYLE = 'underlined';

export const DEFAULT_PAYCAFE_META = {
  name: 'Paycafe',
  title: 'Оплатите заказ',
  description: 'Онлайн-оплата счета по ссылке удобным способом',
  image: {
    url: process.env.NODE_ENV === 'development' ? PaycafeDefaultOgImage : process.env.PAYCAFE_URL.replace('paycafeWidget.js', 'assets/img/paycafe-og-image.png'),
    width: 1200,
    height: 630,
  },
};
