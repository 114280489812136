import { Modal } from '@teko/ui-kit';
import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../actions/app';
import DomHolder from './DomHolder';

function Header({ header }) {
  return (
    <DomHolder>
      {header && header}
    </DomHolder>
  );
}

const ModalContainer = memo(() => {
  const modalContent = useSelector((state) => state.app.modal);
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return (
    <Modal
      className="global-modal"
      header={<Header header={modalContent.header} />}
      active={!!modalContent.id}
      onClose={handleClose}
    >
      <DomHolder>
        {!!modalContent.id && (
          <>
            <div className="global-modal-content">
              { modalContent.description }
            </div>
            {modalContent.footer}
          </>
        )}
      </DomHolder>
    </Modal>
  );
});

export default ModalContainer;
