import React, { useCallback } from 'react';
import { Input } from '@teko/ui-kit';

function MoneyInput(props) {
  const { value, onChange, ...rest } = props;

  const handleKeyDown = useCallback((e) => {
    if (['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.value) {
      e.target.value = Math.abs(Math.trunc((parseFloat(e.target.value.replace(/\s/g, '')) * 100).toFixed()));
    }

    onChange(e);
  }, [onChange]);

  return (
    <Input
      value={typeof value === 'number' ? (parseInt(value, 10) / 100) : ''}
      onChange={handleChange}
      type="number"
      step="any"
      placeholder="0"
      suffix="₽"
      onKeyDown={handleKeyDown}
      {...rest}
    />
  );
}

export default MoneyInput;
