import store from '../store';
import { getOrigin } from './index';

export function getReferralByUrl() {
  const origin = getOrigin();
  const service = store.getState()?.auth?.oauthParams.service;

  if (service) {
    return service;
  }

  if (origin.indexOf('art') > -1) return 'art';
  if (origin.indexOf('atol') > -1) return 'atol';
  if (origin.indexOf('talkto') > -1) return 'talktoBusiness';

  // for local
  if (process.env.NODE_ENV === 'development') return 'talktoBusiness';
  return null;
}

export function getReferral() {
  return store.getState()?.user?.referral ?? getReferralByUrl();
}

export function getPageTitleSuffixByReferral() {
  const brand = getReferralByUrl() || 'teko';
  const titleSuffixByBrand = {
    atol: 'АТОЛ Онлайн powered by TEKO',
    teko: 'Teko',
    talkto: 'Talkto',
    talktoBusiness: 'Talkto Business',
  };
  return titleSuffixByBrand[brand] ?? 'Teko';
}
