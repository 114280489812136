import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/boxretail';
import normalize from '../utils/normalize';
import { asyncReducerFactory, tableReducerFactory } from '../utils/redux-utils';

const notificationsDataReducer = createReducer({})
  .handleAction(actions.getBoxretailNotifications.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload),
  }))
  .handleAction(actions.removeBoxretailNotification.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const servicesRequestsByCompanyIdReducer = createReducer({})
  .handleAction(actions.getBoxretailServicesContractsRequests.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      ...normalize(payload),
    },
  }))
  .handleAction(actions.sendServicesContractsRequest.success, (state, { payload }) => ({
    ...state,
    [payload.companyId]: {
      ...state[payload.companyId],
      [payload.id]: payload,
    },
  }))
  .handleAction(actions.acceptBoxretailServicesContractsRequest.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      [meta.id]: {
        ...state[meta.companyId][meta.id],
        status: meta.status,
      },
    },
  }));

export default combineReducers({
  notifications: combineReducers({
    byId: notificationsDataReducer,
    table: tableReducerFactory(actions.boxretailNotificationsTable),
    fetchStatus: asyncReducerFactory(actions.getBoxretailNotifications),
    createStatus: asyncReducerFactory(actions.createBoxretailNotification),
    removeStatus: asyncReducerFactory(actions.removeBoxretailNotification),
  }),
  servicesRequestsByCompanyId: servicesRequestsByCompanyIdReducer,
  saveServicesStatus: asyncReducerFactory(actions.saveBoxretailServices),
  sendServicesContractsRequestFetchStatus: asyncReducerFactory(actions.sendServicesContractsRequest),
  getServicesRequestsFetchStatus: asyncReducerFactory(actions.getBoxretailServicesContractsRequests),
  sendBoxretailServicesContractsFilesFetchStatus: asyncReducerFactory(actions.sendBoxretailServicesContractsFiles),
  acceptBoxretailServicesContractsRequestFetchStatus: asyncReducerFactory(actions.acceptBoxretailServicesContractsRequest),
  updateTaxInfoFetchStatus: asyncReducerFactory(actions.updateTaxInfo),
  acceptBoxretailOutletStatus: asyncReducerFactory(actions.acceptBoxretailOutlet),
  setOrderNotificationsStatus: asyncReducerFactory(actions.setOrderNotifications),
});
