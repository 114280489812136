import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/clients';
import * as orderActions from '../actions/orders';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getClients.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.clients),
  }))
  .handleAction(actions.updateClient.success, (state, { meta }) => ({
    ...state,
    [meta.clientId]: {
      ...state[meta.clientId],
      fullName: meta.fullName,
    },
  }))
  .handleAction(actions.getClientDetailsById.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }))
  .handleAction(orderActions.getOrdersByClientId.success, (state, { payload, meta }) => ({
    ...state,
    [meta.clientIds]: {
      ...state[meta.clientIds],
      orders: {
        ids: payload.orders.map((item) => item.orderId),
        totalCount: payload.totalCount,
      },
    },
  }));

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getClients.success, (state, { payload }) => ({
    ids: payload.clients.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const paymentsByClientsIdReducer = createReducer({})
  .handleAction(actions.getClientPayments.success, (state, { payload, meta }) => ({
    ...state,
    [meta.clientId]: payload.txHeaders,
  }));

const foundClientsReducer = createReducer([])
  .handleAction(actions.searchClients.success, (state, { payload }) => payload.clients)
  .handleAction(actions.clearClientsSearch, () => []);

export default combineReducers({
  byId: byIdReducer,
  table: tableReducer,
  paymentsByClientsId: paymentsByClientsIdReducer,
  foundClients: foundClientsReducer,
  fetchStatus: asyncReducerFactory(actions.getClients),
  updateFetchStatus: asyncReducerFactory(actions.updateClient),
  clientDetailsFetchStatus: asyncReducerFactory(actions.getClientDetailsById),
  paymentsFetchStatus: asyncReducerFactory(actions.getClientPayments),
});
