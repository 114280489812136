import { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash.memoize';

export const ViewportSize = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

export const ViewportOrientation = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
};

const mobileMaxWidth = 599;
const tabletMaxWidth = 1023;
const defaultSizes = [
  { key: ViewportSize.MOBILE, max: mobileMaxWidth },
  { key: ViewportSize.TABLET, max: tabletMaxWidth },
  { key: ViewportSize.DESKTOP, max: Number.POSITIVE_INFINITY },
];

function getViewport(size, orientation) {
  return { viewport: { size, orientation } };
}

function resolver(size, orientation) {
  return size + orientation;
}

const memoizedGetViewport = memoize(getViewport, resolver);

class Viewport extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
    sizes: PropTypes.array,
  };

  static contextTypes = {
    viewport: PropTypes.object,
  };

  static defaultProps = {
    sizes: undefined,
  };

  constructor(props, context) {
    super(props);

    this.sizes = this.props.sizes ? defaultSizes.concat(this.props.sizes).sort((a, b) => a.max - b.max) : defaultSizes;

    if (context.viewport) {
      this.state = { ...context.viewport };
    } else {
      this.state = {
        size: this.getViewportSize(),
        orientation: Viewport.getViewportOrientation(),
      };
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.onResize);
  }

  onResize = () => {
    const size = this.getViewportSize();
    const orientation = Viewport.getViewportOrientation();

    if (size !== this.state.size || orientation !== this.state.orientation) {
      this.setState({
        size,
        orientation,
      });
    }
  };

  static getViewportOrientation() {
    if (typeof window.screen !== 'undefined' && typeof window.screen.orientation !== 'undefined') {
      const { orientation } = window.screen;

      if (orientation.angle === 0 || orientation.angle === 180) {
        return ViewportOrientation.PORTRAIT;
      }

      if (orientation.angle === 90 || orientation.angle === -90) {
        return ViewportOrientation.LANDSCAPE;
      }

      if (orientation.type === 'portrait-primary' || orientation.type === 'portrait-secondary') {
        return ViewportOrientation.PORTRAIT;
      }

      if (orientation.type === 'landscape-primary' || orientation.type === 'landscape-secondary') {
        return ViewportOrientation.LANDSCAPE;
      }
    }

    if (typeof window.orientation !== 'undefined') {
      if (window.orientation === 0 || window.orientation === 180) {
        return ViewportOrientation.PORTRAIT;
      }

      if (window.orientation === 90 || window.orientation === -90) {
        return ViewportOrientation.LANDSCAPE;
      }
    }

    return window.matchMedia('(orientation: portrait)').matches ? ViewportOrientation.PORTRAIT : ViewportOrientation.LANDSCAPE;
  }

  getViewportSize() {
    const width = window.innerWidth;

    for (const size of this.sizes) {
      if (width <= size.max) {
        return size.key;
      }
    }

    throw new Error(`Size "${width}px" not found in sizes: ${JSON.stringify(this.sizes)}`);
  }

  render() {
    const { render } = this.props;
    const { size, orientation } = this.state;
    // const props = { viewport: { size, orientation } };
    const props = memoizedGetViewport(size, orientation);

    return render(props);
  }
}

export default Viewport;
