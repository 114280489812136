import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/products';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getRetailProducts.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.barcodes),
  }));

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getRetailProducts.success, (state, { payload }) => ({
    ids: payload.barcodes.map((product) => product.id),
    totalCount: payload.totalCount,
  }));

const stockReducer = createReducer({})
  .handleAction(actions.getProductStock.success, (state, { payload, meta }) => ({
    ...state,
    [meta.barcode]: payload.stockInfo.map((info) => ({
      posId: info.posId,
      availableQuantity: info.stock[0]?.availableQuantity,
      price: info.stock[0]?.price,
    })),
  }));

export default combineReducers({
  byId: byIdReducer,
  table: tableReducer,
  stockByBarcode: stockReducer,
  fetchStatus: asyncReducerFactory(actions.getRetailProducts),
  stockFetchStatus: asyncReducerFactory(actions.getProductStock),
});
