import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/emails';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const emailSettingsReducer = createReducer({ settingsById: {} })
  .handleAction(actions.getEmailSettings.success, (state, { payload }) => ({
    ...payload,
    settingsById: normalize(payload.settings, 'key'),
  }));

export default combineReducers({
  emailSettings: emailSettingsReducer,
  createEmailFetchStatus: asyncReducerFactory(actions.createEmail),
  getEmailSettingsFetchStatus: asyncReducerFactory(actions.getEmailSettings),
  sendEmailPreviewFetchStatus: asyncReducerFactory(actions.sendEmailPreview),
});
