export const getOrigin = () => {
  let { location: { origin } } = window;
  const { location: { protocol, hostname, port } } = window;
  if (!origin) {
    origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  }
  return origin.toLowerCase();
};

export const getAuthRequestExtraData = (withUtmInfo = true) => {
  const { redirectUrl, utmInfo } = JSON.parse(sessionStorage.getItem('authQueryParams')) ?? {};

  return {
    ...(withUtmInfo && utmInfo && { utmInfo }),
    ...(redirectUrl && { redirectHostname: new URL(redirectUrl).hostname }),
  };
};
