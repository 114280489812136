import React, { memo, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { googleLoginAsync } from '../../actions/auth';
import GoogleIcon from '../../assets/svg/networks/google.svg';
import { getAuthRequestExtraData } from '../../utils';
import { useAsyncStatus } from '../common/hooks';
import { triggerAllMetricsEvents } from '../common/utils/metrics';

function loadGoogleOld() {
  return new Promise((resolve) => {
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        resolve();
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js';
      js.onload = () => {
        resolve();
      };
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'googleAuth');
  });
}

let auth2;

function GoogleLoginButton() {
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const { success: googleLoginSuccess } = useAsyncStatus((state) => state.auth.googleLoginStatus);
  const isNewGoogleUser = useSelector((state) => state.auth.isNewGoogleUser);

  const attachSignin = useCallback((element) => {
    auth2.attachClickHandler(element, {},
      (response) => {
        dispatch(googleLoginAsync({ ...response.getAuthResponse(true), ...getAuthRequestExtraData() }));
      }, (error) => {
        console.log(error);
      });
  }, []);

  const startApp = useCallback(() => {
    window.gapi.load('auth2', () => {
      auth2 = window.gapi.auth2.init({
        client_id: '513019241219-rugd2bp152cr5nnsk7jenp7p1gg50kq9.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
      });
      attachSignin(buttonRef.current);
    });
  }, []);

  const handleTriggerAllMetrics = useCallback(() => {
    triggerAllMetricsEvents('reggoogleclick');
  }, []);

  useEffect(() => {
    if (googleLoginSuccess) {
      sessionStorage.removeItem('authQueryParams');
    }
  }, [googleLoginSuccess]);

  useEffect(() => {
    loadGoogleOld()
      .then(startApp);
  }, []);

  return (
    <button type="button" className="social-auth-button google" ref={buttonRef} onClick={handleTriggerAllMetrics}>
      <GoogleIcon className="icon google-icon" />
      {/*<Button ref={buttonRef} className="google social-auth-button" type="button" icon={<GoogleIcon className="google-icon" />} />*/}
    </button>
  );
}

export default memo(GoogleLoginButton);
