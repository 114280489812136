import { Button } from '@teko/ui-kit';
import { parse, stringify } from 'qs';
import React from 'react';
import { hideNotification, setCompanyId } from '../actions/app';
import history from './history';
import { iconByAppId } from './constants';
import { capitalizeFirstChar, getNoun } from './format';

export function getAcceptNotification(company, status) {
  const type = status === 'accepted' ? 'success' : 'info';
  const message = (() => {
    switch (status) {
      case 'accepted':
        return <span>Заявка по добавлению проекта <br />{company.name} одобрена</span>;
      case 'declined':
      case 'spam':
        return <span>Заявка по добавлению проекта <br />{company.name} отклонена</span>;
      default:
        return <span>Статус проекта {company.name} изменен</span>;
    }
  })();

  return { type, message, timeout: 5000 };
}

const getNotificationChildClickHandler = (dispatch, notification, user, isReadLoading, readUserNotificationAsync) => (e) => {
  e.preventDefault();
  const url = new URL(e.currentTarget.href);
  const params = parse(url.search, { ignoreQueryPrefix: true, strictNullHandling: true });

  if (url.pathname.includes('set-company')) {
    // works with both:
    // /lk/set-company/ooo_test
    // /lk/set-company/ooo_test/
    const companyId = url.pathname.split('/set-company/')[1].replace(/\/$/, '');
    dispatch(setCompanyId(companyId, user.id));
    history.push(decodeURIComponent(params.redirectTo));
  } else if ('chat' in params) {
    history.push({
      search: stringify({
        ...parse(history.location.search, { ignoreQueryPrefix: true }),
        ...params,
      }, { strictNullHandling: true }),
    });
  } else {
    history.push(url.pathname + url.search);
  }
  if (!notification.alreadyRead && !isReadLoading) {
    dispatch(readUserNotificationAsync(notification.id));
  }
  dispatch(hideNotification(notification));
};

export function getNotificationTagByAppId(appId) {
  const Icon = iconByAppId[appId.toLowerCase()];
  return (
    <>
      {!!Icon && <Icon className="ui-alert-tag-icon" />}
      <span className="ui-alert-tag-text">{capitalizeFirstChar(appId)}</span>
    </>
  );
}

export function getNewUserNotificationsNotification(notifications, dispatch, state, readUserNotificationAsync) {
  const { users: { currentUser }, notifications: { readLoadingById } } = state;

  const notificationProps = {
    type: 'info',
    timeout: 5000,
    title: `У вас ${getNoun(notifications.length, 'новое уведомление', 'новых уведомления', 'новых уведомлений', true)}`,
    message: 'Подробная информация доступна в «Центре уведомлений» 🔔',
  };

  if (notifications.length === 1) {
  // if (notifications.find((item) => item.button)) {
  //   const notification = notifications.find((item) => item.button); // notifications[0]
    const notification = notifications[0];
    const { id, level, type, tag, title, message, button, timeout } = notification;

    if (tag) {
      const appId = tag.split('|')[0]; // awiza|getSmmSettings (second part to make possible triggering some requests)
      notificationProps.tag = getNotificationTagByAppId(appId);
    }

    const handleNotificationChildClick = getNotificationChildClickHandler(dispatch, notification, currentUser, readLoadingById[id], readUserNotificationAsync);
    const messageHTML = document.createElement('div');
    messageHTML.innerHTML = message;
    const messageNode = (
      <>
        <p>
          {[...messageHTML.childNodes].map((node) => {
            if (node.tagName === 'A') {
              return (
                <a
                  key={node.href}
                  href={node.href}
                  className="ui-alert-link"
                  onClick={handleNotificationChildClick}
                >
                  {node.textContent}
                </a>
              );
            }
            // if (node.nodeType !== Node.TEXT_NODE) {}
            return node.textContent;
          })}
        </p>
        {!!button && (
          <Button
            tag="a"
            href={button.link}
            size="s"
            className="ui-alert-button"
            onClick={handleNotificationChildClick}
          >
            {button.name}
          </Button>
        )}
      </>
    );

    notificationProps.id = id;
    notificationProps.type = (level ?? type ?? 'info').toLowerCase();
    notificationProps.title = title ? <div dangerouslySetInnerHTML={{ __html: title }} /> : null;
    notificationProps.message = message ? messageNode : null;
    notificationProps.timeout = timeout ?? notificationProps.timeout;
  }

  return notificationProps;
}

export function getEmployeesCreateNotification(t, created, failed) {
  return {
    type: created.length > 0 ? 'success' : 'error',
    message: (
      <>
        {created.length > 0 && (
          <p dangerouslySetInnerHTML={{ __html: t(`employees-successfully-created-${created.length > 1 ? 'many' : 'one'}`, { logins: created.join(', ') }) }} />
        )}
        {created.length > 0 && failed.length > 0 && <br />}
        {failed.length > 0 && (
          <p dangerouslySetInnerHTML={{ __html: t('employees-failed-to-create', { logins: failed.join(', ') }) }} />
        )}
      </>
    ),
    icon: true,
    timeout: created.length > 0 && failed.length > 0 ? 7000 : 5000,
    tag: getNotificationTagByAppId('boxretail'),
  };
}
