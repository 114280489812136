import { useField } from 'formik';
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@teko/ui-kit';

const RadioGroupField = (props) => {
  const [field] = useField(props.name);
  const onChange = useCallback((e) => {
    field.onChange(e);
    field.onBlur(e); // to make formik 'touched' work
  }, [field]);

  return (
    <RadioGroup
      {...field}
      onChange={onChange}
      {...props}
    />
  );
};

RadioGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default memo(RadioGroupField);
