import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/crm';
import { asyncReducerFactory } from '../utils/redux-utils';

const smsResponseReducer = createReducer({})
  .handleAction(actions.sendSms.success, (state, { payload }) => payload.response);

const smsProjectsReducer = createReducer([])
  .handleAction(actions.getSmsProjects.success, (state, { payload }) => payload);

const smsProjectInfoReducer = createReducer({})
  .handleAction(actions.getSmsProjectInfo.success, (state, { payload }) => payload);

const smppConfigReducer = createReducer([])
  .handleAction(actions.getSmppConfig.success, (state, { payload }) => payload.smppConfigs);

const subscriptionsHistoryReducer = createReducer([])
  .handleAction(actions.getSubscriptionsHistory.success, (state, { payload }) => payload);

export default combineReducers({
  smsResponse: smsResponseReducer,
  smsProjects: smsProjectsReducer,
  smsProjectInfo: smsProjectInfoReducer,
  smppConfig: smppConfigReducer,
  subscriptionsHistory: subscriptionsHistoryReducer,
  sendSmsStatus: asyncReducerFactory(actions.sendSms),
  smsProjectsFetchStatus: asyncReducerFactory(actions.getSmsProjects),
  smsProjectInfoFetchStatus: asyncReducerFactory(actions.getSmsProjectInfo),
  smppConfigFetchStatus: asyncReducerFactory(actions.getSmppConfig),
  updateSmsProjectStatusFetchStatus: asyncReducerFactory(actions.updateSmsProjectStatus),
  updateSmsProjectFetchStatus: asyncReducerFactory(actions.updateSmsProject),
  createSmsProjectFetchStatus: asyncReducerFactory(actions.createSmsProject),
  changeTariffCrmFetchStatus: asyncReducerFactory(actions.changeTariffCrm),
  updateTrialSubscriptionFetchStatus: asyncReducerFactory(actions.updateTrialSubscription),
  deleteTrialSubscriptionFetchStatus: asyncReducerFactory(actions.deleteTrialSubscription),
  getSubscriptionsHistoryFetchStatus: asyncReducerFactory(actions.getSubscriptionsHistory),
});
