import React, { useCallback, useMemo } from 'react';
import MaskInput from './MaskInput';

function NumberInput(props) {
  const {
    value,
    onChange,
    type = 'text',
    scale = 10,
    min = 0,
    max,
    thousandsSeparator = '',
    radix = '.',
    mapToRadix = [','],
    ...rest
  } = props;

  const mask = useMemo(() => ({
    mask: Number,
    scale,
    min,
    max,
    thousandsSeparator,
    radix,
    mapToRadix,
  }), [scale, min, max, thousandsSeparator, radix, mapToRadix]);

  const handleChange = useCallback((e) => {
    const { target: { value } } = e;
    onChange({
      target: {
        value: type === 'number' && value !== '' ? +value : value,
        name: e.target.name,
      },

    });
  }, [onChange]);

  return (
    <MaskInput
      value={typeof value === 'number' || type === 'text' ? value : ''}
      onChange={handleChange}
      mask={mask}
      {...rest}
    />
  );
}

export default NumberInput;
