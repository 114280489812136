import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getRetailProducts = createAsyncAction(
  'PRODUCTS_GET_LIST_REQUEST',
  'PRODUCTS_GET_LIST_SUCCESS',
  'PRODUCTS_GET_LIST_FAIL',
)();

export function getRetailProductsAsync(params) {
  return (dispatch) => {
    dispatch(getRetailProducts.request());

    ApiClient.companies.getRetailProducts(params)
      .then((data) => dispatch(getRetailProducts.success(data)))
      .catch((error) => dispatch(getRetailProducts.failure(error)));
  };
}

export const getProductStock = createAsyncAction(
  'PRODUCTS_GET_STOCK_REQUEST',
  'PRODUCTS_GET_STOCK_SUCCESS',
  'PRODUCTS_GET_STOCK_FAIL',
)();

export function getProductStockAsync(barcode) {
  return (dispatch) => {
    dispatch(getProductStock.request());

    ApiClient.companies.outletStock({ barcode })
      .then((data) => dispatch(getProductStock.success(data, { barcode })))
      .catch((error) => dispatch(getProductStock.failure(error)));
  };
}
