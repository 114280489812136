import { Button } from '@teko/ui-kit';
import classnames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import talktoIllustrationSrc1x from '../../assets/img/auth/talkto-image.png';
import talktoIllustrationSrc2x from '../../assets/img/auth/talkto-image@2x.png';
import { useThunderBot } from './hooks';
import { triggerYMetrics } from './utils/metrics';

function ErrorFallback({ error, className }) {
  const { t } = useTranslation();
  const tb = useThunderBot();

  const handleSupportClick = useCallback((e) => {
    e.preventDefault();
    tb.openWidget();
  }, [tb]);

  useEffect(() => {
    triggerYMetrics('frontError', { error: error.message, url: window.location.href });
  }, []);

  return (
    <div className={classnames('fallback-wrapper', className)}>
      <img className="fallback-illustration" srcSet={`${talktoIllustrationSrc1x} 1x, ${talktoIllustrationSrc2x} 2x`} src={talktoIllustrationSrc1x} alt="fallback" />

      <div className="fallback-container">
        <h2 className="fallback-title">{t('fallback-title')}</h2>
        <p className="fallback-description">{t('fallback-description')}</p>
        {!!tb && (
          <Button className="fallback-button" onClick={handleSupportClick} design="primary">{t('fallback-button-label')}</Button>
        )}
      </div>
    </div>
  );
}

export default ErrorFallback;
