import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getBillingMethodsAsync } from '../../../actions/billing';
import { currentCompanyIdSelector } from '../../../selectors/companies';

// const paymentDetails = {
//   main: {
//     brand: 'visa',
//     pan: '•••• 5850',
//     id: 'test-visa',
//   },
//   reserve: [
//     {
//       brand: 'uatp',
//       pan: '•••• 3452',
//       id: 'test-uatp',
//     },
//     {
//       brand: 'mastercard',
//       pan: '•••• 5850',
//       id: 'test-mastercard',
//     },
//     {
//       brand: 'discover',
//       pan: '•••• 5850',
//       id: 'test-discover',
//     },
//     {
//       brand: 'american-express',
//       pan: '•••• 5850',
//       id: 'test-american-express',
//     },
//     {
//       brand: 'nspk-mir',
//       pan: '•••• 5850',
//       id: 'test-nspk-mir',
//     },
//     {
//       brand: 'maestro',
//       pan: '•••• 5850',
//       id: 'test-maestro',
//     },
//   ],
//   email: '',
// };

// const billingMethodsSelector = createSelector(
//   (state) => state.billing.methodsByCompanyId,
//   currentCompanyIdSelector,
//   (methodsByCompanyId, companyId) => (companyId ? (methodsByCompanyId[companyId] ?? {}) : {}),
// );

const paymentDetailsByCompanyIdSelector = (state) => state.billing.methodsByCompanyId;

function useBillingMethods(companyId) {
  const dispatch = useDispatch();
  const paymentDetailsByCompanyId = useSelector(paymentDetailsByCompanyIdSelector);
  // const currentCompanyId = useSelector(currentCompanyIdSelector);

  useEffect(() => {
    // if (currentCompanyId) {
      dispatch(getBillingMethodsAsync());
    // }
  }, []);

  return companyId ? (paymentDetailsByCompanyId[companyId] ?? {}) : paymentDetailsByCompanyId;
}

export default useBillingMethods;
