import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const createEmail = createAsyncAction(
  'CREATE_EMAIL_REQUEST',
  'CREATE_EMAIL_SUCCESS',
  'CREATE_EMAIL_FAIL',
)();

export function createEmailAsync(data) {
  return (dispatch) => {
    dispatch(createEmail.request());

    ApiClient.emails.createEmail(data)
      .then((data) => dispatch(createEmail.success(data)))
      .catch((error) => dispatch(createEmail.failure(error)));
  };
}

export const getEmailSettings = createAsyncAction(
  'GET_EMAIL_SETTINGS_REQUEST',
  'GET_EMAIL_SETTINGS_SUCCESS',
  'GET_EMAIL_SETTINGS_FAIL',
)();

export function getEmailSettingsAsync(context, projectId) {
  return (dispatch) => {
    dispatch(getEmailSettings.request());

    ApiClient.emails.getEmailSettings(context, projectId)
      .then((data) => dispatch(getEmailSettings.success(data)))
      .catch((error) => dispatch(getEmailSettings.failure(error)));
  };
}

export const sendEmailPreview = createAsyncAction(
  'SEND_EMAIL-PREVIEW_REQUEST',
  'SEND_EMAIL-PREVIEW_SUCCESS',
  'SEND_EMAIL-PREVIEW_FAIL',
)();

export function sendEmailPreviewAsync(data) {
  return (dispatch) => {
    dispatch(sendEmailPreview.request());

    ApiClient.emails.sendEmailPreview(data)
      .then((data) => dispatch(sendEmailPreview.success(data)))
      .catch((error) => dispatch(sendEmailPreview.failure(error)));
  };
}
