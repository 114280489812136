import { Input, Portal, useOnClickOutside, ModalContext } from '@teko/ui-kit';
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';

const options = {
  placement: 'right',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ],
};

function ColorInput(props) {
  const { name, value, onChange, onFocus, ...rest } = props;
  const [opened, setOpened] = useState(false);
  const [reference, setReference] = useState(null);
  const [popper, setPopper] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(reference, popper, options);
  const clickOutsideRef = useOnClickOutside(() => setOpened(false), opened, { ignoreClass: 'color-picker-popper' });
  const modalContext = useContext(ModalContext);
  const styles = useMemo(() => ({
    ...popperStyles.popper,
    zIndex: 100 + (modalContext?.index ?? 0) + 1,
  }), [popperStyles]);

  const handleFocus = useCallback((e) => {
    setOpened(true);
    onFocus?.(e);
  }, [onFocus]);
  const handleChange = useCallback((e) => {
    if (e.target.value) {
      const hex = e.target.value.replace(/[^0-9A-F]/ig, '');

      if (hex) {
        e.target.value = `#${hex}`;
      }
    }

    onChange(e);
  }, [onChange]);
  const handleColorChange = useCallback((color) => {
    onChange({
      target: {
        name,
        value: color.hex,
      },
    });
  }, [name, onChange]);

  const btn = (
    <button
      type="button"
      className="color-input-btn"
      ref={setReference}
      style={{ backgroundColor: value }}
      onClick={() => setOpened((opened) => !opened)}
    />
  );

  return (
    <div className="color-input" ref={clickOutsideRef}>
      <Input
        {...rest}
        value={(value ?? '').replace(/[^0-9A-F]/ig, '')}
        onChange={handleChange}
        onFocus={handleFocus}
        name={name}
        placeholder="000000"
        prefix="#"
        suffix={btn}
      />
      <Portal id="__tooltip-dd-modal-container">
        <CSSTransition
          classNames="color-picker-popper"
          timeout={150}
          in={opened}
          mountOnEnter
          unmountOnExit
        >
          <div className="color-picker-popper" ref={setPopper} style={styles} {...attributes.popper}>
            <div className="color-picker">
              <ChromePicker color={value} onChange={handleColorChange} disableAlpha />
            </div>
          </div>
        </CSSTransition>
      </Portal>
    </div>
  );
}

export default memo(ColorInput);
