import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router';
import { LoaderLight } from '@teko/ui-kit';
import classnames from 'classnames';
import { yandexLoginAsync } from '../../actions/auth';
import { getAuthRequestExtraData } from '../../utils';
import { loadScript } from '../../utils/loadScript';
import { updateQuery } from '../../utils/url';
import { useAsyncStatus } from '../common/hooks';
import usePassQueryToSessionStorage from '../common/hooks/usePassQueryToSessionStorage';
import { triggerAllMetricsEvents } from '../common/utils/metrics';
import YandexIcon from '../../assets/svg/networks/yandex.svg';
import { YandexAuthService } from './services/YandexAuthService';

function YandexLoginButton() {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const { success, error } = useAsyncStatus((state) => state.auth.yandexLoginStatus);
  const yandexAuthToken = useSelector((state) => state.auth.yandexAuthToken);
  const isMountedRef = useRef(false);
  const [loading, setLoading] = useState(true);
  const startApp = useCallback(() => {
    if (!isMountedRef.current) return;

    Promise.all(YandexAuthService.lastPendingPromises)
      .then(() => {
        if (!isMountedRef.current) return null;
        return YandexAuthService.init();
      })
      .then((data) => {
        if (!isMountedRef.current || !data) return null;
        const res = data.handler();
        setTimeout(() => {
          if (!isMountedRef.current) return;
          setLoading(false);
        }, 700); // yandex doesn't give info about when loading fully finished, so we have to guess somehow
        return res;
      })
      .then((data) => {
        if (!isMountedRef.current || !data) return;

        const oldParams = JSON.parse(sessionStorage.getItem('socialAuthParams'));
        sessionStorage.setItem('socialAuthParams', JSON.stringify({
          ...oldParams,
          accessToken: data.access_token,
        }));

        dispatch(yandexLoginAsync({ accessToken: data.access_token, ...getAuthRequestExtraData() }));
      })
      .catch((error) => {
        console.log('Yandex auth process error', error);
      });
  }, []);

  usePassQueryToSessionStorage(yandexAuthToken);

  const handleTriggerAllMetrics = useCallback(() => {
    triggerAllMetricsEvents('regyandexclick');
  }, []);

  useEffect(() => {
    if (success) {
      sessionStorage.removeItem('authQueryParams');
      sessionStorage.removeItem('socialAuthParams');
    }
  }, [success]);

  useEffect(() => {
    isMountedRef.current = true;
    loadScript('https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js', 'yandex-suggest')
      .then(startApp)
      .catch(() => {
        console.warn('yandex auth script loading error');
      });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (error?.code === 581) {
      history.push({ pathname: '/register', search: updateQuery(history.location.search, { nonEmail: true, authType: 'yandex' }) });
    }
  }, [error]);

  return (
    <div className={classnames('social-auth-button', 'yandex', { loading })} ref={ref}>
      <CSSTransition in={loading} timeout={300} classNames="loader-icon-wrap" mountOnEnter unmountOnExit>
        <div className="loader-icon-wrap">
          <LoaderLight className="loader-icon" />
        </div>
      </CSSTransition>
      <YandexIcon className="icon yandex-icon placeholder" />
      <div id="ya-login-button-container" className="icon yandex-icon" onClickCapture={handleTriggerAllMetrics} />
    </div>
  );
}

export default memo(YandexLoginButton);
