import { createSelector } from 'reselect';
import { outletsSortByStatusAndCreatedAt } from '../utils/sorts';
import { allBoxretailOutletsByIdSelector } from './boxretail';

export const paycafeOutletsSelector = createSelector(
  (state) => state.paycafe.outlets,
  (outlets) => Object.values(outlets).sort(outletsSortByStatusAndCreatedAt),
);

export const activePaycafeOutletsSelector = createSelector(
  paycafeOutletsSelector,
  (outlets) => outlets.filter((outlet) => outlet.status === 'accepted'),
);

export const currentPaycafeOutletsSelector = createSelector(
  paycafeOutletsSelector,
  (state) => state.app.currentCompanyId,
  (outlets, currentCompanyId) => {
    if (currentCompanyId) {
      return outlets.filter((outlet) => outlet.merchants.includes(currentCompanyId));
    }

    return outlets;
  },
);

export const currentPaycafeInvoicesSelector = createSelector(
  (state) => state.paycafe.invoicesById,
  (state) => state.app.currentCompanyId,
  (invoices, currentCompanyId) => Object.values(invoices).filter(({ companyId }) => companyId === currentCompanyId),
);

export const paycafeInvoicesTableSelector = createSelector(
  (state) => state.paycafe.invoicesTable.ids,
  (state) => state.paycafe.invoicesById,
  (ids, byId) => ids.map((id) => byId[id]),
);

export const staticQrCodesSelector = createSelector(
  (state) => state.paycafe.staticQrCodesById,
  (staticQrCodesById) => Object.values(staticQrCodesById),
);

export const currentPaycafeStaticQrCodesSelector = createSelector(
  staticQrCodesSelector,
  allBoxretailOutletsByIdSelector,
  (state) => state.app.currentCompanyId,
  (staticQrCodes, boxretailOutletsById, currentCompanyId) => {
    const enrichedWithOutletNamesStaticQrCodes = staticQrCodes.map((qrCode) => ({
      ...qrCode,
      ...(qrCode.outletId ? { outletName: boxretailOutletsById[qrCode.outletId]?.fullName ?? qrCode.outletId } : null),
    }));

    if (currentCompanyId) {
      return enrichedWithOutletNamesStaticQrCodes.filter((staticQrCode) => staticQrCode.companyId === currentCompanyId);
    }

    return enrichedWithOutletNamesStaticQrCodes;
  },
);

export const onlineCashboxesSelector = createSelector(
  (state) => state.paycafe.onlineCashboxesById,
  (onlineCashboxes) => Object.values(onlineCashboxes),
);
