import { createAction, createAsyncAction } from 'typesafe-actions';
import { triggerAllMetricsEvents } from '../components/common/utils/metrics';
import { ApiClient, defaultErrorDesc } from '../utils/api-client';
import { isAuthVersion } from '../utils/isAuthVersion';
import redirectToTalkto from '../utils/redirectToTalkto';
import { showNotification } from './app';

export const redirectIfNeeded = (getState, metricsGoal, checkNewUser) => (data) => {
  const state = getState();
  const service = state.auth.oauthParams?.service;
  const redirectUrl = state.auth.oauthParams?.redirectUrl;

  if (metricsGoal && (!checkNewUser || data.isNewUser)) {
    triggerAllMetricsEvents(metricsGoal);
  }

  if (process.env.NODE_ENV === 'development') return data;

  if (metricsGoal && (!checkNewUser || data.isNewUser)) {
    triggerAllMetricsEvents(metricsGoal);
  }

  if (isAuthVersion() || (service && redirectUrl)) {
    const { users: { referrerUrls: { talkto, loki } } } = state;
    // use data if triggered after /users/me request, in any other cases use store referrerUrl
    const fallbackUrl = data?.referrerUrls?.talkto || data?.referrerUrls?.loki || talkto || loki || window.location.origin;
    redirectToTalkto({ redirectUrl: redirectUrl || `${fallbackUrl}/lk`, service });

    return new Promise(() => {}); // to stop next steps (# extra /me requests) - location replace is not sync :(
  }

  return data;
};

export const login = createAsyncAction(
  'AUTH_SIGNIN_REQUEST',
  'AUTH_SIGNIN_SUCCESS',
  'AUTH_SIGNIN_FAIL',
)();

export function loginAsync(data) {
  return (dispatch, getState) => {
    dispatch(login.request());

    ApiClient.auth.login(data)
      .then(redirectIfNeeded(getState))
      .then(() => dispatch(login.success()))
      .catch((error) => {
        dispatch(login.failure(error, data));

        if (data.type === 'trustedAuth') {
          const notification = {
            type: 'error',
            message: error.message ?? error.description ?? defaultErrorDesc,
            timeout: 10000,
          };

          dispatch(showNotification(notification));
        }
      });
  };
}

export const googleLogin = createAsyncAction(
  'GOOGLE_AUTH_REQUEST',
  'GOOGLE_AUTH_SUCCESS',
  'GOOGLE_AUTH_FAIL',
)();

export function googleLoginAsync(data) {
  return (dispatch, getState) => {
    dispatch(googleLogin.request());

    ApiClient.auth.googleLogin(data)
      .then(redirectIfNeeded(getState, 'reggooglesuccess', true))
      .then((data) => dispatch(googleLogin.success(data)))
      .catch((error) => {
        dispatch(googleLogin.failure(error, { ...data, ...getState().users.currentUser }));

        // if (data.type === 'trustedAuth') {
        //   const notification = {
        //     type: 'error',
        //     message: error.message ?? error.description ?? JSON.stringify(error),
        //     timeout: 10000,
        //   };
        //
        //   dispatch(showNotification(notification));
        // }
      });
  };
}

export const facebookLogin = createAsyncAction(
  'FACEBOOK_AUTH_REQUEST',
  'FACEBOOK_AUTH_SUCCESS',
  'FACEBOOK_AUTH_FAIL',
)();

export function facebookLoginAsync(data) {
  return (dispatch, getState) => {
    dispatch(facebookLogin.request());

    ApiClient.auth.facebookLogin(data)
      .then(redirectIfNeeded(getState))
      .then(() => dispatch(facebookLogin.success()))
      .catch((error) => {
        dispatch(facebookLogin.failure(error, { ...data, ...getState().users.currentUser }));

        // if (data.type === 'trustedAuth') {
        //   const notification = {
        //     type: 'error',
        //     message: error.message ?? error.description ?? JSON.stringify(error),
        //     timeout: 10000,
        //   };
        //
        //   dispatch(showNotification(notification));
        // }
      });
  };
}

export const appleLogin = createAsyncAction(
  'APPLE_AUTH_REQUEST',
  'APPLE_AUTH_SUCCESS',
  'APPLE_AUTH_FAIL',
)();

export function appleLoginAsync(data) {
  return (dispatch, getState) => {
    dispatch(appleLogin.request());

    ApiClient.auth.appleLogin(data)
      .then(redirectIfNeeded(getState, 'regapplesuccess', true))
      .then((data) => dispatch(appleLogin.success(data)))
      .catch((error) => {
        dispatch(appleLogin.failure(error, { ...data, ...getState().users.currentUser }));
      });
  };
}

export const yandexLogin = createAsyncAction(
  'YANDEX_AUTH_REQUEST',
  'YANDEX_AUTH_SUCCESS',
  'YANDEX_AUTH_FAIL',
)();

export function yandexLoginAsync(_data) {
  return (dispatch, getState) => {
    const { otpId } = getState().auth.registration;
    const data = { ..._data, otpId: _data.otpId ?? otpId };

    dispatch(yandexLogin.request());

    ApiClient.auth.yandexLogin(data)
      .then(redirectIfNeeded(getState, 'regyandexsuccess', true))
      .then((data) => dispatch(yandexLogin.success(data)))
      .catch((error) => {
        dispatch(yandexLogin.failure(error, { ...data, ...getState().users.currentUser }));
      });
  };
}

export const vkLogin = createAsyncAction(
  'VK_AUTH_REQUEST',
  'VK_AUTH_SUCCESS',
  'VK_AUTH_FAIL',
)();

export function vkLoginAsync(_data) {
  return (dispatch, getState) => {
    const { otpId } = getState().auth.registration;
    const data = { ..._data, otpId: _data.otpId ?? otpId };

    dispatch(vkLogin.request());

    ApiClient.auth.vkLogin(data)
      .then(redirectIfNeeded(getState, 'regvksuccess', true))
      .then((data) => {
        dispatch(vkLogin.success(data));
      })
      .catch((error) => {
        dispatch(vkLogin.failure(error, { ...data, ...getState().users.currentUser }));
      });
  };
}

export const logout = createAsyncAction(
  'AUTH_LOGOUT_REQUEST',
  'AUTH_LOGOUT_SUCCESS',
  'AUTH_LOGOUT_FAIL',
)();

export function logoutAsync() {
  return (dispatch) => {
    dispatch(logout.request());

    ApiClient.auth.logout()
      .then(() => {
        dispatch(logout.success());
        dispatch(unauthorized());
      })
      .catch((error) => dispatch(logout.failure(error)));
  };
}

export const registerEmail = createAsyncAction(
  'AUTH_REGISTRATION_EMAIL_REQUEST',
  'AUTH_REGISTRATION_EMAIL_SUCCESS',
  'AUTH_REGISTRATION_EMAIL_FAIL',
)();

/**
 * @param {{ email: string; redirectHostname: [string] }} data
 */
export function registerEmailAsync(data) {
  return (dispatch) => {
    dispatch(registerEmail.request());

    ApiClient.auth.registrationEmail(data)
      .then((responseData) => dispatch(registerEmail.success(responseData, data)))
      .catch((error) => dispatch(registerEmail.failure(error)));
  };
}

export const registerCode = createAsyncAction(
  'AUTH_REGISTRATION_CODE_REQUEST',
  'AUTH_REGISTRATION_CODE_SUCCESS',
  'AUTH_REGISTRATION_CODE_FAIL',
)();

/**
 * @param {string} code
 */
export function registerCodeAsync(code) {
  return (dispatch, getState) => {
    const { id } = getState().auth.registration;

    dispatch(registerCode.request());

    ApiClient.auth.registrationCode({ code, id })
      .then((data) => dispatch(registerCode.success(data)))
      .catch((error) => dispatch(registerCode.failure(error)));
  };
}

export const registerPassword = createAsyncAction(
  'AUTH_REGISTRATION_PASS_REQUEST',
  'AUTH_REGISTRATION_PASS_SUCCESS',
  'AUTH_REGISTRATION_PASS_FAIL',
)();

/**
 * @typedef {Object} params
 * @property {string} password
 * @property {Object} [utmInfo]
 * @property {string} [redirectHostname]
 */
export function registerPasswordAsync(params) {
  return (dispatch, getState) => {
    const { id } = getState().auth.registration;

    dispatch(registerPassword.request());

    ApiClient.auth.registrationPass({ id, ...params })
      .then(redirectIfNeeded(getState, 'regsuccess'))
      .then((data) => dispatch(registerPassword.success(data)))
      .catch((error) => dispatch(registerPassword.failure(error)));
  };
}

export const recoverPassword = createAsyncAction(
  'AUTH_RECOVER_PASS_REQUEST',
  'AUTH_RECOVER_PASS_SUCCESS',
  'AUTH_RECOVER_PASS_FAIL',
)();

/**
 * @param {string} email
 */
export function recoverPasswordAsync(email) {
  return (dispatch, getState) => {
    dispatch(recoverPassword.request());
    const { oauthParams } = getState().auth;
    const data = {
      email,
      ...(oauthParams?.service && { service: oauthParams.service }),
      ...(oauthParams?.redirectUrl && { redirectUrl: oauthParams.redirectUrl }),
    };

    ApiClient.auth.recoverPass(data)
      .then((data) => dispatch(recoverPassword.success(data)))
      .catch((error) => dispatch(recoverPassword.failure(error)));
  };
}

export const checkResetPasswordToken = createAsyncAction(
  'AUTH_CHECK_RESET_PASSWORD_TOKEN_REQUEST',
  'AUTH_CHECK_RESET_PASSWORD_TOKEN_SUCCESS',
  'AUTH_CHECK_RESET_PASSWORD_TOKEN_FAIL',
)();

/**
 * @param {string} token
 */
export function checkResetPasswordTokenAsync(token) {
  return (dispatch) => {
    dispatch(checkResetPasswordToken.request());

    ApiClient.auth.checkResetPasswordToken(token)
      .then((data) => dispatch(checkResetPasswordToken.success(data)))
      .catch((error) => dispatch(checkResetPasswordToken.failure(error)));
  };
}

export const resetAndChangePassword = createAsyncAction(
  'AUTH_RESET_AND_CHANGE_PASSWORD_REQUEST',
  'AUTH_RESET_AND_CHANGE_PASSWORD_SUCCESS',
  'AUTH_RESET_AND_CHANGE_PASSWORD_FAIL',
)();

/**
 * @param {string} token
 * @param {string} password
 */
export function resetAndChangePasswordAsync({ token, password }) {
  return (dispatch) => {
    dispatch(resetAndChangePassword.request());

    ApiClient.auth.resetAndChangePassword({ token, password })
      .then((data) => dispatch(resetAndChangePassword.success(data)))
      .catch((error) => dispatch(resetAndChangePassword.failure(error)));
  };
}

export const checkInviteToken = createAsyncAction(
  'AUTH_CHECK_INVITE_TOKEN_REQUEST',
  'AUTH_CHECK_INVITE_TOKEN_SUCCESS',
  'AUTH_CHECK_INVITE_TOKEN_FAIL',
)();

/**
 * @param {string} token
 */
export function checkInviteTokenAsync(token) {
  return (dispatch) => {
    dispatch(checkInviteToken.request());

    ApiClient.users.checkInviteToken(token)
      .then((data) => dispatch(checkInviteToken.success(data)))
      .catch((error) => dispatch(checkInviteToken.failure(error)));
  };
}

export const changePassword = createAsyncAction(
  'CHANGE_PASSWORD_REQUEST',
  'CHANGE_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_FAIL',
)();

/**
 * @param {string} oldPassword
 * @param {string} newPassword
 */
export function changePasswordAsync({ oldPassword, newPassword }) {
  return (dispatch) => {
    dispatch(changePassword.request());

    ApiClient.users.changePassword({ oldPassword, newPassword })
      .then((data) => {
        dispatch(changePassword.success(data));
        dispatch(showNotification({
          type: 'success',
          message: 'Пароль успешно изменен',
          timeout: 5000,
        }));
      })
      .catch((error) => dispatch(changePassword.failure(error)));
  };
}

export const setInvitedUserPassword = createAsyncAction(
  'AUTH_SET_INVITED_USER_PASSWORD_REQUEST',
  'AUTH_SET_INVITED_USER_PASSWORD_SUCCESS',
  'AUTH_SET_INVITED_USER_PASSWORD_FAIL',
)();

/**
 * @param {string} token
 * @param {string} password
 */
export function setInvitedUserPasswordAsync({ token, password }) {
  return (dispatch) => {
    dispatch(setInvitedUserPassword.request());

    ApiClient.users.setPassword({ token, password })
      .then((data) => dispatch(setInvitedUserPassword.success(data)))
      .catch((error) => dispatch(setInvitedUserPassword.failure(error)));
  };
}

export const unauthorized = createAction('AUTH_UNAUTHORIZED')();

export const setLoginEmail = createAction('AUTH_SET_LOGIN_EMAIL')();

export const setOauthParams = createAction('AUTH_SET_OAUTH_PARAMS')();
