import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';

export const getSubscriptions = createAsyncAction(
  'SUBSCRIPTIONS_GET_LIST_REQUEST',
  'SUBSCRIPTIONS_GET_LIST_SUCCESS',
  'SUBSCRIPTIONS_GET_LIST_FAIL',
)();

export function getSubscriptionsAsync(number) {
  return (dispatch) => {
    dispatch(getSubscriptions.request());

    ApiClient.subscriptions.get(number)
      .then((data) => dispatch(getSubscriptions.success(data, { number })))
      .catch((error) => dispatch(getSubscriptions.failure(error)));
  };
}

export const startSubscriptions = createAsyncAction(
  'SUBSCRIPTIONS_START_REQUEST',
  'SUBSCRIPTIONS_START_SUCCESS',
  'SUBSCRIPTIONS_START_FAIL',
)();

export function startSubscriptionsAsync(model) {
  return (dispatch) => {
    dispatch(startSubscriptions.request());

    ApiClient.subscriptions.start(model)
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Подписки успешно запущены',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(startSubscriptions.success(data));
      })
      .catch((error) => dispatch(startSubscriptions.failure(error)));
  };
}

export const stopSubscriptions = createAsyncAction(
  'SUBSCRIPTIONS_STOP_REQUEST',
  'SUBSCRIPTIONS_STOP_SUCCESS',
  'SUBSCRIPTIONS_STOP_FAIL',
)();

export function stopSubscriptionsAsync(model) {
  return (dispatch) => {
    dispatch(stopSubscriptions.request());

    ApiClient.subscriptions.stop(model)
      .then((data) => {
        const notification = {
          type: 'success',
          message: 'Подписки успешно остановлены',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(stopSubscriptions.success(data));
      })
      .catch((error) => dispatch(stopSubscriptions.failure(error)));
  };
}
