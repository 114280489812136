import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actionsAwiza from '../actions/awiza';
import * as actions from '../actions/companies';
import * as actionsPaycafe from '../actions/paycafe';
import * as actionsPoster from '../actions/poster';
import * as actionsMyclients from '../actions/myclients';
import * as actionsBoxretail from '../actions/boxretail';
import * as actionsBilling from '../actions/billing';
import { getPaymentId } from '../utils/format';
import normalize from '../utils/normalize';
import {
  asyncReducerFactory,
  asyncReducerFactoryByKey,
  getFailureState,
  getRequestState,
  getSuccessState,
} from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction(actions.getCompanies.success, (state, { payload }) => normalize(payload.filter(({ status }) => status !== 'removed'))) // removed -- system status for companies deleted by user, which can be restored in future by creating new company with same inn
  .handleAction(actionsAwiza.connectAwiza.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], awizaInstalledAt: Date.now() },
  }))
  .handleAction(actions.saveCdekSettings.success, (state, { meta }) => {
    if (state[meta.projectId].cdekInstalledAt) return state;

    return {
      ...state,
      [meta.projectId]: { ...state[meta.projectId], cdekInstalledAt: Date.now() },
    };
  })
  .handleAction(actions.connectYabusiness.success, (state, { meta }) => ({
    ...state,
    [meta.projectId]: { ...state[meta.projectId], yandexBusinessInstalledAt: Date.now() },
  }))
  .handleAction(actionsPoster.connectPoster.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], posterInstalledAt: Date.now() },
  }))
  .handleAction(actionsMyclients.connectMyclients.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], myclientsInstalledAt: Date.now() },
  }))
  .handleAction(actions.updateProject.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...meta,
    },
  }))
  .handleAction(actions.updateCompany.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...meta,
      companyStatus: 'infoInProgress',
      // inn: meta.inn ?? '-',
      // address: meta.legalAddress?.address ?? '-',
    },
  }))
  .handleAction(actions.deleteProject.request, (state, { meta }) => {
    if (!state[meta.id]) return state;

    return {
      ...state,
      [meta.id]: {
        ...state[meta.id],
        status: 'loading',
      },
    };
  })
  .handleAction(actions.acceptCompany.request, (state, { meta }) => {
    if (!state[meta.id]) return state;

    return {
      ...state,
      [meta.id]: {
        ...state[meta.id],
        companyStatus: 'loading',
      },
    };
  })
  .handleAction(
    [
      actions.deleteProject.success,
      actions.deleteProject.failure,
    ],
    (state, { meta }) => {
      if (meta.status === 'removed') { // type === getType(actions.deleteProject.success)
        const { [meta.id]: ignore, ...filteredState } = state;
        return filteredState;
      }
      if (!state[meta.id]) return state;
      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          status: meta.status,
        },
      };
    },
  )
  .handleAction(
    [
      actions.acceptCompany.success,
      actions.acceptCompany.failure,
      actions.sendKYC.success,
      actions.sendContractFiles.success,
    ],
    (state, { meta }) => {
      if (!state[meta.id]) return state;
      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          companyStatus: meta.status ?? state[meta.id].companyStatus,
        },
      };
    },
  )
  .handleAction(actions.createProject.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const removedByIdReducer = createReducer({})
  .handleAction(actions.getCompanies.success, (state, { payload }) => normalize(payload.filter(({ status }) => status === 'removed'))) // removed -- system status for companies deleted by user, which can be restored in future by creating new company with same inn
  .handleAction(actions.updateProject.success, (state, { meta }) => {
    const { [meta.id]: ignore, ...filteredState } = state;
    return filteredState;
  })
  .handleAction(actions.deleteProject.success, (state, { meta }) => ({
    ...state,
    ...(meta.status === 'removed' ? { [meta.id]: meta } : null),
  }));

const balanceByCompanyReducer = createReducer({})
  .handleAction(actions.getBalance.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload.balance,
  }));

const failedOutletsByCompanyReducer = createReducer({})
  .handleAction(actions.createOutlet.success, (state, { payload, meta }) => {
    const byGlobalId = payload.reduce((result, outlet, i) => {
      if (!result[outlet.globalId]) {
        result[outlet.globalId] = {};
      }

      if (outlet.error) {
        result[outlet.globalId][outlet.id] = { ...meta[i], ...outlet };
      }

      return result;
    }, {});

    return Object.entries(byGlobalId).reduce((state, [globalId, outlets]) => ({
      ...state,
      [globalId]: outlets,
    }), state);
  });

// TODO: outlets by id (globalId|id)?
const outletsByCompanyReducer = createReducer({})
  .handleAction(
    [actions.getOutlets.success, actions.getOutletsTable.success],
    (state, { payload }) => payload.reduce((result, outlet) => {
      if (!result[outlet.globalId]) {
        result[outlet.globalId] = {};
      }

      result[outlet.globalId][outlet.id] = outlet;

      return result;
    }, {}),
  )
  .handleAction(actions.createOutlet.success, (state, { payload, meta }) => {
    const byGlobalId = payload.reduce((result, outlet, i) => {
      if (!outlet.error && outlet.isCreated) { // TODO: handle errors
        if (!result[outlet.globalId]) {
          result[outlet.globalId] = {};
        }

        result[outlet.globalId][outlet.id] = { ...meta[i], ...outlet };
      }

      return result;
    }, {});

    return Object.entries(byGlobalId).reduce((state, [globalId, outlets]) => ({
      ...state,
      [globalId]: {
        ...state[globalId],
        ...outlets,
      },
    }), state);
  })
  .handleAction(actions.deleteOutlet.success, (state, { payload }) => {
    const { companyId, outletId } = payload;
    const filteredCompanyOutlets = Object.entries(state[companyId])
      .filter(([id]) => id !== outletId)
      .reduce((acc, [id, outlet]) => ({ ...acc, [id]: outlet }), {});

    return {
      ...state,
      [companyId]: filteredCompanyOutlets,
    };
  })
  .handleAction(
    [actions.updateOutlet.success, actions.acceptOutlet.success],
    (state, { payload, meta }) => ({
      ...state,
      [meta.globalId]: {
        ...state[meta.globalId],
        [meta.id]: {
          ...state[meta.globalId][meta.id],
          ...meta,
          ...payload,
        },
      },
    }),
  )
  .handleAction(actions.updateOutletPaymentMethods.success, (state, { meta }) => ({
    ...state,
    [meta.globalId]: {
      ...state[meta.globalId],
      [meta.id]: {
        ...state[meta.globalId][meta.id],
        paymentMethods: meta.paymentMethods,
      },
    },
  }))
  .handleAction(actions.setConnectionType.success, (state, { payload, meta }) => {
    const outletId = meta.id;
    const { companyId } = payload.license;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        [outletId]: {
          ...state[companyId][outletId],
          license: payload.license,
          kktInfo: payload.kktInfo,
        },
      },
    };
  })
  .handleAction(actions.reissueLicenseKey.success, (state, { payload }) => {
    const { companyId, outletId } = payload;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        [outletId]: {
          ...state[companyId][outletId],
          license: payload,
        },
      },
    };
  })
  .handleAction(
    [
      actionsPaycafe.createPaycafeStaticQrCode.success,
      actionsPaycafe.updatePaycafeStaticQrCode.success,
    ],
    (state, { payload, meta }) => {
      const { companyId, outletId, prevOutletId } = meta;
      if (outletId || prevOutletId) {
        const newOutlet = {
          ...state[companyId][outletId || prevOutletId],
        };

        if (!outletId && prevOutletId) { // outletId removed
          delete newOutlet.qrCodeId;
        } else { // outletId added/remains
          newOutlet.qrCodeId = payload.id;
        }

        return {
          ...state,
          [companyId]: {
            ...state[companyId],
            [outletId || prevOutletId]: newOutlet,
          },
        };
      }
      return state;
    },
  )
  .handleAction(actionsPaycafe.deletePaycafeStaticQrCode.success, (state, { meta }) => {
    const { companyId, outletId } = meta;
    if (outletId) {
      const { qrCodeId, ...outlet } = state[companyId][outletId];
      return {
        ...state,
        [companyId]: {
          ...state[companyId],
          [outletId]: outlet,
        },
      };
    }
    return state;
  })
  .handleAction(actionsBoxretail.acceptBoxretailOutlet.success, (state, { meta }) => ({
    ...state,
    [meta.globalId]: {
      ...state[meta.globalId],
      [meta.id]: {
        ...state[meta.globalId][meta.id],
        status: meta.status,
      },
    },
  }))
  .handleAction(actions.updateOrderAutoRelease.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      [meta.id]: {
        ...state[meta.companyId][meta.id],
        completeOnPaid: meta.completeOnPaid,
      },
    },
  }))
  .handleAction(actionsBoxretail.setOrderNotifications.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      [meta.id]: {
        ...state[meta.companyId][meta.id],
        orderNotificationsEmails: meta.emails,
      },
    },
  }));

const outletsTableReducer = createReducer({})
  .handleAction(actions.getOutletsTable.success, (state, { payload }) => payload.reduce((result, outlet) => {
    if (!result[outlet.globalId]) {
      result[outlet.globalId] = [];
    }

    result[outlet.globalId].push(outlet.id);

    return result;
  }, {}))
  .handleAction(actions.createOutlet.success, (state, { payload }) => {
    const byGlobalId = payload.reduce((result, outlet) => {
      if (!outlet.error && outlet.isCreated) { // TODO: handle errors
        if (!result[outlet.globalId]) {
          result[outlet.globalId] = [];
        }

        result[outlet.globalId].push(outlet.id);
      }

      return result;
    }, {});

    return Object.entries(byGlobalId).reduce((state, [globalId, outlets]) => ({
      ...state,
      [globalId]: [...(state[globalId] ?? []), ...outlets],
    }), state);
  });

const stockReducer = createReducer({})
  .handleAction(actions.getOutletStock.success, (state, { payload }) => {
    const next = {
      ...state,
    };

    for (let i = 0; i < payload.length; i++) {
      const info = payload[i];
      const posId = info.posId.replace(
        String.fromCharCode(160), // 1C space symbol
        String.fromCharCode(32), // normal space symbol
      );

      next[posId] = info.stock;
    }

    return next;
  });

const merchantCredentialsByCompanyReducer = createReducer({})
  .handleAction(actions.getSubjectMerchantCredentials.success, (state, { payload, meta }) => {
    const { globalId } = meta;

    return {
      ...state,
      [globalId]: payload,
    };
  });

const merchantCredentialsByOutletReducer = createReducer({})
  .handleAction(actions.getOutletMerchantCredentials.success, (state, { payload, meta }) => {
    const { globalId, id } = meta;

    return {
      ...state,
      [`${globalId}|${id}`]: payload,
    };
  });

const companyInfoByIdReducer = createReducer({})
  .handleAction(actions.getCompanyInfo.success, (state, { payload }) => ({
    ...state,
    [payload.id]: {
      ...payload,
      ...(payload.serviceMerchants ? { serviceMerchants: normalize(payload.serviceMerchants, 'merchantId') } : null),
    },
  }))
  .handleAction(actionsAwiza.connectAwiza.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], awizaInstalledAt: Date.now() },
  }))
  .handleAction(actions.saveCdekSettings.success, (state, { meta: { projectId: id, ...meta } }) => ({
    ...state,
    [id]: { ...state[id], cdekInstalledAt: Date.now(), cdekInfo: meta },
  }))
  .handleAction(actionsPoster.connectPoster.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], posterInstalledAt: Date.now() },
  }))
  .handleAction(actionsMyclients.connectMyclients.success, (state, { meta }) => ({
    ...state,
    [meta.id]: { ...state[meta.id], myclientsInstalledAt: Date.now() },
  }))
  .handleAction(actions.createProject.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      ...meta,
      ...payload,
    },
  }))
  .handleAction(actions.updateProject.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...meta,
    },
  }))
  .handleAction(actions.updateCompany.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...meta,
      companyStatus: 'infoInProgress',
    },
  }))
  .handleAction(actions.deleteProject.request, (state, { meta }) => {
    if (!state[meta.id]) return state;

    return {
      ...state,
      [meta.id]: {
        ...state[meta.id],
        status: 'loading',
      },
    };
  })
  .handleAction(actions.acceptCompany.request, (state, { meta }) => {
    if (!state[meta.id]) return state;

    return {
      ...state,
      [meta.id]: {
        ...state[meta.id],
        companyStatus: 'loading',
      },
    };
  })
  .handleAction(
    [
      actions.deleteProject.success,
      actions.deleteProject.failure,
    ],
    (state, { meta }) => {
      if (!state[meta.id]) return state;

      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          notes: meta.notes ?? state[meta.id].notes,
          status: meta.status,
        },
      };
    },
  )
  .handleAction(
    [
      actions.acceptCompany.success,
      actions.acceptCompany.failure,
      actions.sendKYC.success,
      actions.sendContractFiles.success,
    ],
    (state, { meta }) => {
      if (!state[meta.id]) return state;

      return {
        ...state,
        [meta.id]: {
          ...state[meta.id],
          notes: meta.notes ?? state[meta.id].notes,
          companyStatus: meta.status ?? state[meta.id].companyStatus,
        },
      };
    },
  )
  .handleAction(
    [
      actions.getSecretKey.success,
      actions.refreshSecretKey.success,
    ],
    (state, { payload, meta }) => ({
      ...state,
      [meta.id]: {
        ...state[meta.id],
        secretKey: payload.full,
        secretKeyMasked: payload.masked,
      },
    }),
  )
  .handleAction(actions.deleteSecretKey.success, (state, { meta }) => {
    const { secretKey: ignoreKey, secretKeyMasked: ignoreMaskedKey, ...prevCompanyInfo } = state[meta.id];
    return {
      ...state,
      [meta.id]: {
        ...prevCompanyInfo,
      },
    };
  })
  .handleAction(actions.sendKYC.success, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      kycInfoStatus: 'inProgress',
    },
  }))
  .handleAction([actions.acceptCompanyKYC.success, actions.acceptCompanyKYC.failure], (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      kycInfoStatus: meta.status,
      ...(state[meta.id].kycInfo ? {
        kycInfo: {
          ...state[meta.id].kycInfo,
          notes: meta.notes ?? state[meta.id].kycInfo.notes,
        },
      } : null),
    },
  }))
  .handleAction(actions.acceptCompanyKYC.request, (state, { meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      kycInfoStatus: 'loading',
    },
  }))
  .handleAction([actionsPoster.savePosterProductId.success, actionsPoster.savePosterSettings.success], (state, { meta }) => {
    const { companyId, ...restMeta } = meta;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        posterInfo: {
          ...state[companyId].posterInfo,
          ...restMeta,
        },
      },
    };
  })
  .handleAction(actions.setCashierMigration.success, (state, { meta }) => {
    const { companyId, isActive } = meta;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        cashierMigration: isActive,
      },
    };
  })
  .handleAction(actions.updateShortProjectId.success, (state, { meta }) => {
    const { companyId, boxRetailCustomProjectId } = meta;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        boxRetailCustomProjectId,
      },
    };
  })
  .handleAction(actions.editContract.success, (state, { meta }) => {
    const { companyId, filesOfContracts } = meta;
    if (!state[companyId]) return state;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        contractInfo: {
          ...state[companyId].contractInfo,
          filesOfContracts,
        },
      },
    };
  })
  .handleAction(actions.updateContactSettings.success, (state, { meta }) => {
    const { companyId, data } = meta;
    return {
      ...state,
      [companyId]: {
        ...state[companyId],
        contactInfo: {
          ...state[companyId].contactInfo,
          ...data,
        },
      },
    };
  })
  .handleAction(actions.changeSubscriptionPaymentMethod.success, (state, { meta }) => {
    const { projectId, paymentMethod } = meta;
    return {
      ...state,
      [projectId]: {
        ...state[projectId],
        subscriptionPaymentMethod: paymentMethod,
      },
    };
  });

// lets keep it simple - just request company info again (will be not too often because of task type)
// .handleAction(sendServicesContractsRequest.success, (state, { meta }) => ({
//   ...state,
//   [meta.companyId]: {
//     ...state[meta.companyId],
//     serviceMerchants: {
//       ...state[meta.companyId].serviceMerchants,
//       [meta.merchantId]: {
//         merchantId: meta.merchantId,
//         status: 'pending',
//         contractInfo: {
//           ...state[meta.companyId].serviceMerchants?.[meta.merchantId]?.contractInfo,
//           filesOfContracts: [
//             ...state[meta.companyId].serviceMerchants?.[meta.merchantId]?.contractInfo?.filesOfContracts,
//             ...meta.servicesMerchantContractInfo.filesOfContracts,
//           ],
//         },
//       },
//     },
//   },
// }));

const companyInfoFetchStatusesReducer = createReducer({})
  .handleAction(actions.getCompanyInfo.request, (state, { meta }) => ({ ...state, [meta.id]: getRequestState(state[meta.id]) }))
  .handleAction(actions.getCompanyInfo.success, (state, { payload }) => ({ ...state, [payload.id]: getSuccessState(state[payload.id]) }))
  .handleAction(actions.getCompanyInfo.failure, (state, { payload, meta }) => (
    { ...state, [meta.id]: getFailureState(state[meta.id], { payload }) }));

const companyHistoryReducer = createReducer({})
  .handleAction(actions.getCompanyHistory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload.filter(({ change: { newValue } }) => Object.keys(newValue).length && newValue.status !== 'pending' && newValue.status !== 'inProgress' && !('awizaInstalledAt' in newValue)),
  }));

const outletHistoryReducer = createReducer({})
  .handleAction(actions.getOutletHistory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload.filter(({ change: { newValue } }) => Object.keys(newValue).length),
  }));

const subscriptionsByCompanyReducer = createReducer({})
  .handleAction(actions.getCompanySubscriptions.success, (state, { payload }) => normalize(payload, 'companyId'))
  .handleAction(actions.changeTariff.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      nextTariffId: meta.nextTariffId,
      nextTariffName: meta.nextTariffName,
      nextTariffInterval: meta.nextTariffInterval,
      ...payload,
    },
  }))
  .handleAction(actions.setNextTariff, (state, { payload: { companyId, ...payload } }) => ({
    ...state,
    [companyId]: {
      ...state[companyId],
      ...payload,
    },
  }))
  .handleAction(actions.cancelChangeTariff.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      nextTariffId: null,
      nextTariffName: null,
      nextTariffInterval: null,
    },
  }))
  .handleAction(actions.cancelTariff.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      status: 'canceled',
      nextPaymentDate: null,
      endDate: state[meta.companyId].payedPeriodEnd,
    },
  }))
  .handleAction(actions.resumeTariff.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: payload,
  }));

const paymentsByCompanyReducer = createReducer({})
  .handleAction(actions.getPayments.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: normalize(payload.payments.map((payment) => ({ ...payment, id: getPaymentId(payment) }))),
  }))
  .handleAction(actions.getLastPayment.success, (state, { payload, meta }) => {
    const entries = Object.entries(state[meta.companyId]);
    const payablePaymentIndex = entries.findIndex(([id, payment]) => !payment.orderId);
    entries.splice(payablePaymentIndex, 1, [getPaymentId(payload), payload]);
    return {
      ...state,
      [meta.companyId]: Object.fromEntries(entries),
    };
  })
  .handleAction(actions.cancelTariff.success, (state, { meta }) => {
    const newState = {
      ...state,
      [meta.companyId]: { ...state[meta.companyId] },
    };

    const paymentIdToRemove = Object.keys(state[meta.companyId])
      .find((paymentId) => state[meta.companyId][paymentId].status === 'pending');

    delete newState[meta.companyId][paymentIdToRemove];

    return newState;
  })
  .handleAction(actionsBilling.getPaymentReceipt.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      [meta.id]: {
        ...state[meta.companyId]?.[meta.id],
        receiptLink: payload.link,
      },
    },
  }));

const tmpChangedSubscriptionReducer = createReducer(null)
  .handleAction(actions.changeTariff.request, (state, { meta }) => ({ ...meta, changeState: 'loading' }))
  .handleAction(actions.changeTariff.success, (state, { payload, meta }) => ({ ...meta, ...payload, changeState: 'success' }))
  .handleAction(actions.changeTariff.failure, (state, { payload, meta }) => ({ ...meta, ...payload, changeState: 'failed' }))
  .handleAction(actions.resetChangedSubscription, () => null);

const tmpBindCardUrlReducer = createReducer(null) //TODO is this method necessary?
  .handleAction(actions.bindCard.success, (state, { payload }) => payload.url + payload.token)
  .handleAction(actions.resetBindCardUrl, () => null);

const tariffConfigsByCompanyIdReducer = createReducer({})
  .handleAction(actions.getTariffConfigs.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: payload,
  }));

const appsConfigReducer = createReducer([])
  .handleAction(actions.getAppConfigs.success, (state, { payload }) => payload);

const urlChangeTariffNowReducer = createReducer(null)
  .handleAction(actions.changeTariff.success, (state, { payload, meta }) => (meta.now ? payload : null))
  .handleAction(actions.resetUrlChangeTariffNow, () => null);

const noAdditionalPriceValue = {
  month: {
    applyNow: { amount: 0, currency: 643, exp: 2 },
    applyOnPayDay: { amount: 0, currency: 643, exp: 2 },
    interval: 'month',
  },
  year: {
    applyNow: { amount: 0, currency: 643, exp: 2 },
    applyOnPayDay: { amount: 0, currency: 643, exp: 2 },
    interval: 'year',
  },
};

const tariffAdditionalPricesReducer = createReducer({})
  .handleAction(actions.getTariffAdditionalPrice.success, (state, { payload, meta }) => ({
    ...state,
    [`${meta.planId}`]: payload.reduce((acc, priceInfo) => {
      acc[priceInfo.interval] = priceInfo;
      return acc;
    }, {}),
  }))
  // .handleAction(actions.changeTariff.success, (state, { payload, meta }) => ({
  //   ...state,
  //   [meta.tariffId]: noAdditionalPriceValue,
  // }))
  .handleAction(actions.resetAdditionalPrice, () => ({}));

const additionalPriceStatusesReducer = createReducer({})
  .handleAction(actions.getTariffAdditionalPrice.request, (state, { meta }) => ({
    ...state,
    [meta.planId]: getRequestState(state[meta.planId]),
  }))
  .handleAction(actions.getTariffAdditionalPrice.success, (state, { meta }) => ({
    ...state,
    [meta.planId]: getSuccessState(state[meta.planId]),
  }))
  .handleAction(actions.getTariffAdditionalPrice.failure, (state, { payload, meta }) => ({
    ...state,
    [meta.planId]: getFailureState(state[meta.planId], { payload }),
  }));

const invoiceInfoStatusesReducer = createReducer({})
  .handleAction(actions.createInvoice.request, (state, { meta }) => ({
    ...state,
    [meta.id]: getRequestState(state[meta.id]),
  }))
  .handleAction(actions.createInvoice.success, (state, { meta }) => ({
    ...state,
    [meta.id]: getSuccessState(state[meta.id]),
  }))
  .handleAction(actions.createInvoice.failure, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: getFailureState(state[meta.id], { payload }),
  }));

const invoiceInfoByIdReducer = createReducer({})
  .handleAction(actions.createInvoice.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload,
  }));

export default combineReducers({
  byId: byIdReducer,
  removedById: removedByIdReducer,
  balanceByCompany: balanceByCompanyReducer,
  failedOutletsByCompany: failedOutletsByCompanyReducer,
  outletsByCompany: outletsByCompanyReducer,
  outletsTable: outletsTableReducer,
  stockByPosId: stockReducer,
  merchantCredentialsByCompany: merchantCredentialsByCompanyReducer,
  merchantCredentialsByOutlet: merchantCredentialsByOutletReducer,
  companyInfoById: companyInfoByIdReducer,
  companyInfoFetchStatuses: companyInfoFetchStatusesReducer,
  companyHistory: companyHistoryReducer,
  outletHistory: outletHistoryReducer,
  subscriptionsByCompany: subscriptionsByCompanyReducer,
  paymentsByCompany: paymentsByCompanyReducer,
  tmpChangedSubscription: tmpChangedSubscriptionReducer,
  tmpBindCardUrl: tmpBindCardUrlReducer,
  tariffConfigsByCompany: tariffConfigsByCompanyIdReducer,
  getTariffConfigsStatuses: asyncReducerFactoryByKey(actions.getTariffConfigs, 'companyId'),
  tariffAppsConfig: appsConfigReducer,
  tariffAdditionalPrices: tariffAdditionalPricesReducer,
  additionalPriceStatuses: asyncReducerFactoryByKey(actions.getTariffAdditionalPrice, 'planId'),
  urlChangeTariffNow: urlChangeTariffNowReducer,
  invoiceInfoById: invoiceInfoByIdReducer,
  invoiceInfoStatuses: invoiceInfoStatusesReducer,
  cancelTariffFetchStatusByCompanyId: asyncReducerFactoryByKey(actions.cancelTariff, 'companyId'),
  resumeTariffFetchStatusByCompanyId: asyncReducerFactoryByKey(actions.resumeTariff, 'companyId'),
  companiesFetchStatus: asyncReducerFactory(actions.getCompanies),
  outletsFetchStatus: asyncReducerFactory(actions.getOutlets),
  outletsTableFetchStatus: asyncReducerFactory(actions.getOutletsTable),
  outletStockFetchStatus: asyncReducerFactory(actions.getOutletStock),
  outletCreateStatus: asyncReducerFactory(actions.createOutlet),
  outletUpdateStatus: asyncReducerFactory(actions.updateOutlet),
  updateOutletPaymentMethodsStatus: asyncReducerFactory(actions.updateOutletPaymentMethods),
  acceptOutletStatus: asyncReducerFactory(actions.acceptOutlet),
  updateOrderAutoReleaseStatus: asyncReducerFactory(actions.updateOrderAutoRelease),
  createProjectStatus: asyncReducerFactory(actions.createProject),
  updateProjectStatus: asyncReducerFactory(actions.updateProject),
  updateCompanyStatus: asyncReducerFactory(actions.updateCompany),
  deleteProjectStatus: asyncReducerFactory(actions.deleteProject),
  acceptCompanyStatus: asyncReducerFactory(actions.acceptCompany),
  acceptCompanyKYCStatus: asyncReducerFactory(actions.acceptCompanyKYC),
  getCompanyInfoStatus: asyncReducerFactory(actions.getCompanyInfo),
  getCompanyHistoryStatus: asyncReducerFactory(actions.getCompanyHistory),
  getOutletHistoryStatus: asyncReducerFactory(actions.getOutletHistory),
  sendKYCStatus: asyncReducerFactory(actions.sendKYC),
  sendKYCFilesStatus: asyncReducerFactory(actions.sendKYCFiles),
  sendContractFilesStatus: asyncReducerFactory(actions.sendContractFiles),
  saveMultipleOutletsStatus: asyncReducerFactory(actions.saveMultipleOutlets),
  saveMultipleEmployeesStatus: asyncReducerFactory(actions.saveMultipleEmployees),
  getSecretKeyStatus: asyncReducerFactory(actions.getSecretKey),
  refreshSecretKeyStatus: asyncReducerFactory(actions.refreshSecretKey),
  deleteSecretKeyStatus: asyncReducerFactory(actions.deleteSecretKey),
  getSubscriptionsStatus: asyncReducerFactory(actions.getCompanySubscriptions),
  getPaymentsStatus: asyncReducerFactory(actions.getPayments),
  changeTariffStatus: asyncReducerFactory(actions.changeTariff),
  cancelTariffStatus: asyncReducerFactory(actions.cancelTariff),
  cancelChangeTariffStatus: asyncReducerFactory(actions.cancelChangeTariff),
  getLastPaymentStatus: asyncReducerFactory(actions.getLastPayment),
  bindCardFetchStatus: asyncReducerFactory(actions.bindCard),
  cashierMigrationFetchStatus: asyncReducerFactory(actions.setCashierMigration),
  updateShortProjectIdFetchStatus: asyncReducerFactory(actions.updateShortProjectId),
  getTariffConfigsStatus: asyncReducerFactory(actions.getTariffConfigs),
  getAppsConfigStatus: asyncReducerFactory(actions.getAppConfigs),
  getTariffAdditionalPriceStatus: asyncReducerFactory(actions.getTariffAdditionalPrice),
  setConnectionTypeFetchStatus: asyncReducerFactory(actions.setConnectionType),
  reissueKeyFetchStatus: asyncReducerFactory(actions.reissueLicenseKey),
  editContractStatus: asyncReducerFactory(actions.editContract),
  updateContactSettingsFetchStatus: asyncReducerFactory(actions.updateContactSettings),
  saveCdekSettingsFetchStatus: asyncReducerFactory(actions.saveCdekSettings),
  changeSubscriptionPaymentMethodStatus: asyncReducerFactory(actions.changeSubscriptionPaymentMethod),
  createInvoiceFetchStatus: asyncReducerFactory(actions.createInvoice),
  connectYabusinessStatus: asyncReducerFactory(actions.connectYabusiness),
});
