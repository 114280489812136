export function loadScript(url, id) {
  return new Promise((resolve, reject) => {
    const fjs = document.getElementsByTagName('script')[0];
    const alreadyInsertedElement = document.getElementById(id);
    const handleOnLoad = (e) => {
      resolve();
      e.currentTarget.dataset.loaded = true;
    };

    const handleOnError = (e) => {
      reject();
      e.currentTarget.remove(); // to make possible new try
    };

    if (alreadyInsertedElement) {
      if (alreadyInsertedElement.dataset.loaded) {
        resolve();
      } else {
        alreadyInsertedElement.onload = handleOnLoad;
        alreadyInsertedElement.onerror = handleOnError;
      }
      return;
    }

    const js = document.createElement('script');
    js.id = id;
    js.src = url;
    js.onload = handleOnLoad;
    js.onerror = handleOnError;
    fjs.parentNode.insertBefore(js, fjs);
  });
}
