import classnames from 'classnames';
import { Form, Formik } from 'formik';
import React, { memo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as yup from 'yup';
import { Button, FormControl, Hint } from '@teko/ui-kit';
import { registerEmailAsync } from '../../actions/auth';
import { getAuthRequestExtraData } from '../../utils';
import { getReferralByUrl } from '../../utils/getReferral';
import { InputField } from '../common/forms/fields';
import { useAsyncStatus, useQueryParams } from '../common/hooks';
import usePassQueryToSessionStorage from '../common/hooks/usePassQueryToSessionStorage';
import CollapsibleElement from '../common/ui/CollapsibleElement';
import { triggerAllMetricsEvents } from '../common/utils/metrics';
import GoogleLoginButton from './GoogleLoginButton';
import AppleLoginButton from './AppleLoginButton';
import YandexLoginButton from './YandexLoginButton';
import VKLoginButton from './VKLoginButton';

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Required'),
});

function RegistrationFormStepOne({ email, onStepChange }) {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const [params] = useQueryParams();
  const dispatch = useDispatch();
  const { loading, error: regEmailError, success } = useAsyncStatus((state) => state.auth.regEmailStatus);
  const { error: googleLoginError } = useAsyncStatus((state) => state.auth.googleLoginStatus);
  const { error: appleLoginError } = useAsyncStatus((state) => state.auth.appleLoginStatus);
  const { error: facebookLoginError } = useAsyncStatus((state) => state.auth.facebookLoginStatus);
  const authenticated = useSelector((state) => state.auth.login.authenticated);
  const error = regEmailError || googleLoginError || appleLoginError || facebookLoginError;
  const hasError = !!error;
  const brand = getReferralByUrl() || 'teko';
  usePassQueryToSessionStorage(brand === 'talktoBusiness' ? { service: 'talkto', ...params } : params);

  const handleSubmit = useCallback(({ email }) => {
    dispatch(registerEmailAsync({ email, ...getAuthRequestExtraData(false) }));
    triggerAllMetricsEvents('regstep2');
  }, []);

  useEffect(() => {
    if (success || params.haveAccount) {
      onStepChange((step) => step + 1);
    }
  }, [success]);

  useEffect(() => {
    if (authenticated) {
      history.push(params.origin ?? '/lk');
    }
  }, [authenticated]);

  const submitBtn = (
    <Button className="registration-form-step-btn" design="primary" size="l" loading={loading}>
      {t('next')}
    </Button>
  );

  return (
    <Formik initialValues={{ email }} validationSchema={schema} onSubmit={handleSubmit} validateOnBlur={false}>
      <Form className={classnames('registration-form-step', '_step-one', { 'has-error': hasError })}>
        {/*<div className="registration-form-step-hint">{t('registration-step-one-hint')}</div>*/}

        <CollapsibleElement active={hasError}>
          <div className="api-error-wrap">
            <Hint type="error" className="error-auth-hint">
              {hasError && (regEmailError?.code === 1307 ? t('already-exists') : t([`common:errors.${error?.code}`, 'common:errors.default']))}
            </Hint>
          </div>
        </CollapsibleElement>

        <FormControl>
          <InputField name="email" type="email" autoComplete="email" placeholder={t('enter-email')} autoFocus />
        </FormControl>

        <div className="registration-terms-of-use" dangerouslySetInnerHTML={{ __html: t('accept-terms-of-use') }} />

        <div className="registration-form-step-footer">
          {brand === 'talkto' && (
            <ThemeProvider theme={{ primary: '#452994' }}>
              {submitBtn}
            </ThemeProvider>
          )}
          {brand !== 'talkto' && submitBtn}

          <div className="go-back">
            <Link to="/login" className="go-back-link">
              {t('return-back')}
            </Link>
          </div>

          <div className="social-auth-divider">
            <span>{t('or-enter-via')}</span>
          </div>

          <div className="social-auth">
            <GoogleLoginButton />
            {/*<FacebookLoginButton />*/}
            <AppleLoginButton />
            <YandexLoginButton />
            <VKLoginButton />
          </div>

          {/*<div className="no-account">*/}
          {/*  <span>{t('has-account-question')}</span>*/}
          {/*  <Link to="/login" className="registration-link">*/}
          {/*    {t('enter')}*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </Form>
    </Formik>
  );
}

RegistrationFormStepOne.propTypes = {
  email: PropTypes.string.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default memo(RegistrationFormStepOne);
