import { Switch } from '@teko/ui-kit';
import classnames from 'classnames';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FormGroupTitleWithSwitchField({ name, className, title }) {
  const { t } = useTranslation();
  const [field] = useField(name);
  const handleChange = useCallback((value) => field.onChange({ target: { name, value } }), [name, field.onChange]);

  return (
    <div className={classnames('title-with-switch', className)}>
      <span>{title}</span>
      <Switch checked={field.value} onChange={handleChange}>
        {t(field.value ? 'turn-on' : 'switch-off')}
      </Switch>
    </div>
  );
}

FormGroupTitleWithSwitchField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormGroupTitleWithSwitchField;
