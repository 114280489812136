import React from 'react';
import Viewport from './Viewport';

function withViewport(sizes = undefined) {
  function Wrapper(Component) {
    const WithViewport = props => <Viewport render={viewportProps => <Component {...props} {...viewportProps} />} sizes={sizes} />;

    WithViewport.displayName = 'withViewport';
    WithViewport.WrappedComponent = Component;

    return WithViewport;
  }

  return Wrapper;
}

export default withViewport;
