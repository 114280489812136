import { createSelector } from 'reselect';

export const unreadNotificationsSelector = createSelector(
  (state) => state.notifications.unread,
  (state) => state.notifications.byId,
  ({ ids, totalCount }, byId) => ({
    notifications: ids.map((id) => byId[id]),
    totalCount,
  }),
);

export const notificationsTableSelector = createSelector(
  (state) => state.notifications.table.ids,
  (state) => state.notifications.byId,
  (ids, byId) => ids.map((id) => byId[id]),
);
