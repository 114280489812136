import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/support';
import { asyncReducerFactory } from '../utils/redux-utils';

const rutokenCertificatesReducer = createReducer({ certificates: [], totalCount: 0 })
  .handleAction(actions.getRutokenCertificates.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(actions.createRutokenCertificate.success, (state, { payload }) => ({
    ...state,
    certificates: [...state.certificates, payload],
  }))
  .handleAction(actions.updateRutokenCertificate.success, (state, { payload, meta }) => {
    const newCertificatesList = state.certificates.filter((item) => item.id !== meta.oldId);
    newCertificatesList.push(payload);
    return {
      ...state,
      certificates: newCertificatesList,
    };
  })
  .handleAction(actions.deleteRutokenCertificate.success, (state, { meta }) => {
    const newCertificatesList = state.certificates.filter((item) => item.id !== meta.id);
    return {
      ...state,
      certificates: newCertificatesList,
    };
  });

export default combineReducers({
  rutokenCertificates: rutokenCertificatesReducer,
  rutokenCertificatesFetchStatus: asyncReducerFactory(actions.getRutokenCertificates),
  createRutokenCertificateFetchStatus: asyncReducerFactory(actions.createRutokenCertificate),
  updateRutokenCertificateFetchStatus: asyncReducerFactory(actions.updateRutokenCertificate),
  deleteRutokenCertificateFetchStatus: asyncReducerFactory(actions.deleteRutokenCertificate),
});
