import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { editAwizaCategoryPositions } from '../../actions/awiza';
import * as actions from '../../actions/awiza';
import normalize from '../../utils/normalize';
import { asyncReducerFactory, getFailureState, getRequestState, getSuccessState } from '../../utils/redux-utils';
import promotions from './promotions';

const categoriesByCompanyReducer = createReducer({})
  .handleAction(actions.getAwizaCategories.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: payload,
  }))
  .handleAction(actions.addAwizaCategory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: [
      ...state[meta.companyId],
      payload,
    ],
  }));

const awizaCategoriesFetchStatusReducer = createReducer({})
  .handleAction(actions.getAwizaCategories.request, (state, { meta }) => (
    { ...state, [meta.companyId]: getRequestState(state[meta.companyId]) }
  ))
  .handleAction(actions.getAwizaCategories.success, (state, { meta }) => (
    { ...state, [meta.companyId]: getSuccessState(state[meta.companyId]) }
  ))
  .handleAction(actions.getAwizaCategories.failure, (state, { payload, meta }) => (
    { ...state, [meta.companyId]: getFailureState(state[meta.companyId], { payload }) }
  ))
  .handleAction(actions.editAwizaCategoryPositions.success, (state, { payload }) => payload);

const fieldsByCategoryIdReducer = createReducer({})
  .handleAction(actions.getAwizaCategoryFields.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload,
    // primary: payload.primary ? payload.primary.filter((field) => field.id) : [],
    // additional: payload.additional ? payload.additional.filter((field) => field.id) : [],
  }));

const warehousesByCompanyReducer = createReducer({})
  .handleAction(actions.getAwizaWarehouses.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: payload,
  }))
  .handleAction([actions.createWarehouse.success, actions.createBoxretailWarehouse.success], (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: [...state[meta.companyId], payload],
  }))
  .handleAction(actions.deleteWarehouse.success, (state, { meta }) => {
    const changedWarehouses = normalize(state[meta.companyId]);
    changedWarehouses[meta.warehouseId] = { ...changedWarehouses[meta.warehouseId], status: 'deleted' };

    return {
      ...state,
      [meta.companyId]: Object.values(changedWarehouses),
    };
  });

const productsByIdReducer = createReducer({})
  .handleAction(actions.getAwizaProducts.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.products),
  }))
  .handleAction(actions.addAwizaProduct.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }))
  .handleAction(actions.duplicateAwizaProduct.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const justAddedProductReducer = createReducer({})
  .handleAction(actions.addAwizaProduct.success, (state, { payload }) => payload);

const justUpdatedProductPropertiesReducer = createReducer({})
  .handleAction(actions.updateProductProperties.success, (state, { payload }) => payload);

const productsToPublishByIdReducer = createReducer({})
  .handleAction(actions.getAwizaProductsToPublish.success, (state, { payload }) => ({
    ...normalize(payload.products),
  }));

const productDetailsReducer = createReducer({})
  .handleAction(actions.getAwizaProductDetails.success, (state, { payload }) => ({
    ...payload,
  }));

const productsDetailsByIdReducer = createReducer({})
  .handleAction(actions.getAwizaProductDetails.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: payload,
  }));

const productStockReducer = createReducer({})
  .handleAction(actions.getAwizaProductStock.success, (state, { payload }) => ({
    ...payload,
  }));

const stockByWarehouseIdReducer = createReducer({})
  .handleAction(actions.getAwizaProductStock.success, (state, { payload }) => ({
    ...normalize(payload, 'warehouseId'),
  }));

const publishedProductsByPlatformReducer = createReducer({})
  .handleAction([actions.getPublishedProducts.success, actions.getPublishedProducts.failure], (state, { payload, meta }) => ({
    ...state,
    [meta.platform]: {
      ...state[meta.platform],
      [meta.warehouse]: payload?.products ?? [],
    },
  }));

const publishedWarehousesByPlatformReducer = createReducer({})
  .handleAction(actions.getPublishedWarehouses.success, (state, { payload, meta }) => ({
    ...state,
    [meta.platform]: payload,
  }));

const vkAuthSessionDataByCompanyIdReducer = createReducer({})
  .handleAction(actions.getVkGroups.success, (state, { payload, meta }) => ({
    [meta.companyId]: payload,
  }));

const marketplacesByCompanyIdReducer = createReducer({})
  .handleAction(
    actions.getAwizaMarketplaces.success,
    (state, { payload }) => payload.reduce((acc, { projectId, marketplace: rawMarketplace, ...setting }) => {
      const marketplace = rawMarketplace === 'goods' ? 'sbermegamarket' : rawMarketplace;

      if (!acc[projectId]) {
        acc[projectId] = {};
      }
      if (!acc[projectId][marketplace]) {
        acc[projectId][marketplace] = {
          settings: [],
        };
      }

      acc[projectId][marketplace].settings.push(setting);

      return acc;
    }, {}),
  )
  .handleAction(actions.getSbermegamarketSettings.success, (state, { payload }) => ({
    ...state,
    [payload.companyId]: {
      ...state[payload.companyId],
      sbermegamarket: {
        settings: [
          {
            ...state[payload.companyId].sbermegamarket.settings[0],
            ...payload,
          },
        ],
      },
    },
  }))
  .handleAction(actions.saveGoodsSettings.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      sbermegamarket: {
        settings: [
          {
            merchantId: meta.merchantId,
            token: meta.token,
          },
        ],
      },
    },
  }))
  .handleAction(
    actions.saveVKSettings.success,
    (state, { payload }) => ({
      ...state,
      [payload.projectId]: {
        ...state[payload.projectId],
        [payload.marketplace]: {
          ...state[payload.projectId]?.[payload.marketplace],
          settings: [...(state[payload.projectId]?.[payload.marketplace]?.settings ?? []), payload],
        },
      },
    }),
  )
  .handleAction(actions.saveOzonSettings.success, (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      ozon: {
        settings: [
          {
            ...meta.data,
          },
        ],
      },
    },
  }))
  .handleAction([
    actions.changeVKSettingStatus.request,
    actions.changeVKSettingStatus.success,
    actions.changeVKSettingStatus.failure,
  ], (state, { meta }) => ({
    ...state,
    [meta.companyId]: {
      ...state[meta.companyId],
      [meta.marketplaceId]: {
        ...state[meta.companyId][meta.marketplaceId],
        settings: meta.newStatus === 'removed' ?
          state[meta.companyId][meta.marketplaceId].settings.filter((setting) => setting.id !== meta.settingId) :
          state[meta.companyId][meta.marketplaceId].settings.map((setting) => (
            setting.id === meta.settingId ? { ...setting, status: meta.newStatus } : setting
          )),
      },
    },
  }));

const vkPublishedProductsByCompanyIdReducer = createReducer({})
  .handleAction(actions.getVkPublishedProducts.success, (state, { payload, meta }) => ({
    [meta.companyId]: payload,
  }));

const vkCategoriesMatchByCompanyIdReducer = createReducer({})
  .handleAction(actions.getVkCategoriesMatch.success, (state, { payload, meta }) => ({
    [meta.companyId]: payload,
  }));

const mock = [
  { warehouseId: 12, name: 'Первый склад', isRfbs: true },
  { warehouseId: 23, name: 'Второй склад', isRfbs: true },
  { warehouseId: 34, name: 'Третий склад', isRfbs: true },
  { warehouseId: 45, name: 'Четвертый склад', isRfbs: true },
  { warehouseId: 56, name: 'Пятый склад', isRfbs: true },
];

const ozonWarehousesByCompanyIdReducer = createReducer({})
  .handleAction(actions.getOzonWarehouses.success, (state, { payload, meta }) => ({
    ...state,
    [meta.companyId]: payload.length !== 0 ? normalize(payload, 'warehouseId') : normalize(mock, 'warehouseId'),
  }));

const awizaProductsExtraReducer = createReducer([])
  .handleAction(actions.getAwizaProductsExtra.success, (state, { payload }) => payload);

const productsCountByCategoryIdReducer = createReducer({})
  .handleAction(actions.getProductsCount.request, (state, { meta }) => ({
    ...state,
    [meta.categoryId]: {
      ...state[meta.categoryId],
      loading: true,
    },
  }))
  .handleAction(actions.getProductsCount.failure, (state, { payload, meta }) => ({
    ...state,
    [meta.categoryId]: {
      ...state[meta.categoryId],
      loading: false,
      error: payload,
    },
  }))
  .handleAction(actions.getProductsCount.success, (state, { payload, meta }) => ({
    ...state,
    [meta.categoryId]: {
      count: payload.count,
      loading: false,
    },
  }));

const availableWarehousesReducer = createReducer([])
  .handleAction(actions.getAvailableWarehouses.success, (state, { payload }) => payload);

const productsStocksByWarehouseIdReducer = createReducer({})
  .handleAction(actions.getProductsStocks.success, (state, { payload, meta }) => ({
    ...state,
    [meta.warehouseId]: payload,
  }));

const productsTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getAwizaProducts.success, (state, { payload, meta }) => (meta.productIds ? state : {
    ids: payload.products.map((item) => item.id),
    totalCount: payload.totalCount,
  }))
  .handleAction(actions.clearAwizaProductsTable, () => ({ ids: [], totalCount: 0 }));

const publishedProductsTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getPublishedProducts.success, (state, { payload }) => ({
    ids: payload.products.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const productsToPublishTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getAwizaProductsToPublish.success, (state, { payload }) => ({
    ids: payload.products.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const lastAddedCategoryReducer = createReducer({})
  .handleAction(actions.addAwizaCategory.success, (state, { payload }) => payload);

export default combineReducers({
  categoriesByCompany: categoriesByCompanyReducer,
  fieldsByCategoryId: fieldsByCategoryIdReducer,
  warehousesByCompany: warehousesByCompanyReducer,
  productsById: productsByIdReducer,
  justAddedProduct: justAddedProductReducer,
  justUpdatedProductProperties: justUpdatedProductPropertiesReducer,
  productsToPublishById: productsToPublishByIdReducer,
  publishedProductsByPlatform: publishedProductsByPlatformReducer,
  publishedWarehousesByPlatform: publishedWarehousesByPlatformReducer,
  productDetails: productDetailsReducer,
  productsDetailsById: productsDetailsByIdReducer,
  productStock: productStockReducer,
  stockByWarehouseId: stockByWarehouseIdReducer,
  vkAuthSessionDataByCompanyId: vkAuthSessionDataByCompanyIdReducer,
  marketplacesByCompanyId: marketplacesByCompanyIdReducer,
  vkPublishedProductsByCompanyId: vkPublishedProductsByCompanyIdReducer,
  vkCategoriesMatchByCompanyId: vkCategoriesMatchByCompanyIdReducer,
  ozonWarehousesByCompanyId: ozonWarehousesByCompanyIdReducer,
  awizaCategoriesFetchStatus: awizaCategoriesFetchStatusReducer,
  productsExtra: awizaProductsExtraReducer,
  productsCountByCategoryId: productsCountByCategoryIdReducer,
  availableWarehouses: availableWarehousesReducer,
  productsStocksByWarehouseId: productsStocksByWarehouseIdReducer,
  productsTable: productsTableReducer,
  publishedProductsTable: publishedProductsTableReducer,
  productsToPublishTable: productsToPublishTableReducer,
  lastAddedCategory: lastAddedCategoryReducer,
  promotions,
  existingSimilarProductsFetchStatus: asyncReducerFactory(actions.getExistingSimilarProducts),
  existingRelatedProductsFetchStatus: asyncReducerFactory(actions.getExistingRelatedProducts),
  similarOrRelatedProductsFetchStatus: asyncReducerFactory(actions.getSimilarOrRelatedProducts),
  marketplacesFetchStatus: asyncReducerFactory(actions.getAwizaMarketplaces),
  awizaProductsFetchStatus: asyncReducerFactory(actions.getAwizaProducts),
  awizaCategoryFieldsFetchStatus: asyncReducerFactory(actions.getAwizaCategoryFields),
  addAwizaProductFetchStatus: asyncReducerFactory(actions.addAwizaProduct),
  deleteAwizaProductsFetchStatus: asyncReducerFactory(actions.deleteAwizaProducts),
  awizaProductDetailsFetchStatus: asyncReducerFactory(actions.getAwizaProductDetails),
  createBoxretailWarehouseFetchStatus: asyncReducerFactory(actions.createBoxretailWarehouse),
  getWarehousesFetchStatus: asyncReducerFactory(actions.getAwizaWarehouses),
  createWarehouseFetchStatus: asyncReducerFactory(actions.createWarehouse),
  editWarehouseFetchStatus: asyncReducerFactory(actions.editWarehouse),
  deleteWarehouseFetchStatus: asyncReducerFactory(actions.deleteWarehouse),
  connectAwizaFetchStatus: asyncReducerFactory(actions.connectAwiza),
  getProductStockFetchStatus: asyncReducerFactory(actions.getAwizaProductStock),
  updateStockInfoFetchStatus: asyncReducerFactory(actions.updateStockInfo),
  getAwizaProductsToPublishFetchStatus: asyncReducerFactory(actions.getAwizaProductsToPublish),
  publishProductsFetchStatus: asyncReducerFactory(actions.publishProducts),
  unpublishProductsFetchStatus: asyncReducerFactory(actions.unpublishProducts),
  getPublishedProductsFetchStatus: asyncReducerFactory(actions.getPublishedProducts),
  getPublishedWarehousesFetchStatus: asyncReducerFactory(actions.getPublishedWarehouses),
  editProductFetchStatus: asyncReducerFactory(actions.editProduct),
  saveGoodsSettingsFetchStatus: asyncReducerFactory(actions.saveGoodsSettings),
  getSbermegamarketSettingsFetchStatus: asyncReducerFactory(actions.getSbermegamarketSettings),
  addAwizaCategoryStatus: asyncReducerFactory(actions.addAwizaCategory),
  deleteCategoryStatus: asyncReducerFactory(actions.deleteCategory),
  editCategoryStatus: asyncReducerFactory(actions.editAwizaCategory),
  editAwizaCategoryPositionsStatus: asyncReducerFactory(actions.editAwizaCategoryPositions),
  getVkGroupsStatus: asyncReducerFactory(actions.getVkGroups),
  getVkPublishedProductsStatus: asyncReducerFactory(actions.getVkPublishedProducts),
  saveVkSettingsStatus: asyncReducerFactory(actions.saveVKSettings),
  getVkCategoriesMatchStatus: asyncReducerFactory(actions.getVkCategoriesMatch),
  saveVkCategoriesMatchStatus: asyncReducerFactory(actions.saveVkCategoriesMatch),
  changeVKSettingStatus: asyncReducerFactory(actions.changeVKSettingStatus),
  checkOzonTokenStatus: asyncReducerFactory(actions.checkOzonToken),
  saveOzonSettingsStatus: asyncReducerFactory(actions.saveOzonSettings),
  getOzonWarehousesFetchStatus: asyncReducerFactory(actions.getOzonWarehouses),
  getProductsExtraFetchStatus: asyncReducerFactory(actions.getAwizaProductsExtra),
  getCategoriesFetchStatus: asyncReducerFactory(actions.getAwizaCategories),
  getProductsCountFetchStatus: asyncReducerFactory(actions.getProductsCount),
  getAvailableWarehousesFetchStatus: asyncReducerFactory(actions.getAvailableWarehouses),
  updateProductPropertiesStatus: asyncReducerFactory(actions.updateProductProperties),
  updateProductsStocksStatus: asyncReducerFactory(actions.updateProductsStocks),
  getProductsStocksStatus: asyncReducerFactory(actions.getProductsStocks),
  duplicateAwizaProductStatus: asyncReducerFactory(actions.duplicateAwizaProduct),
});
