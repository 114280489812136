import { createAction, createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getBillingMethods = createAsyncAction(
  'BILLING_GET_METHODS_REQUEST',
  'BILLING_GET_METHODS_SUCCESS',
  'BILLING_GET_METHODS_FAIL',
)();

// function getMockBillingMethods(companiesById) {
//   const statuses = ['primary', 'reserve'];
//   const brands = [
//     'uatp',
//     'visa',
//     'mastercard',
//     'discover',
//     'american express',
//     'nspk mir',
//     'maestro',
//   ];
//   const companyIds = Object.keys(companiesById);
//   return [...companyIds, ...companyIds, ...companyIds].map((companyId) => ({
//     companyId,
//     brand: brands[Math.floor(Math.random() * brands.length)],
//     pan: `•••• ${Math.floor(Math.random() * (5999 - 4000) + 4000)}`,
//     status: statuses[Math.floor(Math.random() * statuses.length)],
//   }));
// }

export function getBillingMethodsAsync(force = false, { prevMethodsCount, companyId } = {}) {
  return (dispatch, getState) => {
    const fetchStatus = getState().billing.methodsFetchStatus;

    if (!force && fetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getBillingMethods.request());

    let requestCounter = 0;

    function getMethods() {
      ApiClient.billing.getBillingMethods()
        .then((response) => {
          if (typeof prevMethodsCount !== 'undefined') {
            if (response.methods.filter((method) => method.companyId === companyId).length === prevMethodsCount && requestCounter < 2) {
              setTimeout(() => {
                getMethods();
              }, 1500);
              requestCounter++;
            } else {
              dispatch(getBillingMethods.success(response));
            }
          } else {
            dispatch(getBillingMethods.success(response));
          }
        })
        .catch((error) => dispatch(getBillingMethods.failure(error)));
    }

    getMethods();
  };
}

export const deleteBillingMethod = createAsyncAction(
  'BILLING_DELETE_METHOD_REQUEST',
  'BILLING_DELETE_METHOD_SUCCESS',
  'BILLING_DELETE_METHOD_FAIL',
)();

export function deleteBillingMethodAsync(method, companyId) {
  return (dispatch) => {
    dispatch(deleteBillingMethod.request());

    ApiClient.billing.deleteBillingMethod(method.id)
      .then((response) => dispatch(deleteBillingMethod.success(response, { ...method, companyId })))
      .catch((error) => dispatch(deleteBillingMethod.failure(error)));
  };
}

export const bindCard = createAsyncAction(
  'COMPANIES_BIND_CARD_REQUEST',
  'COMPANIES_BIND_CARD_SUCCESS',
  'COMPANIES_BIND_CARD_FAIL',
)();

export const resetBindCardUrl = createAction('COMPANIES_RESET_BIND_CARD_URL')();

export function bindCardAsync(companyId) {
  return (dispatch) => {
    dispatch(bindCard.request());

    ApiClient.billing.bindCard(companyId)
      .then((response) => dispatch(bindCard.success(response)))
      .catch((error) => dispatch(bindCard.failure(error)));
  };
}

export const setMainCard = createAsyncAction(
  'COMPANIES_SET_MAIN_CARD_REQUEST',
  'COMPANIES_SET_MAIN_CARD_SUCCESS',
  'COMPANIES_SET_MAIN_CARD_FAIL',
)();

export function setMainCardAsync({ id, companyId }) {
  return (dispatch) => {
    dispatch(setMainCard.request());

    ApiClient.billing.setMainCard({ id, companyId })
      .then((response) => dispatch(setMainCard.success(response, { id, companyId })))
      .catch((error) => dispatch(setMainCard.failure(error)));
  };
}

export const getPaymentReceipt = createAsyncAction(
  'BILLING_GET_PAYMENT_RECEIPT_REQUEST',
  'BILLING_GET_PAYMENT_RECEIPT_SUCCESS',
  'BILLING_GET_PAYMENT_RECEIPT_FAIL',
)();

export function getPaymentReceiptAsync({ id, ...data }) {
  return (dispatch) => {
    dispatch(getPaymentReceipt.request());

    ApiClient.billing.getPaymentReceipt(data)
      .then((response) => dispatch(getPaymentReceipt.success(response, { id, ...data })))
      .catch((error) => dispatch(getPaymentReceipt.failure(error)));
  };
}
