import { useField } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';
import MoneyInput from '../inputs/MoneyInput';

function MoneyInputField(props) {
  const [field, meta] = useField(props.name);

  return (
    <MoneyInput
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
    />
  );
}

MoneyInputField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default MoneyInputField;
