const LOKI_HREF = window.location.origin;

export default function redirectToTalkto({ redirectUrl, service = 'talkto' }) {
  const lokiHostName = window.location.hostname.split('.').slice(-2).join('.'); // get last two domains - talkto.io | teko.io
  const redirectUrlHostname = new URL(redirectUrl).hostname.split('.').slice(-2).join('.'); // get last two domains - talkto.io | teko.io
  if (lokiHostName === redirectUrlHostname && redirectUrl) {
    window.location.replace(redirectUrl);
  } else {
    window.location.replace(`${LOKI_HREF}/api/users/oauthRedirect?service=${service}&redirectUrl=${encodeURIComponent(redirectUrl)}`);
  }
}
