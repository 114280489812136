import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import * as reducers from '../reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { createLogger } = require('redux-logger');

  middlewares.push(createLogger({
    collapsed: true,
  }));
}

// const bigDataActionTypes = ['COMPANIES_GET_SUCCESS', 'PAYCAFE_GET_OUTLETS_SUCCESS', 'COMPANIES_GET_OUTLETS_SUCCESS', 'DICTIONARIES_GET_PRODUCTS_SUCCESS'];
//
// const actionSanitizer = (action) => (
//   bigDataActionTypes.includes(action.type) && action.payload ?
//     { ...action, payload: '<<LONG_DATA>>' } : action
// );
//
// const composeEnhancers = composeWithDevTools({
//   actionSanitizer,
//   stateSanitizer: (state) => {
//     const sanitizedState = { ...state };
//
//     if (Object.keys(state?.companies?.byId ?? {}).length > 100) {
//       sanitizedState.companies.byId = {};
//     }
//
//     if (Object.keys(state?.paycafe?.outlets ?? {}).length > 100) {
//       sanitizedState.paycafe.outlets = {};
//     }
//
//     if (state?.dictionaries?.products?.length > 100) {
//       sanitizedState.dictionaries.products = [];
//     }
//
//     return sanitizedState;
//   },
//   // options like actionSanitizer, stateSanitizer
// });

const composeEnhancers = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const reducer = combineReducers(reducers);

const store = createStore(
  (state, action) => {
    if (action.type === 'AUTH_UNAUTHORIZED') {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  },
  composeEnhancers(applyMiddleware(...middlewares)),
);

if (module.hot) {
  module.hot.accept('../reducers', () => {
    // eslint-disable-next-line global-require
    const reducers = require('../reducers');
    store.replaceReducer(combineReducers(reducers));
  });
}

export default store;
