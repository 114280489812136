import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import * as actions from '../actions/services';
import { asyncReducerFactory } from '../utils/redux-utils';
import normalize from '../utils/normalize';

const servicesTreeReducer = createReducer([])
  .handleAction(actions.getServicesTree.success, (state, { payload }) => payload);

const servicesCategoriesByIdReducer = createReducer([])
  .handleAction(actions.getServiceCategories.success, (state, { payload }) => normalize(payload));

const servicesByCategoryReducer = createReducer({})
  .handleAction(actions.getServicesByCategory.success, (state, { payload, meta }) => ({
    ...state,
    [meta.categoryId]: payload,
  }));

const connectedServicesByIdReducer = createReducer({})
  .handleAction(actions.getConnectedServices.success, (state, { payload }) => ({
    ...normalize(payload),
  }));

const servicesToPublishByIdReducer = createReducer({})
  .handleAction(actions.getServicesToPublish.success, (state, { payload }) => ({
    ...normalize(payload.products),
  }));

const publishedServicesByWarehouseReducer = createReducer({})
  .handleAction([actions.getPublishedServices.success, actions.getPublishedServices.failure], (state, { payload, meta }) => ({
    ...state,
    [meta.warehouse]: payload?.products ?? [],
  }));

export default combineReducers({
  servicesTree: servicesTreeReducer,
  servicesCategoriesById: servicesCategoriesByIdReducer,
  servicesByCategory: servicesByCategoryReducer,
  connectedServicesById: connectedServicesByIdReducer,
  servicesToPublishById: servicesToPublishByIdReducer,
  publishedServicesByWarehouse: publishedServicesByWarehouseReducer,
  getServicesTreeFetchStatus: asyncReducerFactory(actions.getServicesTree),
  getServiceCategoriesFetchStatus: asyncReducerFactory(actions.getServiceCategories),
  getServicesByCategoryFetchStatus: asyncReducerFactory(actions.getServicesByCategory),
  addServicesFetchStatus: asyncReducerFactory(actions.addServices),
  getConnectedServicesFetchStatus: asyncReducerFactory(actions.getConnectedServices),
  getServicesToPublishFetchStatus: asyncReducerFactory(actions.getServicesToPublish),
  getPublishedServicesFetchStatus: asyncReducerFactory(actions.getPublishedServices),
});
