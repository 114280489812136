import { useField } from 'formik';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from '@teko/ui-kit';

function TreeSelectField(props) {
  const [field, meta] = useField(props.name);

  return (
    <TreeSelect
      {...field}
      {...props}
      state={meta.error && meta.touched ? 'invalid' : undefined}
    />
  );
}

TreeSelectField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(TreeSelectField);
