import { createSelector } from 'reselect';

export const employeesSelector = createSelector(
  (state) => state.employees.byId,
  (byId) => Object.keys(byId).map((id) => byId[id]),
);

export const currentEmployeesSelector = createSelector(
  employeesSelector,
  (state) => state.app.currentCompanyId,
  (employees, currentCompanyId) => (
    currentCompanyId ? employees.filter((employee) => employee.companyId === currentCompanyId) : employees
  ),
);

export const companyEmployesSelector = createSelector(
  employeesSelector,
  (state) => state.app.currentCompanyId,
  (employees) => (
    employees
  ),
);

export const employeesByLoginSelector = createSelector(
  currentEmployeesSelector,
  (currentEmployees) => currentEmployees.reduce((acc, item) => {
    acc[item.login] = item;
    return acc;
  }, {}),
);

export const employeesTableSelector = createSelector(
  currentEmployeesSelector,
  (state) => state.companies.outletsByCompany,
  (employees, outlets) => employees.filter(({ companyId, outletId }) => outlets[companyId]?.[outletId]),
);

// export const employeesTableSelector = createSelector(
//   (state) => state.employees.table,
//   (state) => state.companies.outletsByCompany,
//   (state) => state.employees.byId,
//   (state) => state.app.currentCompanyId,
//   (tableIds, outlets, employees, currentCompanyId) => tableIds.filter((id) => {
//     const employee = employees[id];
//     const outlet = outlets[employee.companyId]?.[employee.outletId];
//
//     return outlet && (!currentCompanyId || currentCompanyId === employee.companyId);
//   }).map((id) => employees[id]),
// );

export const employeesByOutletsTableSelector = createSelector(
  (state) => state.employees.table,
  (state) => state.companies.outletsByCompany,
  (state) => state.employees.byId,
  (state) => state.companies.byId,
  (state) => state.app.currentCompanyId,
  (tableIds, outlets, employees, companies, currentCompanyId) => {
    const result = [];
    const map = new Map();

    for (const id of tableIds) {
      const employee = employees[id];
      const outlet = outlets[employee.companyId]?.[employee.outletId];

      if (!outlet || (currentCompanyId && currentCompanyId !== employee.companyId)) continue;

      const list = map.get(outlet) ?? [];

      if (list.length === 0) {
        map.set(outlet, list);
      }

      list.push(employee);
    }

    for (const [outlet, employees] of map.entries()) {
      const company = companies[outlet.globalId];

      result.push({ company, outlet, employees });
    }

    return result;
  },
);
