import { Button, Modal } from '@teko/ui-kit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getReferral } from '../../utils/getReferral';
import { useMetaViewport } from '../common/hooks';
import useReferrerUrls from '../common/hooks/useReferrerUrls';

const brandByReferral = {
  teko: 'Teko',
  talktoBusiness: 'Talkto Business',
};

const TALKTO_HREF = process.env.TALKTO_URL;

function InformationModal({ type, active, setActive, setHideModal }) {
  const { t } = useTranslation();
  const brand = brandByReferral[getReferral() || 'teko'] || 'Teko';
  const links = useReferrerUrls();

  const handleClose = () => {
    if (type === 'access-denied' || type === 'wrong-origin') return;

    localStorage.setItem('mobile-view-hint-is-closed', 'true');
    setActive?.(false);

    if (setHideModal) {
      setTimeout(() => setHideModal(true), 500);
    }
  };

  useEffect(() => {
    setActive?.(true);
  }, []);

  useMetaViewport('width=device-width, initial-scale=1, user-scalable=no');

  const btnProps = type === 'access-denied' || type === 'wrong-origin' ? {
    tag: 'a',
    rel: 'noreferrer noopener',
    href: (type === 'wrong-origin' ? links.loki : links.talkto) || TALKTO_HREF,
  } : { onClick: handleClose };

  return (
    <Modal
      className={classnames('info-modal', `type-${type}`)}
      active={active}
      onClose={handleClose}
    >
      <div className="info-modal-content">
        <p className="info-modal-description">
          {t(`${type}-desc`, { brand })}
        </p>

        <Button
          className="info-modal-button"
          design="primary"
          {...btnProps}
        >
          {t(`${type}-btn`)}
        </Button>
      </div>
    </Modal>
  );
}

InformationModal.propTypes = {
  type: PropTypes.oneOf(['mobile-view', 'access-denied', 'wrong-origin']).isRequired,
  links: PropTypes.object,
};

export default InformationModal;
