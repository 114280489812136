import { useContext } from 'react';
import { ThunderBotContext } from '../utils/ThunderBotProvider';

/**
 * @typedef {Object} ThunderBot
 * @property {function} openWidget
 * @property {function} closeWidget
 * @property {boolean} isActive
 */
/**
 * @returns {ThunderBot}
 */
function useThunderBot() {
  return useContext(ThunderBotContext);
}

export default useThunderBot;
