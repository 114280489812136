import { number } from 'currency-codes';
import moment from 'moment';

const phone = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/;

// TODO: libphonenumber-js
export function formatPhone(value) {
  if (!value) {
    return '';
  }

  if (phone.test(value)) {
    return value.replace(phone, '+$1 ($2) $3-$4-$5');
  }

  return value;
}

export function formatCurrency(value, options = {}) {
  const {
    currency = 'RUB',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    exp = 2,
  } = options;
  const formatter = new Intl.NumberFormat('ru', {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formatter.format(value / (10 ** exp));
}

/**
 * @param {MoneyAmount} moneyAmount
 * @param {number} [minimumFractionDigits=2]
 * @returns {string}
 */
export function formatMoneyAmount(moneyAmount, minimumFractionDigits = 2) {
  const {
    amount,
    units = 0,
    cur,
    currency = 643,
    exp = 2,
  } = moneyAmount;

  return formatCurrency(amount ?? units, { exp, currency: number((cur ?? currency).toString())?.code, minimumFractionDigits });
}

/**
 * @param {number} amount
 * @param {{ [currency]: number, [exp]: number }} [options={}]
 * @returns {MoneyAmount}
 */
export function formatToMoneyAmount(amount, options = {}) {
  const {
    currency = 643,
    exp = 2,
  } = options;

  return { amount, currency, exp };
}

export function formatNumber(value, options) {
  const formatter = new Intl.NumberFormat(options?.locale ?? 'ru', options);

  return formatter.format(value);
}

const translitMap = new Map([
  ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'], ['д', 'd'], ['е', 'e'], ['ё', 'e'], ['ж', 'zh'], ['з', 'z'],
  ['и', 'i'], ['й', 'i'], ['к', 'k'], ['л', 'l'], ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
  ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'], ['х', 'h'], ['ц', 'c'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'sh'],
  ['ъ', ''], ['ы', 'i'], ['ь', ''], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'], ['А', 'A'], ['Б', 'B'], ['В', 'V'],
  ['Г', 'G'], ['Д', 'D'], ['Е', 'E'], ['Ё', 'E'], ['Ж', 'Zh'], ['З', 'Z'], ['И', 'I'], ['Й', 'I'], ['К', 'K'],
  ['Л', 'L'], ['М', 'M'], ['Н', 'N'], ['О', 'O'], ['П', 'P'], ['Р', 'R'], ['С', 'S'], ['Т', 'T'], ['У', 'U'],
  ['Ф', 'F'], ['Х', 'H'], ['Ц', 'C'], ['Ч', 'Ch'], ['Ш', 'Sh'], ['Щ', 'Sh'], ['Ъ', ''], ['Ы', 'I'], ['Ь', ''],
  ['Э', 'E'], ['Ю', 'Yu'], ['Я', 'Ya'], [' ', '_'],
]);

// TODO: slugify(str, { separator: '_' });
// https://www.npmjs.com/package/transliteration#slugifystr-options

/**
 * @param {string} str
 * @returns {string}
 */
export function transliterate(str, lowercase = true) {
  const normalized = lowercase ? str.trim().toLowerCase() : str.trim();
  const result = new Array(normalized.length);
  let char;

  for (let i = 0; i < normalized.length; i++) {
    char = normalized[i];

    if (translitMap.has(char)) result[i] = translitMap.get(char);
    else result[i] = char;
  }

  return result.join('');
}

export function getSafeId(name, { replaceSpecialCharsWith = '_' } = {}) {
  return transliterate(name.replace(/["'`""«»]/g, '')).replace(/[^\w]/gi, replaceSpecialCharsWith);
}

export function getSafeUrlStr(url) {
  if (url.startsWith('http')) return url;
  if (url.startsWith('//')) return url.replace('//', 'https://');

  return `https://${url}`;
}

export function getDateAndTimeObject(timestamp) {
  if (!timestamp) return {};
  const momentObject = moment(timestamp);
  return { date: momentObject.format('DD.MM.YYYY'), time: momentObject.format('HH:mm') };
}

export function formatFileSize(bytes) {
  if (typeof bytes !== 'number') {
    return '';
  }
  if (bytes >= 1000000) {
    return `${(bytes / 1000000).toFixed(bytes % 1000000 === 0 ? 0 : 1)}&nbsp;МБ`;
  }

  return `${(bytes / 1000).toFixed(0)}&nbsp;КБ`;
}

export function capitalizeFirstChar(str) {
  return str ? (str.charAt(0).toUpperCase() + str.slice(1)) : '';
}

// pluralize
export function getNoun(number, one, two, five, returnWithNumber) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return returnWithNumber ? `${number} ${five}` : five;
  }
  n %= 10;
  if (n === 1) {
    return returnWithNumber ? `${number} ${one}` : one;
  }
  if (n >= 2 && n <= 4) {
    return returnWithNumber ? `${number} ${two}` : two;
  }
  return returnWithNumber ? `${number} ${five}` : five;
}

export function getPaymentId(payment) {
  return `${payment?.orderId || '0'}-${payment?.type || 'tariff'}`;
}

export function getDurationAsHHmm(durationInMs) {
  const momentDuration = moment.duration(durationInMs);
  const hours = momentDuration.hours();
  const minutes = momentDuration.minutes();
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function getTimeDurationHumanized(durationInMs, t) {
  const momentDuration = moment.duration(durationInMs);
  const hours = momentDuration.hours() > 0 ? `${momentDuration.hours()}${t ? t('common:time.hours-short') : 'ч'} ` : '';
  const minutes = momentDuration.minutes() > 0 ? `${momentDuration.minutes()}${t ? t('common:time.minutes-short') : 'мин'}` : '';
  return `${hours}${minutes}`;
}

export const getSafeIdStringComponent = (str) => transliterate(str).replace(/["'«»]/g, '').replace(/[^\w-]/gi, '_');
export const getSafeIdStringComponentFromUrl = (url) => new URL(getSafeUrlStr(url)).hostname.replace(/[&\\/=?|.]/g, '_').replace(/[^\w-]/gi, '');

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}
