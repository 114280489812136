import { Loader } from '@teko/ui-kit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPaycafeOutletsAsync, yoomoneyAuthAsync } from '../../actions/paycafe';
import { useAsyncStatus, useQueryParams } from '../common/hooks';

function YoomoneyAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams();
  const yoomoneyAuthFetchStatus = useAsyncStatus((state) => state.paycafe.yoomoneyAuthStatus);
  const paycafeOutletsFetchStatus = useAsyncStatus((state) => state.paycafe.paycafeOutletsFetchStatus);
  const outlets = useSelector((state) => state.paycafe.outlets);
  const outletId = queryParams.state.slice(0, queryParams.state.indexOf(':'));
  const clientId = queryParams.state.slice(queryParams.state.lastIndexOf(':') + 1);

  useEffect(() => {
    dispatch(getPaycafeOutletsAsync());
  }, []);

  useEffect(() => {
    if (paycafeOutletsFetchStatus.success) {
      dispatch(yoomoneyAuthAsync({ ...queryParams, outletId }));
    }
  }, [paycafeOutletsFetchStatus.success]);

  useEffect(() => {
    if (yoomoneyAuthFetchStatus.success) {
      const outlet = outlets[outletId];
      if (clientId === outlet.globalId) {
        history.push(`/lk/paycafe/outlets/${outletId}/payment-methods`);
      } else {
        history.push(`/lk/crm/clients/${clientId}/company/${outlet.globalId}/paycafe/outlets/${outlet.id}`);
      }
    }
  }, [yoomoneyAuthFetchStatus.success]);

  return (
    <div className="form-loader yoomoney">
      <Loader />
    </div>
  );
}

export default YoomoneyAuth;
