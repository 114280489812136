import { createSelector } from 'reselect';
import { isActiveCompanyStatus, isActiveOutletStatus } from '../utils/statuses';
import { acceptedCompaniesSelector, activeCompaniesSelector, companiesSelector, currentCompanySelector, currentOutletsSelector, outletsSelector } from './companies';
import { activePaycafeOutletsSelector, currentPaycafeOutletsSelector } from './paycafe';

const currentUserSelector = (state) => state.users.currentUser;

export const companiesStatusSelector = createSelector(
  companiesSelector,
  (companies) => ({
    hasCompanies: companies.length !== 0,
    hasActiveCompanies: companies.some(({ status }) => isActiveCompanyStatus(status)),
    hasAcceptedCompanies: companies.some(({ status }) => status === 'accepted'),
  }),
);

export const boxretailCompanies = createSelector(
  acceptedCompaniesSelector,
  outletsSelector,
  (companies, outletsByCompany) => companies.filter(
    (company) => (outletsByCompany[company.id] ?? []).some(
      (outlet) => isActiveOutletStatus(outlet.status),
    ),
  ),
);

export const boxretailStatusSelector = createSelector(
  currentCompanySelector,
  (state) => state.companies.companyInfoById,
  currentOutletsSelector,
  activeCompaniesSelector,
  boxretailCompanies,
  companiesSelector,
  currentUserSelector,
  (currentCompany, companyInfoById, currentOutlets, activeCompanies, companies, allCompanies, currentUser) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );
    const company = activeCompanies.length === 1 ? activeCompanies[0] : currentCompany;
    const outlets = activeCompanies.length === 1 ? currentOutlets.filter((outlet) => outlet.globalId === company.id) : currentOutlets;
    const companyInfo = companyInfoById[company?.id];
    const isActiveCompany = !!company && isActiveCompanyStatus(company.status);
    const isActive = !!currentCompany && isActiveCompany && outlets.length !== 0;

    return ({
      isInProgress: !isActive && !!company && (outlets.length !== 0 || companyInfo?.services?.length > 0),
      // || (!company && companiesStatus.hasActiveCompanies && !companiesStatus.hasAcceptedCompanies),
      isActive,
      isAvailableByPlan: company?.availableApps?.includes('boxretail_app') || currentUser.isCrmAdmin,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const paycafeCompanies = createSelector(
  acceptedCompaniesSelector,
  activePaycafeOutletsSelector,
  (companies, outlets) => {
    const merchants = new Set(
      outlets.flatMap((outlet) => outlet.merchants),
    );

    return companies.filter((company) => merchants.has(company.id));
  },
);

export const paycafeStatusSelector = createSelector(
  currentCompanySelector,
  (state) => state.companies.companyInfoById,
  currentPaycafeOutletsSelector,
  activeCompaniesSelector,
  paycafeCompanies,
  companiesSelector,
  currentUserSelector,
  (currentCompany, companyInfoById, currentOutlets, activeCompanies, companies, allCompanies, currentUser) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );
    const company = activeCompanies.length === 1 ? activeCompanies[0] : currentCompany;
    const outlets = activeCompanies.length === 1 ? currentOutlets.filter((outlet) => outlet.merchants.includes(company.id)) : currentOutlets;
    const companyInfo = companyInfoById[company?.id];
    // const isAcceptedCompany = !!company && company.companyStatus === 'accepted';
    const isAcceptedCompany = !!company && company.status === 'accepted';
    const isActive = !!currentCompany && isAcceptedCompany && outlets.length !== 0; // companyInfo?.kycInfoStatus === 'accepted'

    return {
      isInProgress: !isActive && !!company && (outlets.length !== 0 || companyInfo?.kycInfo),
      isActive,
      isAvailableByPlan: company?.availableApps?.includes('paycafe_app') || currentUser.isCrmAdmin,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    };
  },
);

export const awizaCompanies = createSelector(
  acceptedCompaniesSelector,
  (companies) => companies.filter(
    (company) => company.awizaInstalledAt,
  ),
);

export const awizaStatusSelector = createSelector(
  currentCompanySelector,
  currentUserSelector,
  companiesSelector,
  awizaCompanies,
  (company, currentUser, allCompanies, companies) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );

    return ({
      isActive: !!company?.awizaInstalledAt,
      isAvailableByPlan: company?.availableApps?.includes('awiza_app') || currentUser.isCrmAdmin,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const posterCompanies = createSelector(
  acceptedCompaniesSelector,
  (companies) => companies.filter(
    (company) => company.posterInstalledAt,
    //(company) => company.awizaEnabled,
  ),
);

export const posterStatusSelector = createSelector(
  currentCompanySelector,
  currentUserSelector,
  companiesSelector,
  posterCompanies,
  (company, currentUser, allCompanies, companies) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );

    return ({
      // isActive: !!company,
      isActive: false, //!!company?.posterInstalledAt,
      isAvailableByPlan: false, //company?.availableApps?.includes('poster_app') || currentUser.isCrmAdmin,
      availableCompanies: [], //allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const cdekCompanies = createSelector(
  acceptedCompaniesSelector,
  (companies) => companies.filter(
    (company) => company.cdekInstalledAt,
  ),
);

export const cdekStatusSelector = createSelector(
  currentCompanySelector,
  currentUserSelector,
  companiesSelector,
  cdekCompanies,
  (company, currentUser, allCompanies, companies) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );

    return ({
      isActive: !!company?.cdekInstalledAt,
      isAvailableByPlan: true, // TODO!!!
      // isAvailableByPlan: company?.availableApps?.includes('cdek_app') || currentUser.isCrmAdmin,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const myclientsCompanies = createSelector(
  acceptedCompaniesSelector,
  (companies) => companies.filter(
    (company) => company.myclientsInstalledAt,
  ),
);

export const myclientsStatusSelector = createSelector(
  currentCompanySelector,
  currentUserSelector,
  companiesSelector,
  myclientsCompanies,
  (company, currentUser, allCompanies, companies) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );

    return ({
      isActive: !!company?.myclientsInstalledAt,
      isAvailableByPlan: company?.availableApps?.includes('myclients_app') || currentUser.isCrmAdmin,
      // isAvailableByPlan: company?.availableApps?.includes('cdek_app') || currentUser.isCrmAdmin,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const talkToStatusSelector = createSelector(
  currentUserSelector,
  (currentUser) => ({
    isActive: !!currentUser?.talktoConnectedAt,
    isAvailableByPlan: true,
  }),
);

export const yaBusinessCompanies = createSelector(
  acceptedCompaniesSelector,
  (companies) => companies.filter(
    (company) => company.yandexBusinessInstalledAt,
  ),
);

export const yaBusinessStatusSelector = createSelector(
  currentCompanySelector,
  currentUserSelector,
  companiesSelector,
  yaBusinessCompanies,
  (company, currentUser, allCompanies, companies) => {
    const ids = new Set(
      companies.map(({ id }) => id),
    );

    return ({
      isActive: !!company?.yandexBusinessInstalledAt,
      isAvailableByPlan: true,
      availableCompanies: allCompanies.filter(({ id, status }) => !ids.has(id) && isActiveCompanyStatus(status)),
      companies,
    });
  },
);

export const appStatusSelector = createSelector(
  companiesStatusSelector,
  boxretailStatusSelector,
  paycafeStatusSelector,
  awizaStatusSelector,
  talkToStatusSelector,
  cdekStatusSelector,
  yaBusinessStatusSelector,
  myclientsStatusSelector,
  (companiesStatus, boxretail, paycafe, awiza, talkto, cdek, yabusiness, myclients) => ({
    ...companiesStatus,
    boxretail,
    paycafe,
    awiza,
    talkto,
    cdek,
    yabusiness,
    myclients,
  }),
);
