import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getOrders = createAsyncAction(
  'GET_ORDERS_REQUEST',
  'GET_ORDERS_SUCCESS',
  'GET_ORDERS_FAIL',
)();

export function getOrdersAsync(params) {
  return (dispatch) => {
    dispatch(getOrders.request());

    ApiClient.orders.getOrders(params)
      .then((response) => dispatch(getOrders.success(response)))
      .catch((error) => dispatch(getOrders.failure(error)));
  };
}

export const getOrderDetailsById = createAsyncAction(
  'GET_ORDER_DETAILS_BY_ID_REQUEST',
  'GET_ORDER_DETAILS_BY_ID_SUCCESS',
  'GET_ORDER_DETAILS_BY_ID_FAIL',
)();

export function getOrderDetailsByIdAsync(id) {
  return (dispatch) => {
    dispatch(getOrderDetailsById.request());

    ApiClient.orders.getOrderDetailsById({ id })
      .then((orders) => dispatch(getOrderDetailsById.success(orders)))
      .catch((error) => dispatch(getOrderDetailsById.failure(error)));
  };
}

export const getOrdersByClientId = createAsyncAction(
  'GET_ORDERS_BY_CLIENT_ID_REQUEST',
  'GET_ORDERS_BY_CLIENT_ID_SUCCESS',
  'GET_ORDERS_BY_CLIENT_ID_FAIL',
)();

export function getOrdersByClientIdAsync(params) {
  return (dispatch) => {
    dispatch(getOrdersByClientId.request());

    ApiClient.orders.getOrdersByClientId(params)
      .then((orders) => dispatch(getOrdersByClientId.success(orders, params)))
      .catch((error) => dispatch(getOrdersByClientId.failure(error)));
  };
}

// export const getOrderTransactions = createAsyncAction(
//   'GET_ORDER_TRANSACTIONS_REQUEST',
//   'GET_ORDER_TRANSACTIONS_SUCCESS',
//   'GET_ORDER_TRANSACTIONS_FAIL',
// )();
//
// export function getOrderTransactionsAsync(ids) {
//   return (dispatch) => {
//     dispatch(getOrderTransactions.request());
//
//     ApiClient.analytics.transactions({ search: ids.join(',') })
//       .then((orders) => dispatch(getOrderTransactions.success(orders)))
//       .catch((error) => dispatch(getOrderTransactions.failure(error)));
//   };
// }
