import useAsyncData from './useAsyncData';

const initial = { initial: true, loading: false, error: null, state: 'initial', success: false };
/**
 * @typedef {Object} AsyncStatus
 * @property {boolean} initial
 * @property {boolean} loading
 * @property {boolean} success
 * @property {any} error
 * @property {'initial'|'loading'|'loaded'|'error'} state
 */
/**
 * @param {Function} selector
 * @returns {AsyncStatus}
 */
const useAsyncStatus = (selector) => useAsyncData(selector, initial);

export default useAsyncStatus;
