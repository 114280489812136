import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSourcesAsync } from '../../../actions/dictionaries';

/**
 * @returns {SourceModel[]}
 */
function useSources() {
  const dispatch = useDispatch();
  const sources = useSelector((state) => state.dictionaries.sources);

  useEffect(() => dispatch(getSourcesAsync()), []);

  return sources;
}

export default useSources;
