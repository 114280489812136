import { H1, Hint } from '@teko/ui-kit';
import classnames from 'classnames';
import React, { memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOauthParams } from '../../actions/auth';
import ChevronLeftIcon from '../../assets/svg/chevron-left-white-30.svg';
import { getReferral } from '../../utils/getReferral';
import { useQueryParams, useReferrerUrls } from '../common/hooks';
import useHeadTitle from '../common/hooks/useHeadTitle';
import RegistrationCompletedContext from './RegistrationCompletedContext';
import RegistrationFormStepFour from './RegistrationFormStepFour';
import RegistrationFormStepOne from './RegistrationFormStepOne';
import RegistrationFormStepThree from './RegistrationFormStepThree';
import RegistrationFormStepTwo from './RegistrationFormStepTwo';

const INDICATOR_STEPS_ARR = new Array(3).fill(0);

function RegistrationForm() {
  const { t } = useTranslation('auth');
  useHeadTitle(t('registration'));
  const dispatch = useDispatch();
  const referrerUrls = useReferrerUrls();

  const [params] = useQueryParams();
  const { setRegistrationCompleted } = useContext(RegistrationCompletedContext);
  const oauthParams = useSelector((state) => state.auth.oauthParams);
  const [step, setStep] = useState(1);
  const email = useSelector((state) => state.auth.registration.email);
  const component = useMemo(() => {
    switch (step) {
      case 1:
      default:
        return <RegistrationFormStepOne email={email} onStepChange={setStep} />;
      case 2:
        return <RegistrationFormStepTwo email={email} onStepChange={setStep} />;
      case 3:
        return <RegistrationFormStepThree email={email} onStepChange={setStep} />;
      case 4:
        return <RegistrationFormStepFour />;
    }
  }, [step, email]);

  const handleBackClick = useCallback(() => setStep((prevStep) => prevStep - 1), []);

  useEffect(() => {
    const initialService = oauthParams?.service;
    const initialRedirectUrl = oauthParams?.redirectUrl;
    const brand = getReferral() || 'teko';
    const oldParams = JSON.parse(sessionStorage.getItem('authQueryParams'));

    if (brand === 'talkto' || brand === 'talktoBusiness') {
      sessionStorage.setItem('authQueryParams', JSON.stringify({
        ...oldParams,
        redirectUrl: referrerUrls.talkto,
        utmInfo: { ...oldParams?.utmInfo, service: 'talkto' },
      }));
      dispatch(setOauthParams({ service: 'talkto', redirectUrl: referrerUrls.talkto }));
    }

    return () => {
      if (brand === 'talkto' || brand === 'talktoBusiness') {
        sessionStorage.setItem('authQueryParams', JSON.stringify({
          ...oldParams,
          redirectUrl: initialRedirectUrl,
          utmInfo: { ...oldParams?.utmInfo, service: initialService },
        }));
        dispatch(setOauthParams({ service: initialService, redirectUrl: initialRedirectUrl }));
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (step === 4) {
      setRegistrationCompleted(true);
    }
  }, [step]);

  return (
    <div className="registration-form">
      <div className="registration-title-wrap">
        {step > 1 && step < 4 && (
          <button type="button" className="auth-back-btn" onClick={handleBackClick}>
            <ChevronLeftIcon />
          </button>
        )}
        {step < 4 && <H1 className="registration-title" bold dangerouslySetInnerHTML={{ __html: t('registration') }} />}
        {params.nonEmail && !email && (
          <div className="registration-hint">
            <Hint type="primary" withIcon={false} title={t('email-not-attached')}>
              {t('set-email-or-use-another-login')}
            </Hint>
          </div>
        )}
        {params.vkError && (
          <div className="registration-hint">
            <Hint type="primary" withIcon={false} title={t('vk-error')}>
              {t('complete-register-form')}
            </Hint>
          </div>
        )}
      </div>
      {step < 4 && (
        <div className="registration-step-indicator">
          {INDICATOR_STEPS_ARR.map((_, index) => (
            <div key={index.toString()} className={classnames('step-indicator-item', { _active: step === index + 1 })} />
          ))}
        </div>
      )}
      {component}
    </div>
  );
}

export default memo(RegistrationForm);
