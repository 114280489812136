import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { redirectIfNeeded } from './auth';

export const getUser = createAsyncAction(
  'USERS_ME_REQUEST',
  'USERS_ME_SUCCESS',
  'USERS_ME_FAIL',
)();

export function getUserAsync() {
  return (dispatch, getState) => {
    dispatch(getUser.request());

    ApiClient.users.me()
      .then(redirectIfNeeded(getState))
      .then((data) => dispatch(getUser.success(data)))
      .catch((error) => dispatch(getUser.failure(error)));
  };
}

export const getUsers = createAsyncAction(
  'USERS_GET_REQUEST',
  'USERS_GET_SUCCESS',
  'USERS_GET_FAIL',
)();

export function getUsersAsync(params) {
  return (dispatch, getState) => {
    if (params.id && getState().users.byId[params.id]) {
      return;
    }

    dispatch(getUsers.request());

    ApiClient.users.get(params)
      .then((data) => dispatch(getUsers.success(data)))
      .catch((error) => dispatch(getUsers.failure(error)));
  };
}

export const getUserById = createAsyncAction(
  'USERS_GET_BY_ID_REQUEST',
  'USERS_GET_BY_ID_SUCCESS',
  'USERS_GET_BY_ID_FAIL',
)();

export function getUserByIdAsync(id) {
  return (dispatch) => {
    dispatch(getUserById.request());

    ApiClient.users.getById(id)
      .then((data) => dispatch(getUserById.success(data)))
      .catch((error) => dispatch(getUserById.failure(error)));
  };
}

export const createUser = createAsyncAction(
  'USERS_CREATE_REQUEST',
  'USERS_CREATE_SUCCESS',
  'USERS_CREATE_FAIL',
)();

export function createUserAsync(user) {
  return (dispatch) => {
    dispatch(createUser.request());

    ApiClient.users.create(user)
      .then((data) => dispatch(createUser.success(data)))
      .catch((error) => dispatch(createUser.failure(error)));
  };
}

export const updateUser = createAsyncAction(
  'USERS_UPDATE_REQUEST',
  'USERS_UPDATE_SUCCESS',
  'USERS_UPDATE_FAIL',
)();

export function updateUserAsync(user) {
  return (dispatch) => {
    dispatch(updateUser.request());

    ApiClient.users.update(user)
      .then((data) => dispatch(updateUser.success(data)))
      .catch((error) => dispatch(updateUser.failure(error)));
  };
}

export const inviteUsers = createAsyncAction(
  'USERS_INVITE_REQUEST',
  'USERS_INVITE_SUCCESS',
  'USERS_INVITE_FAIL',
)();

export function inviteUsersAsync(data) {
  return (dispatch) => {
    dispatch(inviteUsers.request());

    ApiClient.users.invite(data)
      .then((data) => dispatch(inviteUsers.success(data)))
      .catch((error) => dispatch(inviteUsers.failure(error)));
  };
}

export const inviteUserAgain = createAsyncAction(
  'INVITE_USER_AGAIN_REQUEST',
  'INVITE_USER_AGAIN_SUCCESS',
  'INVITE_USER_AGAIN_FAIL',
)();

export function inviteUserAgainAsync(userEmail) {
  return (dispatch) => {
    dispatch(inviteUserAgain.request());

    ApiClient.users.inviteAgain(userEmail)
      .then((data) => dispatch(inviteUserAgain.success(data)))
      .catch((error) => dispatch(inviteUserAgain.failure(error)));
  };
}

export const deleteUserInvite = createAsyncAction(
  'DELETE_USER_INVITE_REQUEST',
  'DELETE_USER_INVITE_SUCCESS',
  'DELETE_USER_INVITE_FAIL',
)();

export function deleteUserInviteAsync(userEmail) {
  return (dispatch) => {
    dispatch(deleteUserInvite.request());

    ApiClient.users.deleteInvite(userEmail)
      .then((data) => dispatch(deleteUserInvite.success(data)))
      .catch((error) => dispatch(deleteUserInvite.failure(error)));
  };
}

export const getUserInvites = createAsyncAction(
  'GET_USER_INVITES_REQUEST',
  'GET_USER_INVITES_SUCCESS',
  'GET_USER_INVITES_FAIL',
)();

export function getUserInvitesAsync(params) {
  return (dispatch) => {
    dispatch(getUserInvites.request());

    ApiClient.users.getInvites(params)
      .then((data) => dispatch(getUserInvites.success(data)))
      .catch((error) => dispatch(getUserInvites.failure(error)));
  };
}

export const getAvailableUserRoles = createAsyncAction(
  'GET_AVAILABLE_USER_ROLES_REQUEST',
  'GET_AVAILABLE_USER_ROLES_SUCCESS',
  'GET_AVAILABLE_USER_ROLES_FAIL',
)();

export function getAvailableUserRolesAsync() {
  return (dispatch, getState) => {
    if (getState().users.getAvailableUserRolesStatus.state !== 'initial') {
      return;
    }

    dispatch(getAvailableUserRoles.request());

    ApiClient.users.getAvailableUserRoles()
      .then((data) => dispatch(getAvailableUserRoles.success(data)))
      .catch((error) => dispatch(getAvailableUserRoles.failure(error)));
  };
}

export const validateMFA = createAsyncAction(
  'VALIDATE_MFA_REQUEST',
  'VALIDATE_MFA_SUCCESS',
  'VALIDATE_MFA_FAIL',
)();

export function validateMFAAsync(data) {
  return (dispatch) => {
    dispatch(validateMFA.request());

    ApiClient.users.validateMFA(data)
      .then((data) => dispatch(validateMFA.success(data)))
      .catch((error) => dispatch(validateMFA.failure(error)));
  };
}

export const disableMFA = createAsyncAction(
  'DISABLE_MFA_REQUEST',
  'DISABLE_MFA_SUCCESS',
  'DISABLE_MFA_FAIL',
)();

export function disableMFAAsync(data) {
  return (dispatch) => {
    dispatch(disableMFA.request());

    ApiClient.users.disableMFA(data)
      .then((data) => dispatch(disableMFA.success(data)))
      .catch((error) => dispatch(disableMFA.failure(error)));
  };
}

export const getLoginHistory = createAsyncAction(
  'GET_LOGIN_HISTORY_REQUEST',
  'GET_LOGIN_HISTORY_SUCCESS',
  'GET_LOGIN_HISTORY_FAIL',
)();

export function getLoginHistoryAsync(params) {
  return (dispatch) => {
    dispatch(getLoginHistory.request());

    ApiClient.users.getLoginHistory(params)
      .then((data) => dispatch(getLoginHistory.success(data)))
      .catch((error) => dispatch(getLoginHistory.failure(error)));
  };
}

export const updateCurrentUser = createAsyncAction(
  'USERS_CURRENT_UPDATE_REQUEST',
  'USERS_CURRENT_UPDATE_SUCCESS',
  'USERS_CURRENT_UPDATE_FAIL',
)();

export function updateCurrentUserAsync(data) {
  return (dispatch) => {
    dispatch(updateCurrentUser.request());

    ApiClient.users.updateCurrent(data)
      .then((responseData) => dispatch(updateCurrentUser.success(responseData, data)))
      .catch((error) => dispatch(updateCurrentUser.failure(error)));
  };
}

export const skipQuickStart = createAsyncAction(
  'SKIP_QUICK_START_REQUEST',
  'SKIP_QUICK_START_SUCCESS',
  'SKIP_QUICK_START_FAIL',
)();

export function skipQuickStartAsync() {
  return (dispatch) => {
    dispatch(skipQuickStart.request());

    ApiClient.users.skipQuickStart()
      .then(() => dispatch(skipQuickStart.success()))
      .catch((error) => dispatch(skipQuickStart.failure(error)));
  };
}

export const finishOnboarding = createAsyncAction(
  'FINISH_ONBOARDING_REQUEST',
  'FINISH_ONBOARDING_SUCCESS',
  'FINISH_ONBOARDING_FAIL',
)();

export function finishOnboardingAsync() {
  return (dispatch) => {
    dispatch(finishOnboarding.request());

    ApiClient.users.finishOnboarding()
      .then(() => dispatch(finishOnboarding.success()))
      .catch((error) => dispatch(finishOnboarding.failure(error)));
  };
}

export const getReferrerUrls = createAsyncAction(
  'USERS_GET_REFERRER_URLS_REQUEST',
  'USERS_GET_REFERRER_URLS_SUCCESS',
  'USERS_GET_REFERRER_URLS_FAIL',
)();

export function getReferrerUrlsAsync(data) {
  return (dispatch) => {
    dispatch(getReferrerUrls.request());

    ApiClient.users.getReferrerUrls(data)
      .then((response) => dispatch(getReferrerUrls.success(response, data)))
      .catch((error) => dispatch(getReferrerUrls.failure(error)));
  };
}
