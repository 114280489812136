import { RadioButton } from '@teko/ui-kit';
import { useField } from 'formik';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function RadioButtonField({ name, options, disabled }) {
  const [field,, helpers] = useField(name);

  return useMemo(() => (
    <div className="radio-button-group">
      {options.map(({ label, value }) => (
        <div className="radio-button-wrap" key={value}>
          <RadioButton active={field.value === value} onClick={() => helpers.setValue(value)} disabled={disabled}>
            {label}
          </RadioButton>
        </div>
      ))}
    </div>
  ), [field.value, options]);
}

RadioButtonField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default RadioButtonField;
