import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';
import { showNotification } from './app';

export const getRutokenCertificates = createAsyncAction(
  'GET_RUTOKEN_CERTIFICATES_REQUEST',
  'GET_RUTOKEN_CERTIFICATES_SUCCESS',
  'GET_RUTOKEN_CERTIFICATES_FAIL',
)();

export function getRutokenCertificatesAsync(params) {
  return (dispatch) => {
    dispatch(getRutokenCertificates.request());

    ApiClient.boxretail.getRutokenCertificates(params)
      .then((response) => dispatch(getRutokenCertificates.success(response)))
      .catch((error) => dispatch(getRutokenCertificates.failure(error)));
  };
}

export const createRutokenCertificate = createAsyncAction(
  'CREATE_RUTOKEN_CERTIFICATE_REQUEST',
  'CREATE_RUTOKEN_CERTIFICATE_SUCCESS',
  'CREATE_RUTOKEN_CERTIFICATE_FAIL',
)();

export function createRutokenCertificateAsync(data) {
  return (dispatch) => {
    dispatch(createRutokenCertificate.request());

    ApiClient.boxretail.createRutokenCertificate(data)
      .then((response) => {
        const notification = {
          type: 'success',
          message: 'Создан сертификат RuToken',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(createRutokenCertificate.success(response));
      })
      .catch((error) => dispatch(createRutokenCertificate.failure(error)));
  };
}

export const updateRutokenCertificate = createAsyncAction(
  'UPDATE_RUTOKEN_CERTIFICATE_REQUEST',
  'UPDATE_RUTOKEN_CERTIFICATE_SUCCESS',
  'UPDATE_RUTOKEN_CERTIFICATE_FAIL',
)();

export function updateRutokenCertificateAsync(data) {
  return (dispatch) => {
    dispatch(updateRutokenCertificate.request());

    ApiClient.boxretail.updateRutokenCertificate(data)
      .then((response) => {
        const notification = {
          type: 'success',
          message: 'Отредактирован сертификат RuToken',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(updateRutokenCertificate.success(response, data));
      })
      .catch((error) => dispatch(updateRutokenCertificate.failure(error)));
  };
}

export const deleteRutokenCertificate = createAsyncAction(
  'DELETE_RUTOKEN_CERTIFICATE_REQUEST',
  'DELETE_RUTOKEN_CERTIFICATE_SUCCESS',
  'DELETE_RUTOKEN_CERTIFICATE_FAIL',
)();

export function deleteRutokenCertificateAsync(params) {
  return (dispatch) => {
    dispatch(deleteRutokenCertificate.request());

    ApiClient.boxretail.deleteCertificate(params)
      .then((response) => {
        const notification = {
          type: 'success',
          message: 'Удален сертификат RuToken',
          timeout: 5000,
        };

        dispatch(showNotification(notification));
        dispatch(deleteRutokenCertificate.success(response, params));
      })
      .catch((error) => dispatch(deleteRutokenCertificate.failure(error)));
  };
}
