import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletsAsync } from '../../../actions/companies';
import { getPaycafeOutletsAsync } from '../../../actions/paycafe';
import { appStatusSelector } from '../../../selectors/app';

/**
 * @returns {{
 *   hasCompanies: boolean,
 *   hasAcceptedCompanies: boolean,
 *   boxretail: { isInProgress: boolean, isActive: boolean, isAvailableByPlan: boolean, companies: CompanyModel[], availableCompanies: CompanyModel[] },
 *   paycafe: { isInProgress: boolean, isActive: boolean, isAvailableByPlan: boolean, companies: CompanyModel[], availableCompanies: CompanyModel[] }
 *   awiza: { isActive: boolean, isAvailableByPlan: boolean, }
 *   poster: { isActive: boolean, isAvailableByPlan: boolean, }
 * }}
 */
function useAppStatus() {
  const dispatch = useDispatch();

  useEffect(() => dispatch(getOutletsAsync()), []);
  useEffect(() => dispatch(getPaycafeOutletsAsync()), []);

  return useSelector(appStatusSelector);
}

export default useAppStatus;
