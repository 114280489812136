import { useCallback, useState } from 'react';

function useSearchInput() {
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const input = {
    value,
    onChange: useCallback((e) => setValue(e.target.value), []),
    onKeyDown: useCallback((e) => {
      if (e.key === 'Enter') {
        setSearch(e.target.value);
      }
    }, []),
  };

  return [search, input];
}

export default useSearchInput;
