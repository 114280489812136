import { parse } from 'qs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../actions/all';

function useUpdateNotifications() {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications.lastFetchedNotifications.array);

  useEffect(() => {
    if (notifications.length > 0) {
      for (const item of notifications) {
        if (!item.tag) continue;

        const split = item.tag.split('|');
        if (split.length > 1) {
          const match = item.tag.match(/(.*)\|{(.*)}/);
          if (match) {
            const action = actions[match[1].split('|')[1]];

            if (!action) continue;

            const requestParams = parse(match[2]);
            dispatch(action(requestParams));
          } else {
            const action = actions[split[1]];
            if (!action) continue;
            dispatch(action());
          }
        }
      }
    }
  }, [notifications]);
}

export default useUpdateNotifications;
