import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as actions from '../actions/orders';
import normalize from '../utils/normalize';
import { asyncReducerFactory } from '../utils/redux-utils';

const byIdReducer = createReducer({})
  .handleAction([actions.getOrders.success, actions.getOrdersByClientId.success], (state, { payload }) => ({
    ...state,
    ...normalize(payload.orders, 'orderId'),
  }));

const detailsByIdReducer = createReducer({})
  .handleAction(actions.getOrderDetailsById.success, (state, { payload }) => ({
    ...state,
    [payload.orderId]: payload,
  }));

const tableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getOrders.success, (state, { payload }) => ({
    ids: payload.orders.map((item) => item.orderId),
    totalCount: payload.totalCount ?? payload.total,
  }));

export default combineReducers({
  byId: byIdReducer,
  detailsById: detailsByIdReducer,
  table: tableReducer,
  fetchStatus: asyncReducerFactory(actions.getOrders),
  detailsFetchStatus: asyncReducerFactory(actions.getOrderDetailsById),
});
