import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { checkPromocode } from '../../actions/awiza';
import * as actions from '../../actions/awiza';
import normalize from '../../utils/normalize';
import { asyncReducerFactory } from '../../utils/redux-utils';

const promocodesByIdReducer = createReducer({})
  .handleAction(actions.getAwizaPromocodes.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.promocodes),
  }))
  .handleAction(actions.updatePartPromocode.success, (state, { payload, meta }) => ({
    ...state,
    [meta.id]: {
      ...state[meta.id],
      ...payload,
    },
  }));

const promocodesDetailsByIdReducer = createReducer({})
  .handleAction(actions.getAwizaPromocodeById.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const promocodesTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getAwizaPromocodes.success, (state, { payload }) => ({
    ids: payload.promocodes.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const discountsByIdReducer = createReducer({})
  .handleAction(actions.getAwizaDiscounts.success, (state, { payload }) => ({
    ...state,
    ...normalize(payload.discounts),
  }));

const discountsDetailsByIdReducer = createReducer({})
  .handleAction(actions.getAwizaDiscountById.success, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const discountsTableReducer = createReducer({ ids: [], totalCount: 0 })
  .handleAction(actions.getAwizaDiscounts.success, (state, { payload }) => ({
    ids: payload.discounts.map((item) => item.id),
    totalCount: payload.totalCount,
  }));

const uniqueCodesReducer = createReducer({})
  .handleAction(actions.checkPromocode.request, (state, { payload }) => ({
    ...state,
    [payload.code]: {
      loading: true,
      error: null,
      success: false,
      isOK: false,
    },
  }))
  .handleAction(actions.checkPromocode.failure, (state, { payload, meta }) => ({
    ...state,
    [meta.code]: {
      loading: false,
      error: payload,
      success: false,
      isOK: false,
    },
  }))
  .handleAction(actions.checkPromocode.success, (state, { payload, meta }) => ({
    ...state,
    [meta.code]: {
      loading: false,
      error: null,
      success: true,
      isOK: payload.isOK,
    },
  }));

export default combineReducers({
  promocodes: combineReducers({
    byId: promocodesByIdReducer,
    detailsById: promocodesDetailsByIdReducer,
    table: promocodesTableReducer,
    uniqueCodes: uniqueCodesReducer,
    getFetchStatus: asyncReducerFactory(actions.getAwizaPromocodes),
    getByIdFetchStatus: asyncReducerFactory(actions.getAwizaPromocodeById),
    createFetchStatus: asyncReducerFactory(actions.createAwizaPromocode),
    updateFetchStatus: asyncReducerFactory(actions.updateAwizaPromocode),
    deleteFetchStatus: asyncReducerFactory(actions.deletePromocode),
    checkFetchStatus: asyncReducerFactory(actions.checkPromocode),
  }),
  discounts: combineReducers({
    byId: discountsByIdReducer,
    detailsById: discountsDetailsByIdReducer,
    table: discountsTableReducer,
    getFetchStatus: asyncReducerFactory(actions.getAwizaDiscounts),
    getByIdFetchStatus: asyncReducerFactory(actions.getAwizaDiscountById),
    createFetchStatus: asyncReducerFactory(actions.createAwizaDiscount),
    updateFetchStatus: asyncReducerFactory(actions.updateAwizaDiscount),
  }),
});
