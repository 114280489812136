import { useLayoutEffect, useMemo, useRef } from 'react';

// Saves incoming handler to the ref in order to avoid "useCallback hell"
// export function useEventCallback<T>(handler?: (value: T) => void): (value: T) => void {
const useEventCallback = (fn) => {
  const ref = useRef(fn);
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useMemo(() => (...args) => {
    const { current } = ref;
    return current && current(...args);
  }, []);
};

export default useEventCallback;
