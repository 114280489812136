import React, { useLayoutEffect } from 'react';
import { getPageTitleSuffixByReferral } from '../../../utils/getReferral';

const noop = () => {};

/**
 * @param {string} title
 */
function useHeadTitle(title) {
  useLayoutEffect(() => {
    if (!title) return noop;

    window.document.title = `${title} | ${getPageTitleSuffixByReferral()}`;

    return noop;
  }, [title]);
}

export default useHeadTitle;
