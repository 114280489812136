import { useCallback, useEffect, useRef } from 'react';

function useAsyncCallback(asyncFunc, callback, deps = []) {
  const ref = useRef(true);

  useEffect(() => () => {
    ref.current = false;
  }, []);

  return useCallback((...args) => asyncFunc(...args).then((result) => {
    if (ref.current) {
      callback(result);
    }
  }), [...deps]);
}

export default useAsyncCallback;
