import { useMemo } from 'react';
import useAwizaCategories from './useAwizaCategories';

function useAwizaCategoriesOptions(withoutDefaultCategory = true) {
  const categories = useAwizaCategories(false, withoutDefaultCategory);

  const toOptions = (categories) => categories.map((item) => ({
    value: item.id,
    label: item.fullName,
    ...(item.subCategories?.length > 0 ? { children: toOptions(item.subCategories) } : null),
  }));

  return useMemo(
    () => toOptions(categories ?? []), [categories],
  );
}

export default useAwizaCategoriesOptions;
