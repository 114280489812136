function debounce(func, wait) {
  let timeout = 0;

  return (...args) => new Promise((resolve) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => resolve(func(...args)), wait);
  });
}

export default debounce;

export function debounceWithAsyncFunc(asyncFunc, wait) {
  let timeout = 0;

  return (...args) => new Promise((resolve, reject) => {
    clearTimeout(timeout);
    const thisTimeout = setTimeout(() => asyncFunc(...args)
      .then((result) => {
        if (thisTimeout === timeout) {
          resolve(result);
        }
      }, (error) => {
        if (thisTimeout === timeout) {
          reject(error);
        }
      }), wait);
    timeout = thisTimeout;
  });
}
