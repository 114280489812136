import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getBoxretailServicesContractsRequestsAsync } from '../../../actions/boxretail';
import { getOutletsAsync } from '../../../actions/companies';
import { getEmployeesAsync } from '../../../actions/employees';
import { getPaycafeOutletsAsync } from '../../../actions/paycafe';
import { getServicesTreeAsync } from '../../../actions/services';
import { boxretailServicesByIdSelector, boxretailServicesContractsRequestsByCompanyIdSelector } from '../../../selectors/boxretail';
import { employeesSelector } from '../../../selectors/employees';
import { paycafeOutletsSelector } from '../../../selectors/paycafe';

/**
 * @returns {{
 *   outlets: Array,
 *   [employees]: Array,
 * }}
 */
function useCompanyAppEntities(companyId, app, entityType, entityId = null) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allEmployees = useSelector(employeesSelector);
  const allPaycafeOutlets = useSelector(paycafeOutletsSelector);
  const { boxretailOutletsById, companyInfo, companiesById } = useSelector((state) => ({
    boxretailOutletsById: state.companies.outletsByCompany[companyId],
    companyInfo: state.companies.companyInfoById[companyId],
    companiesById: state.companies.byId,
  }), shallowEqual);
  const servicesById = useSelector(boxretailServicesByIdSelector);
  const servicesRequestsByCompanyId = useSelector(boxretailServicesContractsRequestsByCompanyIdSelector);
  const lastRequestedOutletsStateHashRef = useRef(null);

  useEffect(() => {
    const stateHash = app + entityType + entityId;

    if (entityType === 'outlets' && entityId && lastRequestedOutletsStateHashRef.current !== stateHash) {
      if (app === 'boxretail' && !boxretailOutletsById?.[entityId]) {
        lastRequestedOutletsStateHashRef.current = stateHash;
        dispatch(getOutletsAsync(true));
      } else if (app === 'paycafe' && !allPaycafeOutlets.find(({ id }) => id === entityId)) {
        lastRequestedOutletsStateHashRef.current = stateHash;
        dispatch(getPaycafeOutletsAsync(true));
      }
    }
  }, [app, allPaycafeOutlets, boxretailOutletsById, entityType, entityId]);

  useEffect(() => {
    if (app === 'boxretail') {
      const params = { companyId };
      dispatch(getEmployeesAsync(params));
      dispatch(getServicesTreeAsync(params));
      dispatch(getBoxretailServicesContractsRequestsAsync(params));
    }
  }, [app, companyId]);

  return useMemo(() => {
    if (app === 'paycafe') {
      return {
        outlets: allPaycafeOutlets.filter((outlet) => outlet.merchants.includes(companyId))
          .map((outlet) => ({ ...outlet, source: outlet.extra?.siteUrl || outlet.extra?.mobileAppUrl || outlet.extra?.whereToRead || 'Без сайта' })),
      };
    }
    const employees = allEmployees.filter((employee) => employee.companyId === companyId);
    return {
      outlets: Object.values(boxretailOutletsById ?? {}),
      employees,
      employeesTable: employees.map((employee) => ({
        ...employee,
        fullName: [employee.lastName, employee.firstName, employee.middleName].filter((it) => !!it).join(' '),
        position: t('role.cashier'),
        outletName: (boxretailOutletsById ?? {})[employee.outletId]?.fullName,
      })),
      services: (companyInfo?.services ?? []).map((service) => {
        const merchants = servicesById[service.id]?.merchants?.map((id) => ({
          id,
          fullName: getMerchantName(companiesById, id),
          status: getMerchantStatus(id, service, companyInfo),
        })).sort(merchantsSortByStatus) ?? [];

        return {
          ...service,
          ...servicesById[service.id],
          status: getServiceStatus(service.merchants),
          merchants,
        };
      }),
      requests: (() => {
        const servicesAndRequestsLoaded = Object.keys(servicesById).length > 0 && servicesRequestsByCompanyId[companyId];

        if (!servicesAndRequestsLoaded) return [];

        return servicesRequestsByCompanyId[companyId].map((request) => ({
          ...request,
          services: request.services.map((id) => servicesById[id]),
        }));
      })(),
    };
  }, [allEmployees, allPaycafeOutlets, boxretailOutletsById, companyId, companyInfo, companiesById, servicesById, servicesRequestsByCompanyId, app]);
}

function getMerchantName(companiesById, merchantId) {
  if (!companiesById[merchantId]) {
    console.warn(`[Merchant] Unknown merchant id: ${merchantId}`);
    return merchantId;
  }
  return companiesById[merchantId].name;
}

function getMerchantStatus(id, service, companyInfo) {
  if (service.merchants?.[id] === 'accepted') return 'accepted';
  if (companyInfo.serviceMerchants?.[id]?.status === 'accepted') return 'semiAccepted';
  return 'initial';
}

const statusNameToOrderMap = {
  accepted: 0,
  semiAccepted: 1,
  initial: 2,
};

function merchantsSortByStatus(a, b) {
  return statusNameToOrderMap[a.status] - statusNameToOrderMap[b.status];
}

function getServiceStatus(merchants) {
  // if (!merchants) return 'created';
  if (!merchants) return 'inProgress';
  if (Object.values(merchants).some((status) => status === 'finished' || status === 'accepted')) return 'finished';
  if (Object.values(merchants).some((status) => status === 'inProgress')) return 'inProgress';
  if (Object.values(merchants).some((status) => status === 'pending')) return 'pending';
  return 'declined';
}

export default useCompanyAppEntities;
