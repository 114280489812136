import { createAsyncAction } from 'typesafe-actions';
import { ApiClient } from '../utils/api-client';

export const getProducts = createAsyncAction(
  'DICTIONARIES_GET_PRODUCTS_REQUEST',
  'DICTIONARIES_GET_PRODUCTS_SUCCESS',
  'DICTIONARIES_GET_PRODUCTS_FAIL',
)();

export function getProductsAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.productsFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getProducts.request());

    ApiClient.analytics.getProducts()
      .then((data) => dispatch(getProducts.success(data)))
      .catch((error) => dispatch(getProducts.failure(error)));
  };
}

export const getSources = createAsyncAction(
  'DICTIONARIES_GET_SOURCES_REQUEST',
  'DICTIONARIES_GET_SOURCES_SUCCESS',
  'DICTIONARIES_GET_SOURCES_FAIL',
)();

export function getSourcesAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.sourcesFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getSources.request());

    ApiClient.analytics.getSources()
      .then((data) => dispatch(getSources.success(data.sources)))
      .catch((error) => dispatch(getSources.failure(error)));
  };
}

export const getProviders = createAsyncAction(
  'DICTIONARIES_GET_PROVIDERS_REQUEST',
  'DICTIONARIES_GET_PROVIDERS_SUCCESS',
  'DICTIONARIES_GET_PROVIDERS_FAIL',
)();

/**
 * @param {string} source
 */
export function getProvidersAsync(source) {
  return (dispatch, getState) => {
    if (getState().dictionaries.providers[source]) {
      return;
    }

    dispatch(getProviders.request());

    ApiClient.companies.getProviders(source)
      .then((data) => dispatch(getProviders.success(data, { source })))
      .catch((error) => dispatch(getProviders.failure(error)));
  };
}

export const getBanks = createAsyncAction(
  'DICTIONARIES_GET_BANKS_REQUEST',
  'DICTIONARIES_GET_BANKS_SUCCESS',
  'DICTIONARIES_GET_BANKS_FAIL',
)();

export function getBanksAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.banksFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getBanks.request());

    ApiClient.companies.banks()
      .then((data) => dispatch(getBanks.success(data.banks)))
      .catch((error) => dispatch(getBanks.failure(error)));
  };
}

export const getBpaBanks = createAsyncAction(
  'DICTIONARIES_GET_BPA_BANKS_REQUEST',
  'DICTIONARIES_GET_BPA_BANKS_SUCCESS',
  'DICTIONARIES_GET_BPA_BANKS_FAIL',
)();

export function getBpaBanksAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.bpaBanksFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getBpaBanks.request());

    ApiClient.companies.bpaBanks()
      .then((data) => dispatch(getBpaBanks.success(data.banks)))
      .catch((error) => dispatch(getBpaBanks.failure(error)));
  };
}

export const getMerchants = createAsyncAction(
  'DICTIONARIES_GET_MERCHANTS_REQUEST',
  'DICTIONARIES_GET_MERCHANTS_SUCCESS',
  'DICTIONARIES_GET_MERCHANTS_FAIL',
)();

export function getMerchantsAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.merchantsFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getMerchants.request());

    ApiClient.companies.merchants()
      .then((data) => dispatch(getMerchants.success(data.merchants)))
      .catch((error) => dispatch(getMerchants.failure(error)));
  };
}

export const getPaycafeCategories = createAsyncAction(
  'DICTIONARIES_GET_PAYCAFE_CATEGORIES_REQUEST',
  'DICTIONARIES_GET_PAYCAFE_CATEGORIES_SUCCESS',
  'DICTIONARIES_GET_PAYCAFE_CATEGORIES_FAIL',
)();

export function getPaycafeCategoriesAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.paycafeCategoriesFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getPaycafeCategories.request());

    ApiClient.paycafe.getCategories()
      .then((data) => dispatch(getPaycafeCategories.success(data)))
      .catch((error) => dispatch(getPaycafeCategories.failure(error)));
  };
}

export const getContracts = createAsyncAction(
  'DICTIONARIES_GET_CONTRACTS_REQUEST',
  'DICTIONARIES_GET_CONTRACTS_SUCCESS',
  'DICTIONARIES_GET_CONTRACTS_FAIL',
)();

export function getContractsAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.contractsFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getContracts.request());

    ApiClient.companies.getContracts()
      .then((data) => dispatch(getContracts.success(data)))
      .catch((error) => dispatch(getContracts.failure(error)));
  };
}

export const getPlansDictionary = createAsyncAction(
  'DICTIONARIES_GET_PLANS_REQUEST',
  'DICTIONARIES_GET_PLANS_SUCCESS',
  'DICTIONARIES_GET_PLANS_FAIL',
)();

export function getPlansDictionaryAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.plansFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getPlansDictionary.request());

    ApiClient.billing.getPlansDictionary()
      .then((data) => dispatch(getPlansDictionary.success(data)))
      .catch((error) => dispatch(getPlansDictionary.failure(error)));
  };
}

export const getVkCategories = createAsyncAction(
  'DICTIONARIES_GET_VK_CATEGORIES_REQUEST',
  'DICTIONARIES_GET_VK_CATEGORIES_SUCCESS',
  'DICTIONARIES_GET_VK_CATEGORIES_FAIL',
)();

export function getVkCategoriesAsync() {
  return (dispatch, getState) => {
    if (getState().dictionaries.vkCategoriesFetchStatus.state !== 'initial') {
      return;
    }

    dispatch(getVkCategories.request());

    ApiClient.awiza.getVkCategories()
      .then((data) => dispatch(getVkCategories.success(data.categories)))
      .catch((error) => dispatch(getVkCategories.failure(error)));
  };
}
